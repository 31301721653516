import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, CircularProgress } from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import MUIDataTable, { Responsive, SelectableRows } from "mui-datatables";
import * as React from "react";
import {GlobalDataContext } from "../contexts/GlobalDataContext";

export default function DoVerificationList() {
  const navigate = useNavigate();
  const { globalData } = React.useContext(GlobalDataContext);
  const [rows, setRows] = React.useState<any>([]);
  const [columns, setColumns] = React.useState<any>([]);
  const [mainState, setMainState] = React.useState<any>({
    error: "",
    isLoading: false,
  });

  const getAllDocumentStatus = async () => {
    setMainState({ ...mainState, isLoading: true });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/all`,
        {
          headers: {
                         'Authorization': `Bearer ${globalData?.accessToken}`
                       }
         }
      );
      // console.log("Response:", res.data);

      if (Array.isArray(res.data)) {
        const transformedData = res.data.map((item: any, index: number) => {
            return {
            transId: "ACAP-" + item[1],
            claim_registration_number: item[17],
            policy_number: item[16],
            name: item[19] + " " + item[18],
            peril_type: item[5],
            sub_status: item[6],
            status: item[20],
            date_loss: new Date(item[21]).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            };
        });

        setRows(transformedData);

        const transformedCols = [
          {
            name: "transId",
            label: "Trans ID",
            options: {
              filter: true, sort: true, filterType: "textField",
              customBodyRender: (value: any, tableMeta: any) => {
                const row = tableMeta.rowData;
                return (
                  <Button
                    size="small"
                    className="tp-custom-button-primary"
                    variant="contained"
                    onClick={() =>
                      window.open(
                        `${window.location.protocol}//${window.location.host}/document-validation/${value.split('-')[1]}`,
                        "_blank"
                      )
                    }
                  >
                    {value}
                  </Button>
                );
              },
            },
          },

          {
            name: "claim_registration_number",
            label: "Claim No.",
            options: { filter: true, sort: true, filterType: "textField" },
          },
          {
            name: "policy_number",
            label: "Policy No.",
            options: { filter: true, sort: true, filterType: "textField" },
          },
          {
            name: "date_loss",
            label: "Date of Loss",
            options: { filter: true, sort: true, filterType: "dateField" },
          },
          {
            name: "name",
            label: "Name",
            options: { filter: true, sort: true, filterType: "textField" },
          },
          {
            name: "peril_type",
            label: "Peril Type",
            options: { filter: true, sort: true },
          },
          {
            name: "status",
            label: "status",
            options: {
              filter: true, sort: true,
              setCellProps: () => ({
                style: { textAlign: "center", textTransform: "uppercase" },
              }),
            },
          },
          {
            name: "sub_status",
            label: "Sub Status",
            options: {
              filter: true, sort: true,
              setCellProps: () => ({
                style: { textAlign: "center", textTransform: "uppercase" },
              }),
            },
          },
        ];

        setColumns(transformedCols);
      } else {
        console.error("Unexpected response format:", res.data);
      }

      setMainState({ ...mainState, isLoading: false });
    } catch (err: any) {
      console.error("Error:", err);

      let errorMessage = "";
      if (err?.response?.status === 401) errorMessage = err?.response?.data;
      if (err?.response?.status === 400) errorMessage = err?.response?.data;

      setMainState({ error: errorMessage, isLoading: false });
    }
  };

  const options = {
    download: false,
    print: false,
    selectableRows: "none" as SelectableRows,
    responsive: "standard" as Responsive,
    resizableColumns: true,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              padding: "5px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "2px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              textAlign: "center",
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
      },
    });

  React.useEffect(() => {
    getAllDocumentStatus();
  }, []);

  return (
    <Container
      maxWidth={"xl"}
      sx={{
        mt: 12,
      }}
    >
      <Box
        sx={{
          my: 4,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={() => navigate("/")}
          disableRipple={true}
          aria-label="open"
          sx={{
            padding: 0,
          }}
        >
          <ArrowBackIcon sx={{ height: 28, width: 28 }} />
          <Typography sx={{ ml: 1 }}>Back</Typography>
        </IconButton>
        <Typography variant="h4">Do Verification List</Typography>
      </Box>
      <Box
        sx={{
          my: 4,
        }}
      >
        {!mainState.isLoading ? (
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={"Task List"}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Container>
  );
}
