import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import BusinessRulesTask from "../components/document_validation/checklist/BusinessRulesTask";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { formatDate } from "../utils/FormatDate";

interface CncData {
  id: number;
  transId: number;
  saveDate: string;
  type: string;
  policyNo: string;
  sender: string;
  status: number;
  emailBody: string;
  submittedDate: string;
  dateLoss: string;
}

export default function CncHistoryPublicPage() {
  const { globalData } = React.useContext(GlobalDataContext);
  const [cncHistory, setCncHistory] = React.useState<CncData[]>([]);
  const [searchParams] = useSearchParams();

  const policyNo = searchParams.get("policyNo");

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const getCncHIstory = async () => {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/cnn-ncc-history/public?policyNo=${policyNo}`
        )
        .then((res: any) => {
          const resData = res?.data?.data
          // const filteredCnc = Array.isArray(resData) ? resData.filter((el) => el.type === "CNC") : [];
          setCncHistory(resData)
        })
        .catch((err: any) => {});
    };
    getCncHIstory();
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth={"lg"} sx={{ my: 12 }}>
        <Card sx={{ minWidth: 275, my: 2 }}>
          <CardContent>
          <Typography variant="h4" className="tp-text-header-primary">
              CNC / NCC History
            </Typography>

            <hr/>
            
            <Stack direction={"row"} alignItems={"center"}>
              <Grid container spacing={2}>
                <Grid item md={10}>
                  <Typography variant="h5" className="tp-text-header-primary">
                    <span className="tp-text-default">
                      Policy No.: &ensp;
                    </span>
                    {policyNo}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>

            <br />

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Policy No</TableCell>
                    <TableCell align="right">Type</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Date Loss</TableCell>
                    <TableCell align="right">Date Generated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!cncHistory || cncHistory.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No CNC/NCC generated on this policy
                      </TableCell>
                    </TableRow>
                  ) : (
                    cncHistory.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.policyNo}
                        </TableCell>
                        <TableCell align="right">{row.type}</TableCell>
                        <TableCell align="right">
                          {row.status === 3 ? (
                            <Chip color="success" variant="outlined" label="Submitted" />
                          ) : row.status === 4 ? (
                            <Chip variant="outlined" color="warning" label="Cancelled" />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell align="right">{row.dateLoss}</TableCell>
                        <TableCell align="right">{row.saveDate}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
}
