import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
// import insurerPdf from '../assets/blank_insurer_reportv2.pdf';
import AWS from 'aws-sdk';
import axios from 'axios';
import boldFontUrl from '../assets/arial-bold.ttf';
import regularFontUrl from '../assets/arial.ttf';
import insurerPdf from '../assets/blank_insurer_report.pdf';
import { GlobalDataContext } from "../contexts/GlobalDataContext";
// import { RedeemTwoTone } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { CircularProgress, Typography } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import InsurerReportHistory from './InsurerReportHistory';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET!;
const REGION = process.env.REACT_APP_REGION!;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY!;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY!;

// Configure AWS S3
AWS.config.update({
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}


const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));


const GenerateInsurer = forwardRef(({ repairersParts, deId, repairersLabor, partsCart,
  totalAmount, laborPlusVat, totalPartsAmount, totalDepreciation, totalDiscountAmount,
  vat, totalDeductible, laborData, transId, perilType, vatChecked, tag, tpName, surveyedBy, surveyedOn }: any, ref) => {

  const [mainState, setMainState] = React.useState<{
    id: 0,
    transId: 0,
    saveDate: "",
    url: "",
  }[]>([]);

  // const [data, setData] = React.useState<any>([
  //   { Date: "November 20, 2024", Link: "https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/preview" },
  //   { Date: "August 20, 2024", Link: "https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/preview" }
  // ])
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);

  const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
  const [isRendering, setIsRendering] = useState(false);
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const costOfParts = (totalAmount - totalDiscountAmount) + vat; 
  const ourOfferGrossTotal = costOfParts + laborPlusVat;
  const repairersEstimate = repairersParts + repairersLabor;
  const repairersLaborVat = repairersLabor * 0.12;
  const repairersGrossAmount = repairersEstimate + repairersLaborVat;
  const ourOfferVat = laborPlusVat * 0.12;
  const approveGrossAmount = ourOfferGrossTotal + ourOfferVat;
  const approveGrossOfferMinusDepre = perilType === "Third Party" 
    ? approveGrossAmount
    : approveGrossAmount - totalDeductible - totalDepreciation;

  const diffPartsAmount = repairersParts - costOfParts;
  const diffLaborAmount = repairersLabor - laborPlusVat;
  const diffTotalAmount = repairersEstimate - ourOfferGrossTotal;
  const diffVatAmount = repairersLaborVat - ourOfferVat;
  const diffGrossAmount = repairersGrossAmount - approveGrossAmount;

  const diffPartsPercentage = (diffPartsAmount / repairersParts) * 100;
  const diffLaborPercentage = (diffLaborAmount / repairersLabor) * 100;
  const diffTotalPercentage = (diffTotalAmount / repairersEstimate) * 100;
  const diffVatPercentage = (diffVatAmount / repairersLaborVat) * 100;
  const diffGrossPercentage = (diffGrossAmount / repairersGrossAmount) * 100;
  const totalPartsMinusDiscountPlusVat = totalPartsAmount - totalDiscountAmount + vat;
  const totalPartsAfterDepreciation = totalPartsMinusDiscountPlusVat - totalDepreciation;

  const totalAdditionalCost = laborData.additionalCosts?.tinsmith + laborData.additionalCosts?.paintwork + laborData.additionalCosts?.materials + laborData.additionalCosts?.reform + laborData.additionalCosts?.outsideWorks + laborData.additionalCosts?.airconWorks + laborData.additionalCosts?.glassWorks + laborData.additionalCosts?.upholsteryWorks + laborData.additionalCosts?.mechanicalWorks + laborData.additionalCosts?.electricalWorks + laborData.additionalCosts?.miscellaneousCost + laborData.additionalCosts?.laborAdjustment;
  // const laborVat = vatChecked ? (laborData.totalLaborCost + totalAdditionalCost) * 0.12 : 0;
  const laborVat =  laborData.totalLaborCost * 0.12;
  const totalSumLaborCost = laborData.totalLaborCost + laborVat;


  const [currentPage, setCurrentPage] = useState(1); // 

 


  const generatePdfBytes = async () => {

    // console.log('labordata', laborData);

    // console.log('total labor cost', laborData.totalLaborCost);
    // console.log('perilTypeGenIn', perilType);
    try {
      const shopResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single`, {
        params: { transId, limit: 1 },
        headers: { 'Authorization': `Bearer ${globalData?.accessToken}` },
      },
      );

      let shopData = shopResponse.data.data;
      if (!shopData) {
        shopData = shopResponse.data;
      }
      // if (!shopData) {
      //   throw new Error('shopData is missing in the response');
      // }


      const policyResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia`, {
        params: { transId },
        headers: { 'Authorization': `Bearer ${globalData?.accessToken}` },
      });
      const policyData = policyResponse.data.data;
      // if (!policyData) {
      //   throw new Error('policyData is missing in the response');
      // }

      // Fetch CR data
      let crResponse;
      let result: any;
      if (perilType === "Third Party") {
        crResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/document/${transId}/certificate%20of%20registration`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        // Filter the received data by tag and get the item with the highest id
        const filteredData = crResponse.data.data.filter((item: any) => item.tag === tag && item.status === "2");
        const highestCertificateItem = filteredData.reduce((prev: any, current: any) => current.id > prev.id ? current : prev, { id: 0 });
        const highestCertificateId = highestCertificateItem.docId;
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-cr?transId=${transId}&id=${highestCertificateId}`, {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((response) => {
            result = response;
          })
          .catch((error) => {
            console.log('error', error);
          });

      
      } else {
        crResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/document/${transId}/certificate%20of%20registration`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        // Filter the received data by tag and get the item with the highest id
        const filteredData = crResponse.data.data.filter((item: any) => item.tag === tag && item.status === "2");
        const highestCertificateItem = filteredData.reduce((prev: any, current: any) => current.id > prev.id ? current : prev, { id: 0 });
        const highestCertificateId = highestCertificateItem.docId;
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-cr?transId=${transId}&id=${highestCertificateId}`, {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((response) => {
            result = response;
          })
          .catch((error) => {
            console.log('error', error);
          });



      }
  
     
      let crData = null;
      if (typeof result !== 'string') {
        crData = result?.data.data;
      }
      // console.log('crData', crData);
      // if (!crData) {
      //   throw new Error('crData is missing in the response');
      // }

      // Fetch claim data
      const claimResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration`, {
        params: { transId },
        headers: { 'Authorization': `Bearer ${globalData?.accessToken}` },
      });
      const claimData = claimResponse.data.data;

      // if (!claimData) {
      //   throw new Error('claimData is missing in the response');
      // }
      const existingPdfBytes = await fetch(insurerPdf).then((res) => res.arrayBuffer());
      const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer());
      const regularFontBytes = await fetch(regularFontUrl).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit)

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const boldFont = await pdfDoc.embedFont(boldFontBytes);
      const regularFont = await pdfDoc.embedFont(regularFontBytes);
      const { width, height } = firstPage.getSize();

      const drawReverseText = (text: any, endX: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        const textWidth = font.widthOfTextAtSize(text, 7);
        firstPage.drawText(text, {
          x: endX - textWidth,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      const drawReverseText2ndpage = (text: any, endX: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        const textWidth = font.widthOfTextAtSize(text, 7);
        secondPage.drawText(text, {
          x: endX - textWidth,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      // console.log('vatChecked', vatChecked);



      firstPage.drawText(`${claimData?.claimNumber ?? ''}`, {
        x: 131,
        y: height - 198,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${shopData?.vendorName ?? ''}`, {
        x: 131,
        y: height - 210,
        size: 7,
        color: rgb(0, 0, 0),
      });


      firstPage.drawText(`${policyData?.longName ?? ''}`, {
        x: 131,
        y: height - 236,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${claimData?.dateLoss ?? ''}`, {
        x: 131,
        y: height - 248,
        size: 7,
        color: rgb(0, 0, 0),
      });

   

      firstPage.drawText(`${crData?.plateNo ?? ''}`, {
        x: 131,
        y: height - 276,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(
        `${perilType === "Third Party" ? (crData?.make || '') : (policyData?.motorMaker || '')} ${perilType === "Third Party" ? (crData?.series || '') : (policyData?.model || '')}`,
        {
          x: 131,
          y: height - 288,
          size: 7,
          color: rgb(0, 0, 0),
        }
      );

      firstPage.drawText(`${perilType === "Third Party" ? (crData?.engineNo || '') : (policyData?.vehicleEngine || '')}`, {
        x: 131,
        y: height - 300,
        size: 7,
        color: rgb(0, 0, 0),
      });

      // surveyedby

      firstPage.drawText(`${toTitleCase(surveyedBy)} `, {
        x: 131,
        y: height - 316,
        size: 7,
        color: rgb(0, 0, 0),
      });

      //completed by
      firstPage.drawText(`${toTitleCase(globalData?.firstName)} ${toTitleCase(globalData?.lastName)} `, {
        x: 131,
        y: height - 328,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(new Date().toLocaleDateString(), {
        x: 456,
        y: height - 198,
        size: 7,
        color: rgb(0, 0, 0),
      });

      if (perilType === "Third Party") {
        firstPage.drawText(`Third Party Name: `, {
          x: 355,
          y: height - 210,
          size: 7,
          font: boldFont,
          color: rgb(0, 0, 0),
        });

        firstPage.drawText(`${tpName}`, {
          x: 456,
          y: height - 210,
          size: 7,
          color: rgb(0, 0, 0),
        });
      }


      firstPage.drawText(`${policyData?.policyNumber ?? ''}`, {
        x: 456,
        y: height - 235,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${perilType ?? ''}`, {
        x: 456,
        y: height - 247,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${perilType === "Third Party" ? '' : policyData?.interestDescription ?? ''}`, {
        x: 456,
        y: height - 275,
        size: 7,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${crData?.orDate ?? ''}`, {
        x: 456,
        y: height - 287,
        size: 7,
        color: rgb(0, 0, 0),
      });


      firstPage.drawText(`${perilType === "Third Party" ? (crData?.chassis || '') : (policyData?.vehicleChassis || '')}`, {
        x: 456,
        y: height - 300,
        size: 7,
        color: rgb(0, 0, 0),
      });

      //surevyed on

      firstPage.drawText(`${toTitleCase(surveyedOn)} `, {
        x: 456,
        y: height - 316,
        size: 7,
        color: rgb(0, 0, 0),
      });


      firstPage.drawText(new Date().toLocaleDateString(), {
        x: 456,
        y: height - 328,
        size: 7,
        color: rgb(0, 0, 0),
      });

      // Total Offer Part
      // firstPage.drawText(`${toTitleCase(globalData?.firstName)} ${toTitleCase(globalData?.lastName)}  ON ${new Date().toLocaleDateString()}`, {
      //   x: 156,
      //   y: height - 382,
      //   size: 7,
      //   color: rgb(0, 0, 0),
      // });
      // const test = 100;


      // Replace 'P' with '₱' in the drawReverseText calls
      drawReverseText(`₱${repairersEstimate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 382, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${approveGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 395, boldFont, rgb(0, 0, 0));
      // drawReverseText(`₱${ourOfferVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 411, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${totalDepreciation.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 407, boldFont, rgb(0, 0, 0));
      if (perilType !== "Third Party") {
        drawReverseText(`₱${totalDeductible.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 418, boldFont, rgb(0, 0, 0));
      }
      drawReverseText(`₱${approveGrossOfferMinusDepre.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 430, boldFont, rgb(0, 0, 0));


      // Cost of claims repairers part
      drawReverseText(`₱${repairersParts.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersLabor.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersEstimate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersLaborVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${repairersGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 386, height - 540, boldFont, rgb(0, 0, 0));

      // Cost of claims our offer part
      drawReverseText(`₱${costOfParts.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${laborPlusVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${ourOfferGrossTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${ourOfferVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${approveGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 458, height - 540, boldFont, rgb(0, 0, 0));

      // Cost of claims difference part
      drawReverseText(`₱${diffPartsAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffLaborAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffTotalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffVatAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`₱${diffGrossAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 537, height - 540, boldFont, rgb(0, 0, 0));



      // Cost of claims difference % part
      drawReverseText(`${isFinite(diffPartsPercentage) ? diffPartsPercentage.toFixed(2) : '-100'}`, 578, height - 493, regularFont, rgb(0, 0, 0));
      drawReverseText(`${isFinite(diffLaborPercentage) ? diffLaborPercentage.toFixed(2) : '-100'}`, 578, height - 502, regularFont, rgb(0, 0, 0));
      drawReverseText(`${isFinite(diffTotalPercentage) ? diffTotalPercentage.toFixed(2) : '-100'}`, 578, height - 516, boldFont, rgb(0, 0, 0));
      drawReverseText(`${isFinite(diffVatPercentage) ? diffVatPercentage.toFixed(2) : '-100'}`, 578, height - 525, regularFont, rgb(0, 0, 0));
      drawReverseText(`${isFinite(diffGrossPercentage) ? diffGrossPercentage.toFixed(2) : '-100'}`, 578, height - 540, boldFont, rgb(0, 0, 0));





      // Add a second page
      const secondPage = pages[1];

      let yPosition = height - 80;
      const lineHeight = 18;
      const drawText = (text: any, x: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        secondPage.drawText(text, {
          x,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };



      partsCart.forEach((part: any, index: any) => {
        drawText(`${index + 1}`, 33, yPosition); // No.
        drawText(`${part.qty}`, 74, yPosition); // Quantity
        drawText(`${part.name}`, 113, yPosition); // PartName
        if (part.condition === "Standard operating procedure when replacing parts") {
          drawText("Standard operating procedure", 227, yPosition);
          drawText("when replacing parts", 227, yPosition - 9); // Adjust the yPosition as needed
        } else {
          drawText(`${part.condition}`, 227, yPosition); // Condition
        }
        drawReverseText2ndpage(`₱${part.shopPrice === 0 || '' || null ? '0.00' : part.shopPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 355, yPosition); // Condition
        drawText(`${part.remarks === "" ? "" : '*' + part.remarks}`, 114, yPosition - 8); // remarks
        drawReverseText2ndpage(`₱${part.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 442, yPosition); // Amount
        drawText(`${part.discount === 0 ? '0' : part.discount}%`, 480, yPosition);
        drawText(`${part.depreciation === 0 ? '0' : part.depreciation}%`, 545, yPosition);

        yPosition -= lineHeight; // Move y position for the next entry
      });

      // Dynamic line positioning based on partsCart length
      const lineYPosition = yPosition - 5;
      secondPage.drawLine({
        start: { x: 29, y: lineYPosition },
        end: { x: 580, y: lineYPosition },
        thickness: 1,
        color: rgb(0, 0, 0),
      });

      // Dynamic positioning for totalPartsAmount
      const totalYPosition = lineYPosition - 15;

      // Add "Total Parts (P)" label in red bold font
      drawText("Sub Total", 350, totalYPosition, boldFont, rgb(0, 0, 0));
      drawText("- Parts Discount", 350, totalYPosition - 10, boldFont, rgb(0, 0, 0));
      drawText("+ Vat", 350, totalYPosition - 20, boldFont, rgb(0, 0, 0));

      drawText("Total Parts", 350, totalYPosition - 30, boldFont, rgb(0, 0, 0));
      // secondPage.drawLine({
      //   start: { x: 350, y: totalYPosition - 50 },
      //   end: { x: 580, y: totalYPosition - 50 },
      //   thickness: 1,
      //   color: rgb(0, 0, 0),
      // });
      drawText("- Total Depreciation", 350, totalYPosition - 40, boldFont, rgb(0, 0, 0));
      drawText("Total Parts after Depreciation", 350, totalYPosition - 50, boldFont, rgb(0, 0, 0));

      drawReverseText2ndpage(`₱${totalPartsAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, totalYPosition, boldFont, rgb(0, 0, 0));
      drawReverseText2ndpage(`₱${totalDiscountAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, totalYPosition - 10, boldFont, rgb(0, 0, 0));
      drawReverseText2ndpage(`₱${vat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, totalYPosition - 20, boldFont, rgb(0, 0, 0));
      drawReverseText2ndpage(`₱${totalPartsMinusDiscountPlusVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, totalYPosition - 30, boldFont, rgb(0, 0, 0));
      drawReverseText2ndpage(`₱${totalDepreciation.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, totalYPosition - 40, boldFont, rgb(0, 0, 0));
      drawReverseText2ndpage(`₱${totalPartsAfterDepreciation.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, totalYPosition - 50, boldFont, rgb(0, 0, 0));
      const thirdPage = pages[2];

      const drawText3 = (text: any, x: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        thirdPage.drawText(text, {
          x,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      const drawReverseText3rdpage = (text: any, endX: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
        const textWidth = font.widthOfTextAtSize(text, 7);
        thirdPage.drawText(text, {
          x: endX - textWidth,
          y,
          size: 7,
          font: font,
          color: color,
        });
      };

      let laborYPosition = height - 93; // Adjust this value as needed
      const laborLineHeight = 20;
      const textLaborLineHeight = 10;


      if (laborData && Array.isArray(laborData.rows)) {
        laborData.rows.forEach((row: any, index: any) => {
          const total = row.repair + row.replace + row.painting;
          drawText3(`${index + 1}`, 29, laborYPosition);
          drawText3(row.scopeOfWorks, 77, laborYPosition);
          drawText3(`${row.laborRemarks === "" ? "" : '*' + row.laborRemarks}`, 77, laborYPosition - 8); // remarks
          drawText3(row.repairCondition, 275, laborYPosition);
          drawReverseText3rdpage(`${row.repair.toFixed(2)}`, 380, laborYPosition);
          drawReverseText3rdpage(`${row.replace.toFixed(2)}`, 450, laborYPosition);
          drawReverseText3rdpage(`${row.painting.toFixed(2)}`, 518, laborYPosition);
          drawReverseText3rdpage(`${total.toFixed(2)}`, 580, laborYPosition);

          laborYPosition -= laborLineHeight;
        });
      }

      thirdPage.drawLine({
        start: { x: 29, y: laborYPosition },
        end: { x: 580, y: laborYPosition },
        thickness: 1,
        color: rgb(0, 0, 0),
      });

      // Draw totals
      laborYPosition -= 20;
      drawText3("Total Time (hrs)", 430, laborYPosition);
      drawReverseText3rdpage(`${laborData.totalTimeHours.toFixed(2)}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3("Labor Rates (₱/hr)", 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.laborRate.toFixed(2)}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3("Calculated Labor Cost", 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.calculatedLaborCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3("+ Paintwork Materials", 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.paintWorkMaterials.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Tinsmith Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.tinsmith.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Paintwork Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.paintwork.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Materials Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.materials.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Reform Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.reform.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Outside Work Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.outsideWorks.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Aircon Works Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.airconWorks.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Glass Works Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.glassWorks.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Upholstery Works Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.upholsteryWorks.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Mechanical Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.mechanicalWorks.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Electrical Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.electricalWorks.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Miscellaneous Cost`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.miscellaneousCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`Labor Adjustment`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborData.additionalCosts.laborAdjustment.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3(`VAT`, 430, laborYPosition);
      drawReverseText3rdpage(`₱${laborVat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition);

      laborYPosition -= textLaborLineHeight;
      drawText3("Total Labor Cost (₱)", 430, laborYPosition, boldFont);
      drawReverseText3rdpage(`₱${totalSumLaborCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 580, laborYPosition, boldFont);




      const pdfBytes = await pdfDoc.save();
      setPdfBytes(pdfBytes);


      // Directly preview the PDF after generating it
      previewPdf(new Uint8Array(pdfBytes), 1);
      return pdfBytes;
    } catch (error) {
      console.error("Error generating PDF bytes:", error);
      alert('Error generating PDF, please refresh the page and try again.');
    }
  };

  const uploadPdfToS3 = async (pdfBytes: Uint8Array) => {
    const s3 = new AWS.S3();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
    const fileName = `ACAP_Completed-In-house-Survey_${formattedDate}.pdf`;
    const folderName = `ACAP-${transId}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: `${folderName}/${fileName}`,
      Body: new Blob([pdfBytes], { type: 'application/pdf' }),
      ACL: 'public-read',
      ContentType: 'application/pdf',
    };

    try {
      const data = await s3.upload(params).promise();

      setUploadUrl(data.Location);
      return data.Location;
    } catch (error) {

      return null;
    }
  };

  const sendPostRequest = async (pdfUrl: string) => {
    const requestBody = {
      transId: deId,
      url: pdfUrl,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/insurer-report`, requestBody,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

    } catch (error) {

    }
  };

  const handleGenerateAndUploadPdf = async () => {

    const pdfBytes = await generatePdfBytes();

    if (pdfBytes != null) {
      const pdfUrl = await uploadPdfToS3(pdfBytes);
      if (pdfUrl) {
        await sendPostRequest(pdfUrl);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    generateAndUploadPdf: handleGenerateAndUploadPdf,
  }));


  const previewPdf = async (bytes: Uint8Array, pageNum: number) => {
    if (!bytes) return;

    if (isRendering) return; // Prevent overlapping render operations
    setIsRendering(true);

    const loadingTask = pdfjsLib.getDocument({ data: bytes });
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(pageNum);

    const viewport = page.getViewport({ scale: 1 });
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (canvas && context) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    }

    setIsRendering(false); // Mark rendering as completed
  };

  useEffect(() => {
    if (pdfBytes) {
      previewPdf(new Uint8Array(pdfBytes), currentPage); // Clone the pdfBytes for preview and specify the page number
    }
  }, [pdfBytes, currentPage]);

  // #region fetchData
  const fetchData = (endpoint: string) => {
    apiHandler(endpoint);
  };

  React.useEffect(() => {
    fetchData(`/api/acap/insurer-report/${deId}`);
  }, []);

  const apiHandler = async (endpoint: String) => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    )
      .then((res: any) => {

        setMainState(res.data);
      })
      .catch((err: any) => {
        let errorMessage = "";
        if (err?.response?.status === 401 || 400) {
          errorMessage = err?.response?.data;
        }
      });
  };


  const savePdf = () => {
    if (pdfBytes) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
      const fileName = `ACAP Insurer Report_${formattedDate}.pdf`;
      saveAs(blob, fileName);
      handleGenerateAndUploadPdf();

    } else {

    }
  };

  // Function to handle opening the new tab
  const openNewTab = (link: string) => {
    // URL you want to open in a new tab
    const url = link;
    window.open(url, '_blank');
  }

  return (
    <div style={{ justifyItems: "center" }}>

      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          sx={{ paddingLeft: "10px", paddingRight: "10px" }}
          variant="contained"
          onClick={async () => {
            setIsRendering(true);
            await generatePdfBytes();
          }}
          disabled={isRendering}
        >
          {isRendering ? <CircularProgress size={24} /> : "Preview PDF"}
        </Button>
        <Button disabled={!pdfBytes} size="large" variant="contained" onClick={() => setCurrentPage(1)}>
          Page 1
        </Button>
        <Button disabled={!pdfBytes} size="large" variant="contained" onClick={() => setCurrentPage(2)}>
          Page 2
        </Button>
        <Button disabled={!pdfBytes} size="large" variant="contained" onClick={() => setCurrentPage(3)}>
          Page 3
        </Button>
        <Button disabled={!pdfBytes} size="large" variant="contained" onClick={savePdf}>
          Save PDF
        </Button>
      </Stack>

      {/* <Stack direction="row" spacing={2}>
      <Button sx={{width: "70%"}} size="large" variant="contained" onClick={handleGenerateAndUploadPdf}>Generate, Upload & Send</Button>
      </Stack> */}
      <br />
      {/* {uploadUrl && <p>
        <Typography variant="h6"> Uploaded PDF URL: </Typography>
        <a href={uploadUrl} target="_blank" rel="noopener noreferrer">{uploadUrl}</a></p>} */}

      <canvas ref={canvasRef} style={{ border: '1px solid black', marginTop: '20px', width: '775px' }}></canvas>
      <br /><br />


      <InsurerReportHistory data={mainState} />
      <br /><br />
    </div>
  );
});

export default GenerateInsurer;
