import React from "react";
import {
  Typography,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef } from "mui-datatables";
import axios from "axios";
import CustomToolbarSelect from "./table_components/CustomToolbarSelect";
import {
  convertChannel,
  convertPriority,
  convertStatus,
} from "../../utils/dataMapping";
import { NotificationContext } from "../../contexts/NotificationContext";

export default function AcapTaskManagement({
  globalData,
  mainState,
  rows,
  processors,
  setProcessors,
  setRows,
}: any) {
  const { setNotification } = React.useContext(NotificationContext);

  // Define a custom search function that translates the "assignedTo" ID into a username
  const customSearch = (
    searchQuery: string,
    currentRow: any[],
    columns: any[]
  ): boolean => {
    const query = searchQuery.toLowerCase();

    for (let i = 0; i < currentRow.length; i++) {
      let cellValue = currentRow[i];

      // For the "assignedTo" column, convert the numeric id to the username.
      if (columns[i].name === "assignedTo") {
        const processor = processors.find(
          (p: any) => p.id === cellValue
        );
        if (
          processor &&
          processor.username.toLowerCase().includes(query)
        ) {
          return true;
        }
      } else {
        // For other columns, perform a simple string search.
        if (
          cellValue &&
          cellValue.toString().toLowerCase().includes(query)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    customSearch, // Use our custom search function here.
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} data={rows} />
    ),
  };

  // Handle changes in the Assigned To dropdown
  const handleChangeProcessor = async (rowId: number, newValue: number) => {
    try {
      // Update the server
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/change-processor/${rowId}?userId=${newValue}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );

      // Update the local state
      setRows((prevData: any) =>
        prevData.map((row: any) =>
          row.id === rowId ? { ...row, assignedTo: newValue } : row
        )
      );
    } catch (error: any) {
      console.error("Failed to update server:", error);
      setNotification({
        level: "error",
        showAlert: true,
        message: error?.response?.data || "Something went wrong",
      });
    }
  };

  const columns = [
    {
      name: "id",
      label: "TRANS-ID",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "sender",
      label: "Sender",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "saveDate",
      label: "Date Received",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => {
          const dataDisplay =
            value !== null ? convertPriority(value) : null;
          return (
            dataDisplay && (
              <Chip
                label={dataDisplay.component.label}
                size={dataDisplay.component.size}
                variant={dataDisplay.component.variant}
                color={dataDisplay.component.color}
              />
            )
          );
        },
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "channel",
      label: "Channel",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => {
          const dataDisplay =
            value !== null ? convertChannel(value) : null;
          return (
            dataDisplay && (
              <Chip
                label={dataDisplay.component.label}
                size={dataDisplay.component.size}
                variant={dataDisplay.component.variant}
                color={dataDisplay.component.color}
              />
            )
          );
        },
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const rowId = tableMeta.rowData[0]; // Assuming the first column is the row ID
          const assignedUser = processors.find((item: any) => item.id === value); // Find user by ID
    
          return ["Claims Admin"].includes(globalData?.role) ? (
            <FormControl fullWidth size="small">
              <InputLabel>Assigned To</InputLabel>
              <Select
                value={value}
                onChange={(e) => handleChangeProcessor(rowId, e.target.value)}
                label="Assigned To"
              >
                {processors.map((item: any, idx: number) => (
                  <MenuItem value={item.id} key={idx}>
                    {item.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <span>{assignedUser ? assignedUser.username : "N/A"}</span>
          );
        },
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
        filterOptions: {
          names: processors.map((item: any) => item.username), // Show usernames in filter dropdown
          logic(value: any, filters: any) {
            if (filters.length === 0) return true;
            const assignedUser = processors.find((item: any) => item.id === value);
            return assignedUser ? !filters.includes(assignedUser.username) : true;
          },
        },
        customFilterListOptions: {
          render: (value: any) => `Assigned To: ${value}`,
        },
      },
    }    
  ];



  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              marginLeft: "12px !important",
              marginRight: "12px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
              fontFamily: "Poppins",
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              marginLeft: "12px !important",
              marginRight: "12px !important",
              fontFamily: "Poppins",
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#a42a25 !important",
            },
          },
        },
      },
    });

  return (
    <>
      <Typography variant="h3" className="tp-text-header-primary">
        ACAP Task Management
      </Typography>
      <Typography sx={{ color: "#607274" }}></Typography>
      <hr />
      <br />
      <br />
      {mainState.isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title=""
              data={rows}
              columns={columns as MUIDataTableColumnDef[]}
              options={options}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}
