import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  Menu,
  ListItem,
  MenuItem,
  Modal,
  Select as MuiSelect,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ActionMeta, SingleValue } from "react-select";
import ClientTransaction from "./damage_evaluation/ClientTransaction";
import ForProcessor from "./damage_evaluation/ForProcessor";
// import FieldChecklistClec from "./processor_validation/FieldChecklistClec";
import ComputationSheet from "./ComputationSheet";
import { DEDetails as DEDetailsType } from "./DEDetails";
import DocumentTable from "./DocumentTable";
import GenerateInsurer from "./GenerateInsurer";
import ComputationSheetTotalLoss from "./ComputationSheetTotalLoss";
// import GenerateInsurerTotalLoss from "./GenerateInsurerTotalLoss";
import { IRepairShop } from "../types/RepairShops";
import { IUserDetail } from "../types/UserDetail";
import { IShopEstimate } from "../types/ShopEstimate";
// import EstimationTable from "./document_validation/EstimationTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { GlobalData, GlobalDataContext } from "../contexts/GlobalDataContext";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../api/FormatDate";
import TaskRemarks from "./activity_logs/TaskRemarks";
import { IOpenTask } from "../types/OpenTask";
import InfoIcon from "@mui/icons-material/Info";
import DocumentViewer from "./document_validation/DocumentViewer";
// import { String } from "aws-sdk/clients/cloudsearchdomain";
import { ReserveCodeKey, reserveCodeMapping } from "./reserveCodeMapping";
import { format } from 'date-fns';
import { CartItemCT, CartItemDE } from "../types/Cart";
import { set, update } from "lodash";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";
import { capitalizeAndReplaceUnderscore } from "../utils/stringUtils";

// Utility function
function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DamageEvaluationTask({
  // totalRepairersAmount,
  // setTotalRepairersAmount,
  // totalLaborAmount,
  // setTotalLaborAmount,
  selectedBrand,
  selectedModel,
  onBrandModelUpdate,
}: {
  // totalRepairersAmount: number;
  // setTotalRepairersAmount: React.Dispatch<React.SetStateAction<number>>;
  // setTotalLaborAmount: React.Dispatch<React.SetStateAction<number>>;
  // totalLaborAmount: number;
  selectedBrand: string | null;
  selectedModel: string | null;
  onBrandModelUpdate: (brand: string | null, model: string | null) => void;
}) {
  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(parsedValue)) {
      setter(parsedValue);
    }
  };

  type OptionType = { value: number; label: string };

  interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    saveDate?: string;
    transId?: string;
  }

  interface IShopItemTable {
    shopEstimate: {
      totalParts: string | null;
      totalLabors: string | null;
    } | null;
    headers: string[] | null;
    rows: Object[] | null;
  }

  interface SEDetails {
    data: {
      id: number;
      transId: number;
      vendorName: string;
      customerName: string | null;
      url: string;

      totalLabor: string | null;
      totalParts: string | null;
    };
  }

  interface IRepairShopDetails {
    data: string[];
    isShow: boolean;
  }

  interface ClaimOption {
    id: number;
    label: string;
    claimParticipant: string;
    tag: string;
    claimId: string;
    status: string;
  }

  interface AcapTaskResponseData {
    data: {
      policyNo: string;
      caseId: string;
      status: number;
      // ... other fields as needed
    };
    success: boolean;
  }

  const [ourOfferGrossTotal, setOurOfferGrossTotal] = React.useState<number>(0);
  const [totalDeductibleAmount, setTotalDeductibleAmount] =
    React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [laborRows, setLaborRows] = React.useState<LaborRow[]>([]);
  const [cart, setCart] = React.useState<CartItemDE[]>([]);
  const laborAndMaterials = laborPlusVat;

  const [lossReserveParts, setLossReserveParts] = React.useState<number>(0);
  const [lossReserveLabor, setLossReserveLabor] = React.useState<number>(0);
  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const { claimNo, docId } = location.state || {};
  const [claimNumber, setClaimNumber] = React.useState<string | null>(null);
  const [policyNo, setPolicyNo] = React.useState<string | null>(null);
  const { transId, deId } = useParams();
  const [deFromDb, setDeFromDb] = React.useState<any>({
    data: {},
  });
  const [shopItemTable, setShopItemTable] = React.useState({
    headers: [],
    rows: [],
  });
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [totalRepairersAmount, setTotalRepairersAmount] = React.useState(0);
  const [totalLaborAmount, setTotalLaborAmount] = React.useState(0);
  const [users, setUsers] = React.useState<IUserDetail[]>([]);
  const [perilTypeDe, setPerilTypeDe] = React.useState<string | null>(null);
  const [assignedUser, setAssignedUser] = React.useState<
    IUserDetail | undefined
  >({
    id: 0,
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    roleName: "",
  });
  const [openTasks, setOpenTasks] = React.useState<IOpenTask[]>([]);
  const [insuredName, setInsuredName] = React.useState<string | null>(null);
  const [perilType, setPerilType] = React.useState<string[]>([]);
  const [crDate, setCrDate] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openTaskHistoryAnchor = Boolean(anchorEl);
  const [premiumClasses, setPremiumClasses] = React.useState<string[]>([]);
  // const [selectedPeril, setSelectedPeril] = React.useState<string | null>(null);
  const [entries, setEntries] = React.useState<
    { id: string; premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[]
  >([]);
  const [oldEntries, setOldEntries] = React.useState<
    { id: string; premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[]
  >([]);
  const [cartCTrans, setCartCTrans] = React.useState<CartItemCT[]>([]);
  const [vatCTrans, setVatCTrans] = React.useState<number>(0);
  const [totalDiscountCTrans, setTotalDiscountCTrans] = React.useState<number>(0);
  const [laborPlusVatCT, setLaborPlusVatCT] = React.useState<number>(0);

  const handleOpenTaskHistoryClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenTaskHistoryClose = () => {
    setAnchorEl(null);
  };

  const [laborData, setLaborData] = React.useState({
    rows: [],
    laborRate: 410,
    totalTimeHours: 0,
    calculatedLaborCost: 0,
    paintWorkMaterials: 0,
    totalLaborCost: 0,
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [claimTypeId, setClaimTypeId] = React.useState<string | null>(null);
  const [claimOptions, setClaimOptions] = React.useState<ClaimOption[]>([]);
  const [pendingForOffer, setPendingForOffer] = React.useState<boolean>(false);
  const [isVat, setIsVat] = React.useState<boolean>(false);

  const [perilLabel, setPerilLabel] = React.useState<string[]>([]);

  const handleVatCheckedChange = (vatChecked: boolean): void => {
    setIsVat(vatChecked);
  };

  const insurerRef = React.useRef<any>(null);

  const handleExternalCall = () => {

    if (insurerRef.current) {
      insurerRef.current.generateAndUploadPdf(); // Call the exposed method
    }
  };

  const handleLaborDataChange = (newLaborData: any) => {
    setLaborData(newLaborData);
  };
  const formatNumber = (value: number) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const parseNumber = (value: string) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const [selectedClaimType, setSelectedClaimType] = React.useState<string>("");

  const [initialTotalParts, setTotalParts] = React.useState<number>(0);
  const [initialTotalLabor, setTotalLabor] = React.useState<number>(0);
  const [deDetails, setDeDetails] = React.useState<DEDetailsType | null>(null);
  const [shopEstimate, setShopEstimate] = React.useState<SEDetails | null>(
    null
  );

  const handleUpdate = (parts: number, labor: number) => {
    setTotalParts(parts);
    setTotalLabor(labor / 1.12);
  };

  const [raEmail, setRaEmail] = React.useState<string | null>(null);

  const handleRaEmailValueChange = (newEmail: string) => {
    setRaEmail(newEmail);
  };



  const [isTotalLoss, setIsTotalLoss] = React.useState<boolean>(false);
  const [isResend, setIsResend] = React.useState<boolean>(false);
  const [syncAW, setSyncAW] = React.useState<boolean>(false);
  const [tag, setTag] = React.useState<string>("insured");
  const [tpName, setTpName] = React.useState<string | null>(null);
  const [tpLabel, setTpLabel] = React.useState<string | null>(null);
  const [claimType, setClaimType] = React.useState<string | null>(null);
  const [docValiStatus, setDocValiStatus] = React.useState<String | null>(null);
  const [vatValue, setVatValue] = React.useState<number>(0);
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const [assignTaskLoading,setAssignTaskLoading] = React.useState(false);



  const handleIsTotalLossChange = (percentLoss: number) => {
    const newIsTotalLoss = percentLoss >= 75;
    setIsTotalLoss(newIsTotalLoss);
  };

  const [totalLoss, setTotalLoss] = React.useState<string>("Not Total Loss");

  const handleTotalLossChange = (newTotalLoss: string) => {
    setTotalLoss(newTotalLoss);
  };

  const updateTotalDeductible = (amount: number, totalNumber: number) => {
    const totalDeductible = amount * totalNumber;
    const currentPeril = perilTypeDe || perilType[0];
    if (currentPeril === "Third Party") {
      setTotalDeductibleAmount(0);
    } else {
      setTotalDeductibleAmount(totalDeductible);
    }
  };

  // console.log("totalDeductibleAmount", totalDeductibleAmount);
  const handleUpdateCart = (newCart: CartItemDE[]) => {
    setCart(newCart);
  };

  const updateGrossTotal = (amount: number, laborVat: number) => {
    setTotalAmount(amount);
    setLaborPlusVat(laborVat);
    const grossTotal = amount + laborVat;
    setOurOfferGrossTotal(grossTotal);
  };

  const [repairShopDetails, setRepairShopDetails] =
    React.useState<IRepairShopDetails>({
      data: [],
      isShow: false,
    });

  const [repairShops, setRepairShops] = React.useState<string[]>([]);
  const [vendorList, setVendorList] = React.useState<IRepairShop[]>([]);
  const [dol, setDol] = React.useState<string | null>(null);

  const handleShowRepairShopDetails = () => {

    const dataFound =
      vendorList.find(
        (item: IRepairShop) => item.name === mainState?.data?.vendorName
      ) || null;

    const dataList = dataFound !== null ? dataFound.remarks.split("\n") : [];

    setRepairShopDetails({
      data: dataList,
      isShow: true,
    });
  };






  // #region fetchClaimRegistrationData useeffect
  const fetchClaimRegistrationData = async (newtag: string = "insured") => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const claimData = data.data;

      // Update basic claim registration data
      setDol(claimData.dateLoss);
      setClaimNumber(claimData.claimNumber);
      setClaimRegistrationData(claimData);

      // Build the array of premium classes
      const premiumClassesLocal = [
        claimData.premiumClass01,
        claimData.premiumClass02,
        claimData.premiumClass03,
        claimData.premiumClass04,
        claimData.premiumClass05,
        claimData.premiumClass06,
        claimData.premiumClass07,
        claimData.premiumClass08,
        claimData.premiumClass09,
        claimData.premiumClass10,
      ];
      setPremiumClasses(premiumClassesLocal);

      // Set peril type based on premium classes
      // if (premiumClasses.includes("202") && premiumClasses.includes("206")) {
      //   console.log("perilTypeDe", perilTypeDe);



      // Process the entries
      const newEntries = [];
      for (let i = 1; i <= 10; i++) {
        const index = String(i).padStart(2, '0');
        const premiumClassKey = `premiumClass${index}`;
        const reserveCodeKey = `reserveCode${index}`;
        const balanceOutstandingKey = `balanceOutstanding${index}`;
        const codeDescriptionKey = `codeDesc${index}`;
        const tag = claimData[`tag${index}`];

        const premiumClass = claimData[premiumClassKey];
        const reserveCode = claimData[reserveCodeKey];
        const balanceOutstanding = claimData[balanceOutstandingKey];
        const codeDescription = claimData[codeDescriptionKey];

        if (premiumClass && reserveCode && balanceOutstanding && codeDescription) {
          newEntries.push({
            id: index,
            premiumClass,
            reserveCode,
            tag,
            estimationAmount: balanceOutstanding,
            codeDesc: codeDescription,
          });
        }
      }

      // console.log("fetchClaimRegistrationData: ", newEntries);

      setEntries(newEntries.filter(entry => entry.tag === newtag));
      setOldEntries(newEntries.filter(entry => entry.tag !== newtag));

    } catch (error) {
      console.error("Error fetching claim registration data:", error);
    }
  };


  React.useEffect(() => {
    fetchClaimRegistrationData();
  }, []);



  React.useEffect(() => {
    if (claimTypeId && claimOptions.length > 0) {
      // Build the full string that is used in the options.
      const storedValue = claimTypeId; // e.g., "469 - VTPL - PROPERTY DAMAGE"
      // Find a matching option from the filtered data.
      const matchingOption = claimOptions.find(
        (option) => `${option.id} - ${option.label}` === storedValue
      );
      if (matchingOption) {
        setSelectedClaimType(storedValue);
        setTpName(matchingOption.claimParticipant);
        setIsResend(matchingOption.claimId !== null && matchingOption.claimId !== undefined && globalData?.role === "Clec");
        setSyncAW(matchingOption.claimId !== null && matchingOption.claimId !== undefined && globalData?.role !== "Clec");
        setPendingForOffer(matchingOption.status=== "9");
      } else {
        // Optionally, if no match is found, you can set a fallback value
        setSelectedClaimType(storedValue);
      }
    }
  }, [claimTypeId, claimOptions]);

  function toTitleCase(str: string | undefined | null): string {
    if (!str) {
      return "";
    }
    return str.replace(/\w\S*/g, (word) =>
      word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
  }


  const transformPerilType = (perilTypeString: string): string => {
    if (!perilTypeString) return "";
    const parts = perilTypeString.split(" - ");
    // Use the part after the dash and convert it to title case
    return parts.length > 1 ? toTitleCase(parts[1]) : toTitleCase(perilTypeString);
  };


  React.useEffect(() => {
    // If there is no value from the DB but we have a single claim option,
    // default selectedClaimType to that option.
    if (!claimTypeId && claimOptions.length === 1) {
      const defaultOption = claimOptions[0];
      const fullValue = `${defaultOption.id} - ${defaultOption.label}`;
      setSelectedClaimType(fullValue);

      // if (defaultOption.label.toLowerCase() === "own damage") {
      //   setPerilType(["Own Damage"]);
      // } else {
      //   setPerilType(["Third Party"]);
      // }
      setTpName(defaultOption.claimParticipant);
    }
  }, [claimTypeId, claimOptions]);



  React.useEffect(() => {
    if (deDetails && deDetails.perilType) {
      const transformed = transformPerilType(deDetails.perilType);
      // Only update if the new value differs from the current state.
      if (transformed !== perilTypeDe) {
        setPerilTypeDe(transformed);
        setPerilType([transformed]);
      }
    } else if ((!claimTypeId || claimTypeId === "null") && claimOptions.length === 1) {
      const defaultOption = claimOptions[0];
      const computedPeril =
        defaultOption.label.toLowerCase() === "own damage"
          ? "Own Damage"
          : defaultOption.label.toLowerCase() === "theft - partial theft"
            ? "Partial Theft"
            : defaultOption.label.toLowerCase() === "acts of nature"
              ? "Acts of Nature"
              : defaultOption.label.toLowerCase() === "vtpl - property damage"
              ? "Third Party"
              : "N/A";
      if (computedPeril !== perilTypeDe) {
        setPerilTypeDe(computedPeril);
        setPerilType([computedPeril]);
      }
    }
  }, [deDetails, claimTypeId, claimOptions, perilTypeDe]);









  // #region fetchClaimData

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<AcapTaskResponseData>(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/task/${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        const fetchedPolicyNo = response.data.data.policyNo;
        // const fetchedCaseId = response.data.data.caseId;

        // const fetchedStatus = response.data.data.status;



        setPolicyNo(fetchedPolicyNo);

        let crData: any = {};

        try {
          const crResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/document/${transId}/certificate%20of%20registration`,
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );

          // console.log("crResponse", crResponse.data.data);

          const filteredData = crResponse.data.data.filter((item: any) => item.tag === "insured" && item.status === "2");
          const highestCertificateItem = filteredData.reduce((prev: any, current: any) => current.id > prev.id ? current : prev, { id: 0 });
          const highestCertificateId = highestCertificateItem.docId;
          await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-cr?transId=${transId}&id=${highestCertificateId}`, {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          })
            .then((response) => {
              crData = response.data.data.orDate;

            })
            .catch((error) => {
              console.log('error', error);
            });

          // if (
          //   Array.isArray(crResponse.data.data) &&
          //   crResponse.data.data.length > 0
          // ) {
          //   crData = crResponse.data.data[0].orDate;
          // } else {
          //   crData = crResponse.data.data.orDate;
          // }
          // console.log("crData", crData);
          setCrDate(crData);
        } catch (error) {
          if (
            axios.isAxiosError(error) &&
            error.response &&
            error.response.status === 400
          ) {
            crData = {}; // Make crData blank if the response status is 400
          } else {
            throw error; // Re-throw other errors
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {

    const fetchDocumentValidationData = async () => {
      try {
        // Only run if tag is not empty (which you get from DE details)
        if (!tag) {
          console.warn("Tag not set yet; skipping document validation fetch.");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const validationData = await response.json();

        const filteredData = validationData.data.filter((item: any) => {
          return (
            (item.perilType === "206 - VTPL - PROPERTY DAMAGE" ||
              item.perilType === "202 - OWN DAMAGE" ||
              item.perilType === "203 - THEFT - Partial Theft" ||
              item.perilType === "209 - ACTS OF NATURE")
          );
        });
        console.log("filteredData", filteredData);
        // setClaimTypeId(filteredData.map((item: any) => item.id));
        const options: ClaimOption[] = filteredData.map((item: any) => {
          const label = item.perilType.replace(/^\d+\s*-\s*/, "");
          return { id: item.id, label, claimParticipant: item.claimParticipant, tag: item.tag, claimId: item.claimtypeId, status: item.status.statusNo };
        });
        console.log("claimOptions", options);
        setClaimOptions(options);

      } catch (error) {
        console.error("Error fetching document validation data:", error);
      }
    };


    fetchDocumentValidationData();
  }, [tag, transId, perilTypeDe, perilType]);




  React.useEffect(() => {
    const fetchInsured = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        const fetchedInsuredName =
          response.data.data.givenName + " " + response.data.data.surName;
        setInsuredName(fetchedInsuredName);
      } catch (error) {
        console.error("Error fetching task:", error);
      }
    };

    fetchInsured();
  }, []);




  // #region fetchRepairShops
  const fetchRepairShops = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/repair-shops`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const data = res.data.data;

            const tdata = data.map((el: any) => {
              return el.name;
            });
            setRepairShops(tdata);
            setVendorList(data);
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // region fetchAllImsiAdminUsers
  const fetchAllUsers = async () => {
    try {
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/available-users?userId=${globalData?.id}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const usersData: IUserDetail[] = usersResponse?.data.data;

      setUsers(usersData);

      const openTasksResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/open-task/?transId=${transId}&deId=${deId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const openTasksData: IOpenTask[] = openTasksResponse?.data?.data;
      setOpenTasks(openTasksData);


      if (openTasksData && usersData) {
        const selectedUserId = openTasksData[0].assignTo;
        const selectedUser = usersData?.find(
          (user: IUserDetail) => user?.id === Number(selectedUserId)
        );

        setAssignedUser(selectedUser);
      }
    } catch (err) {
      console.error("Error fetching Users:", err);
    }
  };

  const handleImsiAdminUserChange = (event: SelectChangeEvent<string>) => {
    const selectedUserId = event.target.value;
    const selectedUser: IUserDetail | undefined = users?.find(
      (user: IUserDetail) => user?.id === Number(selectedUserId)
    );


    setAssignedUser(selectedUser);
  };

  const assignTaskUser = async () => {
    if (assignedUser === null || assignedUser === undefined) {
      alert("Select User before submit");
      return;
    }

    setAssignTaskLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/assign`,
        {
          transId: transId,
          deId: deId,
          userId: assignedUser?.id,
          assignor: globalData.id,
        },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: Number(transId),
          taskType: ["Imsi Admin","Imsi Encoder"].includes(assignedUser.roleName) ? "imsi_task" : "damage_evaluation",
          event: ["Imsi Admin","Imsi Encoder"].includes(assignedUser.roleName) ? "assign_imsi_task" : "assign_damage_evaluation",
          description: `${["Imsi Admin","Imsi Encoder"].includes(assignedUser.roleName) ? "Imsi Task" : "Damage Evaluation"} with trans-id #${transId} is assigned to ${assignedUser.firstName} ${assignedUser.lastName} (${assignedUser.userName})`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        })

        alert(res?.data?.message);
        setAssignTaskLoading(false);
      })
      .catch((err: any) => {
        alert(err?.response?.data?.message);
        console.error(err?.response?.data?.message);
        setAssignTaskLoading(false);
      });

    const openTasksResponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/open-task/?transId=${transId}&deId=${deId}`,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    );
    const openTasksData: IOpenTask[] = openTasksResponse?.data?.data;
    setOpenTasks(openTasksData);
  };



  // console.log('perilType', perilType)
  // React.useEffect(() => {
  //   const fetchClaimData = async () => {
  //     try {
  //       const claimResponse = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`
  //       );
  //       // const claimData = claimResponse.data.data; // Removed unused variable
  //     } catch (error) {
  //       console.error("Error fetching claim data:", error);
  //     }
  //   };
  //   fetchClaimData();
  // }, []);

  React.useEffect(() => {
    const fetchShopEstimate = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        )
        .then((res: any) => {
          const data: IShopEstimate = res.data.data;

          const tblShopItemsJSON = data.tableItems
            ? JSON.parse(data.tableItems)
            : null;

          if (tblShopItemsJSON) {
            const tblShopItemRows = tblShopItemsJSON.rows.map((row: any) => {
              // Generate a unique ID for each row
              const id = uuidv4();

              // Handle the case where row is empty
              if (row.length === 0) {
                return tblShopItemsJSON.header.reduce(
                  (acc: any, key: any) => {
                    acc[key] = "";
                    return acc;
                  },
                  { id }
                ); // Include unique id in the object
              }

              return row.reduce(
                (acc: any, value: any, index: any) => {
                  acc[tblShopItemsJSON.header[index]] = value;
                  return acc;
                },
                { id }
              ); // Include unique id in the object
            });



            setShopItemTable({
              headers: tblShopItemsJSON.header,
              rows: tblShopItemRows,
            });
          }
          setTotalRepairersAmount(parseNumber(data.totalParts || "0"));
          setTotalLaborAmount(parseNumber(data.totalLabor || "0"));
          setShopEstimate(res.data);
        })
        .catch((err: any) => {

          setError(err.message || "An unexpected error occurred");
          setLoading(false);
        });
    };
    //   try {
    //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`);

    //     if (!response.ok) {
    //       throw new Error(`Error: ${response.status} ${response.statusText}`);
    //     }

    //     const data: SEDetails = await response.json();

    //     setTotalRepairersAmount(parseNumber(data.data.totalParts || '0'));
    //     setTotalLaborAmount(parseNumber(data.data.totalLabor || '0'));
    //     setShopEstimate(data);
    //   } catch (err: any) {
    //     setError(err.message || 'An unexpected error occurred');
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    fetchRepairShops();
    fetchShopEstimate();
  }, []);

  // #region mainstate
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    statusOptions:
      globalData?.role === "Head Clec"
        ? [
          { value: 24, label: "Approve" },
          { value: 5, label: "For Review By Clec" },
          { value: 19, label: "Pending Adjuster's Report" },
          // { value: 26, label: "Pending For Offer" },
        ]
        : isTotalLoss
          ? [
            { value: 4, label: "Forward To Head CLEC" },
            { value: 27, label: "Forward To Head Motor Claims" },
            { value: 28, label: "Forward To Head Claims" },
          ]
          : [
            { value: 21, label: "Pending In-house Survey (Clec)" },
            { value: 22, label: "Pending Adjuster's Survey (IMSI)" },
            { value: 9, label: "Pending for Offer" },
            // { value: 26, label: "Pending for Offer" },
            { value: 19, label: "Pending Adjuster's Report" },
          ],
  });
  // console.log("mainState", mainState.statusOptions);
  const [totalDepreciation, setTotalDepreciation] = React.useState(0);
  const [totalPartsAmount, setTotalOfferAmount] = React.useState(0);
  const [totalDiscount, setTotalDiscount] = React.useState(0);
  const [vat, setVat] = React.useState(0);
  const regularParts = (totalAmount - totalDiscount) + vat;



  const handleTotalDepreciationChange = (newTotalDepreciation: number) => {
    setTotalDepreciation(newTotalDepreciation);
  };

  const handleTotalOfferAmountChange = (newTotalOfferAmount: number) => {
    setTotalOfferAmount(newTotalOfferAmount);
  };

  const handleTotalDiscountChange = (newTotalDiscount: number) => {
    setTotalDiscount(newTotalDiscount);
  };

  const handleVatChange = (newVat: number) => {
    setVat(newVat);
  };

  const [userData, setUserData] = React.useState<any>({});
  const [isDisable, setIsDisable] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const [fmv, setFmv] = React.useState<number>(0);
  const [salvage, setSalvage] = React.useState<number>(100000);
  const [deStatus, setDeStatus] = React.useState<number>(0);

  React.useEffect(() => {
    if (deStatus && mainState.statusOptions) {
        const defaultOption = mainState.statusOptions.find(
            (option: { value: number; label: string }) => option.value === Number(deStatus)
        );
        if (defaultOption) {
            setMainState((prev: any) => ({
                ...prev,
                selections: { status: defaultOption }
            }));
        }
    }
}, [deStatus, mainState.statusOptions]);

  const handleSelectChange = (
    selection: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection?.value },
      selections: { status: selection },
    });
  };

  const fetchDeTaskData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/${transId}/${deId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const data = res.data.data;
      setDeStatus(data.status);
      console.log('stattusFromDe', data.status);
      setDeFromDb({
        ...deFromDb,
        data: data,
      });
      setIsDisable(
        (globalData?.role === "Clec" && data?.status === 4) ||
        data?.submitted === 1
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("not-found");
    }
  };

  // const apiSubmitEmail = async (endpoint: string) => {
  //   try {
  //     await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`);
  //   } catch (err: any) {
  //     console.error(err?.response?.data);
  //   }
  // };



  // Add this state near your other state declarations

  React.useEffect(() => {
    const fetchDeDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            headers: {
              "Authorization": `Bearer ${globalData?.accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const deDetailsData: DEDetailsType = await response.json();
        setDeDetails(deDetailsData);
        // const updatedPerilType = deDetailsData.perilType === "Third Party"
        //   ? ["Third Party", "Own Damage"]
        //   : [deDetailsData.perilType];
        // setPerilType(updatedPerilType.filter((t): t is string => t !== null));
        // setPerilTypeDe(deDetailsData.perilType || null);

        setPerilTypeDe(deDetailsData.perilType || null);
        setClaimTypeId(deDetailsData.claimtypeId || null);
        console.log("deDetailsData peril", deDetailsData.perilType);
        setVatValue(deDetailsData.vat || 0);
        // Only update tag if the fetched tag exists.
        if (deDetailsData.tag) {
          setTag(deDetailsData.tag);
          console.log("deDetailsData.tag", tag);
          await fetchClaimRegistrationData(deDetailsData.tag);
        }
      } catch (err: any) {
        setError(err.message || "An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchDeDetails();
    fetchAllUsers();
  }, []);

  function handleAlertAndRefresh() {
    alert("This claim is already submitted. Please refresh the page and select another claim type ID.");
    window.location.reload();
  }

  const handleClaimTypeIdChange = async (event: SelectChangeEvent<string>) => {
    // Now the value is the combined string, e.g., "470 - OWN DAMAGE"
    const fullValue = event.target.value;
    setSelectedClaimType(fullValue);

    // Split the full string to extract the id and label parts
    const parts = fullValue.split(" - ");
    const idPart = parts[0];
    const extraLabelPart = parts.slice(1).join(" - "); // handles cases with extra dashes

    console.log("Selected ClaimTypeID:", fullValue);
    console.log("ID Part:", idPart, "Extra Label Part:", extraLabelPart);
    const selectedOption = claimOptions.find(
      (opt) => opt.id.toString() === idPart
    );
    if (selectedOption) {
      if (selectedOption.status === "9") {
        handleAlertAndRefresh();
        return;
      }
      setSelectedClaimType(fullValue);
      setTpName(selectedOption.claimParticipant);
      setTag(selectedOption.tag);
      fetchClaimRegistrationData(selectedOption.tag);
      // await fetchClaimRegistrationData(selectedOption.tag);
      console.log('selectedOption', selectedOption);
      // if (selectedOption.claimId !== null && selectedOption.claimId !== undefined) {
      //   setIsResend(true);
      // } else {
      //   setIsResend(false);
      // }
   


      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          perilType: extraLabelPart === "VTPL - PROPERTY DAMAGE" ? "Third Party" : extraLabelPart,
          claimtypeId: fullValue, tag: selectedOption.tag
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );

      setDeDetails((prev) =>
        prev
          ? {
            ...prev,
            claimtypeId: fullValue,
            perilType: extraLabelPart === "VTPL - PROPERTY DAMAGE" ? "Third Party" : extraLabelPart,
          }
          : null
      );
      setClaimTypeId(fullValue);
      setSelectedClaimType(fullValue);
    }


    // Update perilTypeDe and set perilType based on the extra label part.
    setPerilTypeDe(extraLabelPart === "VTPL - PROPERTY DAMAGE" ? "Third Party" : extraLabelPart);
    if (extraLabelPart.toLowerCase() === "own damage") {
      setPerilType(["Own Damage"]);
    } else if (extraLabelPart.toLowerCase() === "theft - partial theft") {
      setPerilType(["Partial Theft"]);
    } else if (extraLabelPart.toLowerCase() === "acts of nature") {
      setPerilType(["Acts Of Nature"]);

    }
    else {
      setPerilType(["Third Party"]);
    }

  };

  const patchStatusAndUser = async (docIdFromDe: Number, approver: String) => {
    try {


      const approverId =
        approver === "Head Clec"
          ? process.env.REACT_APP_HEAD_CLEC
          : approver === "Head Motor Claims"
            ? process.env.REACT_APP_HEAD_MOTOR_CLAIM
            : approver === "Head Claims"
              ? process.env.REACT_APP_HEAD_CLAIM
              : null;

      const approverUserName =
        approver === "Head Clec"
          ? process.env.REACT_APP_HEAD_CLEC_USERNAME
          : approver === "Head Motor Claims"
            ? process.env.REACT_APP_HEAD_MOTOR_CLAIM_USERNAME
            : approver === "Head Claims"
              ? process.env.REACT_APP_HEAD_CLAIM_USERNAME
              : null
      const approverStatus = approver === "Head Clec" ? 4 :
        approver === "Head Motor Claims" ? 27 :
          approver === "Head Claims" ? 28 : 9;

      axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
        {
          status: approverStatus,
          userId: approverId,
        },
        {
          params: {
            transId: transId,
            id: docIdFromDe,
          },
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      // 6. Navigate After Successful Updates
      navigate("/damage-evaluation");
    } catch (error: any) {
      console.error("Error in patchStatusAndUser:", error);

      // Optionally, display an error message to the user
      // For example, using a state variable to show a notification
      // setErrorMessage(error.message || 'An unexpected error occurred.');
    }
  };

  // State to handle snackbar visibility
  // const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  // const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() + 8);
  const formattedDateTime = currentDateTime
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  const [formData, setFormData] = React.useState({
    dateInspection: new Date().toISOString().split("T")[0],
    placeInspection: "",
    inspectedBy: "",
    reinspectedBy: "",
    dateReinspected: new Date().toISOString().split("T")[0],
    dateAdditionalest: new Date().toISOString().split("T")[0],
    territory: ""
  });

  const validateFields = () => {
    const { dateInspection, placeInspection, inspectedBy } = formData;
    return (
      (dateInspection ? dateInspection.trim() : "") !== "" &&
      (placeInspection ? placeInspection.trim() : "") !== "" &&
      (inspectedBy ? inspectedBy.trim() : "") !== ""
    );
  };

  const reValidateFields = () => {
    const { dateReinspected, reinspectedBy } = formData;
    return (
      (dateReinspected ? dateReinspected.trim() : "") !== "" &&
      (reinspectedBy ? reinspectedBy.trim() : "") !== ""
    );
  };

  const currentPeril = perilTypeDe || perilType[0];
  // const correctPerilCode =
  //   currentPeril === "Own Damage"
  //     ? 202
  //     : currentPeril === "Partial Theft"
  //       ? 203
  //       : currentPeril === "Acts Of Nature"
  //         ? 209
  //         : currentPeril === "Third Party"
  //           ? 206
  //           : "";



  // update premium class
  const handleUpdatePremiumClass = () => {
    const filteredParts = entries.filter(item => /(?:\s|-|)PARTS/.test(item.codeDesc) && item.tag === tag);
    const filteredLabors = entries.filter(item => /(?:\s|-|)LABOR/.test(item.codeDesc) && item.tag === tag);

    console.log("handleUpdatePremiumClass", filteredParts, filteredLabors);

    // rules
    let errors = 0;

    // // 1. should only one each
    // errors = filteredLabors.length !== 1 ? errors + 1 : errors;
    // errors = filteredParts.length !== 1 ? errors + 1 : errors;
    if (filteredLabors.length === 0 || filteredParts.length === 0) {
      errors += 1;
    }

    // 2. should match all code
    const codes2 = new Set(filteredParts.map(item => item.premiumClass));
    errors = filteredLabors.every(item => codes2.has(item.premiumClass)) === false ? errors + 1 : errors;

    // #region TODO: update claims
    if (errors === 0) {
      const filteredNotPartsAndLabors = entries.filter(item => /(?:\s|-|)PARTS/.test(item.codeDesc) === false && /(?:\s|-|)LABOR/.test(item.codeDesc) === false && item.tag === tag)
      const totalParts = String(cartCTrans.reduce((acc, item) => acc + (item.shopAmount ? item.shopPrice : item.amount) * item.qty, 0) - totalDiscount + vat)
      const totalLabor = String(laborPlusVatCT);
      console.log("handleUpdatePremiumClass TOTAL PARTS: ", totalParts);
      console.log("handleUpdatePremiumClass TOTAL LABOR: ", totalLabor);
      console.log("handleUpdatePremiumClass Result: ", errors);

      filteredParts[filteredParts.length - 1].estimationAmount = parseFloat(totalParts).toLocaleString(
        "en-PH",
        { style: "currency", currency: "PHP" }
      )
      filteredLabors[filteredLabors.length - 1].estimationAmount = parseFloat(totalLabor).toLocaleString(
        "en-PH",
        { style: "currency", currency: "PHP" }
      )

      let entriesPayload = [
        ...oldEntries,
        ...filteredNotPartsAndLabors,
        ...filteredParts,
        ...filteredLabors
      ]

      const sortedData = entriesPayload.sort((a, b) => parseInt(a.id) - parseInt(b.id));

      console.log('entries payload', sortedData);

      // #region TODO: finish update
      claimsModificationCreate(entriesPayload);

      let oldEntriesPayload = [
        ...filteredNotPartsAndLabors,
        ...filteredParts,
        ...filteredLabors
      ]

      const sortedOldData = oldEntriesPayload.sort((a, b) => parseInt(a.id) - parseInt(b.id));

      setEntries(sortedOldData)
    }
  }

  const [claimLoading, setClaimLoading] = React.useState<boolean>(false);
  const [claimRegistrationData, setClaimRegistrationData] = React.useState<any>(null);

  const claimsModificationCreate = async (
    updatedEntries: {
      premiumClass: string;
      reserveCode: string;
      estimationAmount: string;
      codeDesc: string;
      tag: string;
    }[]
  ) => {
    setClaimLoading(true);
    // Replace these with actual values from your context or fetched data
    // const riskNumber = claimRegistrationData?.riskNumber; // Example value
    // const policyNo = claimRegistrationData?.policyNumber; // Example value
    // const claimStatus = claimRegistrationData?.claimStatus; // Example value
    // const dateOccurs = claimRegistrationData?.dateLoss; // Example value
    // const claimDescription = claimRegistrationData?.claimDesc; // Example value
    // const dateReported = claimRegistrationData?.dateReported;
    // const majorEventCode = claimRegistrationData?.claimMajoreventcode;
    // const claimNumber = claimRegistrationData?.claimNumber;
    // Update claimFilingapprovaltime to current datetime
    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 8);
    const getKeyByValue = (value: string): ReserveCodeKey | undefined => {
      return (Object.keys(reserveCodeMapping) as ReserveCodeKey[]).find(
        (key) => reserveCodeMapping[key] === value
      );
    };
    const formattedDateTime = currentDateTime
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");


    const claimsPayloadDb = {
      ...claimRegistrationData,
      balanceOutstanding01: updatedEntries[0]?.estimationAmount || "",
      balanceOutstanding02: updatedEntries[1]?.estimationAmount || "",
      balanceOutstanding03: updatedEntries[2]?.estimationAmount || "",
      balanceOutstanding04: updatedEntries[3]?.estimationAmount || "",
      balanceOutstanding05: updatedEntries[4]?.estimationAmount || "",
      balanceOutstanding06: updatedEntries[5]?.estimationAmount || "",
      balanceOutstanding07: updatedEntries[6]?.estimationAmount || "",
      balanceOutstanding08: updatedEntries[7]?.estimationAmount || "",
      balanceOutstanding09: updatedEntries[8]?.estimationAmount || "",
      balanceOutstanding10: updatedEntries[9]?.estimationAmount || "",
      premiumClass01: updatedEntries[0]?.premiumClass,
      premiumClass02: updatedEntries[1]?.premiumClass,
      premiumClass03: updatedEntries[2]?.premiumClass,
      premiumClass04: updatedEntries[3]?.premiumClass,
      premiumClass05: updatedEntries[4]?.premiumClass,
      premiumClass06: updatedEntries[5]?.premiumClass,
      premiumClass07: updatedEntries[6]?.premiumClass,
      premiumClass08: updatedEntries[7]?.premiumClass,
      premiumClass09: updatedEntries[8]?.premiumClass,
      premiumClass10: updatedEntries[9]?.premiumClass,
      reserveCode01: updatedEntries[0]?.reserveCode || "",
      reserveCode02: updatedEntries[1]?.reserveCode || "",
      reserveCode03: updatedEntries[2]?.reserveCode || "",
      reserveCode04: updatedEntries[3]?.reserveCode || "",
      reserveCode05: updatedEntries[4]?.reserveCode || "",
      reserveCode06: updatedEntries[5]?.reserveCode || "",
      reserveCode07: updatedEntries[6]?.reserveCode || "",
      reserveCode08: updatedEntries[7]?.reserveCode || "",
      reserveCode09: updatedEntries[8]?.reserveCode || "",
      reserveCode10: updatedEntries[9]?.reserveCode || "",
      codeDesc01: updatedEntries[0]?.codeDesc || "",
      codeDesc02: updatedEntries[1]?.codeDesc || "",
      codeDesc03: updatedEntries[2]?.codeDesc || "",
      codeDesc04: updatedEntries[3]?.codeDesc || "",
      codeDesc05: updatedEntries[4]?.codeDesc || "",
      codeDesc06: updatedEntries[5]?.codeDesc || "",
      codeDesc07: updatedEntries[6]?.codeDesc || "",
      codeDesc08: updatedEntries[7]?.codeDesc || "",
      codeDesc09: updatedEntries[8]?.codeDesc || "",
      codeDesc10: updatedEntries[9]?.codeDesc || "",
      tag01: updatedEntries[0]?.tag || "",
      tag02: updatedEntries[1]?.tag || "",
      tag03: updatedEntries[2]?.tag || "",
      tag04: updatedEntries[3]?.tag || "",
      tag05: updatedEntries[4]?.tag || "",
      tag06: updatedEntries[5]?.tag || "",
      tag07: updatedEntries[6]?.tag || "",
      tag08: updatedEntries[7]?.tag || "",
      tag09: updatedEntries[8]?.tag || "",
      tag10: updatedEntries[9]?.tag || "",
    };

    // Assuming the backend expects claimFilingapprovaltime
    // If claimFilingapprovaltime is part of the payload, include it
    // If not, ensure your backend sets it automatically
    // (claimsPayload as any)["claimFilingapprovaltime"] = formattedDateTime;
    // // (claimsPayloadDb as any)["claimFilingapprovaltime"] = currentDatetime;
    // // (claimsPayloadDb as any)["saveDate"] = currentDatetime;

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration`,
        claimsPayloadDb, // Adjust based on what your backend expects

        {
          params: { transId: transId },
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      // ... [rest of your try block]
      // alert("Claim DB updated successfully!");
    } catch (err: any) {
      // Handle errors
      alert(err.message || "An error occurred while updating the claim.");
    }


  };

  // console.log("entries", updatedEntries);

  const handleSubmit = async () => {
    // if(mainState?.selections?.status?.value !== null) {
    //   alert(mainState?.selections?.status?.value);
    //   return;
    // }

    // console.log("perilType", perilType);
    // console.log("perilType[0]", perilType[0]);
    const currentPeril = perilTypeDe || perilType[0]; if (!currentPeril || currentPeril === "N/A") { alert("Please choose a claimtype ID"); return;

    }
    // if (!claimTypeId || claimTypeId === "") {
    //   alert("Please select a Claim Type");
    //   return;
    // }
    const docNum = (!claimTypeId || claimTypeId === "")
      ? claimOptions[0].id
      : Number(claimTypeId.match(/^\d+/)?.[0]);
    const label = (!claimTypeId || claimTypeId === "")
      ? claimOptions[0].label
      : claimTypeId.includes("PROPERTY DAMAGE")
        ? "Third Party"
        : claimTypeId;
    const claimId = (!claimTypeId || claimTypeId === "") ? `${claimOptions[0].id} - ${claimOptions[0].label}` : claimTypeId;
    const tagging = (!claimTypeId || claimTypeId === "") ? claimOptions[0].tag : tag;

    setSubmitting(true);
    if (
      (mainState?.selections?.status?.value === 4 ||
        mainState?.selections?.status?.value === 28 ||
        mainState?.selections?.status?.value === 27) &&
      globalData?.role === "Clec"
    ) {
      // console.log("status 4,28,27")
      if (!validateFields()) {
        alert("Please fill in Date Inspection, Place Inspection, and Inspected By fields.");
        setSubmitting(false);
        return;
      }

      const approver =
        mainState?.selections?.status?.value === 4
          ? "Head Clec"
          : mainState?.selections?.status?.value === 27
            ? "Head Motor Claims"
            : mainState?.selections?.status?.value === 28
              ? "Head Claims"
              : "";
      // setSubmitting(false);
      handleExternalCall();
      patchStatusAndUser(docId, approver);
      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 9 &&
      globalData?.role === "Clec"
    ) {
      // console.log("status 9 Clec")
      // alert('pasok sa else if')
      setSubmitting(true);

      if (entries.length === 0) {
        alert("Please use correct tagging");
        setSubmitting(false);
        return;
      }

      if (!validateFields()) {
        alert("Please fill in Date Inspection, Place Inspection, and Inspected By fields.");
        setSubmitting(false);
        return;
      }


      handleExternalCall();

      // check and update premium class
      handleUpdatePremiumClass();


      // console.log("claimTypeId", claimTypeId);
      // console.log("tag", tag);
      // console.log('perilTypeDe', perilTypeDe);
      // console.log('perilType', perilType);

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          perilType: label,
          claimtypeId: claimId, tag: tagging
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );

      try {
        // 1. Fetch Head CLEC Users
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 9,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: deId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
            userId: globalData?.id,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        let error_catch = "";



        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update/${docNum}`,
            {
              "statusNo": 10,
              "docComplete": 1
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating docvalidation:", error);
          error_catch = "Error updating docvalidation";
        }

        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending for Offer"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating claim registration:", error);
          error_catch = "Error updating claim registration";
        }
        if (error_catch === "") {

          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  status: 9
                }),
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
          } catch (error) {
            console.error("Error updating task status:", error);
          }
        }
      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }

      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 19 &&
      globalData?.role === "Clec"
    ) {
      // console.log("status 19 Clec")
      // alert('pasok sa 19')

      if (!validateFields()) {
        alert("Please fill in Date Inspection, Place Inspection, and Inspected By fields.");
        setSubmitting(false);
        return;
      }
      setSubmitting(true);

      if (currentPeril === "") {
        alert("Please register the correct peril type.");
        setSubmitting(false);
        return;
      }


      handleExternalCall();

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          perilType: label,
          claimtypeId: claimId, tag: tagging
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );
      try {
        // 1. Fetch Head CLEC Users
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 19,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: deId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
            userId: globalData?.id,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        let error_catch = "";
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update/${docNum}`,
            {

              "statusNo": 20,
              "docComplete": 1
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating docvalidation:", error);
          error_catch = "error updating docvalidation";
        }

        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending Adjuster's Report"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating claim registration:", error);
          error_catch = "error updating claim registration";
        }


        if (error_catch === "") {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  status: 19
                }),
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
          } catch (error) {
            console.error("Error updating task status:", error);
          }
        }


      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }
      // const endpoint = `/api/acap/email/ca`
      // const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };

      // axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      //   email: emailRequest.email,
      //   claimNo: emailRequest.claimNo,
      // })
      navigate("/damage-evaluation");
    }
    else if (
      mainState?.selections?.status?.value === 9 &&
      (globalData?.role === "Head Clec" ||
        globalData?.role === "Head Motor Claims" ||
        globalData?.role === "Head Claims")
    ) {
      // console.log("status 9 Head")
      // alert('pasok sa pending offer ni head clec')
      if (!validateFields()) {
        alert("Please fill in Date Inspection, Place Inspection, and Inspected By fields.");
        setSubmitting(false);
        return;
      }

      if (currentPeril === "") {
        alert("Please register the correct peril type.");
        setSubmitting(false);
        return;
      }

      setSubmitting(true);

      
      // patchStatusAndUser(docId);
      handleExternalCall();

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          perilType: label,
          claimtypeId: claimId, tag: tagging
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 9,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: docId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
            userId: globalData?.id,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        let error_catch = "";
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update/${docNum}`,
            {

              "statusNo": 10,
              "docComplete": 1
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          )
        } catch (error) {
          console.error("Error updating docvalidation:", error);
          error_catch = "error updating docvalidation";
        }
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending for Offer"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          )
        } catch (error) {
          console.error("Error updating claim registration:", error);
          error_catch = "error updating claim registration";
        }
        if (error_catch === "") {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  status: 9
                }),
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
          } catch (error) {
            console.error("Error updating task status:", error);
          }
        }




      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }

      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 21 &&
      globalData?.role === "Clec"
    ) {
      // console.log("status 21 Clec")
      // alert('pasok sa else 21')
      setSubmitting(true);
      // handleUpdatePremiumClass();

      // if (currentPeril === "") {
      //   alert("Please register the correct peril type.");
      //   return;
      // }


      handleExternalCall();

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          perilType: label,
          claimtypeId: claimId, tag: tagging
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );

      try {
        // 1. Fetch Head CLEC Users
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 21,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: deId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
            userId: globalData?.id,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        let error_catch = "";
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update/${docNum}`,
            {

              "statusNo": 22,
              "docComplete": 0
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating docvalidation:", error);
          error_catch = "error updating docvalidation";
        }



        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending In-house Survey (Clec)"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating claim registration:", error);
          error_catch = "error updating docvalidation";
        }


        if (error_catch === "") {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  status: 21
                }),
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
          } catch (error) {
            console.error("Error updating task status:", error);
          }
        }


      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }

      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 22 &&
      globalData?.role === "Clec"
    ) {
      // console.log("status 22 Clec")
      // alert('pasok sa else if')
      setSubmitting(true);

      // if (currentPeril === "") {
      //   alert("Please register the correct peril type.");
      //   return;
      // }


      handleExternalCall();

      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          perilType: label,
          claimtypeId: claimId, tag: tagging
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );
      try {
        // 1. Fetch Head CLEC Users
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 22,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: deId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
            userId: globalData?.id,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        let error_catch = "";
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update/${docNum}`,
            {

              "statusNo": 23,
              "docComplete": 0
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating docvalidation:", error);
          error_catch = "error updating docvalidation";
        }

        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending Adjuster's Survey (IMSI)"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating claim registration:", error);
          error_catch = "error updating docvalidation";
        }

        if (error_catch === "") {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  status: 22
                }),
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
          } catch (error) {
            console.error("Error updating task status:", error);
          }
        }
      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }

      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 5 &&
      (globalData?.role === "Head Clec" ||
        globalData?.role === "Head Motor Claims" ||
        globalData?.role === "Head Claims")
    ) {
      // console.log("status 5 Head")
      // alert('pasok sa pending offer ni head clec')
      setSubmitting(true);
      // patchStatusAndUser(docId);
      handleExternalCall();
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 21,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: docId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
            userId: globalData?.id,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        let error_catch = "";
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update/${docNum}`,
            {

              "statusNo": 22,
              "docComplete": 0
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          )
        } catch (error) {
          console.error("Error updating docvalidation:", error);
          error_catch = "error updating docvalidation";
        }
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending In-house Survey (Clec)"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          )
        } catch (error) {
          console.error("Error updating claim registration:", error);
          error_catch = "error updating claim registration";
        }
        if (error_catch === "") {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  status: 21
                }),
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
          } catch (error) {
            console.error("Error updating task status:", error);
          }
        }




      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }

      navigate("/damage-evaluation");
    } else {
      navigate("/damage-evaluation");
    }
  };

  const handleSubmitResend = async () => {
    setSubmitting(true);
    // console.log('raEmail', raEmail)
    // console.log('revalidateFields', reValidateFields)
    if (raEmail === "" || raEmail === null || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(raEmail)) {
      alert("Please fill in a correct CA Email field.");
      setSubmitting(false);
      return;
    }
    if (!reValidateFields()) {
      alert("Please fill in reinspected by and date reinspected field.");
      setSubmitting(false);
      return;
    }
    handleExternalCall();

    const endpoint = `/api/acap/email/ca`;
    const emailRequest = { email: raEmail, claimNo: claimNo };

    axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      email: emailRequest.email,
      claimNo: emailRequest.claimNo,
    },
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      });

    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          submittedBy: globalData?.firstName + " " + globalData?.lastName,
          datetimeSubmitted: formattedDateTime,
          userId: globalData?.id,
          status: 1,
        },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
    } catch (error: any) {
      console.error("Error in patchStatusAndUser:", error);
    }
    // const endpoint = `/api/acap/email/ca`
    // const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };

    // axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
    //   email: emailRequest.email,
    //   claimNo: emailRequest.claimNo,
    // })
    setSubmitting(false);
    navigate("/damage-evaluation");
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    setUserData(userData);
    fetchDeTaskData();
    // fetchShopItems();
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography align="center">{snackbarMessage}</Typography>
        </Alert>
      </Snackbar> */}
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card sx={{ my: 4 }}>
        <CardContent className="tp-mlr-50px tp-mtb-20px">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Typography variant="h4" className="tp-text-header-primary">
                    Damage Evaluation
                  </Typography>
                  <Typography
                    variant="body1"
                    display="block"
                    className="tp-text-input-sub"
                  >
                    Note: Please check all Required Fields with (
                    <span className="tp-text-required">*</span>) before you
                    submit.
                  </Typography>
                </Box>

                <Stack direction={"row"} spacing={1}>
                  {["Clec", "Jr Clec", "Canvasser"].includes(
                    globalData.role
                  ) && (
                      <>
                        <IconButton
                          aria-label="delete"
                          id="basic-button"
                          aria-controls={
                            openTaskHistoryAnchor ? "basic-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            openTaskHistoryAnchor ? "true" : undefined
                          }
                          onClick={handleOpenTaskHistoryClick}
                        >
                          <InfoIcon />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openTaskHistoryAnchor}
                          onClose={handleOpenTaskHistoryClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {openTasks &&
                            openTasks.map((item: IOpenTask, idx: number) => (
                              <MenuItem
                                key={idx}
                                onClick={handleOpenTaskHistoryClose}
                              >{`Task #${item.id} is assigned to ${item.name} (${item.role})`}</MenuItem>
                            ))}
                        </Menu>
                        <FormControl>
                          <InputLabel>Assign User</InputLabel>
                          <MuiSelect
                            value={assignedUser?.id?.toString() || ""}
                            onChange={handleImsiAdminUserChange}
                            label="Assign User"
                            sx={{ width: 200, maxWidth: 300 }}
                          >
                            {users &&
                              users.map((item: IUserDetail) => (
                                <MenuItem
                                  key={item.id}
                                  value={item.id.toString()}
                                >
                                  {`${item.userName} - ${item.roleName}`}
                                </MenuItem>
                              ))}
                          </MuiSelect>
                        </FormControl>
                        <Button
                          className="tp-custom-button-primary"
                          variant="contained"
                          onClick={assignTaskUser}
                        >
                          Assign
                        </Button>
                      </>
                    )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <br />
              {claimOptions.length > 1 ? (
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                  <InputLabel id="peril-type-label">Claim Type ID:</InputLabel>
                  <MuiSelect
                    labelId="peril-type-label"
                    id="peril-type-select"
                    value={selectedClaimType}
                    label="Peril Type"
                    onChange={handleClaimTypeIdChange}
                    disabled={isResend || syncAW || pendingForOffer}
                  >
                    {claimOptions.map((option) => (
                      <MenuItem key={option.id} value={`${option.id} - ${option.label}`}>
                        {`${option.id} - ${option.label}`}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              ) : (
                <Typography variant="h5" className="tp-text-header-primary">
                  Claim Type ID: <span className="tp-text-default">{selectedClaimType}</span>
                </Typography>
              )}

            </Grid>
            {isResend && (
              <Grid item>
                <Typography
                  variant="h5"
                  className="tp-text-header-error"
                  color="error"
                >
                  Resend to AW
                </Typography>
              </Grid>
            )}
            {syncAW && (
              <Grid item>
                <Typography
                  variant="h5"
                  className="tp-text-header-error"
                  color="error"
                >
                  Sync with AW
                </Typography>
              </Grid>
            )}
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Claim No: <span className="tp-text-default">{claimNumber}</span>
              </Typography>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <Box>
            <Card
              sx={{
                boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                borderRadius: 2,
              }}
            >
              <div style={{ textAlign: "center", marginTop: "35px" }}>
                <Typography variant="body1">Transaction No.:</Typography>
                <Button
                  size="medium"
                  className="tp-custom-button-primary"
                  onClick={() =>
                    window.open(
                      `${window.location.protocol}//${window.location.host}/document-validation/${transId || deDetails?.transId}`, "_blank")
                  }
                >
                  <Typography variant="h3">
                    ACAP - {transId || deDetails?.transId}
                  </Typography>
                </Button>
                <br />
                <Typography variant="body1">
                  Peril Type:{" "}
                  <span style={{ fontWeight: "bold", color: "navy" }}>
                    {perilTypeDe !== null ? perilTypeDe : "N/A"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  Claim No:{" "}
                  <span style={{ fontWeight: "bold", color: "navy" }}>
                    {claimNumber}
                  </span>
                </Typography>
              </div>
              <br />
              <center>

                <FormControl style={{ width: "300px" }}>
                  <Typography variant="body1">
                    Tag: {tag}
                  </Typography>
                </FormControl>

              </center>
              <br />
              {/* <hr className="tp-border-hr_primary" /> */}
              {/* <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9}>
              <Typography variant="h5" className="tp-text-header-primary">
                Initial Estimate
              </Typography>
              <br />

              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Clec Estimate: &ensp;</span>
              </Typography>

              <UploadClec
                claimNo={claimNo}
                onUpdate={handleUpdate}
                transId={transId}
                onBrandModelUpdate={onBrandModelUpdate}
              />
              <br />
              <br />
              <div className="tp-text-bold">
                Initial Total Parts Amount: ₱ {formatNumber(initialTotalParts)}
              </div>
              <div className="tp-text-bold">
                Initial Total Labor Amount: ₱ {formatNumber(initialTotalLabor)}
              </div>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br /> */}
              <br />

              <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={shopEstimate?.data.url ? 8 : 12}>
                  <br />
                  <br />

                  <div style={{ textAlign: "center" }}>
                    <Typography variant="body1">
                      Name:
                    </Typography>
                    <Typography variant="h4" className="tp-text-header-primary">
                      {((perilTypeDe === "Third Party")
                        ? (tpName || "N/A")
                        : (insuredName || "N/A")
                      ).toUpperCase()}
                    </Typography>
                    <Typography variant="body1">
                      Policy No.:{" "}
                      <span style={{ fontWeight: "bold", color: "navy" }}>
                        {policyNo || ""}
                      </span>
                    </Typography>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <TextField
                      label="Total Parts Amount"
                      variant="outlined"
                      type="text"
                      size="small"
                      value={formatNumber(totalRepairersAmount)}
                      onChange={(e) =>
                        handleNumberChange(e, setTotalRepairersAmount)
                      }
                      style={{ width: "500px" }}
                      margin="normal"
                    />
                    {shopEstimate?.data.url ? "" : <span>&emsp;</span>}
                    {/* Total Labor Amount */}
                    <TextField
                      label="Total Labor Amount"
                      variant="outlined"
                      type="text"
                      size="small"
                      value={formatNumber(totalLaborAmount)}
                      onChange={(e) =>
                        handleNumberChange(e, setTotalLaborAmount)
                      }
                      style={{ width: "500px" }}
                      margin="normal"
                    />
                  </div>
                  <br />
                  <br />
                  {/* Conditionally Rendered Components */}
                  {shopEstimate && (
                    <div style={{ textAlign: "center", justifySelf: "center" }}>
                      <Typography variant="body1" className="tp-text-bold">
                        Vendor Name:
                      </Typography>
                      <Autocomplete
                        size="small"
                        disablePortal
                        freeSolo
                        style={{ width: "500px" }}
                        value={shopEstimate?.data?.vendorName}
                        options={repairShops}
                        renderInput={(params) => <TextField {...params} />}
                        onInputChange={(
                          _event: any,
                          value: string,
                          reason: string
                        ) => {

                          setMainState((prev: any) => ({
                            ...prev,
                            data: { ...prev.data, vendorName: value },
                          }));
                        }}
                      />
                      <br />
                      <Button
                        // disabled={isLoading}
                        onClick={() => {
                          handleShowRepairShopDetails();
                        }}
                        variant="contained"
                        size="medium"
                        color="error"
                        style={{ width: "300px" }}
                      >
                        See Details
                      </Button>
                      <br />
                      {/* {shopEstimate?.data?.customerName?.toLowerCase().includes('jimmy') ? (
                    <EstimationTableJimmy transID={transId} />
                  ) : (
                    <EstimationTable transID={transId} />
                  )} */}

                      {/* <EstimationTable
                    transID={transId}
                    mainState={null}
                    shopItemTable={shopItemTable}
                    setShopItemTable={setShopItemTable}
                    disabled
                  /> */}
                    </div>
                  )}
                </Grid>

                {/* Conditionally Rendered Document Viewer */}
                {shopEstimate && (
                  <Grid item xs={4} sm={4} md={shopEstimate.data.url ? 4 : 1}>
                    <DocumentViewer documentUrl={shopEstimate.data.url} />
                  </Grid>
                )}
              </Grid>
              <br />
              <br />
            </Card>
          </Box>

          {
            // #endregion Estimation Table
          }

          <hr className="tp-border-hr_primary" />
          <br />
          {
            // #region ClientTransaction
          }
          {/* Client Transaction */}
          <ClientTransaction
            transID={transId}
            deId={deId}
            tag={tag}
            data={mainState?.data}
            lossReserveParts={lossReserveParts}
            setLossReserveParts={setLossReserveParts}
            lossReserveLabor={lossReserveLabor}
            setLossReserveLabor={setLossReserveLabor}
            initialLaborAmount={initialTotalLabor}
            initialPartsAmount={initialTotalParts}
            updateGrossTotal={updateGrossTotal}
            onLaborDataChange={handleLaborDataChange}
            updateTotalDeductible={updateTotalDeductible}
            updateCart={handleUpdateCart}
            onTotalDepreciationChange={handleTotalDepreciationChange}
            onTotalDiscountChange={handleTotalDiscountChange}
            onVatChange={handleVatChange}
            onVatCheckedChange={handleVatCheckedChange}
            onTotalOfferAmountChange={handleTotalOfferAmountChange}
            totalRepairersAmount={totalRepairersAmount}
            totalLaborAmount={totalLaborAmount}
            setTotalLaborAmount={setTotalLaborAmount}
            selectedBrand={selectedBrand}
            selectedModel={selectedModel}
            mainState={mainState}
            setMainState={setMainState}
            onIsTotalLossChange={handleIsTotalLossChange}
            onTotalLossChange={handleTotalLossChange}
            fmv={fmv}
            vatValue={vatValue}
            setFmv={setFmv}
            salvage={salvage}
            setSalvage={setSalvage}
            policyNo={policyNo}
            crDate={crDate}
            perilType={perilTypeDe || perilType[0]}
            isResend={isResend}
            formData={formData}
            setFormData={setFormData}
            validateFields={validateFields}
            dol={dol ?? ""}
            entries={entries}
            oldEntries={oldEntries}
            setEntries={setEntries}
            fetchClaimRegistrationData={fetchClaimRegistrationData}
            claimsModificationCreate={claimsModificationCreate}
            claimLoading={claimLoading}
            cartCTrans={cartCTrans}
            setCartCTrans={setCartCTrans}
            vatCTrans={vatCTrans}
            setVatCTrans={setVatCTrans}
            totalDiscountCTrans={totalDiscountCTrans}
            setTotalDiscountCTrans={setTotalDiscountCTrans}
            laborPlusVatCT={laborPlusVatCT}
            setLaborPlusVatCT={setLaborPlusVatCT}
          />
          {
            // #endregion ClientTransaction
          }

          {
            // #endregion Insurer Report
          }
          {
            // #region Computation Sheet
          }

          {
            // #endregion Computation Sheet
          }

          {/* <br />
          <FieldChecklistClec /> */}

          {
            // #region For Processor
          }

          <br />
          <br />
          <br />

          {
            // #endregion For Processor
          }
          <hr className="tp-border-hr_primary" />
          <br />

          <Card style={{ width: "100%", marginTop: "15px", padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="h5"
                  className="tp-text-header-primary"
                  textAlign="center"
                >
                  CLEC Reports
                </Typography>
              </Grid>
            </Grid>
            <br />
            <br />

            {
              // #region Insurer Report
            }
            <Typography variant="h6" className="tp-text-header-primary">
              Completed In-House Survey
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            {/* {isTotalLoss ? (
              <GenerateInsurerTotalLoss
                ref={insurerRef}
                repairersParts={totalRepairersAmount}
                repairersLabor={totalLaborAmount}
                laborData={laborData}
                laborPlusVat={laborPlusVat}
                totalAmount={totalAmount}
                totalDepreciation={totalDepreciation}
                totalPartsAmount={totalPartsAmount}
                totalDiscountAmount={totalDiscount}
                vat={vat}
                totalDeductible={totalDeductibleAmount}
                ourOfferGrossTotal={ourOfferGrossTotal}
                partsCart={cart}
                transId={transId}
                deId={deId}
                salvage={salvage}
              />
            ) : ( */}
            <GenerateInsurer
              ref={insurerRef}
              repairersParts={totalRepairersAmount}
              repairersLabor={totalLaborAmount}
              laborData={laborData}
              laborPlusVat={laborPlusVat}
              totalAmount={totalAmount}
              totalDiscountAmount={totalDiscount}
              vat={vat}
              vatChecked={isVat}
              totalDepreciation={totalDepreciation}
              totalPartsAmount={totalPartsAmount}
              totalDeductible={totalDeductibleAmount}
              ourOfferGrossTotal={ourOfferGrossTotal}
              partsCart={cart}
              transId={transId}
              deId={deId}
              surveyedBy={formData.inspectedBy}
              surveyedOn={formData.placeInspection}
              tag={tag}
              tpName={tpName}
              perilType={perilTypeDe !== null ? perilTypeDe : perilType[0]}
            />

            {
              // #endregion Insurer Report
            }
            {
              // #region Computation Sheet
            }
            <Typography variant="h6" className="tp-text-header-primary">
              Computation Sheet
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            {isTotalLoss ? (
              <ComputationSheetTotalLoss
                fmv={fmv}
                salvage={salvage}
                deductible={totalDeductibleAmount}
              />
            ) : (
              <ComputationSheet
                laborAndMaterials={laborAndMaterials + laborAndMaterials * 0.12}
                totalLabor={laborAndMaterials}
                regularParts={regularParts}
                depreciation={totalDepreciation}
                deductible={totalDeductibleAmount}
                totalLossReserve={lossReserveParts + lossReserveLabor}
                deDetails={deDetails}
                deId={deId}
              />
            )}
            <br />
            <br />

            {
              // #region Document Table
            }
            <DocumentTable />

            <br />
            <br />
          </Card>
          {
            // #endregion Computation Sheet
          }
          {
            // #region For Processor
          }
          <br />
          <br />
          <br />
          {globalData.role !== "Jr Clec" || "Processor" ? (
            <ForProcessor
              mainState={mainState}
              setMainState={setMainState}
              isDisable={isDisable}
              isResend={isResend}
              handleSelectChange={handleSelectChange}
              transId={transId}
              status={deStatus}
              onRaEmailValueChange={handleRaEmailValueChange}
              isTotalLoss={isTotalLoss}
            />
          ) : null}

          <TaskRemarks />
          {
            // #endregion For Processor
          }
          <hr className="tp-border-hr_primary" />
          <br />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            {globalData.role !== "Jr Clec" && globalData.role !== "Processor" && isResend === false ? (
              <Button
                disabled={isDisable || submitting}
                className="tp-custom-button-primary"
                variant="contained"
                onClick={handleSubmit}
                aria-label="submit-button"
              >
                {submitting ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            ) : null}

            {globalData.role !== "Jr Clec" && globalData.role !== "Processor" && isResend === true ? (
              <Button
                disabled={isDisable || submitting}
                className="tp-custom-button-primary"
                variant="contained"
                onClick={handleSubmitResend}
                aria-label="submit-button"
              >
                {submitting ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            ) : null}
          </Box>
          <Modal
            open={repairShopDetails.isShow}
            onClose={() =>
              setRepairShopDetails((prev: any) => ({
                data: [],
                isShow: false,
              }))
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                p: 4,
              }}
            >
              {repairShopDetails?.data.length > 0 ? (
                <React.Fragment>
                  <Typography variant="h6" className="tp-text-header-primary">
                    Negotiated Agreed Rates
                  </Typography>
                  <hr />
                  <List>
                    {repairShopDetails?.data.map((item: any) => (
                      <ListItem>{item}</ListItem>
                    ))}
                  </List>
                </React.Fragment>
              ) : (
                <Typography>
                  No details, make sure that vendor name is valid
                </Typography>
              )}
            </Box>
          </Modal>
          <br />
        </CardContent>
      </Card>
    </Container>
  );
}
