export interface IUserTask {
  id: number;
  userId: number;
  username: string;
  firstName: string; 
  lastName: string;  
  roleName: string;  
  available: number; 
  taskOpen: number;
  taskCompleted: number;
}

export const initialUserTask = {
  id: 0,
  userId: 0,
  username: "",
  firstName: "", 
  lastName: "",  
  roleName: "",  
  available: 0, 
  taskOpen: 0,
  taskCompleted: 0
}