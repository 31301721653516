import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { GlobalDataContext } from "../contexts/GlobalDataContext";

const themeLight = createTheme({
  palette: {
    background: { default: "#F7FBFC" },
    primary: { main: "#1C1364", contrastText: "#fff" },
    warning: { main: "#ddbc19", contrastText: "#fff" },
    error: { main: "#a42a25", contrastText: "#fff" },
  },
  typography: {
    fontFamily: '"Poppins", "system-ui", "sans-serif"',
  },
});

export default function Notification() {
  const { globalData } = useContext(GlobalDataContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [notifications, setNotifications] = useState([]);
  const isFetching = useRef(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isFetching.current && notifications.length === 0) {
      isFetching.current = true; // Set fetching flag to prevent re-fetching

      const selfData = secureLocalStorage.getItem('id') ? JSON.parse(secureLocalStorage.getItem('id') as string) : null;
      const fetchTaskData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/notification/${globalData?.id}`,
            {
              headers: {
                'Authorization':`Bearer ${globalData?.accessToken}`
              }
            }
          );
          setNotifications(res.data);
        } catch (err) {
          setError("Failed to load task data.");
        } finally {
          setLoading(false);
          isFetching.current = false; // Reset fetching flag after completion
        }
      };

      fetchTaskData();
    }
  }, [notifications.length]);

  const handleNotificationClick = async (notification: any) => {
    setNotifications((prevNotifications: any) =>
      prevNotifications.map((notify: any) =>
        notify.id === notification.id ? { ...notify, status: true } : notify
      )
    );

    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/notification/${notification.id}`,
        { status: true },
        {
          headers: {
                         'Authorization': `Bearer ${globalData?.accessToken}`
                       }
         }
      );
    } catch (err) {
      console.error("Failed to update notification status:", err);
    }
    
    switch (notification?.module) {
      case "document_validation":
        navigate(`/document-validation/${notification.moduleId}`);
        break;
      case "open_task":
        navigate(`/task/${notification.moduleId}`)
        break;
      default:
        break;
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={themeLight}>
      <Container component="main" maxWidth="xl" sx={{ height: "110vh" }}>
        <CssBaseline />
        {/* Back Button */}
        <IconButton
          onClick={() => window.history.back()} // Navigate back
          disableRipple={true}
          aria-label="open"
          sx={{
            my: 2,
            padding: 0,
            paddingTop: 12,
          }}
        >
          <ArrowBackIcon sx={{ height: 28, width: 28 }} />
          <Typography sx={{ ml: 1 }}>Back</Typography>
        </IconButton>

        {/* Card Layout */}
        <Card sx={{ minWidth: 275, my: 2 }}>
          <CardContent className="tp-padding-50px">
            <Typography variant="h4" className="tp-text-header-primary">
              Notifications
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            {error && <p style={{ color: "red" }}>{error}</p>}

            {notifications.length > 0 ? (
              notifications.map((notify: any) => (
                <Box
                  key={notify.id}
                  onClick={() => handleNotificationClick(notify)}
                  sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: 1,
                    backgroundColor: "#f0f4f8",
                    border: "1px solid #e0e0e0",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: notify.status === false ? "bold" : "normal",
                    }}
                  >
                    {notify.description}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography>No notifications available.</Typography>
            )}
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}
