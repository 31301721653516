export interface IAcapTask {
    id?: number;
    sender: string;
    saveDate: string;
    taskType: string;
    channel: number;
    priority: string;
    priorityDesc: string;
    policyNo: string;
    claimNo: string;
    status: number;
    userId: number;
    businessRuleUrl: string;
    duplicatedId: number | null;
    requiredDocOverride: boolean;
    requiredDodApprove: boolean;
    requiredDocComment: string;
    caseId: string;
    emailBody: string;
    assignedUser: string;
}

export const initialAcapTask = {
    id: 0,
    sender: "",
    saveDate: "",
    taskType: "",
    channel: 0,
    priority: "",
    priorityDesc: "",
    policyNo: "",
    claimNo: "",
    status: 0,
    userId: 0,
    businessRuleUrl: "",
    duplicatedId: null,
    requiredDocOverride: false,
    requiredDodApprove: false,
    requiredDocComment: "",
    caseId: "",
    emailBody: "",
    assignedUser: ""
}