import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { format } from "date-fns";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";
// import { useBusinessRules } from "../contexts/BusinessRulesContext";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { IClaimHistory } from "../types/ClaimHistory";
import { PolicyData } from "../types/PolicyData";
import {
  capitalizeAndReplaceUnderscore,
  convertTo12HourFormat,
  convertToMilitaryTime,
  formatCustomDate,
  // convertToUTC,
  formatISODate,
  handleJSONStringArray,
  isValidDate,
  isValidTime,
  tfObjectsToArray,
} from "../utils/stringUtils";
import reserveCodes from "./reserveCode";
import { ReserveCodeKey, reserveCodeMapping } from "./reserveCodeMapping";
import { updateSfClaimRegistration } from "../services/SalesforceApi";
import { ISfClaimRegistration } from "../types/SfClaimRegistration";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import AdjusterSlip from "./document_validation/AdjusterSlip";


interface ClaimRegistrationProps {
  transId: any;
  policyNo: any;
  claimRegistrationData: any;
  setClaimRegistrationData: any;
  isValidated: any;
  dateOfLoss: any;
  sender: any;
  setDateOfLoss: any;
  timeOfLoss: any;
  claimParticipant: any;
  setClaimParticipant: any;
  syncAW: boolean;
  setTimeOfLoss: any;
  policyData: PolicyData | null;
  policyValidated: boolean;
  caseId: string;
  claimHistory: IClaimHistory[];
  setClaimHistory: (claimHistory: IClaimHistory[]) => void;
}

interface IClaimEntry {
  value: string;
  label: string;
  premiumClass: string;
}

const ClaimRegistration = ({
  transId,
  policyNo,
  claimRegistrationData,
  setClaimRegistrationData,
  isValidated,
  dateOfLoss,
  claimParticipant,
  timeOfLoss,
  syncAW,
  policyData,
  policyValidated,
  caseId,
  claimHistory,
  setClaimHistory,
}: // businessRules
  ClaimRegistrationProps) => {
  const [premiumClass, setPremiumClass] = React.useState("202");
  const [claimStatus, setClaimStatus] = React.useState("Incoming Claim");
  const [reserveCode, setReserveCode] = React.useState("");
  // const [codeDesc, setCodeDesc] = React.useState("");
  const [estimationAmount, setEstimationAmount] = React.useState("");
  const [newTagClaim, setNewTagClaim] = React.useState("");
  const [newPremiumClass, setNewPremiumClass] = React.useState("202");
  const [newReserveCode, setNewReserveCode] = React.useState("");
  const [newEstimationAmount, setNewEstimationAmount] = React.useState("");
  // const [newCodeDesc, setNewCodeDesc] = React.useState("");
  const [openAdjusterSlip, setOpenAdjusterSlip] = React.useState(false);
  const [entries, setEntries] = React.useState<
    {
      premiumClass: string;
      reserveCode: string;
      estimationAmount: string;
      tag: string;
      codeDesc: string;
    }[]
  >([]);
  // const [oldEntries, setOldEntries] = React.useState<
  //   {
  //     premiumClass: string;
  //     reserveCode: string;
  //     estimationAmount: string;
  //     tag: string;
  //     codeDesc: string;
  //   }[]
  // >([]);
  const [claimNumber, setClaimNumber] = React.useState<string>("");
  const [claimModalOpen, setClaimModalOpen] = React.useState(false);
  const [claimModalClrOpen, setClaimModalClrOpen] = React.useState(false);
  const [existingClr, setExistingClr] = React.useState([]);
  const [registering, setRegistering] = React.useState(false);
  // const [docComplete, setDocComplete] = React.useState<number>(0);
  // const [docStatus, setDocStatus] = React.useState<number>(1);
  const [documentValidationData, setDocumentValidationData] = React.useState<
    any[]
  >([]);
  const [oldDocumentValidationData, setOldDocumentValidationData] =
    React.useState<any[]>([]);
  const { globalData } = React.useContext(GlobalDataContext);
  const [claimDescription, setClaimDescription] = React.useState("");
  const [riskNumber, setRiskNumber] = React.useState<string>("1");
  const [majorEventCodes, setMajorEventCodes] = React.useState<
    { key: string; value: string }[]
  >([]);
  const [majorEventCode, setMajorEventCode] = React.useState<string>("");
  const [dateOccurs, setDateOccurs] = React.useState<string>("");
  const [timeOfLossClaim, setTimeOfLossClaim] = React.useState<string>("");
  const [dateReported, setDateReported] = React.useState<string>(
    formatISODate(new Date().toISOString())
  );
  // const { businessRules, setBusinessRules } = useBusinessRules();
  const [loading, setLoading] = React.useState(false);
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const [editIndex, setEditIndex] = React.useState<number>(0);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [claimLoading, setClaimLoading] = React.useState<boolean>(false);
  const [history, setHistory] = React.useState<CnnNccHistory[] | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [claimParticipants, setClaimParticipants] = React.useState<any[]>([]);
  const [newclaimParticipants, setNewClaimParticipants] = React.useState<any[]>([]);
  const [biCp, setBiCp] = React.useState<any[]>([]);
  const [newBiCp, setNewBiCp] = React.useState<any[]>([]);
  const [claimTag, setClaimTag] = React.useState<string>("insured");
  // const [syncAw2, setSyncAw2] = React.useState<boolean>(false);
  // Add a new state at the top of your component:
  const [docValidationUpdating, setDocValidationUpdating] = React.useState(false);

 




  const claimParticipantsChange = (event: any) => {
    setClaimParticipants(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setClaimDescription(event.target.value);
  };

  const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    // Format as MM/DD/YYYY
    return date.toLocaleDateString("en-US");
  };

  // alert(policyData?.policyNumber);

  interface CnnNccHistory {
    id: number;
    transId: number;
    saveDate: string; // ISO string
    policyNo: string;
    sender: string;
    status: number;
    emailBody: string;
    type: string;
    submittedDate: string; // ISO string
  }

  interface GenericResponse<T> {
    data: T | null;
    message: string;
    success: boolean;
  }

  const premiumClasses = [
    { value: "201", label: "201 - COMPULSORY TPL - BODILY INJURY" },
    { value: "202", label: "202 - OWN DAMAGE" },
    { value: "203", label: "203 - THEFT - Carnap" },
    { value: "2031", label: "203 - THEFT - Partial Theft" },
    { value: "205", label: "205 - VTPL  BODILY INJURY" },
    { value: "206", label: "206 - VTPL - PROPERTY DAMAGE" },
    { value: "2061", label: "206 - VTPL - PROPERTY DAMAGE Other than Vehicle" },
    { value: "207", label: "207 - AUTO PERSONAL ACCIDENT" },
    { value: "209", label: "209 - ACTS OF NATURE" },
    { value: "211", label: "211 - STRIKES, RIOTS, CIVIL COMMOTIONS" },
    { value: "212", label: "212 - 24-HOUR ROADSIDE ASSISTANCE" },
    { value: "214", label: "214 - ALTERNATIVE TRANSPO ALLOWANCE" },
    { value: "216", label: "216 - CSL VTPL-BI/PD" },
  ];

  // const navigate = useNavigate();

  const claimDescriptions = [
    "Bumped a pedestrian",
    "Bumped another property",
    "Bumped by another vehicle",
    "Carnap- by use of force",
    "Carnap- while parked",
    "Carnap- failed to return",
    "Collision",
    "Damage due to AON",
    "Damage due to Riot, Strike and Civil Commotion",
    "Damage while parked unattended",
    "Damage windshield",
    "Hit another vehicle",
    "Animal Bites",
    "Hit an Animal",
    "Partial theft",
    "Self-accident",
    "Fire loss",
    "Vandalism",
    "Others",
  ];

  const claimStatuses = [
    { value: "Incoming Claim", label: "Incoming Claim" },
    {
      value: "Pending In-house Survey (Clec)",
      label: "Pending In-house Survey (Clec)",
    },
    {
      value: "Pending Adjuster's Survey (IMSI)",
      label: "Pending Adjuster Survey (IMSI)",
    },
    {
      value: "Pending Adjuster's Report",
      label: "Pending Adjuster's Report",
    },
    { value: "Pending Documents", label: "Pending Documents" },
    { value: "Pending for Offer", label: "Pending for Offer" },
  ];

  const docStatusOptions = [
    { value: 1, label: "Incoming Claim" },
    { value: 18, label: "Pending Documents" },
    { value: 21, label: "Pending In-house Survey (Clec)" },
    { value: 22, label: "Pending Adjuster's Survey (IMSI)" },
    { value: 9, label: "Pending for Offer" },
    { value: 19, label: "Pending Adjuster's Report" },
    // { value: 31, label: "Closed Withdrawn" },
    { value: 29, label: "Pending for Authorization" },
    { value: 30, label: "Pending Claimant Acceptance" },
    { value: 33, label: "Pending Shop's Bill" },
    { value: 34, label: "Pending for Payment" },
    { value: 35, label: "Pending for Recovery" },
    { value: 36, label: "Closed" },
  ];

  const handlePremiumClassChange = (event: any) => {
    const newPremiumClass = event.target.value;
    setPremiumClass(newPremiumClass);
    setReserveCode(""); // Reset reserve code when premium class changes
    // Reset tag to "insured" if premium class doesn't support custom tags
    if (!["206", "2061"].includes(newPremiumClass)) {
      setClaimTag("insured");
    } else if ("206".includes(newPremiumClass)) {
      setClaimTag("tp1");
    } else if ("2061".includes(newPremiumClass)) {
      setClaimTag("p1");
    }
  };

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const handleClaimStatusChange = (event: any) => {
    setClaimStatus(event.target.value);
  };

  const handleReserveCodeChange = (event: any) => {
    setReserveCode(event.target.value);
  };

  const handleEstimationAmountChange = (event: any) => {
    setEstimationAmount(event.target.value);
  };

  const handleAddEntry = () => {
    if (entries.length < 10) {
      let adjustedPremiumClass = premiumClass;
      if (premiumClass === "2031") {
        adjustedPremiumClass = "2031";
      } else if (premiumClass === "2061") {
        adjustedPremiumClass = "2061";
      }
      // alert(adjustedPremiumClass);

      setEntries([
        ...entries,
        {
          premiumClass: adjustedPremiumClass,
          reserveCode: reserveCode,
          estimationAmount: parseFloat(estimationAmount).toLocaleString(
            "en-PH",
            { style: "currency", currency: "PHP" }
          ),
          tag: claimTag,
          codeDesc: reserveCode
            ? reserveCodes.find(
              (rc) =>
                rc.value === reserveCode &&
                rc.premiumClass === adjustedPremiumClass
            )?.label || ""
            : "",
        },
      ]);

      console.log('entries', entries);
      setPremiumClass("");
      setReserveCode("");
      setEstimationAmount("");
    }
  };

  const handleDeleteEntry = (index: any) => {
    setEntries(entries.filter((entry, i) => i !== index));
  };

  const filteredReserveCodes = reserveCodes.filter(
    (code) => code.premiumClass === premiumClass
  );

  const getPolicyAsiaRecord = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transId}`, {
      headers: {
        'Authorization': `Bearer ${globalData?.accessToken}`
      }
    }
    );
    return response.data?.data?.policyNumber;
  };

  React.useEffect(() => {
    const fetchCnnNccHistory = async () => {
      if (!policyValidated && !policyNo) {
        console.warn(
          "Policy number is validated. Skipping CNN/NCC history fetch."
        );
        setHistory([]);
        setError("Policy number is missing.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null); // Reset error state

      try {
        const resPolicyNo = await getPolicyAsiaRecord();

        const response = await axios.get<GenericResponse<CnnNccHistory[]>>(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/cnn-ncc-history/by-policy-no`, // Updated URL
          {
            params: { policyNo: resPolicyNo },
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        if (response.data.data) {
          setHistory(response.data.data);
        } else {
          setHistory([]);
          setError(response.data.message || "No CNC/NCC History found.");
        }
      } catch (err: any) {
        console.error("Error fetching CNN/NCC History:", err);
        setError(
          err.message || "An error occurred while fetching CNN/NCC History."
        );
        setHistory([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCnnNccHistory();
  }, [transId, policyNo, policyValidated]); // Updated dependencies

  React.useEffect(() => {
    const fetchClaimHistory = async () => {
      if (!policyValidated && !policyNo) {
        console.warn(
          "Policy number is not validated. Skipping Claims history fetch."
        );
        setClaimHistory([]);
        setError("Policy number is missing.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null); // Reset error state

      // await axios.get(
      //   `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/history?policyNo=${policyNo}`,
      // ).then((res: any) => {

      //   setClaimHistory(res.data.data)
      // }).catch((err: any) => {

      // })

      try {
        const resPolicyNo = await getPolicyAsiaRecord();

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/history?policyNo=${resPolicyNo}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        if (response.data.data) {
          setClaimHistory(response.data.data);
        } else {
          setClaimHistory([]);
          setError(response.data.message || "No Claim History found.");
        }
      } catch (err: any) {
        console.error("Error fetching Claim History:", err);
        setError(
          err.message || "An error occurred while fetching Claim History."
        );
        setClaimHistory([]);
      } finally {
        setLoading(false);
      }
    };

    fetchClaimHistory();
  }, [transId, policyNo, policyValidated]);

  // region for existing entries

  const claimsModificationCreate = async (
    updatedEntries: {
      premiumClass: string;
      reserveCode: string;
      estimationAmount: string;
      codeDesc: string;
      tag: string;
    }[]
  ) => {
    setClaimLoading(true);
    // Replace these with actual values from your context or fetched data
    const riskNumber = claimRegistrationData?.riskNumber; // Example value
    const policyNo = claimRegistrationData?.policyNumber; // Example value
    const claimStatus = claimRegistrationData?.claimStatus; // Example value
    const dateOccurs = claimRegistrationData?.dateLoss; // Example value
    const claimDescription = claimRegistrationData?.claimDesc; // Example value
    const dateReported = claimRegistrationData?.dateReported;
    const majorEventCode = claimRegistrationData?.claimMajoreventcode;
    const claimNumber = claimRegistrationData?.claimNumber;
    // Update claimFilingapprovaltime to current datetime
    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 8);
    const getKeyByValue = (value: string): ReserveCodeKey | undefined => {
      return (Object.keys(reserveCodeMapping) as ReserveCodeKey[]).find(
        (key) => reserveCodeMapping[key] === value
      );
    };
    const formattedDateTime = currentDateTime
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const claimsPayload = {
      claimRiskNumber: riskNumber,
      claimNumber: claimNumber,
      policyNumber: policyNo,
      claimStatus: claimStatus,
      dateOccurs: format(new Date(dateOccurs), "yyyy-MM-dd"),
      claimDescription: claimDescription,
      dateReported: dateReported,
      subrogation: "N",
      claimMajoreventCode: majorEventCode,
      balanceOutstanding01: updatedEntries[0]?.estimationAmount || "",
      balanceOutstanding02: updatedEntries[1]?.estimationAmount || "",
      balanceOutstanding03: updatedEntries[2]?.estimationAmount || "",
      balanceOutstanding04: updatedEntries[3]?.estimationAmount || "",
      balanceOutstanding05: updatedEntries[4]?.estimationAmount || "",
      balanceOutstanding06: updatedEntries[5]?.estimationAmount || "",
      balanceOutstanding07: updatedEntries[6]?.estimationAmount || "",
      balanceOutstanding08: updatedEntries[7]?.estimationAmount || "",
      balanceOutstanding09: updatedEntries[8]?.estimationAmount || "",
      balanceOutstanding10: updatedEntries[9]?.estimationAmount || "",
      premiumClass01: updatedEntries[0]?.premiumClass.slice(0, 3) || "",
      premiumClass02: updatedEntries[1]?.premiumClass.slice(0, 3) || "",
      premiumClass03: updatedEntries[2]?.premiumClass.slice(0, 3) || "",
      premiumClass04: updatedEntries[3]?.premiumClass.slice(0, 3) || "",
      premiumClass05: updatedEntries[4]?.premiumClass.slice(0, 3) || "",
      premiumClass06: updatedEntries[5]?.premiumClass.slice(0, 3) || "",
      premiumClass07: updatedEntries[6]?.premiumClass.slice(0, 3) || "",
      premiumClass08: updatedEntries[7]?.premiumClass.slice(0, 3) || "",
      premiumClass09: updatedEntries[8]?.premiumClass.slice(0, 3) || "",
      premiumClass10: updatedEntries[9]?.premiumClass.slice(0, 3) || "",
      reserveCode01: updatedEntries[0]?.reserveCode || "",
      reserveCode02: updatedEntries[1]?.reserveCode || "",
      reserveCode03: updatedEntries[2]?.reserveCode || "",
      reserveCode04: updatedEntries[3]?.reserveCode || "",
      reserveCode05: updatedEntries[4]?.reserveCode || "",
      reserveCode06: updatedEntries[5]?.reserveCode || "",
      reserveCode07: updatedEntries[6]?.reserveCode || "",
      reserveCode08: updatedEntries[7]?.reserveCode || "",
      reserveCode09: updatedEntries[8]?.reserveCode || "",
      reserveCode10: updatedEntries[9]?.reserveCode || "",
    };

    const claimsPayloadDb = {
      ...claimRegistrationData,
      timeLoss: convertToMilitaryTime(claimRegistrationData.timeLoss),
      balanceOutstanding01: updatedEntries[0]?.estimationAmount || "",
      balanceOutstanding02: updatedEntries[1]?.estimationAmount || "",
      balanceOutstanding03: updatedEntries[2]?.estimationAmount || "",
      balanceOutstanding04: updatedEntries[3]?.estimationAmount || "",
      balanceOutstanding05: updatedEntries[4]?.estimationAmount || "",
      balanceOutstanding06: updatedEntries[5]?.estimationAmount || "",
      balanceOutstanding07: updatedEntries[6]?.estimationAmount || "",
      balanceOutstanding08: updatedEntries[7]?.estimationAmount || "",
      balanceOutstanding09: updatedEntries[8]?.estimationAmount || "",
      balanceOutstanding10: updatedEntries[9]?.estimationAmount || "",
      premiumClass01: updatedEntries[0]?.premiumClass || "",
      premiumClass02: updatedEntries[1]?.premiumClass || "",
      premiumClass03: updatedEntries[2]?.premiumClass || "",
      premiumClass04: updatedEntries[3]?.premiumClass || "",
      premiumClass05: updatedEntries[4]?.premiumClass || "",
      premiumClass06: updatedEntries[5]?.premiumClass || "",
      premiumClass07: updatedEntries[6]?.premiumClass || "",
      premiumClass08: updatedEntries[7]?.premiumClass || "",
      premiumClass09: updatedEntries[8]?.premiumClass || "",
      premiumClass10: updatedEntries[9]?.premiumClass || "",
      reserveCode01: updatedEntries[0]?.reserveCode || "",
      reserveCode02: updatedEntries[1]?.reserveCode || "",
      reserveCode03: updatedEntries[2]?.reserveCode || "",
      reserveCode04: updatedEntries[3]?.reserveCode || "",
      reserveCode05: updatedEntries[4]?.reserveCode || "",
      reserveCode06: updatedEntries[5]?.reserveCode || "",
      reserveCode07: updatedEntries[6]?.reserveCode || "",
      reserveCode08: updatedEntries[7]?.reserveCode || "",
      reserveCode09: updatedEntries[8]?.reserveCode || "",
      reserveCode10: updatedEntries[9]?.reserveCode || "",
      codeDesc01: updatedEntries[0]?.codeDesc || "",
      codeDesc02: updatedEntries[1]?.codeDesc || "",
      codeDesc03: updatedEntries[2]?.codeDesc || "",
      codeDesc04: updatedEntries[3]?.codeDesc || "",
      codeDesc05: updatedEntries[4]?.codeDesc || "",
      codeDesc06: updatedEntries[5]?.codeDesc || "",
      codeDesc07: updatedEntries[6]?.codeDesc || "",
      codeDesc08: updatedEntries[7]?.codeDesc || "",
      codeDesc09: updatedEntries[8]?.codeDesc || "",
      codeDesc10: updatedEntries[9]?.codeDesc || "",
      tag01: updatedEntries[0]?.tag || "",
      tag02: updatedEntries[1]?.tag || "",
      tag03: updatedEntries[2]?.tag || "",
      tag04: updatedEntries[3]?.tag || "",
      tag05: updatedEntries[4]?.tag || "",
      tag06: updatedEntries[5]?.tag || "",
      tag07: updatedEntries[6]?.tag || "",
      tag08: updatedEntries[7]?.tag || "",
      tag09: updatedEntries[8]?.tag || "",
      tag10: updatedEntries[9]?.tag || "",
    };

    // Assuming the backend expects claimFilingapprovaltime
    // If claimFilingapprovaltime is part of the payload, include it
    // If not, ensure your backend sets it automatically
    (claimsPayload as any)["claimFilingapprovaltime"] = formattedDateTime;
    // (claimsPayloadDb as any)["claimFilingapprovaltime"] = currentDatetime;
    // (claimsPayloadDb as any)["saveDate"] = currentDatetime;

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration`,
        claimsPayloadDb, // Adjust based on what your backend expects

        {
          params: { transId: transId },
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      // ... [rest of your try block]
      // alert("Claim DB updated successfully!");
    } catch (err: any) {
      // Handle errors
      alert(err.message || "An error occurred while updating the claim.");
    }
    setClaimLoading(false);
  }


  const toggleEditDialog = (open: boolean, index: number) => {
    setOpenDialog(open);
    setEditIndex(index);
    if (open === true) {
      setNewPremiumClass(entries[index]?.premiumClass);
      setNewReserveCode(entries[index]?.reserveCode);
      setNewEstimationAmount(entries[index]?.estimationAmount);
      setNewTagClaim(entries[index]?.tag)
    } else {
      setNewPremiumClass("");
      setNewReserveCode("");
      setNewEstimationAmount("");
      setNewTagClaim("insured");
    }
  };

  const handleNewPremiumClassChange = (event: any, index: number) => {
    setNewPremiumClass(event.target.value);
  };

  const handleNewReserveCodeChange = (event: any, index: number) => {
    setNewReserveCode(event.target.value);
  };

  // const handleNewTagClaimChange = (event: any, index: number) => {
  //   setNewTagClaim(event.target.value);
  // };

  const handleNewEstimationAmountChange = (event: any, index: number) => {
    setNewEstimationAmount(
      parseFloat(event.target.value).toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      })
    );
  };

  const handleSaveEditedEntry = (index: number) => {
    setEntries((prevEntries) => {
      // Create a new array copy
      const updatedEntries = [...prevEntries];

      // Update the specific attribute in the object at the given index
      updatedEntries[index] = {
        premiumClass: newPremiumClass,
        reserveCode: newReserveCode,
        estimationAmount: newEstimationAmount,
        tag: newTagClaim,
        codeDesc: newReserveCode
          ? reserveCodes.find((rc) => rc.value === newReserveCode)?.label || ""
          : "",
      };

      claimsModificationCreate(updatedEntries);
      // Return the modified array to update the state
      return updatedEntries;
    });

    toggleEditDialog(false, 0);
  };

  const handleDuplicateOwnDamage = async () => {
    // Create regex with word boundaries and 'i' for case-insensitive search
    const regex = /\bOWN DAMAGE\b/i;
    const filteredOwnDamage = entries.filter((e) => regex.test(e.codeDesc));

    if (entries.length + filteredOwnDamage.length > 10) {
      alert("Cannot add entries, maximum 10 entries only");
      return;
    }

    if (filteredOwnDamage.length === 0) {
      alert("Cannot duplicate entries, no own damage");
      return;
    }

    // Update entries state once
    setEntries([...entries, ...filteredOwnDamage]);

    // Update claim registration with the new entries
    await claimsModificationCreate([...entries, ...filteredOwnDamage]);

    // tag duplicate own damage
    const duplicatedItems = filteredOwnDamage.map((el) => ({
      ...el,
      duplicate: true
    }))

    // add ids
    const updatedEntriesWithId: {
        id?: string;
        duplicate?: boolean;
        premiumClass: string;
        reserveCode: string;
        estimationAmount: string;
        tag: string;
        codeDesc: string;
    }[] = [...entries, ...duplicatedItems].map((obj, index) => ({
      id: String(index + 1).padStart(2, "0"),
      ...obj
    }));

    // collect ids from filtered items
    const filteredUpdatedOwnDamage = updatedEntriesWithId.filter((e) => regex.test(e.codeDesc) && e?.duplicate === true );
    const collectedReserveCodeIds = filteredUpdatedOwnDamage.map(obj => obj.id).join(",");
    console.log(collectedReserveCodeIds)

    // Execute the document validation POST request
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${globalData?.accessToken}`
      },
      body: JSON.stringify({
        transId: transId,
        perilCode: 202,
        perilType: "202 - OWN DAMAGE",
        docComplete: 0,
        status: {
          id: 1,
          statusNo: 21
        },
        tag: "insured",
        userDetails: `${globalData.firstName} ${globalData.lastName}`,
        userId: globalData.id,
        datetimeSubmitted: formattedDateTime,
        reserveCode: collectedReserveCodeIds
      }),
    });

    // Refresh document validation data without requiring a page refresh
    await fetchDocumentValidationData();
  };

  const handleAddNewEntry = () => {
    if (entries.length < 10) {
      let adjustedPremiumClass = premiumClass;
      if (premiumClass === "2031") {
        adjustedPremiumClass = "2031";
      } else if (premiumClass === "2061") {
        adjustedPremiumClass = "2061";
      }

      setEntries((prevEntries) => {
        // Create a new array copy
        const updatedEntries = [
          ...prevEntries,
          {
            premiumClass: adjustedPremiumClass,
            reserveCode: reserveCode,
            estimationAmount: parseFloat(estimationAmount).toLocaleString(
              "en-PH",
              { style: "currency", currency: "PHP" }
            ),
            tag: claimTag,
            codeDesc: reserveCode
              ? reserveCodes.find(
                (rc) =>
                  rc.value === reserveCode &&
                  rc.premiumClass === adjustedPremiumClass
              )?.label || ""
              : "",
          },
        ];
        console.log('updated entries', updatedEntries);

        claimsModificationCreate(updatedEntries);
        // Return the modified array to update the state
        return updatedEntries;
      });
      setPremiumClass("");
      setReserveCode("");
      setEstimationAmount("");
    }
  };

  // const handleDeleteNewEntry = (index: any) => {
  //   setEntries((prevEntries) => {
  //     // Create a new array copy
  //     const updatedEntries = prevEntries.filter((entry, i) => i !== index);

  //     claimsModificationCreate(updatedEntries);
  //     // Return the modified array to update the state
  //     return updatedEntries;
  //   });
  //   // setEntries(entries.filter((entry, i) => i !== index));
  // };
  const getPayloadPremiumClass = (pc?: string): string => {
    if (!pc) return "";
    return pc.slice(0, 3);
  };
  const filteredExistingReserveCode = (
    premiumClassCode: string
  ): IClaimEntry[] => {
    return reserveCodes.filter(
      (code) => code.premiumClass === premiumClassCode
    );
  };

  const isDisabled = entries.length >= 10;

  async function validateDateOfLoss(dateOfLoss: any, policyInceptionDate: any, policyExpiryDate: any) {
    // Ensure both dates are defined and valid

    if (!dateOfLoss || !policyInceptionDate) {
      // Attempt to fetch or refresh policyInceptionDate if it's undefined
      if (!policyInceptionDate) {
        try {
          const policyResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${globalData?.accessToken}`
              },
            }
          );

          if (!policyResponse.ok) {
            throw new Error(`Error: ${policyResponse.statusText}`);
          }

          const policyData = await policyResponse.json();
          policyInceptionDate = policyData.data.inceptionDate;
          policyExpiryDate = policyData.data.expiryDate;

          if (!policyInceptionDate || !policyExpiryDate) {
            alert(
              "Error: Policy Inception or Expiry Date is still missing or invalid after fetch."
            );
            return false; // Invalid due to missing date
          }
        } catch (error) {
          console.error("Error fetching policy inception/expiry date:", error);
          alert("Error: Unable to fetch Policy Inception/exipiry Date.");
          return false; // Invalid due to fetch error
        }
      } else {
        alert(
          "Error: Date of Loss or Policy Inception/Expiry Date is missing or invalid."
        );
        return false; // Invalid due to missing date
      }
    }

    // Convert both dates to Date objects
    const lossDate = new Date(dateOfLoss);
    const formattedDate = `${policyInceptionDate.slice(
      4,
      6
    )}/${policyInceptionDate.slice(6, 8)}/${policyInceptionDate.slice(0, 4)}`;
    const expiryFormattedDate = `${policyExpiryDate.slice(
      4,
      6
    )}/${policyExpiryDate.slice(6, 8)}/${policyExpiryDate.slice(0, 4)}`;
    const inceptionDate = new Date(formattedDate);
    const expiryDate = new Date(expiryFormattedDate);
    // Check if the Date of Loss is before the Policy Issuance Date
    const currentDateTime = new Date();
    if (lossDate < inceptionDate) {
      return false; // Invalid claim (Date of Loss is before Policy Issuance Date)
    } else if (lossDate > currentDateTime) {
      alert("Date of loss is in the future. Cannot register claim.");
      return false; // Invalid claim (Date of Loss is in the future)
    } else if (lossDate > expiryDate) {
      alert("Date of loss is after the expiry date of the policy. Cannot register claim.");
      return false; // Invalid claim (Date of Loss is after the Policy Expiry Date)
    } else {
      return true; // Valid claim
    }
  }


  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() + 8);
  const formattedDateTime = currentDateTime
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  function validateEntries(
    policyDataItemCoverage: {
      itemCoverage: string;
      sumInsuredperItemCoverage: number;
    }[],
    entries: {
      premiumClass: string;
    }[]
  ): {
    success: boolean;
    message: string;
  } {
    // Create a lookup map for efficient access
    const policyCoverageMap: Record<string, number> =
      policyDataItemCoverage.reduce((map, policy) => {
        map[policy.itemCoverage] = policy.sumInsuredperItemCoverage;
        return map;
      }, {} as Record<string, number>);


    // Validate each entry
    for (const entry of entries) {
      const sumInsured = policyCoverageMap[entry.premiumClass.slice(0, 3)];



      // Check if premiumClass exists in policyDataItemCoverage
      if (sumInsured === undefined) {
        return {
          success: false,
          message: `Cannot Register Claim: Premium Class "${entry.premiumClass}" is not present in Sum Insured Details.`,
        };
      }

      // Check if sumInsuredperItemCoverage is greater than 0
      if (sumInsured <= 0) {
        return {
          success: false,
          message: `Cannot Register Claim: Item Coverage "${entry.premiumClass}" has a Sum Insured of 0 or less.`,
        };
      }
    }

    // If all validations pass
    return {
      success: true,
      message: "Validation succeeded: All entries are valid.",
    };
  }

  // #region handleFinalSubmit
  const handleFinalSubmit = async () => {
    setRegistering(true);



    currentDateTime.setHours(currentDateTime.getHours() + 8);
    const formattedDateTime = currentDateTime
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const filingTime = new Date(new Date().getTime() - 4 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");



    console.log("Entries", entries);

    const policyNoResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${globalData?.accessToken}`
        },
      }
    );

    const policyNoResponseData = await policyNoResponse.json();

    const policyNo = policyNoResponseData.data.policyNumber;

    // Constructing the claims payload dynamically
    // Helper function to remove currency symbols and commas
    const parseAmount = (amount: string): number =>
      parseFloat(amount.replace(/[^0-9.-]+/g, ""));

    // Group premiumClass "206" entries by reserveCode and sum the estimationAmount.
    const group206Entries = (entries: any[]) => {
      return entries.reduce((acc, entry) => {
        if (entry.premiumClass.slice(0, 3) === "206") {
          const key = entry.reserveCode;
          const amt = parseAmount(entry.estimationAmount);
          if (acc[key]) {
            acc[key].estimationAmount += amt;
          } else {
            // Clone the entry, but replace the estimationAmount with a numeric value for now.
            acc[key] = { ...entry, estimationAmount: amt };
          }
        }
        return acc;
      }, {} as { [key: string]: any });
    };

    const buildGroupedEntries = (entries: any[]) => {
      // Separate non-206 and 206 entries
      const non206Entries = entries.filter(
        (e) => e.premiumClass.slice(0, 3) !== "206"
      );
      const premium206Entries = entries.filter(
        (e) => e.premiumClass.slice(0, 3) === "206"
      );

      // Group the 206 entries by reserveCode and sum their amounts.
      const grouped206 = group206Entries(premium206Entries);

      // Convert summed amounts back to formatted strings (assuming currency ₱)
      const grouped206Array = Object.values(grouped206).map((item) => {
        const typedItem = item as { estimationAmount: number } & Record<string, any>;
        return {
          ...typedItem,
          estimationAmount: `₱${typedItem.estimationAmount.toFixed(2)}`,
        };
      });

      // Return a new array that first has the non-206 entries and then the grouped 206 entries.
      return [...non206Entries, ...grouped206Array];
    };

    // Usage:
    const newEntries = buildGroupedEntries(entries);

    // Now, build your claimsPayload using the newEntries array.
    // The first two entries remain unchanged (non‑206) and the next two are the grouped 206 entries.
    const claimsPayload = {
      claimRiskNumber: riskNumber,
      policyNumber: policyNo,
      claimStatus: claimStatus,
      dateOccurs: format(new Date(dateOccurs), "yyyy-MM-dd"),
      claimDescription: claimDescription,
      dateReported: dateReported,
      subrogation: "N",
      claimMajoreventCode: majorEventCode,
      balanceOutstanding01: newEntries[0]?.estimationAmount || "",
      balanceOutstanding02: newEntries[1]?.estimationAmount || "",
      balanceOutstanding03: newEntries[2]?.estimationAmount || "",
      balanceOutstanding04: newEntries[3]?.estimationAmount || "",
      balanceOutstanding05: newEntries[4]?.estimationAmount || "",
      balanceOutstanding06: newEntries[5]?.estimationAmount || "",
      balanceOutstanding07: newEntries[6]?.estimationAmount || "",
      balanceOutstanding08: newEntries[7]?.estimationAmount || "",
      balanceOutstanding09: newEntries[8]?.estimationAmount || "",
      balanceOutstanding10: newEntries[9]?.estimationAmount || "",
      premiumClass01: newEntries[0]?.premiumClass.slice(0, 3) || "",
      premiumClass02: newEntries[1]?.premiumClass.slice(0, 3) || "",
      premiumClass03: newEntries[2]?.premiumClass.slice(0, 3) || "",
      premiumClass04: newEntries[3]?.premiumClass.slice(0, 3) || "",
      premiumClass05: newEntries[4]?.premiumClass.slice(0, 3) || "",
      premiumClass06: newEntries[5]?.premiumClass.slice(0, 3) || "",
      premiumClass07: newEntries[6]?.premiumClass.slice(0, 3) || "",
      premiumClass08: newEntries[7]?.premiumClass.slice(0, 3) || "",
      premiumClass09: newEntries[8]?.premiumClass.slice(0, 3) || "",
      premiumClass10: newEntries[9]?.premiumClass.slice(0, 3) || "",
      reserveCode01: newEntries[0]?.reserveCode || "",
      reserveCode02: newEntries[1]?.reserveCode || "",
      reserveCode03: newEntries[2]?.reserveCode || "",
      reserveCode04: newEntries[3]?.reserveCode || "",
      reserveCode05: newEntries[4]?.reserveCode || "",
      reserveCode06: newEntries[5]?.reserveCode || "",
      reserveCode07: newEntries[6]?.reserveCode || "",
      reserveCode08: newEntries[7]?.reserveCode || "",
      reserveCode09: newEntries[8]?.reserveCode || "",
      reserveCode10: newEntries[9]?.reserveCode || "",
      claimFilingapprovaltime: filingTime,
    };

    console.log("Claims Payload", claimsPayload);


    try {
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              claims: claimsPayload,
            },
            signature: "",
          }),
        }
      );

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      const claimRegister = await fetch(
        `${process.env.REACT_APP_CLAIM_ENDPOINT}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              claims: claimsPayload,
            },
            signature: signature,
          }),
        }
      );

      if (!claimRegister.ok) {
        alert("Fail to register claim. Ask BPI MS IT for assistance.");
        throw new Error(`Error: ${claimRegister.statusText}`);
      }

      const claimRegisterData = await claimRegister.json();
      setClaimNumber(claimRegisterData.data.claimNumber);


      // Additional POST request after claim registration
      const claimRegistrationPayload = {
        transId: transId,
        saveDate: formattedDateTime,
        status: 1, // Set the status to 1 as default
        policyNumber: policyNo,
        claimNumber: claimRegisterData.data.claimNumber,
        riskNumber: riskNumber,
        dateLoss: format(new Date(dateOccurs), "yyyy-MM-dd"),
        timeLoss: convertToMilitaryTime(timeOfLossClaim),
        dateReported: format(new Date(dateReported), "yyyy-MM-dd"),
        claimDesc: claimDescription,
        claimMajoreventcode: majorEventCode,
        claimStatus: claimStatus,
        subrogation: "N",
        balanceOutstanding01: entries[0]?.estimationAmount || "",
        balanceOutstanding02: entries[1]?.estimationAmount || "",
        balanceOutstanding03: entries[2]?.estimationAmount || "",
        balanceOutstanding04: entries[3]?.estimationAmount || "",
        balanceOutstanding05: entries[4]?.estimationAmount || "",
        balanceOutstanding06: entries[5]?.estimationAmount || "",
        balanceOutstanding07: entries[6]?.estimationAmount || "",
        balanceOutstanding08: entries[7]?.estimationAmount || "",
        balanceOutstanding09: entries[8]?.estimationAmount || "",
        balanceOutstanding10: entries[9]?.estimationAmount || "",
        premiumClass01: entries[0]?.premiumClass || "",
        premiumClass02: entries[1]?.premiumClass || "",
        premiumClass03: entries[2]?.premiumClass || "",
        premiumClass04: entries[3]?.premiumClass || "",
        premiumClass05: entries[4]?.premiumClass || "",
        premiumClass06: entries[5]?.premiumClass || "",
        premiumClass07: entries[6]?.premiumClass || "",
        premiumClass08: entries[7]?.premiumClass || "",
        premiumClass09: entries[8]?.premiumClass || "",
        premiumClass10: entries[9]?.premiumClass || "",
        reserveCode01: entries[0]?.reserveCode || "",
        reserveCode02: entries[1]?.reserveCode || "",
        reserveCode03: entries[2]?.reserveCode || "",
        reserveCode04: entries[3]?.reserveCode || "",
        reserveCode05: entries[4]?.reserveCode || "",
        reserveCode06: entries[5]?.reserveCode || "",
        reserveCode07: entries[6]?.reserveCode || "",
        reserveCode08: entries[7]?.reserveCode || "",
        reserveCode09: entries[8]?.reserveCode || "",
        reserveCode10: entries[9]?.reserveCode || "",
        codeDesc01: entries[0]?.codeDesc || "",
        codeDesc02: entries[1]?.codeDesc || "",
        codeDesc03: entries[2]?.codeDesc || "",
        codeDesc04: entries[3]?.codeDesc || "",
        codeDesc05: entries[4]?.codeDesc || "",
        codeDesc06: entries[5]?.codeDesc || "",
        codeDesc07: entries[6]?.codeDesc || "",
        codeDesc08: entries[7]?.codeDesc || "",
        codeDesc09: entries[8]?.codeDesc || "",
        codeDesc10: entries[9]?.codeDesc || "",
        tag01: entries[0]?.tag || "",
        tag02: entries[1]?.tag || "",
        tag03: entries[2]?.tag || "",
        tag04: entries[3]?.tag || "",
        tag05: entries[4]?.tag || "",
        tag06: entries[5]?.tag || "",
        tag07: entries[6]?.tag || "",
        tag08: entries[7]?.tag || "",
        tag09: entries[8]?.tag || "",
        tag10: entries[9]?.tag || "",
        claimFilingapprovaltime: formattedDateTime,
        claimParticipants:
          Array.isArray(claimParticipants) && claimParticipants.length === 1 && claimParticipants[0] === ""
            ? ""
            : Array.isArray(claimParticipants) ? claimParticipants.join(", ") : "",
        biCp:
          Array.isArray(biCp) && biCp.length === 1 && biCp[0] === ""
            ? ""
            : Array.isArray(biCp) ? biCp.join(", ") : "",
      };


      const claimRegistrationResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
          body: JSON.stringify(claimRegistrationPayload),
        }
      );

      if (!claimRegistrationResponse.ok) {
        throw new Error(`Error: ${claimRegistrationResponse.statusText}`);
      }

      // create log
      createAuditTrailData(
        globalData!.accessToken!,
        {
          transId: transId,
          taskType: "document_validation",
          event: "register_claim",
          description: `Register claim with claim number #${claimRegistrationPayload.claimNumber}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(
            globalData.firstName
          )} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role,
        });

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`,
        {
          method: "PUT",
          body: JSON.stringify({
            status:
              claimStatus === "Incoming Claim"
                ? 1
                : claimStatus === "Pending Documents"
                  ? 18
                  : claimStatus === "Pending In-house Survey (Clec)"
                    ? 21
                    : claimStatus === "Pending Adjuster's Survey (IMSI)"
                      ? 22
                      : claimStatus === "Pending for Offer"
                        ? 9
                        : claimStatus === "Pending Adjuster's Report"
                          ? 19
                          : 0,
            claimNo: claimRegisterData.data.claimNumber,
            policyNo: policyNo,
            dateLoss: format(new Date(dateOccurs), "yyyy-MM-dd"),
          }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      // await fetch(
      //   `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/approve?id=${transId}`,
      //   {
      //     method: "POST",
      //   }
      // );
      // }

      // POST request for document validation
      const uniquePerilLabels = Array.from(
        new Set(
          entries.map(
            (entry) =>
              premiumClasses.find((pc) => pc.value === entry.premiumClass)
                ?.label
          )
        )
      ).filter((label): label is string => label !== undefined);
      let docCompleteValue =
        claimStatus === "Pending for Offer" ||
          claimStatus === "Pending Adjuster's Report"
          ? 1
          : 0;

      for (const perilLabel of uniquePerilLabels) {
        console.log('perilLabel', perilLabel)
        if (perilLabel === "207 - AUTO PERSONAL ACCIDENT" && claimParticipants.length > 0) {
          const participants = typeof claimParticipants === 'string'
            ? (claimParticipants as string).split(',').map((p: string) => p.trim())
            : Array.isArray(claimParticipants)
              ? claimParticipants.flatMap((participant: string) => participant.split(',').map(p => p.trim()))
              : [];
          participants.forEach((participant: string, index: number) => {
            const tag = `cp${index + 1}`;
            fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${globalData?.accessToken}`
                },
                body: JSON.stringify({
                  transId: transId,
                  perilCode: perilLabel.slice(0, 3),
                  perilType: perilLabel,
                  // premiumClasses.find((pc) => pc.value === perilCode)
                  //   ?.label || "",
                  docComplete: 0,

                  status: {
                    id: 1,
                    statusNo:
                      claimStatus === "Incoming Claim"
                        ? 1
                        : claimStatus === "Pending Documents"
                          ? 18
                          : claimStatus === "Pending In-house Survey (Clec)"
                            ? 21
                            : claimStatus === "Pending Adjuster's Survey (IMSI)"
                              ? 22
                              : claimStatus === "Pending for Offer"
                                ? 9
                                : claimStatus === "Pending Adjuster's Report"
                                  ? 19
                                  : 0,
                  },
                  claimParticipant: participant,
                  tag: tag,
                  userDetails: `${globalData.firstName} ${globalData.lastName}`,
                  userId: globalData.id,
                  datetimeSubmitted: formattedDateTime,
                }),
              }
            )
              .then((docValidationResponse) => {
                if (!docValidationResponse.ok) {
                  throw new Error(`Error: ${docValidationResponse.statusText}`);
                }
              })
              .catch((error) => {
                console.error(
                  "Error during document validation POST request:",
                  error
                );
              });
          });
        }
        else if (
          perilLabel === "206 - VTPL - PROPERTY DAMAGE" &&
          new Set(entries.filter((entry) => entry.tag.startsWith("tp")).map(entry => entry.tag)).size >= 2
        ) {
          // Get unique entries by tag (one per unique "tp" tag)
          const tpEntries = entries.filter((entry) => entry.tag.startsWith("tp"));
          const uniqueTPEntries = Array.from(
            new Map(tpEntries.map((entry) => [entry.tag, entry])).values()
          );

          uniqueTPEntries.forEach((entry) => {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${globalData?.accessToken}`,
              },
              body: JSON.stringify({
                transId: transId,
                perilCode: "206",
                perilType: perilLabel,
                docComplete: docCompleteValue,
                status: {
                  id: 1,
                  statusNo:
                    claimStatus === "Incoming Claim"
                      ? 1
                      : claimStatus === "Pending Documents"
                        ? 18
                        : claimStatus === "Pending In-house Survey (Clec)"
                          ? 21
                          : claimStatus === "Pending Adjuster's Survey (IMSI)"
                            ? 22
                            : claimStatus === "Pending for Offer"
                              ? 9
                              : claimStatus === "Pending Adjuster's Report"
                                ? 19
                                : 0,
                },
                claimParticipant: "",
                tag: entry.tag,
                userDetails: `${globalData.firstName} ${globalData.lastName}`,
                userId: globalData.id,
                datetimeSubmitted: formattedDateTime,
              }),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`Error: ${response.statusText}`);
                }
              })
              .catch((error) => {
                console.error("Error during document validation POST request:", error);
              });
          });
        } else if (
          perilLabel === "206 - VTPL - PROPERTY DAMAGE Other than Vehicle" &&
          new Set(entries.filter((entry) => entry.tag.startsWith("p")).map(entry => entry.tag)).size >= 2
        ) {
          const pEntries = entries.filter((entry) => entry.tag.startsWith("p"));
          pEntries.forEach((entry) => {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${globalData?.accessToken}`
              },
              body: JSON.stringify({
                transId: transId,
                perilCode: "206",
                perilType: perilLabel,
                docComplete: docCompleteValue,
                status: {
                  id: 1,
                  statusNo:
                    claimStatus === "Incoming Claim"
                      ? 1
                      : claimStatus === "Pending Documents"
                        ? 18
                        : claimStatus === "Pending In-house Survey (Clec)"
                          ? 21
                          : claimStatus === "Pending Adjuster's Survey (IMSI)"
                            ? 22
                            : claimStatus === "Pending for Offer"
                              ? 9
                              : claimStatus === "Pending Adjuster's Report"
                                ? 19
                                : 0,
                },
                claimParticipant: "",
                tag: entry.tag,
                userDetails: `${globalData.firstName} ${globalData.lastName}`,
                userId: globalData.id,
                datetimeSubmitted: formattedDateTime,
              }),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`Error: ${response.statusText}`);
                }
              })
              .catch((error) => {
                console.error("Error during document validation POST request:", error);
              });
          });
        } else if (perilLabel === "205 - VTPL  BODILY INJURY" || perilLabel === "201 - COMPULSORY TPL - BODILY INJURY") {
            const bicps = typeof biCp === 'string'
            ? biCp
            : Array.isArray(biCp)
              ? biCp.flatMap((participant: string) => participant.split(',').map(p => p.trim())).join(', ')
              : '';

          // console.log('bicps', bicps);
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
            body: JSON.stringify({
              transId: transId,
              perilCode: perilLabel.slice(0, 3),

              perilType: perilLabel,
              // premiumClasses.find((pc) => pc.value === perilCode)
              //   ?.label || "",
              docComplete: 0,
              status: {
                id: 1,
                statusNo:
                  claimStatus === "Incoming Claim"
                    ? 1
                    : claimStatus === "Pending Documents"
                      ? 18
                      : claimStatus === "Pending In-house Survey (Clec)"
                        ? 21
                        : claimStatus === "Pending Adjuster's Survey (IMSI)"
                          ? 22
                          : claimStatus === "Pending for Offer"
                            ? 9
                            : claimStatus === "Pending Adjuster's Report"
                              ? 19
                              : 0,
              },
              tag: "insured",
              claimParticipant: bicps,
              userDetails: `${globalData.firstName} ${globalData.lastName}`,
              userId: globalData.id,
              datetimeSubmitted: formattedDateTime,
            }),
          })
            .then((docValidationResponse) => {
              if (!docValidationResponse.ok) {
                throw new Error(`Error: ${docValidationResponse.statusText}`);
              }
            })
            .catch((error) => {
              console.error(
                "Error during document validation POST request:",
                error
              );
            });
        
        }
        else {

          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
            body: JSON.stringify({
              transId: transId,
              perilCode: perilLabel.slice(0, 3),

              perilType: perilLabel,
              // premiumClasses.find((pc) => pc.value === perilCode)
              //   ?.label || "",
              docComplete: docCompleteValue,
              status: {
                id: 1,
                statusNo:
                  claimStatus === "Incoming Claim"
                    ? 1
                    : claimStatus === "Pending Documents"
                      ? 18
                      : claimStatus === "Pending In-house Survey (Clec)"
                        ? 21
                        : claimStatus === "Pending Adjuster's Survey (IMSI)"
                          ? 22
                          : claimStatus === "Pending for Offer"
                            ? 9
                            : claimStatus === "Pending Adjuster's Report"
                              ? 19
                              : 0,
              },
              tag: "insured",
              userDetails: `${globalData.firstName} ${globalData.lastName}`,
              userId: globalData.id,
              datetimeSubmitted: formattedDateTime,
            }),
          })
            .then((docValidationResponse) => {
              if (!docValidationResponse.ok) {
                throw new Error(`Error: ${docValidationResponse.statusText}`);
              }
            })
            .catch((error) => {
              console.error(
                "Error during document validation POST request:",
                error
              );
            });
        }
      }

      // GET request to fetch document validation data
      try {
        const docValidationGetResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        if (!docValidationGetResponse.ok) {
          throw new Error(`Error: ${docValidationGetResponse.statusText}`);
        }
        const validationData = await docValidationGetResponse.json();

        setDocumentValidationData(validationData);
        setOldDocumentValidationData(validationData);
      } catch (error) {
        console.error("Error fetching document validation data:", error);
      }

      // Fetch claim registration data after successful submission
      try {
        const claimRegistrationResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        if (!claimRegistrationResponse.ok) {
          throw new Error(`Error: ${claimRegistrationResponse.statusText}`);
        }
        const claimData = await claimRegistrationResponse.json();
        const tfClaimData = {
          ...claimData.data,
          timeLoss: convertTo12HourFormat(claimData.data.timeLoss),
        };

        setClaimRegistrationData(tfClaimData);
      } catch (error) {
        console.error("Error fetching claim registration data:", error);
      }
      setClaimModalOpen(true);
    } catch (error: any) {
      console.error("Error claim registration:", error);
      alert("Failed to register the claim. Ask IT for assistance");
      setClaimModalOpen(false);
      setSubmitDisabled(false);
    } finally {
      await fetchClaimRegistrationData();
      await fetchDocumentValidationData();
      setRegistering(false);
    }
  }

  const currentId = 1; // define a local id if you only have one row


  // #region handleSubmit
  const handleSubmit = async () => {
    // Check if the Date of Loss is before the Policy Issuance Date
    const totalEstimation = entries.reduce((acc, entry) => {
      const amount = parseFloat(entry.estimationAmount.replace(/[^0-9.-]+/g, ""));
      return acc + amount;
    }, 0);
    // console.log('entries', entries);
    // console.log('Total Estimation Amount:', totalEstimation);

    if (totalEstimation > 100000) {
      setSubmitDisabled(false);
      alert("Total estimation amount exceeds 100,000. Cannot register claim.");
      return;
    }

    if (
      (await validateDateOfLoss(dateOccurs, policyData?.inceptionDate, policyData?.expiryDate)) ===
      false
    ) {
      alert(
        "Date of loss is before the issuance date of the policy. Cannot register claim."
      );
      setSubmitDisabled(false);
      return;
    }
    const currentDateTime = new Date();
    if (dateReported > currentDateTime.toISOString()) {
      alert("Date reported cannot be in the future.");
      setSubmitDisabled(false);
      return;
    }

    const dateOccursNew = new Date(dateOccurs);
    const dateReportedNew = new Date(dateReported);

    if (dateOccursNew > dateReportedNew) {
      // alert(dateOccurs)
      // alert(dateReported)
      alert("Date of loss cannot be after the date reported.");
      setSubmitDisabled(false);
      return;
    }

    const policyDataItemCoverage = handleJSONStringArray(
      policyData?.itemCoverages
    ) as {
      itemCoverage: string;
      sumInsuredperItemCoverage: number;
    }[];
    const entriesResult = validateEntries(policyDataItemCoverage, entries);
    if (entriesResult.success === false) {
      alert(entriesResult.message);
      setSubmitDisabled(false);
      return;
    }

    // check existing policy number and date of loss 
    try {
      const checkExistingPolicyAndDOL = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/existing?pn=${policyNo}&dol=${format(new Date(dateOccurs), "yyyy-MM-dd")}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })

      const clrSize = checkExistingPolicyAndDOL?.data?.size as number
      setExistingClr(checkExistingPolicyAndDOL?.data?.data)
      if (clrSize > 0) {
        console.log(checkExistingPolicyAndDOL?.data?.data)
        setClaimModalClrOpen(true);
      } else {
        handleFinalSubmit();
      }
    } catch (error) {
      alert("Error: Cannot proceed, contact the admin for assistance")
    }
  };

  // Fetch document validation data on component mount
  const fetchClaimRegistrationData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setNewClaimParticipants(data.data.claimParticipants);
      const tfClaimData = {
        ...data.data,
        timeLoss: convertTo12HourFormat(data.data.timeLoss),
      };

      setClaimRegistrationData(tfClaimData);
    } catch (error) {
      console.error("Error fetching claim registration data:", error);
    }
  };

  const [docValidationContent, setDocValidationContent] = React.useState(false);

  const fetchDocumentValidationData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      setDocumentValidationData(data.data);
      if (data.data.length === 0) {
        setDocValidationContent(false);
      } else {
        setDocValidationContent(true);
      }

      setOldDocumentValidationData(data.data);
    } catch (error) {
      console.error("Error fetching document validation data:", error);
    }
  };

  const fetchMajorEventCode = async () => {
    await axios
      .get(`${process.env.REACT_APP_MAJOR_EVENT_CODES}`)
      .then((res: any) => {
        const tfArray = tfObjectsToArray(res.data);

        setMajorEventCodes(tfArray);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // region Update Approval Workspace Claim Registration data
  const updateSfClaim = async () => {
    const sfClaimData: ISfClaimRegistration = {
      Date_of_Loss__c: new Date(claimRegistrationData?.dateLoss).toISOString(),
      Report_Date__c: new Date(
        claimRegistrationData?.dateReported
      ).toISOString(),
      Description_of_Loss__c: claimRegistrationData?.claimDesc,
      Risk_Number__c: claimRegistrationData?.riskNumber,
      Major_Event_Code__c: claimRegistrationData?.claimMajoreventcode,
      Time_of_Loss__c: `${convertToMilitaryTime(
        claimRegistrationData?.timeLoss
      )}:00.000Z`,
    };

    await claimsModificationCreate(entries);

    // NOTE: Disabled for now
    // await updateSfClaimRegistration(sfClaimData, caseId);
  };

  React.useEffect(() => {
    fetchDocumentValidationData();
    fetchClaimRegistrationData();
    fetchMajorEventCode();
  }, [transId]); // Run only when transId changes

  React.useEffect(() => {
    if (dateOfLoss) {
      setDateOccurs(
        dateOfLoss.mvf ?? dateOfLoss.police ?? dateOfLoss.affidavit ?? null
      );
    }
    if (timeOfLoss) {
      setTimeOfLossClaim(
        timeOfLoss.mvf ?? timeOfLoss.police ?? timeOfLoss.affidavit ?? null
      );
    }
    if (claimParticipant) {
      setClaimParticipants([
        claimParticipant.mvf ??
        claimParticipant.police ??
        claimParticipant.affidavit ??
        ""
      ]);
    }
  }, [dateOfLoss, timeOfLoss, claimParticipant]);

  // Handle document validation submission for each row
  const handleDocumentValidationSubmit = async (data: any) => {
    // if () {}
    setLoading(true);

    try {
      const statusNo = Number(data.status.statusNo);
      let docCompleteValue = (statusNo === 9 || statusNo === 19) ? 1 : 0;
      ;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${data.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
          body: JSON.stringify({
            transId: Number(transId),
            tag: data.tag,
            perilCode: data.perilCode,
            perilType: data.perilType,
            claimParticipant: data.claimParticipant,
            docComplete: docCompleteValue,
            status: data.status,
            userDetails: `${globalData.firstName} ${globalData.lastName}`,
            userId: globalData.id,
            datetimeSubmitted: formattedDateTime,
          }),
        }
      );

      try {
        const responseStatus = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
          body: JSON.stringify({

            claimStatus:
              statusNo === 1
                ? "Incoming Claim"
                : statusNo === 18
                  ? "Pending Documents"
                  : statusNo === 21
                    ? "Pending In-house Survey (Clec)"
                    : statusNo === 22
                      ? "Pending Adjuster's Survey (IMSI)"
                      : statusNo === 9
                        ? "Pending for Offer"
                        : statusNo === 19
                          ? "Pending Adjuster's Report"
                          : "0",
          }),
        });



        if (!responseStatus.ok) {
          throw new Error("Failed to update Claim Status Value");
        }

        // Optionally, handle response data

      } catch (error) {
        console.error("Error updating Claim status:", error);
        // Optionally, revert the state or notify the user
        alert("Failed to update status. Please try again.");
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const previousData = oldDocumentValidationData.find(
        (item) => item.id === data?.id
      );
      const previousStatus = docStatusOptions.find(
        (item) => item.value === previousData?.status
      );
      const newStatus = docStatusOptions.find(
        (item) => item.value === data?.status
      );


      createAuditTrailData(
        globalData!.accessToken!,
        {
          transId: transId,
          taskType: "document_validation",
          event: "update_claim_status",
          description: `Update claim status from "${previousStatus?.label}" to "${newStatus?.label}" with peril code #${data?.perilCode}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(
            globalData.firstName
          )} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role,
        });

      alert("Document validation updated successfully");
      // navigate(-1);

      // Refresh the data after successful update
      await fetchDocumentValidationData();
    } catch (error) {
      console.error("Error submitting document validation:", error);
      alert("Failed to submit document validation");
    } finally {
      setLoading(false);
    }
  };


  const handleResubmit = async () => {
    // Set the loader flag so the UI shows the spinner
    setDocValidationUpdating(true);
    try {
      // First, fetch the existing document validations for this transaction.
      const getResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );
      if (!getResponse.ok) {
        throw new Error(`Error: ${getResponse.statusText}`);
      }
      const validationData = await getResponse.json();
      const existingValidations: any[] = validationData.data || [];

      // Determine unique peril labels from your entries.
      const uniquePerilLabels = Array.from(
        new Set(
          entries.map(
            (entry) =>
              premiumClasses.find((pc) => pc.value === entry.premiumClass)?.label
          )
        )
      ).filter((label): label is string => label !== undefined);

      const claimStatus = claimRegistrationData.claimStatus;
      const docCompleteValue =
        claimStatus === "Pending for Offer" ||
          claimStatus === "Pending Adjuster's Report"
          ? 1
          : 0;

      // Collect all POST requests as promises.
      const postPromises: Promise<Response>[] = [];

      // Helper to create the status object.
      const getStatusOne = () => ({
        id: 1,
        statusNo: 1
        ,
      });

      const getStatusObj = () => ({
        id: 1,
        statusNo:
          claimStatus === "Incoming Claim"
            ? 1
            : claimStatus === "Pending Documents"
              ? 18
              : claimStatus === "Pending In-house Survey (Clec)"
                ? 21
                : claimStatus === "Pending Adjuster's Survey (IMSI)"
                  ? 22
                  : claimStatus === "Pending for Offer"
                    ? 9
                    : claimStatus === "Pending Adjuster's Report"
                      ? 19
                      : 0,
      });

    


      // Loop through each unique peril label.
      for (const perilLabel of uniquePerilLabels) {
        console.log('perilLabel', perilLabel)
        console.log('newclaimParticipants data', newclaimParticipants)
        console.log('newclaimParticipants', newclaimParticipants.length)
        if (perilLabel === "207 - AUTO PERSONAL ACCIDENT" && newclaimParticipants.length > 0 && newclaimParticipants[0] !== "") {
          // alert('pasok sa if ng 207')
          const participants = typeof newclaimParticipants === 'string'
            ? (newclaimParticipants as string).split(',').map((p: string) => p.trim())
            : Array.isArray(newclaimParticipants)
              ? newclaimParticipants.flatMap((participant: string) => participant.split(',').map(p => p.trim()))
              : [];
         
          participants.forEach((participant: string, index: number) => {
            const tag = `cp${index + 1}`;
            postPromises.push(
              fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${globalData?.accessToken}`,
                },
                body: JSON.stringify({
                  transId: transId,
                  perilCode: perilLabel.slice(0, 3),
                  perilType: perilLabel,
                  docComplete: 0,
                  status: getStatusOne(),
                  claimParticipant: participant,
                  tag: tag,
                  userDetails: `${globalData.firstName} ${globalData.lastName}`,
                  userId: globalData.id,
                  datetimeSubmitted: formattedDateTime,
                }),
              })
            );
          });
        } 


        else if (
          perilLabel === "206 - VTPL - PROPERTY DAMAGE" &&
          new Set(
            entries.filter((entry) => entry.tag.startsWith("tp")).map((entry) => entry.tag)
          ).size >= 1
        ) {
          // Process unique entries with tags starting with "tp"
          const tpEntries = entries.filter((entry) => entry.tag.startsWith("tp"));
          const uniqueTPEntries = Array.from(
            new Map(tpEntries.map((entry) => [entry.tag, entry])).values()
          );

          for (const entry of uniqueTPEntries) {
            const exists = existingValidations.some(
              (record) =>
                record.perilType === perilLabel && record.tag === entry.tag
            );
            if (!exists) {
              postPromises.push(
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${globalData?.accessToken}`,
                  },
                  body: JSON.stringify({
                    transId: transId,
                    perilCode: "206",
                    perilType: perilLabel,
                    docComplete: docCompleteValue,
                    status: getStatusOne(),
                    claimParticipant: "",
                    tag: entry.tag,
                    userDetails: `${globalData.firstName} ${globalData.lastName}`,
                    userId: globalData.id,
                    datetimeSubmitted: formattedDateTime,
                  }),
                })
              );
            }
          }
        } else if (
          perilLabel === "206 - VTPL - PROPERTY DAMAGE Other than Vehicle") {
          const pEntries = entries.filter((entry) => entry.tag.startsWith("p"));
          const uniquepEntries = Array.from(
            new Map(pEntries.map((entry) => [entry.tag, entry])).values()
          );
          for (const entry of uniquepEntries) {
            const exists = existingValidations.some(
              (record) =>
                record.perilType === perilLabel && record.tag === entry.tag
            );
            if (!exists) {
              postPromises.push(
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${globalData?.accessToken}`,
                  },
                  body: JSON.stringify({
                    transId: transId,
                    perilCode: "206",
                    perilType: perilLabel,
                    docComplete: docCompleteValue,
                    status: getStatusOne(),
                    claimParticipant: "",
                    tag: entry.tag,
                    userDetails: `${globalData.firstName} ${globalData.lastName}`,
                    userId: globalData.id,
                    datetimeSubmitted: formattedDateTime,
                  }),
                })
              );
            }
          }
        }else if (perilLabel === "205 - VTPL  BODILY INJURY" && newBiCp.length > 0 && newBiCp[0] !== "") {
          const newbicps = typeof newBiCp === 'string'
          ? newBiCp
          : Array.isArray(newBiCp)
            ? newBiCp.flatMap((participant: string) => participant.split(',').map(p => p.trim())).join(', ')
            : '';
          
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
            body: JSON.stringify({
              transId: transId,
              perilCode: perilLabel.slice(0, 3),

              perilType: perilLabel,
              // premiumClasses.find((pc) => pc.value === perilCode)
              //   ?.label || "",
              docComplete: 0,
              status: getStatusOne(),
              tag: "insured",
              claimParticipant:newbicps,
              userDetails: `${globalData.firstName} ${globalData.lastName}`,
              userId: globalData.id,
              datetimeSubmitted: formattedDateTime,
            }),
          })
            .then((docValidationResponse) => {
              if (!docValidationResponse.ok) {
                throw new Error(`Error: ${docValidationResponse.statusText}`);
              }
            })
            .catch((error) => {
              console.error(
                "Error during document validation POST request:",
                error
              );
            });
        
        } 
        
        else {
          // For all other peril types, the tag is "insured".
          
          const exists = existingValidations.some(
            (record) =>
              record.perilType === perilLabel && record.tag === "insured"
          );
          if (!exists && perilLabel !== "207 - AUTO PERSONAL ACCIDENT") {
            postPromises.push(
              fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${globalData?.accessToken}`,
                },
                body: JSON.stringify({
                  transId: transId,
                  perilCode: perilLabel.slice(0, 3),
                  perilType: perilLabel,
                  docComplete: docCompleteValue,
                  status: perilLabel.slice(0, 3) === "207" ? getStatusOne() : getStatusObj(),
                  tag: perilLabel.slice(0, 3) === "207" ? "cp1" : "insured",
                  userDetails: `${globalData.firstName} ${globalData.lastName}`,
                  userId: globalData.id,
                  datetimeSubmitted: formattedDateTime,
                }),
              })
            );
          }
        }
      }
      if (newclaimParticipants.length > 0) {
      try {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/participants/${transId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
          body: JSON.stringify({
            claimParticipants: Array.isArray(newclaimParticipants) && newclaimParticipants.length === 1 && newclaimParticipants[0] === ""
              ? ""
              : Array.isArray(newclaimParticipants) ? newclaimParticipants.join(", ") : "",
          })
        });
        console.log("Patch request successful");
      } catch (error) {
        console.error("Error making patch request:", error);
      }
    }

    if (newBiCp.length > 0) {
      try {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/bicp/${transId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
          body: JSON.stringify({
            biCp: Array.isArray(newBiCp) && newBiCp.length === 1 && newBiCp[0] === ""
              ? ""
              : Array.isArray(newBiCp) ? newBiCp.join(", ") : "",
          })
        });
        console.log("Patch request successful");
      } catch (error) {
        console.error("Error making patch request:", error);
      }
    }


      // Wait until all POST requests complete.
      await Promise.all(postPromises);

      // Finally, fetch the updated document validation data to update the UI.
      await fetchDocumentValidationData();
    } catch (error) {
      console.error("Error updating document validations:", error);
    } finally {
      // Turn off the loader
      setDocValidationUpdating(false);
    }
  };






  // Map claimRegistrationData to entries for table display
  React.useEffect(() => {
    if (claimRegistrationData) {
      const newEntries = [];
      for (let i = 1; i <= 10; i++) {
        const index = String(i).padStart(2, "0"); // Pads single-digit numbers with a leading zero
        const premiumClassKey = `premiumClass${index}`;
        const reserveCodeKey = `reserveCode${index}`;
        const balanceOutstandingKey = `balanceOutstanding${index}`;
        const codeDescriptionKey = `codeDesc${index}`;
        const tag = claimRegistrationData[`tag${index}`];
        const premiumClass = claimRegistrationData[premiumClassKey];
        const reserveCode = claimRegistrationData[reserveCodeKey];
        const balanceOutstanding = claimRegistrationData[balanceOutstandingKey];
        const codeDescription = claimRegistrationData[codeDescriptionKey];

        if (
          premiumClass &&
          reserveCode &&
          balanceOutstanding &&
          codeDescription
        ) {
          newEntries.push({
            premiumClass,
            reserveCode,
            tag,
            estimationAmount: balanceOutstanding,
            codeDesc: codeDescription,
          });
        }
      }

      setEntries(newEntries);
    }
  }, [claimRegistrationData]);

  // const [showForm, setShowForm] = React.useState(false);

  const formatDateMMDDYYYY = (dateString: string): string => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short", // Short month format (e.g., Jan)
      day: "numeric", // Numeric day
      year: "numeric", // Full year
    });

    return formatter.format(date);
  };

  return (
    <div>
      {/* 
        // region Claim Registration Data Exist 
      */}
      {claimRegistrationData ? (
        <>
          <Box>
            <Card
              sx={{
                boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                borderRadius: 2,
                padding: "40px",
              }}
            >
              <br />
              <Typography
                variant="h4"
                className="tp-text-header-primary"
                textAlign="center"
              >
                Claim Registration
              </Typography>

              <hr className="tp-border-hr_primary" />
              <br />

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Date of Loss"
                    type="date"
                    placeholder="mm/dd/yyyy"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={claimRegistrationData?.dateLoss}
                    onChange={(e) =>
                      setClaimRegistrationData((prevData: any) => ({
                        ...prevData,
                        dateLoss: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Time of Loss"
                    type="text"
                    fullWidth
                    value={claimRegistrationData?.timeLoss}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setClaimRegistrationData((prevData: any) => ({
                        ...prevData,
                        timeLoss: e.target.value,
                      }))
                    }
                    error={!isValidTime(claimRegistrationData.timeLoss)}
                  />
                  {!isValidTime(claimRegistrationData.timeLoss) && (
                    <FormHelperText error id="mobileNo">
                      Invalid time. Please input correct date
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Report Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={claimRegistrationData?.dateReported}
                    onChange={(e) =>
                      setClaimRegistrationData((prevData: any) => ({
                        ...prevData,
                        dateReported: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Claim Description</InputLabel>
                    <Select
                      value={claimRegistrationData?.claimDesc}
                      onChange={(e) =>
                        setClaimRegistrationData((prevData: any) => ({
                          ...prevData,
                          claimDesc: e.target.value,
                        }))
                      }
                      label="Claim Description"
                      fullWidth
                    >
                      {claimDescriptions.map((description) => (
                        <MenuItem key={description} value={description}>
                          {description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Risk Number"
                    type="text"
                    fullWidth
                    value={claimRegistrationData?.riskNumber}
                    onChange={(e) =>
                      setClaimRegistrationData((prevData: any) => ({
                        ...prevData,
                        riskNumber: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    label="Major Event Code"
                    value={claimRegistrationData?.claimMajoreventcode}
                    onChange={(e) =>
                      setClaimRegistrationData((prevData: any) => ({
                        ...prevData,
                        claimMajoreventcode: e.target.value,
                      }))
                    }
                    fullWidth
                  >
                    {majorEventCodes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <TextField
                    label="Claim Participants"
                    type="text"
                    fullWidth
                    value={claimRegistrationData?.claimParticipants}
                    disabled
                  />
                </Grid> */}

                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={updateSfClaim}
                    disabled={[
                      "Clec",
                      "Jr Clec",
                      "Head Motor Claims",
                      "Head Claims",
                      "Head Clec",
                      "Call Center",
                      "Canvasser"
                    ].includes(globalData?.role)}
                  >
                    Update Claim Registration
                  </Button>
                </Grid>

                <Grid item xs={12} md={6} />
              </Grid>

              <br />

              <Grid item xs={12} md={12} textAlign="center">
                <Typography variant="body1">Claim Status:</Typography>
                <Typography variant="h5" className="tp-text-header-primary">
                  {claimRegistrationData.claimStatus}
                </Typography>
              </Grid>
              <hr />
              <Grid container textAlign="center">
                {/* // region CNC/NCC History */}
                <Grid item xs={6} md={6}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon color="primary" />}
                      aria-controls="panel-1-content"
                      id="panel1-header"
                    >
                      <Typography color="primary" textAlign="center">
                        {`CNC/NCC History: ${!loading && history && history.length > 0 && !error
                          ? ""
                          : "No CNC/NCC generated"
                          }`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {loading && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            my: 2,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}

                      {!loading && history && history.length > 0 && (
                        <List>
                          {history.map((item, index) => (
                            <ListItem key={item.id} divider>
                              <ListItemText
                                primary={`${index + 1}. ${item.type}`}
                                secondary={formatDate(item.submittedDate)}
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {/* // region Claim Registration History */}
                <Grid item xs={6} md={6} lg={6}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon color="primary" />}
                      aria-controls="panel-1-content"
                      id="panel1-header"
                    >
                      <Typography color="primary" textAlign="center">
                        Claim Registration History:{" "}
                        {claimHistory.length === 0 && "No Data Available"}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {claimHistory && claimHistory.length > 0 && (
                        <>
                          {claimHistory.map((data, index) => (
                            <div key={data.id}>
                              <Grid container textAlign="left">
                                <Grid item sm={8} md={8} lg={8}>
                                  <Typography
                                    variant="body1"
                                    className="tp-text-header-primary"
                                  >
                                    {data.claimNumber}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="GrayText"
                                  >
                                    {formatDateMMDDYYYY(data.dateLoss)}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  alignSelf="center"
                                  textAlign="right"
                                  paddingRight="20px"
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() =>
                                      window.open(
                                        `${window.location.protocol}//${window.location.host}/document-validation/${data.transId}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    {`ACAP-${data.transId}`}
                                  </Button>
                                </Grid>
                              </Grid>
                              {index < claimHistory.length - 1 && <hr />}
                            </div>
                          ))}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <br />
            </Card>
          </Box>
          <br />
          <Box>
            <Card
              sx={{
                boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                borderRadius: 2,
                padding: "40px",
              }}
            >
              <Typography
                variant="h5"
                textAlign="center"
                className="tp-text-header-primary"
              >
                Premium Class
                <Typography variant="body2" color="error" marginBottom="10px">
                  Maximum Entry: {10 - entries.length}
                </Typography>
              </Typography>
              <hr />
              <br />
              {globalData.role !== "Claim Assistant" && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3} lg={3}>
                      <TextField
                        select
                        label="Premium Class"
                        size="small"
                        value={premiumClass}
                        onChange={handlePremiumClassChange}
                        fullWidth
                        disabled={isDisabled}
                      >
                        {premiumClasses.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <TextField
                        select
                        label="Reserve Code"
                        value={reserveCode}
                        onChange={handleReserveCodeChange}
                        fullWidth
                        size="small"
                        disabled={!premiumClass || isDisabled}
                      >
                        {filteredExistingReserveCode(premiumClass).map(
                          (option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <TextField
                        label="Estimation Amount"
                        type="number"
                        fullWidth
                        size="small"
                        value={estimationAmount}
                        onChange={handleEstimationAmountChange}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Tag</InputLabel>
                        <Select
                          size="small"
                          value={claimTag}
                          onChange={(e) => setClaimTag(e.target.value)}
                          fullWidth
                          // Enable only if premiumClass is 206 or 2061; otherwise it’s disabled
                          disabled={!["206", "2061"].includes(premiumClass)}
                        >
                          {(() => {
                            let options: string[] = [];
                            if (premiumClass === "2061") {
                              options = ["p1", "p2", "p3", "p4", "p5"];
                            } else if (premiumClass === "206") {
                              options = ["tp1", "tp2", "tp3", "tp4", "tp5"];
                            } else {
                              options = ["insured"];
                            }
                            // Remove tags already selected in other rows (if needed)
                            const selectedTags = Array.isArray(documentValidationData)
                              ? documentValidationData.filter((item) => item.id !== currentId).map((item) => item.tag)
                              : [];
                            const availableOptions = options.filter(
                              (option) => !selectedTags.includes(option) || option === claimTag
                            );
                            return availableOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ));
                          })()}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <Button
                        size="medium"
                        fullWidth
                        color="error"
                        variant="contained"
                        onClick={handleAddNewEntry}
                        disabled={
                          !premiumClass ||
                          !reserveCode ||
                          !estimationAmount ||
                          claimTag === "" ||
                          Number(estimationAmount) > 100000 ||
                          isDisabled
                        }
                      >
                        Add
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Claim Participants (UPPA)  *Separated by comma"
                        type="text"
                        fullWidth
                        value={newclaimParticipants}
                        // disabled
                        onChange={(e) => {
                          // isValidDate(e.target.value)
                          setNewClaimParticipants([e.target.value]);
                          // setDateOccurs(e.target.value ? new Date(e.target.value).toISOString() : "")
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Claim Participants (BI)  *Separated by comma"
                        type="text"
                        fullWidth
                        value={newBiCp}
                        // disabled
                        onChange={(e) => {
                          // isValidDate(e.target.value)
                          setNewBiCp([e.target.value]);
                          // setDateOccurs(e.target.value ? new Date(e.target.value).toISOString() : "")
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                </>
              )}

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Premium Class</TableCell>
                      <TableCell>Reserve Code</TableCell>
                      <TableCell>Estimation Amount</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entries.map((entry, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {
                            premiumClasses.find(
                              (pc) => pc.value === entry.premiumClass
                            )?.label
                          }
                        </TableCell>
                        <TableCell>{entry.codeDesc}</TableCell>
                        <TableCell>{entry.estimationAmount}</TableCell>
                        <TableCell>{entry.tag}</TableCell>
                        <TableCell>
                          {globalData.role &&
                            ![
                              "Claim Assistant",
                              "Jr Clec",
                              "Clec",
                              "Head Clec",
                              "Call Center"
                            ].includes(globalData.role) && (
                              <>
                                <IconButton
                                  onClick={() => toggleEditDialog(true, index)}
                                >
                                  <EditIcon color="primary" />
                                </IconButton>
                                &emsp;

                              </>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
          <br />
          <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"center"} marginTop={2}>
            <Button
              variant="contained"
              color="error"
              onClick={handleResubmit}
              disabled={docValidationUpdating ||  [
                "Clec",
                "Jr Clec",
                "Head Motor Claims",
                "Head Claims",
                "Head Clec",
                "Claims Admin",
                "Call Center",
                "Canvasser"
              ].includes(globalData?.role)}
            >
              {docValidationUpdating ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Update Document Validation Details"
              )}
            </Button>

            <Button
              variant="contained"
              color="error"
              onClick={handleDuplicateOwnDamage}
              disabled={claimLoading || entries.length >= 10 || !syncAW ||
                [
                  "Clec",
                  "Jr Clec",
                  "Head Motor Claims",
                  "Head Claims",
                  "Head Clec",
                  "Claims Admin",
                  "Call Center",
                  "Canvasser"
                ].includes(globalData?.role)
              }
              style={{ marginLeft: "auto" }}
            >
              {claimLoading ? (
              <CircularProgress size={24} color="inherit" />
              ) : (
              "Duplicate Own Damage"
              )}
            </Button>
          </Stack>
          <br />
          <Box
            style={{
              backgroundColor: "#130d46",
              color: "white",
              textAlign: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            Claim Number:
            <Typography variant="h4" style={{ fontStyle: "bold" }}>
              {claimRegistrationData.claimNumber}
              {claimRegistrationData.mvClaim ? ` - ${claimRegistrationData.mvClaim}` : ""}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {/* 
          // region No Claim Registration Data 
        */}
          <Box>
            <Card
              sx={{
                boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                borderRadius: 2,
                padding: "40px",
              }}
            >
              <Typography
                variant="h4"
                className="tp-text-header-primary"
                textAlign="center"
              >
                Claim Registration
              </Typography>

              <hr className="tp-border-hr_primary" />
              <br />
              <Grid container spacing={2} marginBottom="20px">
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label="Date of Loss"
                    type="text"
                    size="small"
                    placeholder="mm/dd/yyyy"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dateOccurs}
                    error={!isValidDate(dateOccurs)}
                    onChange={(e) => {
                      // isValidDate(e.target.value)
                      setDateOccurs(e.target.value);
                      // setDateOccurs(e.target.value ? new Date(e.target.value).toISOString() : "")
                    }}
                  />
                  {!isValidDate(dateOccurs) && (
                    <FormHelperText error id="mobileNo">
                      Invalid date. Please input correct date
                    </FormHelperText>
                  )}
                  {isValidDate(dateOccurs) && new Date(dateOccurs) > new Date() && (
                    <FormHelperText style={{ color: 'red' }} id="futureDate">
                      Invalid. Date is in the future.
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label="Time of Loss"
                    type="text"
                    size="small"
                    fullWidth
                    value={timeOfLossClaim}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setTimeOfLossClaim(e.target.value)}
                    error={!isValidTime(timeOfLossClaim)}
                  />
                  {!isValidTime(timeOfLossClaim) && (
                    <FormHelperText error id="mobileNo">
                      Invalid time. Please input correct date
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label="Report Date"
                    type="date"
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dateReported}
                    onChange={(e) =>
                      setDateReported(
                        e.target.value
                          ? formatISODate(
                            new Date(e.target.value).toISOString()
                          )
                          : ""
                      )
                    }
                  />
                  {isValidDate(dateReported) && new Date(dateReported) > new Date() && (
                    <FormHelperText style={{ color: 'red' }} id="futureDateReported">
                      Invalid. Report Date must not be in the future.
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} marginBottom="20px">
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel>Claim Description</InputLabel>
                    <Select
                      value={claimDescription}
                      onChange={handleChange}
                      label="Claim Description"
                      size="small"
                      fullWidth
                      error={!claimDescription}
                    >
                      {claimDescriptions.map((description) => (
                        <MenuItem key={description} value={description}>
                          {description}
                        </MenuItem>
                      ))}
                    </Select>
                    {!claimDescription && (
                      <FormHelperText error id="claimDescription">
                        Invalid value. Please input the correct data
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label="Risk Number"
                    type="text"
                    fullWidth
                    value={riskNumber}
                    // defaultValue="1"
                    size="small"
                    error={!riskNumber}
                    onChange={(e) => setRiskNumber(e.target.value)}
                  />
                  {!riskNumber && (
                    <FormHelperText error id="riskNumber">
                      Invalid value. Please input the correct data
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    select
                    label="Major Event Code"
                    size="small"
                    value={majorEventCode}
                    onChange={(e) => setMajorEventCode(e.target.value)}
                    fullWidth
                  >
                    {majorEventCodes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginTop="30px">
                <Grid item xs={12} md={3} lg={3}>
                  <TextField
                    select
                    label="Premium Class"
                    value={premiumClass}
                    size="small"
                    onChange={handlePremiumClassChange}
                    fullWidth
                    disabled={isDisabled}
                  >
                    {premiumClasses.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <TextField
                    select
                    label="Reserve Code"
                    value={reserveCode}
                    size="small"
                    onChange={handleReserveCodeChange}
                    fullWidth
                    disabled={!premiumClass || isDisabled}
                  >
                    {filteredReserveCodes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <TextField
                    label="Estimation Amount"
                    type="number"
                    fullWidth
                    size="small"
                    value={estimationAmount}
                    onChange={handleEstimationAmountChange}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Tag</InputLabel>


                    <Select
                      size="small"
                      value={claimTag}
                      defaultValue="insured"
                      onChange={(e) => setClaimTag(e.target.value)}
                      fullWidth
                      disabled={!["206", "2061"].includes(premiumClass)}
                    >
                      {(() => {
                        let options: string[] = [];
                        if (premiumClass === "2061") {
                          options = ["p1", "p2", "p3", "p4", "p5"];
                        } else if (premiumClass === "206") {
                          options = ["tp1", "tp2", "tp3", "tp4", "tp5"];
                        } else {
                          options = ["insured"];
                        }
                        // Optionally remove tags already selected in other rows (if needed)
                        const selectedTags = Array.isArray(documentValidationData)
                          ? documentValidationData.filter((item) => item.id !== currentId).map((item) => item.tag)
                          : [];
                        const availableOptions = options.filter(
                          (option) => !selectedTags.includes(option) || option === claimTag
                        );
                        return availableOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ));
                      })()}
                    </Select>
                  </FormControl>


                </Grid>
                <Grid item xs={12} md={1} lg={1} textAlign="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleAddEntry}
                    disabled={
                      !premiumClass ||
                      !reserveCode ||
                      !estimationAmount ||
                      Number(estimationAmount) > 100000 ||
                      isDisabled
                    }
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <br />
              <br />
              <Typography
                variant="h5"
                textAlign="center"
                className="tp-text-header-primary"
              >
                Premium Class
                <Typography variant="body2" color="error" marginBottom="10px">
                  Maximum Entry: {10 - entries.length}
                </Typography>
              </Typography>
              <hr />
              <br />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Premium Class</TableCell>
                      <TableCell>Reserve Code</TableCell>
                      <TableCell>Estimation Amount</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entries.map((entry, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {
                            premiumClasses.find(
                              (pc) => pc.value === entry.premiumClass
                            )?.label
                          }
                        </TableCell>
                        <TableCell>{entry.codeDesc}</TableCell>
                        <TableCell>{entry.estimationAmount}</TableCell>
                        <TableCell>{entry.tag}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteEntry(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                item
                xs={12}
                md={6}
                className="tp-mb-20px"
                style={{ marginTop: "20px" }}
              >
                <TextField
                  select
                  label="Claim Status"
                  value={claimStatus}
                  onChange={handleClaimStatusChange}
                  fullWidth
                >
                  {claimStatuses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  label="Claim Participants(UPPA)  *Separated by comma"
                  type="text"
                  fullWidth
                  value={claimParticipants}
                  onChange={(e) => {
                    setClaimParticipants([e.target.value]);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  label="Claim Participants(BI)  *Separated by comma"
                  type="text"
                  fullWidth
                  value={biCp}
                  onChange={(e) => {
                    setBiCp([e.target.value]);
                  }}
                />
              </Grid>
              </Grid>

              <Grid container textAlign="center">
                {/* // region CNC/NCC History */}
                <Grid item xs={6} md={6}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon color="primary" />}
                      aria-controls="panel-1-content"
                      id="panel1-header"
                    >
                      <Typography color="primary" textAlign="center">
                        {`CNC/NCC History: ${!loading && history && history.length > 0 && !error
                          ? ""
                          : "No CNC/NCC generated"
                          }`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {loading && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            my: 2,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}

                      {!loading && history && history.length > 0 && (
                        <List>
                          {history.map((item, index) => (
                            <ListItem key={item.id} divider>
                              <ListItemText
                                primary={`${index + 1}. ${item.type}`}
                                secondary={formatDate(item.submittedDate)}
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {/* // region Claim Registration History */}
                <Grid item xs={6} md={6} lg={6}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon color="primary" />}
                      aria-controls="panel-1-content"
                      id="panel1-header"
                    >
                      <Typography color="primary" textAlign="center">
                        Claim Registration History:{" "}
                        {claimHistory.length === 0 && "No Data Available"}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {claimHistory && claimHistory.length > 0 && (
                        <>
                          {claimHistory.map((data, index) => (
                            <div key={data.id}>
                              <Grid container textAlign="left">
                                <Grid item sm={8} md={8} lg={8}>
                                  <Typography
                                    variant="body1"
                                    className="tp-text-header-primary"
                                  >
                                    {data.claimNumber}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="GrayText"
                                  >
                                    {formatDateMMDDYYYY(data.dateLoss)}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  alignSelf="center"
                                  textAlign="right"
                                  paddingRight="20px"
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() =>
                                      window.open(
                                        `${window.location.protocol}//${window.location.host}/document-validation/${data.transId}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    {`ACAP-${data.transId}`}
                                  </Button>
                                </Grid>
                              </Grid>
                              {index < claimHistory.length - 1 && <hr />}
                            </div>
                          ))}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                {entries.length === 0 && (
                  <Typography
                    variant="h6"
                    color="GrayText"
                    sx={{ mt: 1, mb: 3 }}
                  >
                    Please add at least one entry to enable submission and Policy Details should be confirmed
                  </Typography>
                )}


                <Button
                  className="tp-custom-button-primary"
                  variant="contained"
                  disabled={
                    submitDisabled ||
                    !isValidated ||
                    entries.length === 0 ||
                    !isValidDate(dateOccurs) ||
                    !isValidTime(timeOfLossClaim) ||
                    !dateReported ||
                    !claimDescription ||
                    !riskNumber ||
                    [
                      "Clec",
                      "Jr Clec",
                      "Head Motor Claims",
                      "Head Claims",
                      "Head Clec",
                      "Claims Admin",
                      "Call Center",
                      "Canvasser"
                    ].includes(globalData?.role)
                  }
                  onClick={() => {
                    setSubmitDisabled(true);
                    handleSubmit();
                  }}
                  fullWidth
                  sx={{ maxWidth: 300, marginBottom: "15px" }}
                >
                  Submit
                </Button>

              </Box>
            </Card>
          </Box>
        </>
      )}
      <br />
      <br />
      {documentValidationData && (
        <>
          <Typography
            variant="h6"
            className="tp-text-header-primary"
            sx={{ mb: 2 }}
          >
            Document Validation Details
          </Typography>


        </>
      )}

      { /* Generate Adjuster Slip Button */}


      { /* Adjuster Slip Modal */}
      <Modal
        open={openAdjusterSlip}
        onClose={() => setOpenAdjusterSlip(false)}
        aria-labelledby="adjuster-slip-title"
        aria-describedby="adjuster-slip-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1000,
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
          }}
        >
          <Typography
            id="adjuster-slip-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Adjuster's Assignment Slip
          </Typography>
          <Typography id="adjuster-slip-description" sx={{ mt: 2 }}>
            {/* Adjuster slip content goes here */}
            <AdjusterSlip transId={transId}
              claimRegistrationData={claimRegistrationData}
              policyData={policyData} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenAdjusterSlip(false)}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      { /* State for Adjuster Slip Modal */}

      {docValidationContent && (
        <>
          <Box textAlign="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAdjusterSlip(true)}
            >
              Generate Adjuster's Slip
            </Button>
          </Box>
          <br />
          <br />
        </>

      )}


      {Array.isArray(documentValidationData) && (
        <>


          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>Claim Type ID </TableCell>
                  <TableCell>Peril Type</TableCell>
                  <TableCell>Tag</TableCell>
                  <TableCell>Claim Participant (UPPA, BI, Third Party only)</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentValidationData.map((data: any, index: number) => {
                  if (data === null || data === "") {
                    return (
                      <Typography variant="body1" className="tp-text-bold">
                        No Available Data
                      </Typography>
                    );
                  }

                  // Get all selected tags excluding the current row's tag
                  const selectedTags = documentValidationData
                    .filter((item: any) => item.id !== data.id && item.tag)
                    .map((item: any) => item.tag);

                  // Available tags are those not already selected
                  // const availableTags = [
                  //   "insured",
                  //   "tp1",
                  //   "tp2",
                  //   "tp3",
                  //   "tp4",
                  //   "tp5",
                  //   "cp1",
                  //   "cp2",
                  //   "cp3",
                  //   "cp4",
                  //   "cp5",
                  //   "p1",
                  //   "p2",
                  //   "p3",
                  //   "p4",
                  //   "p5",
                  // ].filter(tag => !selectedTags.includes(tag));

                  return (
                    <TableRow key={data.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>{data.perilType}</TableCell>
                      <TableCell>
                        <Select
                          size="small"
                          value={data.tag}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setDocumentValidationData((prevData) =>
                              prevData.map((item) =>
                                item.id === data.id ? { ...item, tag: newValue } : item
                              )
                            );
                          }}
                          fullWidth
                          disabled={
                            ![
                              "207 - AUTO PERSONAL ACCIDENT",
                              "206 - VTPL - PROPERTY DAMAGE",
                              "206 - VTPL - PROPERTY DAMAGE Other than Vehicle",
                            ].includes(data.perilType)
                          }
                        >
                          {(() => {
                            let options: string[] = [];
                            if (data.perilType === "207 - AUTO PERSONAL ACCIDENT") {
                              options = ["cp1", "cp2", "cp3", "cp4", "cp5"];
                            } else if (
                              data.perilType === "206 - VTPL - PROPERTY DAMAGE Other than Vehicle"
                            ) {
                              options = ["p1", "p2", "p3", "p4", "p5"];
                            } else if (data.perilType === "206 - VTPL - PROPERTY DAMAGE") {
                              options = ["tp1", "tp2", "tp3", "tp4", "tp5"];
                            } else {
                              options = ["insured"];
                            }
                            // Get the tags already selected in other rows.
                            const selectedTags = documentValidationData
                              .filter((item) => item.id !== data.id)
                              .map((item) => item.tag);
                            // Allow the current tag to remain in the options list even if it's already selected.
                            const availableOptions = options.filter(
                              (option) => !selectedTags.includes(option) || option === data.tag
                            );
                            return availableOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ));
                          })()}
                        </Select>

                      </TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          value={data.claimParticipant}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setDocumentValidationData((prevData) =>
                              prevData.map((item) =>
                                item.id === data.id
                                  ? { ...item, claimParticipant: newValue }
                                  : item
                              )
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        {data.docComplete === 2 ? (
                          <Typography>
                            {data.status.name.replace(
                              /\w\S*/g,
                              (w: string) =>
                                w.charAt(0).toUpperCase() +
                                w.slice(1).toLowerCase()
                            )}
                          </Typography>
                        ) : (
                          <Select
                            value={data.status?.statusNo || 18}
                            onChange={(e) => {
                              const newValue = Number(e.target.value);
                              setDocumentValidationData((prevData) =>
                                prevData.map((item) =>
                                  item.id === data.id
                                    ? {
                                      ...item,
                                      status: {
                                        ...item.status,
                                        statusNo: newValue,
                                      },
                                    }
                                    : item
                                )
                              );
                            }}
                            fullWidth
                          >
                            {docStatusOptions.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                disabled={[29, 30, 33, 34, 35, 36].includes(
                                  option.value
                                )}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </TableCell>
                      <TableCell>
                        {data.docComplete !== 2 && (
                          <Button
                            disabled={
                              loading ||
                              [
                                "Clec",
                                "Jr Clec",
                                "Head Motor Claims",
                                "Head Claims",
                                "Head Clec",
                                "Claims Admin",
                                "Call Center",
                                "Canvasser"
                              ].includes(globalData?.role)
                            }
                            className="tp-custom-button-primary"
                            variant="contained"
                            onClick={() => handleDocumentValidationSubmit(data)}
                          >
                            {loading ? <CircularProgress size={24} /> : "Submit"}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* 
        // #region Submit
      */}
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2
        }}
      >
        <Typography variant="h6" className="tp-text-header-primary">
          Login As User: &ensp;
          <span style={{ color: "#525252" }}>
            {toTitleCase(globalData?.firstName)} {toTitleCase(globalData?.lastName)}
          </span>
        </Typography>
      </Box> */}

      {/* 
        // #region Modal for existing policy number and date of loss
      */}
      <Modal
        open={claimModalClrOpen}
        onClose={() => {
          setClaimModalClrOpen(false);
        }}
        aria-labelledby="claim-modal-title"
        aria-describedby="claim-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="claim-modal-title" variant="h6" component="h2">
            With Existing Same Policy Number and Same Date Of Loss
          </Typography>
          <Typography id="claim-modal-description">
            <br />
            System has found that there are existing policy number and date of loss. Do you want to proceed?
          </Typography>
          <br />
          {existingClr.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Date and Time Registered</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {existingClr.map((clr: any) => (
                    <div>
                      <ListItem>
                        <ListItemText
                          primary={formatCustomDate(clr?.claimFilingapprovaltime)}
                        />
                      </ListItem>
                      <Divider component="li" />
                    </div>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
          <br />
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Button
              color="error"
              onClick={() => {
                setClaimModalClrOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="error"
              onClick={() => {
                setClaimModalClrOpen(false);
                handleFinalSubmit();
              }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>

      {/* 
        // #region Modal for success registration
      */}
      <Modal
        open={claimModalOpen}
        onClose={() => {
          setClaimModalOpen(false);
          setRegistering(false); // Ensure registering is false when closing
        }}
        aria-labelledby="claim-modal-title"
        aria-describedby="claim-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="claim-modal-title" variant="h6" component="h2">
            Claim Registered
          </Typography>
          <Typography id="claim-modal-description">
            <br />
            The claim has been registered successfully. Claim Number:{" "}
            {claimNumber}
          </Typography>
          <Button
            color="error"
            onClick={() => {
              setClaimModalOpen(false);
              setRegistering(false); // Ensure registering is false when closing
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
      {registering && (
        <Box
          color="error"
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2, backgroundColor: "#130d46", color: "white", textAlign: "center" }}>
            Registering the Claim. Please wait...
            Do not refresh or close the page.
          </Typography>
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={(event, reason) => toggleEditDialog(false, 0)}
      >
        <DialogTitle>Edit Entry #{editIndex}</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            fullWidth
            select
            label="Premium Class"
            value={
              premiumClasses.find((pc) => pc.value === newPremiumClass)?.value
            }
            onChange={(event) => handleNewPremiumClassChange(event, editIndex)}
          >
            {premiumClasses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <TextField
            select
            fullWidth
            label="Reserve Code"
            value={
              reserveCodes.find((rc) => rc.value === newReserveCode)?.value
            }
            onChange={(event) => handleNewReserveCodeChange(event, editIndex)}
            disabled={!newPremiumClass}
          >
            {filteredExistingReserveCode(newPremiumClass).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <TextField
            fullWidth
            label="Estimation Amount"
            type="number"
            value={parseFloat(newEstimationAmount.replace(/[₱,]/g, ""))}
            onChange={(event) =>
              handleNewEstimationAmountChange(event, editIndex)
            }
          />
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel>Tag</InputLabel>
            <Select
              label="Tag"
              value={newTagClaim}
              onChange={(e) => setNewTagClaim(e.target.value)}
              defaultValue="insured"
            >
                {(() => {
                let options: string[];
                if (newPremiumClass === "2061") {
                  options = ["p1", "p2", "p3", "p4", "p5"];
                } else if (newPremiumClass === "206") {
                  options = ["tp1", "tp2", "tp3", "tp4", "tp5"];
                } else {
                  options = ["insured"];
                }
                return options.map((option) => (
                  <MenuItem key={option} value={option}>
                  {option}
                  </MenuItem>
                ));
                })()}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSaveEditedEntry(editIndex)}
          >
            Save
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => toggleEditDialog(false, 0)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={claimLoading === true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ClaimRegistration;
