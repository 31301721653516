import CloseIcon from '@mui/icons-material/Close';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Box, Button, CircularProgress, Grid, IconButton, Modal, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { v4 as uuidv4 } from 'uuid';

interface FileUploadProps {
    transId: any; // This is the folder name you want to pass
    onFileUpload: () => void; // Callback function to notify file upload completion
}

const FileUpload: React.FC<FileUploadProps> = ({ transId, onFileUpload }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploadMessage, setUploadMessage] = useState(''); // State for success/error message
    const [uploadUrl, setUploadUrl] = useState<string | null>(null); // Store the URL
    const [open, setOpen] = useState(false); // State to handle modal open/close
    const [isUploading, setIsUploading] = useState(false); // State to show loader
    const { globalData } = React.useContext(GlobalDataContext);

    // Function to handle modal open
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Function to handle modal close
    const handleClose = () => {
        setOpen(false);
        setSelectedFiles([]); // Clear selected files when modal closes
        setUploadMessage(''); // Clear message on close
        setUploadUrl(null); // Clear the URL on close
    };

    // Helper to sanitize filenames by removing dangerous substrings
    const sanitizeFilename = (filename: string): string => {
        // Remove any occurrences of ../ or directory separators
        return filename.replace(/(\.\.[/\\])/g, "").replace(/[/\\]/g, "_");
    };

    // Helper to generate a unique filename while preserving the file extension
    const generateUniqueFilename = (originalFilename: string): string => {
        const extension = originalFilename.substring(originalFilename.lastIndexOf("."));
        return `${uuidv4()}${extension}`;
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files as FileList);
        if (files.length > 10) {
            alert('You can only upload a maximum of 10 files.');
        } else {
            setSelectedFiles(files as File[]);
        }
    };

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            alert('Please select files to upload.');
            return;
        }

        setIsUploading(true); // Show loader during upload

        const formData = new FormData();
        // Loop through each selected file, sanitize and rename it before appending
        selectedFiles.forEach(file => {
            const sanitized = sanitizeFilename(file.name);
            const newFileName = generateUniqueFilename(sanitized);
            // Create a new File object with the same content, type, and a new name.
            const newFile = new File([file], newFileName, { type: file.type });
            formData.append('files', newFile);
        });

        // Add the folder name (transId) to the form data
        formData.append('folderName', transId);

        
        for (const file of selectedFiles) {
            // Create a new FormData for each individual file
            const individualFormData = new FormData();
            individualFormData.append('file', file);
            individualFormData.append('folderName', transId);

            try {
            // Call your backend endpoint to upload/validate the individual file
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/check-upload`,
                individualFormData,
                {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${globalData?.accessToken}`,
                },
                }
            );

            // Assuming the backend returns the URL of the uploaded file
            console.log(`Response for ${file.name}:`, response.data);
            } catch (error) {
            setUploadMessage('Error uploading files or saving document information. Make sure the files are not corrupted or valid.');
            setIsUploading(false);
            return;
            }
        }



        try {
            // Upload files and get the URL from the backend
            const uploadResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${globalData?.accessToken}`
                },
            });

            const s3Url = uploadResponse.data; // Assuming the backend returns the URL

            // After getting the S3 URL, send the second POST request
            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            const otherDocumentData = {
                transId: transId,
                filename: `merge_${timestamp}.pdf`, // Filename with timestamp
                url: s3Url, // URL received from the first request
                channel: 1, // Default value
                status: 2, // Default value
                imsi: false
            };

            const documentResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/other-document`, otherDocumentData,
                {
                    headers: {
                        'Authorization': `Bearer ${globalData?.accessToken}`
                    }
                }
            );

            setUploadMessage('Files uploaded successfully and document information saved.');
            setUploadUrl(documentResponse.data.url); // Store the URL for clickable link

            // Notify the parent component after upload
            onFileUpload();
        } catch (error) {
            console.error('Error uploading files or saving document information', error);
            setUploadMessage('Error uploading files or saving document information. Make sure the files are not corrupted or valid.');
        } finally {
            setIsUploading(false); // Hide loader after upload
        }
    };

    return (
        <div>
            {/* Button to open the modal */}
            <Button
                variant="contained"
                color="error"
                onClick={handleClickOpen}
                startIcon={<FilePresentIcon />}
            >
                Upload Files
            </Button>

            {/* File upload modal */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        padding: 4,
                        backgroundColor: "white",
                        margin: "auto",
                        width: "50%",
                        position: "relative",
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: "absolute", top: 20, right: 25 }}
                    >
                        <CloseIcon color="error" fontSize="large" />
                    </IconButton>
                    <Typography variant="h4" className="tp-text-header-primary">
                        Upload Files to AW
                    </Typography>
                    <Typography variant="body1">Upload Files (Max of 10 Files) </Typography>

                    <hr className="tp-border-hr_primary" />
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={12} md={12} lg={12} textAlign="center">

                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf, .tiff"
                                multiple
                                onChange={handleFileChange}
                            />
                            {selectedFiles.length > 0 && (
                                <Typography variant="body2" color="textSecondary">
                                    {selectedFiles.length} file(s) selected
                                </Typography>
                            )}
                            {uploadMessage && (
                                <Typography variant="body2" color="textPrimary" style={{ marginTop: '16px' }}>
                                    {uploadMessage}{' '}
                                    {uploadUrl && (
                                        <a href={uploadUrl} target="_blank" rel="noopener noreferrer">
                                            Click here to view the document
                                        </a>
                                    )}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <br /><br />
                    <div style={{ textAlign: "center" }}>
                        {!uploadMessage && (
                            <Button
                                onClick={handleUpload}
                                color="primary"
                                variant="contained"
                                disabled={selectedFiles.length === 0 || isUploading} // Disable if no files or during upload
                            >
                                {isUploading ? <CircularProgress size={24} /> : 'Upload'}
                            </Button>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default FileUpload;
