import {
  Container,
  Card,
  CardContent,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  MenuItem,
  Button,
  IconButton,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserFormSkeleton from "../loadings/UserFormSkeleton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../api/FormatDate";
import React from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { convertKeysToSnakeCase } from "../../utils/SnakeCaseConverter";
import { getCurrentUser } from "../../utils/GetCurrentUser";
import secureLocalStorage from "react-secure-storage";

export default function EditShop() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isChangePass, setIsChangePass] = React.useState<boolean>(false);
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    isLoading: false,
  });
  const [formError, setFormError] = React.useState<any>({});
  const { globalData, setGlobalData, updateGlobalData } =
    React.useContext(GlobalDataContext);

  const dealerName = mainState?.data?.name || "";

  const shopToUpdate = async () => {
    setMainState({ ...mainState, isLoading: true });
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/repair-shops-sf/${id}`,
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        })
          .then((res: any) => {
            const resData = res.data.data;
            console.log(resData);
            setMainState({
              ...mainState,
              data: resData,
              isLoading: false,
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
            setMainState({ ...mainState, isLoading: false });
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("not-found");
    }
  };

  const updateShop = async () => {
    const loggedInUser = await getCurrentUser(globalData);
    if (loggedInUser?.status === 0) {
      updateGlobalData({
        role: "",
        username: null,
        idToken: null,
        isUserLogOut: true,
        lastLogin: null,
        lastLogout: null,
      });
      secureLocalStorage.removeItem("username");
      secureLocalStorage.removeItem("role");
      secureLocalStorage.removeItem("idToken");
      secureLocalStorage.removeItem("lastLogin");
      secureLocalStorage.removeItem("lastLogout");
      secureLocalStorage.removeItem("status");
      alert("Deactivated user cannot perform such action.");
      const cognitoLoginUrl =
        process.env.COGNITO_LOGIN_PAGE ||
        "https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fbpims.3pparallax.solutions";
      window.location.href = cognitoLoginUrl;
      return;
    } else {
      setMainState({ ...mainState, isLoading: true });
      try {
        (async () => {
          axios({
            method: "put",
            url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/repair-shops-sf/${id}`,
            data: mainState.data,
            headers: {
              Authorization: `Bearer ${globalData?.accessToken}`,
            },
          })
            .then((res: any) => {
              navigate(-1);
              setMainState({ ...mainState, isLoading: false });
            })
            .catch((err: any) => {
              console.error(err?.response?.data);
              let errorMessage = "";
              if (err?.response?.status === 401)
                errorMessage = err?.response?.data;
              if (err?.response?.status === 400)
                errorMessage = err?.response?.data;
              setMainState({ ...mainState, isLoading: false });
            });
        })();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors: any = {};

    // if (mainState.data?.name === "") {
    //   errors.name = "Name should not be empty.";
    //   isValid = false;
    // }

    // if (mainState.data?.nonVat === "") {
    //   errors.brand = "Brand should not be empty.";
    //   isValid = false;
    // }

    // if (mainState.data?.serviceManager === "") {
    //   errors.serviceManager = "Service Manager should not be empty.";
    //   isValid = false;
    // }

    // if (mainState.data?.contactNo === "") {
    //   errors.contact = "Contact should not be empty.";
    //   isValid = false;
    // }

    // if (mainState.data?.remarks === "") {
    //   errors.remarks = "Remarks should not be empty.";
    //   isValid = false;
    // }

    setFormError(errors);
    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      updateShop();
    }
  };

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { value, name } = event.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [name]: value },
    });
  };

  React.useEffect(() => {
    shopToUpdate();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 12,
      }}
    >
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{
          mb: 2,
          padding: 0,
        }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card
        sx={{
          minWidth: 275,
          mb: 4,
          padding: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#2683e0",
                  }}
                >
                  Edit Dealer:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 2,
                    color: "black",
                  }}
                >
                  {dealerName}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#607274",
                }}
              >
                Modify dealer details
              </Typography>
            </Box>
          </Box>
          <hr />
          <br />
          <br />
          {mainState.isLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2} className="tp-mb-20px">
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Name
                      {/* <span className="tp-text-required">*</span> */}
                    </Typography>
                    <OutlinedInput
                      id="name"
                      size="small"
                      type="text"
                      placeholder={`Enter Name Here...`}
                      value={mainState?.data?.name || ""}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "name",
                      }}
                      error={!!formError?.name}
                    />
                    {!!formError?.name && (
                      <FormHelperText error id="name">
                        {formError?.name}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="tp-mb-20px"
                  >
                    <Typography variant="body1" className="tp-text-bold">
                      Non VAT 
                      {/* <span className="tp-text-required">*</span> */}
                    </Typography>
                    <Select
                      id="nonVat"
                      name="nonVat"
                      placeholder="Please select non vat here..."
                      onChange={handleSelectChange}
                      size="small"
                      value={mainState?.data?.nonVat || 0}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                    {!!formError?.role && (
                      <FormHelperText error id="role">
                        {formError?.role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="tp-mb-20px"
                  >
                    <Typography variant="body1" className="tp-text-bold">
                      2% 1%
                      {/* <span className="tp-text-required">*</span> */}
                    </Typography>
                    <Select
                      id="twoOnePercent"
                      name="twoOnePercent"
                      placeholder="Please select value here..."
                      onChange={handleSelectChange}
                      size="small"
                      value={mainState?.data?.twoOnePercent || 0}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                    {!!formError?.role && (
                      <FormHelperText error id="role">
                        {formError?.role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Approval Workspace ID 
                      {/* <span className="tp-text-required">*</span> */}
                    </Typography>
                    <OutlinedInput
                      id="sfId"
                      size="small"
                      type="text"
                      placeholder={`Enter Approval Workspace Here...`}
                      value={mainState?.data?.sfId || ""}
                      onChange={handleInputChange}
                      // inputProps={{
                      //   "aria-label": "sa",
                      // }}
                      // error={!!formError?.contactNo}
                    />
                    {!!formError?.contactNo && (
                      <FormHelperText error id="contactNo">
                        {formError?.contactNo}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                
              </Grid>
              <Grid container>
                <Grid item md={2}>
                  <Button type="submit" fullWidth variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
