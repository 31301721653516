import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Typography
} from "@mui/material";
import axios from "axios";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { capitalizeAndReplaceUnderscore, formatDateToMMDDYYYY, formatToCurrency, handleJSONStringArray } from "../utils/stringUtils";
import PolicyAsiaPaymentTable from "./document_validation/tables/PolicyAsiaPaymentTable";
import { PolicyData } from "../types/PolicyData";
import { IAcapTask } from "../types/AcapTask";

interface PolicyNumberCheckerProps {
  setClientCode: (clientCode: string) => void;
  policyNo: string;
  setPolicyNoField: (policyNo: string) => void;
  setPolicyFromDB: (policyNo: string) => void;
  claimRegistrationData: any;
  setClientValidated: (clientValidated: boolean) => void;
  policyValidated: boolean;
  setPolicyValidated: (policyValidated: boolean) => void;
  getFieldCheckList: () => void;
  setPolicyData: (policyData: PolicyData) => void;
  policyData: PolicyData | null;
  acapTask: IAcapTask;
  policyStatus: number;
  setPolicyStatus: (policyStatus: number) => void;
}

const PolicyChecker: React.FC<PolicyNumberCheckerProps> = ({
  setClientCode,
  policyNo,
  setPolicyNoField,
  setPolicyFromDB,
  claimRegistrationData,
  setClientValidated,
  policyValidated,
  setPolicyValidated,
  getFieldCheckList,
  setPolicyData,
  policyData,
  acapTask,
  policyStatus,
  setPolicyStatus
}: any) => {
  // function formatDate(dateString: any) {
  //   const year = dateString.substring(0, 4);
  //   const month = dateString.substring(4, 6);
  //   const day = dateString.substring(6, 8);
  //   const date = new Date(`${year}-${month}-${day}`);
  //   const options: Intl.DateTimeFormatOptions = {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   };
  //   return date.toLocaleDateString("en-US", options);
  // }
  const [dateOfLoss, setDateOfLoss] = React.useState<Date>();

  const handleRiskChange = (e: any) => {
    const { value, id } = e.target;
    // setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
    setRiskNo(value);
  };

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;

    // setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
    if (!policyNoFromDB) {
      setPolicyNoField(value);
    } else {
      setPolicyNoFromDB(value);
      setPolicyFromDB(value);
    }
  };

  const handleReset = () => {
    // setMainState({ ...mainState, data: { ...mainState.data, policyNo: "" } });

    deletePolicyAsiaRecord(transId);
    deleteClientRecord(transId);
  };

  const location = useLocation();
  // const { sender } = location.state || {};
  const [mainState, setMainState] = React.useState<any>({
    data: {
      policyNo: policyNo,
    },
    attachments: [],
    docStatus: {},
    response: "",
    isLoading: false,
    error: "",
  });
  const { globalData } = React.useContext(GlobalDataContext);
  const { transId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  // const [policyData, setPolicyData] = React.useState<PolicyData | null>(null);
  // const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [selectedComponent, setSelectedComponent] = React.useState<
    string | null
  >(null);
  const domain = window.location.origin;
  const [policyNoFromDB, setPolicyNoFromDB] = React.useState("");
  const [riskNo, setRiskNo] = React.useState(1);
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  const [isLoading,setIsLoading] = React.useState(false);

  interface PolicyData {
    policyNumber: string;
    contractTy: string;
    contractTyp: string;
    policyStatus: string;
    clientCode: string;
    longName: string;
    inceptionDate: string;
    expiryDate: string;
    agentCode: string;
    agentName: string;
    issuingBranch: string;
    makeModelCode: string;
    interestDescription: string;
    vehicleChassis: string;
    yearManufactured: number;
    numberofSeat: number;
    deductiblePercentage: number;
    deductible: number;
    paliabilityPerson: number;
    vehicleRegistrationNumber: string;
    vehicleregistrationNumber: string;
    vehicleEngine: string;
    towingAmount: number;
    repair: number;
    motorMaker: string;
    model: string;
    makerModelCode: string;
    interestedParty: string[] | string;
    interestedPartyName: string[] | string;
    interestedPartyRole: string[] | string;
    contractHeader: { genp: string; clausecode: string } | string;
    itemCoverages:
    | {
      itemCoverage: string;
      sumInsuredperItemCoverage: number;
    }[]
    | string;
    risk: { genp: string; clausecode: string } | string;
    payment: {
      orno: string;
      trandate: string;
      effectivedate: string;
      amount: string;
    }[]
    | string;
    replacementNumber: string;
    premiumPaid: number;
    totalPremium: number;
    sourceExtension: string;
    sourceCode: string;
    riskNumber: string;
    url: string;
    status: number;
    saveDate?: string;
    transId?: number;
    projectCode: string;
    grossPremiumWritten: number;
    reference: string;
    bmaao: any | string;
    clientGroup: any | string;
    colourofVehicle: any | string;
    limitastoUse: number;
    oldAgerate: number;
  }
  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  // #region handleModalOpen
  const handleModalOpen = async () => {

    if (riskNo <= 0) {
      alert("Please input risk no. starting from 1");
      return;
    }

    if (dateOfLoss === undefined) {
      alert("Please input date of loss before checking");
      return;
    }

    if (acapTask.assignedUser === "queue") {
      alert("Please assign a correct Registration User before proceeding in claim processing");
      return;
    }

    if (!policyNo && !policyNoFromDB) {
      alert("Please enter a policy number");
      return;
    }

    // if (globalData?.username === "queue") {
    //   alert("Please login to continue");
    //   return;
    // }
    setLoading(true);

    const newPolicyNo = policyNoFromDB ? policyNoFromDB : policyNo;


    // fetch signature
    try {
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: newPolicyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: riskNo && riskNo !== 0 ? riskNo.toString() : "1",
                },
              },
              dateofloss: formatDateToMMDDYYYY(dateOfLoss)
            },
          }),
        }
      );

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;


      const policyEnquiryResponse = await fetch(
        `${process.env.REACT_APP_POLICY_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: newPolicyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: riskNo && riskNo !== 0 ? riskNo.toString() : "1",
                },
              },
              dateofloss: formatDateToMMDDYYYY(dateOfLoss)
            },
            signature: signature,
          }),
        }
      );

      if (!policyEnquiryResponse.ok) {
        throw new Error(`Error: ${policyEnquiryResponse.statusText}`);
      }

      const policyDataJSON = await policyEnquiryResponse.json();
      if (policyDataJSON?.data && !isEmptyObject(policyDataJSON?.data)) {
        console.log("handle modal open: ",policyDataJSON?.data)
        setPolicyData(policyDataJSON?.data);

        if (policyStatus !== 1) {
          setPolicyStatus(policyDataJSON?.data?.policyStatus !== "IF" ? 40 : 0)
        }
      } else {
        setPolicyData(null);
      }
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      alert('Error Getting Policy Data');
      setPolicyData(null);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // #region getPolicyAsiaRecord
  // if the
  const getPolicyAsiaRecord = async (
    policyNoParam: string,
    transactionId: any
  ) => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transactionId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {

        const policyDataRes = res?.data?.data;
        if (policyDataRes !== null) {
          console.log("getPolicyAsiaRecord", policyStatus)
          setPolicyNoFromDB(policyDataRes?.policyNumber);
          setRiskNo(policyDataRes?.riskNumber);
          setPolicyFromDB(policyDataRes?.policyNumber);
          setPolicyNoField(policyDataRes?.policyNumber);
          setPolicyValidated(policyDataRes?.status === 1 ? true : false);
          setPolicyStatus(policyDataRes?.status);
          setClientCode(policyDataRes?.clientCode);

          const dateLoss = policyDataRes?.dateOfLoss ? new Date(policyDataRes?.dateOfLoss) : undefined;
          setDateOfLoss(dateLoss);

          getFieldCheckList();
        }
      })
      .catch((err: any) => {
        // if motor vehicle has policy no but not validated
        // set validate to false
        console.error(err);
        if (err?.response?.status === 404) {
          setPolicyNoField(policyNoParam);
          setPolicyValidated(false);
        }
      });
  };

  // #region createPolicyAsiaRecord
  const createPolicyAsiaRecord = async (policyDataReq: any) => {
    console.log('policyDataReq', policyDataReq);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia`,
        JSON.stringify(policyDataReq),
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      )
      .then((res: any) => {

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: Number(transId),
          taskType: "document_validation",
          event: "validate_policy_details",
          description: `Validate policy details with #${policyData?.policyNumber}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        })

        // setPolicyValidated(true);
        setClientCode(policyData?.clientCode);
        setModalOpen(false);
        getFieldCheckList();
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  // #region createOrUpdatePolicyAsiaRecord
  const createOrUpdatePolicyAsiaRecord = async (policyDataReq: any) => {
    console.log('policyDataReq', policyDataReq);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia/create-or-update?transId=${transId}`,
        JSON.stringify(policyDataReq),
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      )
      .then((res: any) => {

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: Number(transId),
          taskType: "document_validation",
          event: "validate_policy_details",
          description: `Validate policy details with #${policyData?.policyNumber}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        })

        setPolicyValidated(policyDataReq?.status === 1 ? true : false);
        setPolicyStatus(policyStatus === 0 ? 1 : policyStatus);
        setClientCode(policyData?.clientCode);
        setModalOpen(false);
        getFieldCheckList();
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  // #region deletePolicyAsiaRecord
  const deletePolicyAsiaRecord = async (transactionId: any) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transactionId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {


        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: Number(transId),
          taskType: "document_validation",
          event: "reset_policy_details",
          description: `Reset policy details with #${policyData?.policyNumber}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        })
        setPolicyNoField("");
        setPolicyNoFromDB("");
        setRiskNo(1);
        setPolicyValidated(false);
        setPolicyStatus(0);
        setSelectedComponent(null);
        setClientCode("");
        setDateOfLoss(undefined);
      })
      .catch((err: any) => {
        console.error(err);
      });
    await getFieldCheckList();
  };

  const deleteClientRecord = async (transactionId: any) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transactionId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: Number(transId),
          taskType: "document_validation",
          event: "reset_client_details",
          description: `Reset client details with #${policyData?.clientCode}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        })

        if (res?.data?.data) {
          setClientCode("");
          setMainState({
            data: { clientNo: "" },
            clientData: {},
          });
          setClientValidated(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // #region handleConfirm
  const handleConfirm = () => {
    setIsLoading(true);

    // policyData!.url = `${domain}/policyenquiry?no=${policyNo}&token=${uuidv4()}`;
    // policyData!.status = 1;
    // policyData!.itemCoverages = JSON.stringify(policyData?.itemCoverages);
    // policyData!.payment = JSON.stringify(policyData?.payment);
    // policyData!.interestedParty = JSON.stringify(policyData?.interestedParty?.[0]);
    // policyData!.interestedPartyName = JSON.stringify(policyData?.interestedPartyName?.[0]);
    // policyData!.interestedPartyRole = JSON.stringify(
    //   policyData?.interestedPartyRole
    // );
    // policyData!.contractHeader = JSON.stringify(policyData?.contractHeader);
    // policyData!.risk = JSON.stringify(policyData?.risk);
    // policyData!.transId = Number(transId);
    // NOTE: remove this if the db is okay
    policyData!.agentName = policyData?.agentName.slice(0, 10) as string;
    const payload = {
      ...policyData,
      url: `${domain}/policyenquiry?no=${policyNo}&token=${uuidv4()}`,
      // status: policyStatus === 0 ? 1 : policyStatus,
      status: 1,
      itemCoverages: JSON.stringify(policyData?.itemCoverages),
      payment: JSON.stringify(policyData?.payment),
      interestedParty: policyData?.interestedParty?.[0],
      interestedPartyname: policyData?.interestedPartyName?.[0],
      interestedPartyrole: policyData.interestedPartyRole?.[0],
      vehicleregistrationNumber: policyData?.vehicleRegistrationNumber,
      contractTyp: policyData?.contractTy,
      dateOfLoss: dateOfLoss ? formatDateToMMDDYYYY(dateOfLoss) : formatDateToMMDDYYYY(new Date()),
      transId: Number(transId)
    }

    console.log("handleConfirm",payload)

    // createPolicyAsiaRecord(payload);
    createOrUpdatePolicyAsiaRecord(payload);

  };

  // #region useEffect
  React.useEffect(() => {
    getPolicyAsiaRecord(policyNo, transId);
  }, []);

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <Grid container spacing={2}>
          {/* Policy No. Input */}
          <Grid item xs={4}>
            <Typography variant="body1" className="tp-text-bold">
              Policy No. <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="policyNo"
              size="small"
              type="text"
              placeholder="Enter Policy No. Here..."
              value={policyNoFromDB || policyNo}
              onChange={handleInputChange}
              inputProps={{ 'aria-label': 'policyNo' }}
              disabled={Boolean(claimRegistrationData?.claimNumber)}
              error={policyNo === ''}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleReset}
                    edge="end"
                    disabled={Boolean(claimRegistrationData?.claimNumber)}
                  >
                    <RefreshIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>

          {/* Risk No. Input */}
          <Grid item xs={3}>
            <Typography variant="body1" className="tp-text-bold">
              Risk No. <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="riskNo"
              size="small"
              type="number"
              placeholder="Enter Risk No. Here..."
              value={riskNo}
              onChange={(e) => setRiskNo(Number(e.target.value))}
              inputProps={{ 'aria-label': 'riskNo' }}
              disabled={Boolean(claimRegistrationData?.claimNumber)}
              // error={riskNo === ''}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleReset}
                    edge="end"
                    disabled={Boolean(claimRegistrationData?.claimNumber)}
                  >
                    <RefreshIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item xs={5}>
            <Typography variant="body1" className="tp-text-bold">
              Date of Loss <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="dateOfLoss"
              size="small"
              type="date"
              value={
                dateOfLoss
                  ? new Date(dateOfLoss.getTime() - dateOfLoss.getTimezoneOffset() * 60000)
                      .toISOString()
                      .split('T')[0]
                  : ''
              }
              onChange={(e) => {
                const dateValue = e.target.value ? new Date(e.target.value) : undefined;
                console.log(dateOfLoss)
                setDateOfLoss(dateValue);
              }}
              inputProps={{ 'aria-label': 'dateOfLoss' }}
              disabled={Boolean(claimRegistrationData?.claimNumber)}
              error={!dateOfLoss} // Updated for correct validation
            />
          </Grid>

          {/* Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color='primary'
              onClick={handleModalOpen}
              disabled={loading || riskNo <= 0 || policyNo === "" || policyNo === null || !dateOfLoss}
              fullWidth
            >
              {loading ? (<CircularProgress size={24} />) :
                policyStatus === 1 ? ('Validated / View Details') :
                // policyStatus === 40 ? ('Check Policy Details') : 
                ('Check Policy Details')
              }
            </Button>
          </Grid>
        </Grid>

        {/* Error Message */}
        {(mainState?.data?.policyNo ?? '') === '' && (
          <FormHelperText error id="policyNo">
            {/* Empty required field. Please validate Policy to generate CNC/NCC */}
          </FormHelperText>
        )}
      </FormControl>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        sx={{ overflowY: "scroll" }}
      >
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            width: "70%",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleModalClose}
            sx={{ position: "absolute", top: 20, right: 25 }}
          >
            <CloseIcon color="error" fontSize="large" />
          </IconButton>
          <Typography variant="h4" className="tp-text-header-primary">
            Policy Details
          </Typography>
          <hr className="tp-border-hr_primary tp-mb-20px" />

          {policyData &&
            (
              // Case: No Policy Payment
              (policyData.premiumPaid === 0) ?
                <Typography variant="h5" style={{ marginTop: "20px", paddingTop: "20px", paddingBottom: "20px", color: "white", backgroundColor: "#a42a25", textAlign: "center" }}>
                  No Payment
                </Typography>
                :
                // Case: Outstanding Premium
                (policyData.totalPremium > policyData.premiumPaid) ?
                  <Typography variant="h5" style={{ marginTop: "20px", paddingTop: "20px", paddingBottom: "20px", color: "white", backgroundColor: "#a42a25", textAlign: "center" }}>
                    Outstanding Premium
                  </Typography>
                  :
                  // Case: Payment complete
                  (policyData.totalPremium <= policyData.premiumPaid) ? null
                    :
                    // Case: if there is something wrong and not payment complete return no policy payment
                    <Typography variant="h5" style={{ marginTop: "20px", paddingTop: "20px", paddingBottom: "20px", color: "white", backgroundColor: "#a42a25", textAlign: "center" }}>
                      No Policy Premium
                    </Typography>
            )}
          {/* <hr style={{ width: "1000px", marginTop: "10px", marginBottom: "10px" }} /> */}

          {policyData && !isEmptyObject(policyData) ? (
            <div>
              <Grid container spacing={2} style={{ marginBottom: "20px", marginTop: "30px" }}>
                <Grid item xs={12} md={12} lg={12} textAlign="center">
                  <Typography variant="body1">Full Name:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.longName}
                  </Typography>
                </Grid>
              </Grid>

              <hr style={{ marginTop: "20px", marginBottom: "10px" }} />

              <Grid container spacing={2} style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Policy Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.policyNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Policy Status: {policyData.policyStatus !== "IF" && <Chip color="error" size="small" label='Policy is not "In Force"'/>}</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.policyStatus} 
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Contract Type:</Typography>
                  <Typography
                    variant="h5"
                    className={policyData.contractTy === "LPC" || policyData.contractTy === "LDP" ? "tp-text-header-primary-lcp-ldp" : "tp-text-header-primary"} // For LPC and LDP cases
                  >
                    {policyData.contractTy}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Client Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.clientCode}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Inception Date:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {`${policyData.inceptionDate.slice(4, 6)}/${policyData.inceptionDate.slice(6, 8)}/${policyData.inceptionDate.slice(0, 4)}`}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Expiry Date:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {`${policyData.expiryDate.slice(4, 6)}/${policyData.expiryDate.slice(6, 8)}/${policyData.expiryDate.slice(0, 4)}`}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Agent Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.agentCode}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Agent Name:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.agentName}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Issuing Branch:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.issuingBranch}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                {/* <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Make Model Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.makerModelCode}
                  </Typography>
                </Grid> */}

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Motor Maker:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.motorMaker}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Model:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.model}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Body:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.interestDescription}
                  </Typography>
                </Grid>

              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Vehicle Chassis:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.vehicleChassis}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Vehicle Engine:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.vehicleEngine}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Vehicle Registration Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.vehicleRegistrationNumber}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Year Manufactured:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.yearManufactured}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Number of Seats:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.numberofSeat}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Deductible Percentage:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.deductiblePercentage.toFixed(2)}
                  </Typography>
                </Grid>

              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Deductible:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.deductible || 0)}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Liability Person:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.paliabilityPerson || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Towing Amount:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.towingAmount || 0)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
               

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Repair:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.repair || 0)}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="h5" className="tp-text-header-error" style={{ marginBottom: "15px" }}>
                Sum Insured Details
              </Typography>
              <hr />
              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                {policyData.itemCoverages &&
                  handleJSONStringArray(policyData?.itemCoverages).map(
                    (coverage: any, index: any) => (
                      <Grid item xs={12} md={4} lg={4}>
                        <div>Item {coverage.itemCoverage}:</div>
                        {/* <Typography key={index} variant="body2">
                          &ensp;{formatToCurrency(coverage.sumInsuredperItemCoverage || 0)}
                        </Typography> */}
                        <Typography
                          variant="h5"
                          className="tp-text-header-primary"
                        >
                          {formatToCurrency(coverage.sumInsuredperItemCoverage || 0)}
                        </Typography>
                      </Grid>
                    )
                  )}
              </Grid>
              <hr />

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                {/* {policyData.risk && (
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1">Risk :</Typography>
                    <Typography
                      variant="h5"
                      className="tp-text-header-primary"
                    >
                      {
                        (typeof policyData.risk === "string"
                          ? JSON.parse(policyData.risk)
                          : policyData.risk
                        ).genp
                      }
                      {
                        (typeof policyData.risk === "string"
                          ? JSON.parse(policyData.risk)
                          : policyData.risk
                        ).genp ? ":"
                          : ""}
                      {
                        (typeof policyData.risk === "string"
                          ? JSON.parse(policyData.risk)
                          : policyData.risk
                        ).clausecode
                      }
                    </Typography>
                  </Grid>
                )} */}

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Replacement Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.replacementNumber || "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Total Premium:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.totalPremium || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Premium Paid:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.premiumPaid || 0)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Source Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.sourceCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Source Extension:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.sourceExtension}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Risk Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.riskNumber}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
               
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Project Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.projectCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Gross Premium Written:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.grossPremiumWritten || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Reference:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.reference || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Interested Party Name:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.interestedPartyName?.[0] || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Interested Party Role:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.interestedPartyRole?.[0] || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Interested Party Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.interestedParty?.[0] || "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">BMA / AO:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.bmaao || "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Client Group:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.clientGroup || "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Color:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.colourofVehicle || "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">LATU:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.limitastoUse || 0}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Old Age:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.oldAgerate || 0}
                  </Typography>
                </Grid>

              </Grid>

              {policyData.payment && (
                <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1">Payment:</Typography>
                    <Typography
                      variant="h5"
                      className="tp-text-header-primary"
                    >
                      <PolicyAsiaPaymentTable
                        rows={handleJSONStringArray(policyData.payment)}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {/* 
                // #region Buttons
              */}
              <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                {/* {policyStatus === 0 ? (
                    <Button variant="contained" onClick={handleConfirm} disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center","Canvasser"].includes(globalData?.role) || isLoading}>
                        {isLoading ? 
                          (<CircularProgress size={24} color="inherit" />) : 
                          ("Confirm")
                        }
                    </Button>
                  ) : policyStatus === 40 ? (
                    <Button variant="contained" onClick={handleConfirm} disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center","Canvasser"].includes(globalData?.role) || isLoading}>
                        {isLoading ? 
                          (<CircularProgress size={24} color="inherit" />) : 
                          ("Send to AW")
                        }
                    </Button>
                  ) : null
                } */}

                {policyValidated === false ? (
                    <Button variant="contained" onClick={handleConfirm} disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center","Canvasser"].includes(globalData?.role) || isLoading}>
                        {isLoading ? 
                          (<CircularProgress size={24} color="inherit" />) : 
                          ("Confirm")
                        }
                    </Button>
                  ) : null
                }
              </Box>
            </div>
          ) : (
            <Typography variant="body2" color="error">
              Policy / Risk Number Doesn't Exist
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default PolicyChecker;
