import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";
import axios from "axios";
// import {GlobalDataContext } from "../contexts/GlobalDataContext";

export type AuditLogData = {
  id?: number | null;
  transId?: number | null;
  taskType: string | null;
  event: string | null;
  description: string | null;
  ipAddress?: string | null;
  saveDate?: string | null;
  user?: string | null;
  fullName?: string | null;
  roleLevel?: string | null;
};

type AuditLogDataRequest = {
  transId: number;
  taskType: string;
  event: string;
  description: string;
  user?: string | null;
  fullName?: string | null;
  roleLevel?: string | null;
};

interface AuditTrailState {
  data: AuditLogData[];
  isLoading: boolean;
  pagination: {
    page: number;
    count: number;
    size: number;
  };
}

interface AuditLogDataContextInterface {
  auditTrailData: AuditTrailState;
  setAuditTrailData: React.Dispatch<React.SetStateAction<AuditTrailState>>;
  updateAuditTrailData: (data: Partial<AuditLogData>[]) => void;
  fetchAuditTrailData: (
    accessToken: string,
    transId: number,
    page: number,
    size: number
  ) => Promise<void>;
  createAuditTrailData: (
    accessToken: string,
    dataRequest: AuditLogDataRequest
  ) => Promise<void>;
}

const initialState: AuditTrailState = {
  data: [],
  isLoading: false,
  pagination: {
    page: 1,
    count: 0,
    size: 3,
  },
};

const initialContextState: AuditLogDataContextInterface = {
  auditTrailData: initialState,
  setAuditTrailData: () => {},
  updateAuditTrailData: () => {},
  fetchAuditTrailData: async () => {},
  createAuditTrailData: async () => {},
};

export const AuditTrailDataContext =
  createContext<AuditLogDataContextInterface>(initialContextState);
export const useAuditTrailData = () => useContext(AuditTrailDataContext);

type AuditTrailDataProviderProps = {
  children: ReactNode;
};

export default function AuditTrailDataStore({
  children,
}: AuditTrailDataProviderProps) {
  const [auditTrailData, setAuditTrailData] =
    useState<AuditTrailState>(initialState);

  // Function to update parts of the global data
  const updateAuditTrailData = useCallback((data: Partial<AuditLogData>[]) => {
    setAuditTrailData((prev) => ({
      ...prev,
      data: prev.data.map((item, index) =>
        data[index] ? { ...item, ...data[index] } : item
      ),
    }));
  }, []);

  const fetchAuditTrailData = async (
    accessToken: string,
    transId: number,
    page: number,
    size: number
  ) => {
    try {
      setAuditTrailData((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const paginatedResponse = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/audit-trail/pagination/${transId}?page=${page - 1}&size=${size}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const paginatedData = paginatedResponse?.data?.data?.content;

      const totalItems = paginatedResponse?.data?.data?.totalElements;
      const totalPages = paginatedResponse?.data?.data?.totalPages;

      setAuditTrailData((prev) => ({
        ...prev,
        data: paginatedData,
        isLoading: false,
        pagination: {
          ...prev.pagination,
          page: page,
          count: totalPages,
          size: size,
        },
      }));
    } catch (err) {
      console.error(err);
      setAuditTrailData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const createAuditTrailData = async (
    accessToken: string,
    dataRequest: AuditLogDataRequest
  ) => {
    setAuditTrailData((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/audit-trail/`,
        dataRequest,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      await fetchAuditTrailData(accessToken, dataRequest.transId, 1, 3);
    } catch (err) {
      console.error(err);
    } finally {
      setAuditTrailData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  return (
    <AuditTrailDataContext.Provider
      value={{
        auditTrailData,
        setAuditTrailData,
        updateAuditTrailData,
        fetchAuditTrailData,
        createAuditTrailData,
      }}
    >
      {children}
    </AuditTrailDataContext.Provider>
  );
}
