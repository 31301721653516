// components/BusinessRules.tsx

import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControlLabel,
  Switch,
  Box,
  Stack,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Checkbox,
  FormGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentIcon from "@mui/icons-material/Comment";
import { useBusinessRules } from "../../../contexts/BusinessRulesContext";
import { GlobalDataContext } from "../../../contexts/GlobalDataContext";

export default function BusinessRules({
  docId,
  transId,
  taskType,
  documentType,
}: any) {
  const { globalData } = React.useContext(GlobalDataContext);
  const {
    businessRules,
    fetchBusinessRules,
    setBusinessRules,
    overrideBusinessRules,
  } = useBusinessRules();


  // Local state to manage comment inputs
  const [commentInputs, setCommentInputs] = useState<{ [key: number]: string }>(
    {}
  );
  // State to manage which accordion is expanded
  const [expanded, setExpanded] = useState<number | false>(false);

  useEffect(() => {
    fetchBusinessRules(
      globalData!.accessToken!,
      docId,
      transId,
      taskType,
      documentType
    );
  }, [docId, transId, taskType, documentType, fetchBusinessRules]);

  useEffect(() => {
    // Initialize commentInputs with existing comments using index as key
    const initialComments: { [key: number]: string } = {};
    if (businessRules.rules) {
      businessRules.rules.forEach((item: any, index: number) => {
        initialComments[index] = item.comment || "";
      });
      setCommentInputs(initialComments);
    }
  }, [businessRules.rules]);

  // Toggle the 'validated' state of a rule
  const handleToggle = (index: number) => {
    if (businessRules.rules) {
      const updatedRules = businessRules.rules.map((rule: any, idx: number) =>
        idx === index ? { ...rule, validated: !rule.validated } : rule
      );
      // Update the business rules in your context
      setBusinessRules((prev: any) => ({
        ...prev,
        rules: updatedRules,
      }));

      overrideBusinessRules(
        globalData!.accessToken!,
        docId,
        transId,
        taskType,
        documentType,
        updatedRules
      );
    }
  };

  // Handle comment input changes
  const handleCommentChange = (index: number, comment: string) => {
    setCommentInputs((prev) => ({
      ...prev,
      [index]: comment,
    }));
  };

  // Save or update the comment for a specific rule
  const handleSaveComment = (index: number) => {
    const comment = commentInputs[index] || "";
    if (businessRules.rules) {
      const updatedRules = businessRules.rules.map((rule: any, idx: number) =>
        idx === index ? { ...rule, comment } : rule
      );
      // Update the business rules in your context
      setBusinessRules((prev: any) => ({
        ...prev,
        rules: updatedRules,
      }));

      overrideBusinessRules(
        globalData!.accessToken!,
        docId,
        transId,
        taskType,
        documentType,
        updatedRules
      );
    }
  };

  // handle override
  const handleOverride = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (businessRules.rules) {
      const updatedRules = businessRules.rules.map((rule: any, idx: number) =>
        idx === index ? { ...rule, override: event.target.checked } : rule
      );
      // Update the business rules in your context
      setBusinessRules((prev: any) => ({
        ...prev,
        rules: updatedRules,
      }));

      overrideBusinessRules(
        globalData!.accessToken!,
        docId,
        transId,
        taskType,
        documentType,
        updatedRules
      );
    }
  };

  // Manage accordion expansion
  const handleAccordionChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="tp-mb-20px">
      <Stack direction={"column"} spacing={2}>
        <Typography variant="h6" className="tp-text-header-primary">
          Business Rules
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="body1">
            Note: Please update the form and any associated form before
            validation
          </Typography>
        </Stack>
      </Stack>
      <hr />
      {businessRules.isLoading !== true ? (
        <React.Fragment>
          {businessRules.rules && businessRules.rules.length > 0 ? (
            businessRules.rules.map((item: any, index: number) => (
              <Accordion
                key={index} // Using index as key since 'id' is unavailable
                expanded={expanded === index}
                onChange={handleAccordionChange(index)}
                elevation={1}
                sx={{ mb: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                  sx={{
                    bgcolor: item?.validated
                      ? "transparent"
                      : "#f7e3e3",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography variant="body1">{item.description}</Typography>
                    <Box display="flex" alignItems="center">
                
                      <FormControlLabel
                        control={
                          <Switch
                            checked={item.validated}
                            onChange={() => handleToggle(index)}
                            name={item.description}
                            color="default"
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                color: item?.validated
                                  ? ""
                                  : "rgb(177, 17, 22)", // Thumb color when unchecked
                                "&.Mui-checked": {
                                  color: item?.validated ? "" : "rgb(177, 17, 22)", // Thumb color when checked (default)
                                },
                                "&.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: item?.validated
                                    ? ""
                                    : "rgb(177, 17, 22)", // Track color when checked
                                },
                                "&.Mui-disabled": {
                                  color: "", // Thumb color when disabled
                                  "& + .MuiSwitch-track": {
                                    backgroundColor: item.validated ? "" : "rgb(177, 17, 22)", // Track color when disabled
                                    opacity: 0.5,
                                  },
                                },
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: item?.validated
                                  ? ""
                                  : "rgb(177, 17, 22)", // Track color when unchecked
                                "&.Mui-disabled": {
                                  backgroundColor: "rgb(177, 17, 22)", // Track color when disabled
                                  opacity: 0.5,
                                },
                              },
                            }}
                            disabled={
                              (item.override === false ? true : false) ||
                              [
                                "Clec",
                                "Jr Clec",
                                "Head Motor Claims",
                                "Head Claims",
                                "Head Clec",
                                "Call Center",
                                "Canvasser"
                              ].includes(globalData?.role)
                            }
                          />
                      }
                      label={item.validated ? "Yes" : "No"}
                      />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.override}
                              onChange={(event) => handleOverride(index, event)}
                              disabled={
                                [
                                  "Clec",
                                  "Jr Clec",
                                  "Head Motor Claims",
                                  "Head Claims",
                                  "Head Clec",
                                  "Call Center",
                                  "Canvasser",
                                ].includes(globalData?.role) // Disable if role is in the list
                                  ? true
                                  : item.comment.length === 0 // If role is not in the list, disable if no comment
                              }
                            />
                          }
                          label="Override"
                        />
                      </FormGroup>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent accordion toggle
                          setExpanded(expanded === index ? false : index);
                        }}
                        aria-label={
                          item.comment ? "Update Comment" : "Add Comment"
                        }
                      >
                        <CommentIcon
                          color={item.comment ? "primary" : "action"}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                      label={item.comment ? "Update Comment" : "Add Comment"}
                      variant="outlined"
                      fullWidth
                      value={commentInputs[index] || ""}
                      onChange={(e) =>
                        handleCommentChange(index, e.target.value)
                      }
                      multiline
                      rows={3}
                      disabled={[
                        "Clec",
                        "Jr Clec",
                        "Head Motor Claims",
                        "Head Claims",
                        "Head Clec",
                        "Call Center",
                        "Canvasser"
                      ].includes(globalData?.role)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSaveComment(index)}
                      disabled={[
                        "Clec",
                        "Jr Clec",
                        "Head Motor Claims",
                        "Head Claims",
                        "Head Clec",
                        "Call Center",
                        "Canvasser"
                      ].includes(globalData?.role)}
                    >
                      {item.comment ? "Update Comment" : "Add Comment"}
                    </Button>
                    {item.comment && (
                      <Box mt={2}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Saved Comment:
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: "#9A8311" }}
                        >
                          {item.comment}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ my: 4 }}
              align="center"
            >
              No Data Found, Please Try to Validate First
            </Typography>
          )}
        </React.Fragment>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
