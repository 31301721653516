import * as React from "react";
import MUIDataTable, { SelectableRows, Responsive } from "mui-datatables";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { formatDate } from "../api/FormatDate";
import { GlobalDataContext } from "../contexts/GlobalDataContext";

export function ViewButton({ url, claimNo, docId }: any) {
  const navigate = useNavigate();

  return (
    <Button
      className="tp-custom-button-primary"
      variant="contained"
      size="small"
      onClick={() => navigate(url, { state: { claimNo, docId } })}
    >
      View
    </Button>
  );
}

export default function TaskTable({ task }: any) {
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [rows, setRows] = React.useState<any>([]);
  const [columns, setColumns] = React.useState<any>([]);

  // function formatDate(dateString: string): string {
  //   const date = new Date(dateString);
  
  //   if (isNaN(date.getTime())) {
  //     return 'Invalid Date';
  //   }
  
  //   const optionsDate: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };
  //   const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  
  //   const optionsTime: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
  //   let timeString = date.toLocaleTimeString('en-US', optionsTime).toLowerCase();
  //   timeString = timeString.replace(/:\d{2}\s?(am|pm)/, (match, p1) => p1 ? p1 : match);
  
  //   return `${formattedDate} | ${timeString}`;
  // }
  
  const [mainState, setMainState] = React.useState<any>({
    error: "",
    isLoading: false,
  });

 

  // const sampleDate = '2024-07-18 16:23:17';


  const convertStatus = (status: number) => {
    if (status === 9) {
      return {
        label: "Pending For Offer",
        component: (
          <Chip
            label="Pending For Offer"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === 26) {
      return {
        label: "Pending For Offer",
        component: (
          <Chip
            label="Pending For Offer"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === 19) {
      return {
        label: "Pending Adjuster's Report",
        component: (
          <Chip
            label="Pending Adjuster's Report"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === 21) {
      return {
        label: "Pending In-house Survey (Clec)",
        component: (
          <Chip label="Pending In-house Survey (Clec)" size="small" variant="outlined" color="success" />
        ),
      };
    } else if (status === 22) {
      return {
        label: "Pending Adjuster's Survey (IMSI)",
        component: (
          <Chip
            label="Pending Adjuster's Survey (IMSI)"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === 27) {
      return {
        label: "Head Motor Claims Approval",
        component: (
          <Chip
            label="Head Motor Claims Approval"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    }else if (status === 28) {
      return {
        label: "Head Claims Approval",
        component: (
          <Chip
            label="Head Claims Approval"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    }else if (status === 4) {
      return {
        label: "Head CLEC Approval",
        component: (
          <Chip
            label="Head CLEC Approval"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  const convertPriority = (priority: number) => {
    if (priority == 1) {
      return {
        label: "High",
        component: (
          <Chip label="High" size="small" variant="outlined" color="warning" />
        ),
      };
    } else if (priority == 0) {
      return {
        label: "Low",
        component: (
          <Chip label="Low" size="small" variant="outlined" color="primary" />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  const convertChannel = (channel: number) => {
    if (channel === 1) {
      return {
        label: "Email",
        component: (
          <Chip label="Email" size="small" variant="outlined" color="primary" />
        ),
      };
    } else if (channel === 2) {
      return {
        label: "Web Form",
        component: (
          <Chip
            label="Web Form"
            size="small"
            variant="outlined"
            color="primary"
          />
        ),
      };
    } else if (channel === 3) {
      return {
        label: "From Clec",
        component: (
          <Chip
            label="From Clec"
            size="small"
            variant="outlined"
            color="primary"
          />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  const getAllTaskByTaskType = async (taskType: string) => {
    setMainState({ ...mainState, isLoading: true, error: "" });

    try {
      let res;
      if (taskType === "Damage Evaluation") {
        res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
      } else {
        // Handle other task types if necessary
        console.warn(`Unhandled taskType: ${taskType}`);
        setMainState({ ...mainState, isLoading: false });
        return;
      }



      const filteredData = res?.data.filter(
        (item: any) =>
          [9, 26, 21, 22, 19, 4, 27, 28].includes(item.status)
      );



      if (Array.isArray(filteredData)) {
        
        const transformedData = filteredData.map((item: any) => {
          // console.log("filteredData", item);
          const numericStatus = item.status;
          const displayStatus = convertStatus(item.status);
          const username = item.username ? item.username : "In Progress";

          return {
            id: item.id,
            transId: `ACAP-${item.transId}`,
            claimNo: item.claimNo, // Updated claimNo
            sender: item.sender,
            dateCreated: formatDate(item.saveDate),
            channel: item.channel,
            channel_display: convertChannel(item.channel).component,
            priority: item.priority,
            priority_display: convertPriority(item.priority).component,
            priority_desc: item.priorityDesc,
            status: numericStatus,
            status_display: displayStatus.component,
            username: username,
            action:
              numericStatus !== 0 && username !== "In Progress" ? (
                <ViewButton
                  url={`${item.transId}/${item.id}`}
                  claimNo={item.claimNo}
                  docId={item.id}
                />
              ) : null,
          };
        });

        setRows(transformedData);

        const channelMap: { [key: string]: string } = {
          "1": "Email",
          "2": "Web Form",
        };
        const priorityMap: { [key: string]: string } = {
          "0": "Low",
          "1": "High",
        };
        const statusMap: { [key: string]: string } = {
          "9": "Pending For Offer",
          "26": "Pending For Offer",
          "1": "Open",
          "19": "Pending Adjuster's Report",
          "21": "Pending In-house Survey (Clec)",
          "22": "Pending Adjuster's Survey (IMSI)",
          "4": "Head CLEC Approval",
        };

        const transformedCols = [
          {
            name: "transId",
            label: "Trans ID",
            options: { filter: true, sort: true },
          },
          { name: "claimNo", label: "Claim #", options: { filter: true } },
          {
            name: "sender",
            label: "Sender",
            options: { filter: true, sort: true },
          },
          {
            name: "dateCreated",
            label: "Date Created",
            options: { filter: true, sort: true },
          },
          {
            name: "channel_display",
            label: "CHANNEL",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: any) => value,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "channel",
            label: "Channel",
            options: {
              display: false,
              filter: true,
              filterOptions: {
                names: Object.keys(channelMap).map((key) => channelMap[key]),
                logic(channel: any, filterVal: any) {
                  return !filterVal.includes(channelMap[channel]);
                },
                display: (
                  filterList: any,
                  onChange: any,
                  index: any,
                  column: any
                ) => (
                  <div>
                    <label>Channel</label>
                    <select
                      onChange={(event) => {
                        filterList[index] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: "100%" }}
                    >
                      <option value="">All</option>
                      {Object.entries(channelMap).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                ),
              },
            },
          },
          {
            name: "priority_display",
            label: "PRIORITY",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: any) => value,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "priority",
            label: "Priority",
            options: {
              display: false,
              filter: true,
              filterOptions: {
                names: Object.keys(priorityMap).map((key) => priorityMap[key]),
                logic(priority: any, filterVal: any) {
                  return !filterVal.includes(priorityMap[priority]);
                },
                display: (
                  filterList: any,
                  onChange: any,
                  index: any,
                  column: any
                ) => (
                  <div>
                    <label>Priority</label>
                    <select
                      onChange={(event) => {
                        const value = event.target.value;
                        filterList[index] = value === "" ? [] : [value];
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: "100%" }}
                    >
                      <option value="">All</option>
                      {Object.entries(priorityMap).map(([key, value]) => (
                        <option key={key} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                ),
              },
            },
          },

          // { name: 'priority_desc', label: 'Priority Description', options: { filter: true, sort: true } },
          {
            name: "status_display",
            label: "STATUS",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: any) => value,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "status",
            label: "Status",
            options: {
              display: false,
              filter: true,
              filterOptions: {
                names: Object.keys(statusMap).map((key) => statusMap[key]),
                logic(status: any, filterVal: any) {
                  return !filterVal.includes(statusMap[status]);
                },
                display: (
                  filterList: any,
                  onChange: any,
                  index: any,
                  column: any
                ) => (
                  <div>
                    <label>Status</label>
                    <select
                      onChange={(event) => {
                        filterList[index] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: "100%" }}
                    >
                      <option value="">All</option>
                      {Object.entries(statusMap).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                ),
              },
            },
          },
          {
            name: "username",
            label: "Username",
            options: { filter: true, sort: true },
          },
          {
            name: "action",
            label: "ACTION",
            options: {
              filter: false,
              sort: false,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
        ];

        setColumns(transformedCols);
      } else {
        console.error("Unexpected response format:", res?.data);
      }

      setMainState({ ...mainState, isLoading: false });
    } catch (err: any) {
      console.error("Error:", err);

      let errorMessage = "";
      if (err?.response?.status === 401) errorMessage = err?.response?.data;
      if (err?.response?.status === 400) errorMessage = err?.response?.data;

      setMainState({ error: errorMessage, isLoading: false });
    }
  };

  const options = {
    download: false,
    print: false,
    selectableRows: "none" as SelectableRows,
    responsive: "standard" as Responsive,
    resizableColumns: true,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              padding: "5px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "2px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              textAlign: "center",
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
      },
    });

  React.useEffect(() => {
    getAllTaskByTaskType(task);
  }, []);

  return (
    <Box
      sx={{
        my: 4,
      }}
    >
      {!mainState.isLoading ? (
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Task List"}
            data={rows}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
