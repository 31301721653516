import axios from "axios";
import React from "react";
import PolicyAsiaPaymentTable from "./tables/PolicyAsiaPaymentTable";
import {
  Accordion,
  AccordionDetails,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { formatToCurrency } from "../../utils/stringUtils";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";

interface PolicyData {
  policyNumber: string;
  contractTy: string;
  contractTyp: string;
  policyStatus: string;
  clientCode: string;
  longName: string;
  inceptionDate: string;
  expiryDate: string;
  agentCode: string;
  agentName: string;
  issuingBranch: string;
  makeModelCode: string;
  interestDescription: string;
  vehicleChassis: string;
  yearManufactured: number;
  numberofSeat: number;
  deductiblePercentage: number;
  deductible: number;
  paliabilityPerson: number;
  vehicleRegistrationNumber: string;
  vehicleregistrationNumber: string;
  vehicleEngine: string;
  towingAmount: number;
  repair: number;
  motorMaker: string;
  model: string;
  makerModelCode: string;
  interestedParty: string[] | string;
  interestedPartyName: string[] | string;
  interestedPartyRole: string[] | string;
  interestedPartyname: string;
  interestedPartyrole: string;
  contractHeader: { genp: string; clausecode: string } | string;
  itemCoverages:
    | {
        itemCoverage: string;
        sumInsuredperItemCoverage: number;
      }[]
    | string;
  risk: { genp: string; clausecode: string } | string;
  payment:
    | {
        orno: string;
        trandate: string;
        effectivedate: string;
        amount: string;
      }[]
    | string;
  replacementNumber: string;
  premiumPaid: number;
  totalPremium: number;
  sourceExtension: string;
  sourceCode: string;
  riskNumber: string;
  url: string;
  status: number;
  saveDate?: string;
  transId?: number;
  projectCode: string;
  grossPremiumWritten: number;
  reference: string;
  bmaao: any | string;
  clientGroup: any | string;
  colourofVehicle: any | string;
  limitastoUse: number;
  oldAgerate: number;
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function PolicyDetails({ transId }: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [policyData, setPolicyData] = React.useState<PolicyData | null>(null);
  const { globalData } = React.useContext(GlobalDataContext);
  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  const handleJSONStringArray = (item: any) => {
    let arr = [];

    if (typeof item === "string") {
      try {
        arr = JSON.parse(item);
      } catch (error) {
        console.error(`Failed to parse ${item} string:`, error);
      }
    } else {
      arr = item;
    }

    return Array.isArray(arr) ? arr : [];
  };

  const getPolicyAsiaRecord = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        const policyDataRes = res?.data?.data;
        setPolicyData(policyDataRes);
        setIsLoading(false);
      })
      .catch((err: any) => {
        // if motor vehicle has policy no but not validated
        // set validate to false
        console.error(err);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getPolicyAsiaRecord();
  }, [transId]);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel-a-content"}
          id={"panel-a-header"}
          className={"tp-bg-lightPrimary"}
        >
          <Typography className="tp-text-bold">Policy Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading !== true ? (
            <React.Fragment>
              {policyData && policyData?.status === 1 ? (
                <>
                  {/* <Grid container spacing={2} style={{ marginBottom: "20px", marginTop: "20px", textAlign: "center" }}>
                <Grid item xs={12} md={2} lg={2} />
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Policy Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.policyNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Contract Type:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.contractTy}
                  </Typography>
                </Grid>
              </Grid>
              <hr style={{ width: "1000px", alignSelf: "center", marginTop: "20px", marginBottom: "10px" }} /> */}

                  <Typography variant="body2">
                    <strong>Policy Number:</strong> {policyData.policyNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Contract Type:</strong>{" "}
                    <span
                      style={{
                        color:
                          policyData.contractTyp === "LPC" ||
                          policyData.contractTyp === "LDP"
                            ? "red"
                            : "inherit",
                      }}
                    >
                      {policyData.contractTyp}
                    </span>
                  </Typography>
                  <Typography variant="body2">
                    <strong>Policy Status:</strong> {policyData.policyStatus}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Client Code:</strong> {policyData.clientCode}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Full Name:</strong> {policyData.longName}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Inception Date:</strong>{" "}
                    {`${policyData.inceptionDate.slice(
                      4,
                      6
                    )}/${policyData.inceptionDate.slice(
                      6,
                      8
                    )}/${policyData.inceptionDate.slice(0, 4)}`}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Expiry Date:</strong>{" "}
                    {`${policyData.expiryDate.slice(
                      4,
                      6
                    )}/${policyData.expiryDate.slice(
                      6,
                      8
                    )}/${policyData.expiryDate.slice(0, 4)}`}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Agent Code:</strong> {policyData.agentCode}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Agent Name:</strong> {policyData.agentName}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Issuing Branch:</strong> {policyData.issuingBranch}
                  </Typography>
                  
                  <Typography variant="body2">
                    <strong>Motor Maker:</strong> {policyData.motorMaker}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Model:</strong> {policyData.model}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Body:</strong> {policyData.interestDescription}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Vehicle Chassis:</strong>{" "}
                    {policyData.vehicleChassis}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Vehicle Engine:</strong> {policyData.vehicleEngine}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Vehicle Registration Number:</strong>{" "}
                    {policyData.vehicleregistrationNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Year Manufactured:</strong>{" "}
                    {policyData.yearManufactured}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Number of Seats:</strong> {policyData.numberofSeat}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Deductible Percentage:</strong>{" "}
                    {policyData.deductiblePercentage.toFixed(2)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Deductible:</strong>{" "}
                    {formatToCurrency(policyData.deductible || 0)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Liability Person:</strong>{" "}
                    {formatToCurrency(policyData.paliabilityPerson || 0)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Towing Amount:</strong>{" "}
                    {formatToCurrency(policyData.towingAmount || 0)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Repair:</strong>{" "}
                    {formatToCurrency(policyData.repair || 0)}
                  </Typography>

                  {policyData.itemCoverages &&
                    handleJSONStringArray(policyData?.itemCoverages).map(
                      (coverage: any, index: any) => (
                        <Typography key={index} variant="body2">
                          <strong>Item {coverage.itemCoverage}:</strong>{" "}
                          {formatToCurrency(
                            coverage.sumInsuredperItemCoverage || 0
                          )}
                        </Typography>
                      )
                    )}
                  {policyData.risk && (
                    <Typography variant="body2">
                      <strong>
                        Risk{" "}
                        {
                          (typeof policyData.risk === "string"
                            ? JSON.parse(policyData.risk)
                            : policyData.risk
                          ).genp
                        }
                        :
                      </strong>{" "}
                      {
                        (typeof policyData.risk === "string"
                          ? JSON.parse(policyData.risk)
                          : policyData.risk
                        ).clausecode
                      }
                    </Typography>
                  )}
                  <Typography variant="body2">
                    <strong>Replacement Number:</strong>{" "}
                    {policyData.replacementNumber || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Total Premium:</strong>{" "}
                    {formatToCurrency(policyData.totalPremium || 0)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Source Code:</strong>{" "}
                    {policyData.sourceCode}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Source Extension:</strong>{" "}
                    {policyData.sourceExtension}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Premium Paid:</strong>{" "}
                    {formatToCurrency(policyData.premiumPaid || 0)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Risk Number:</strong>{" "}
                    {policyData.riskNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Project Code:</strong>{" "}
                    {policyData.projectCode}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Gross Premium Written:</strong>{" "}
                    {formatToCurrency(policyData.grossPremiumWritten || 0)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Reference:</strong>{" "}
                    {policyData.reference || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Interested Party Name:</strong>{" "}
                    {policyData.interestedPartyname || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Interested Party Role:</strong>{" "}
                    {policyData.interestedPartyrole || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Interested Party Code:</strong>{" "}
                    {policyData.interestedParty || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>BMA / AO:</strong>{" "}
                    {policyData.bmaao || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Client Group:</strong>{" "}
                    {policyData.clientGroup || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Color:</strong>{" "}
                    {policyData.colourofVehicle || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>LATU:</strong>{" "}
                    {policyData.limitastoUse || 0}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Old Age:</strong>{" "}
                    {policyData.oldAgerate || 0}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Payment:</strong>
                  </Typography>
                  {policyData.payment && (
                    <>
                      <PolicyAsiaPaymentTable
                        rows={handleJSONStringArray(policyData?.payment)}
                      />
                    </>
                  )}
                </>
              ) : policyData && policyData?.status === 40 ? (
                <Typography variant="body2" color="error">
                  Policy is not "In Force"
                </Typography>
              ) : (
                <Typography variant="body2" color="error">
                  Policy Doesn't Exist
                </Typography>
              )}
            </React.Fragment>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
