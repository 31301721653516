import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Button,
  Box,
  Stack,
  Zoom,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
// import { channel } from "diagnostics_channel";
// import useAlertNotification from "../../hooks/useAlertNotification";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import BusinessRules from "./checklist/BusinessRules";
import { NotificationContext } from "../../contexts/NotificationContext";
import { capitalizeAndReplaceUnderscore, isValidDate } from "../../utils/stringUtils";
import { useBusinessRules } from "../../contexts/BusinessRulesContext";
import { z, ZodError } from "zod";

// Validation
export const orSchema = z.object({
  // Registration Details
  orNo: z.string().optional().nullable(),
  orDate: z.string()
    .min(1, "OR Date is required")
    .refine(isValidDate, "Invalid date format")
    .nullable()
    .refine(value => value !== null),
  receivedFrom: z.string().min(1, "Received From is required").nullable().refine(value => value !== null, "Received From should not be empty"),
  address: z.string().optional().nullable(),
  fileNo: z.string().min(1, "File No. is required").nullable().refine(value => value !== null, "File No. should not be empty"),
  engineNo: z.string().optional().nullable(),
  chassisNo: z.string().optional().nullable(),
  plateNo: z.string().optional().nullable(),
  totalAmount: z.string().optional().nullable(),
});
export type OrForm = z.infer<typeof orSchema>;

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}
export default function ORForm({
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
  modalRef
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  // const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  // custom hook
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const { validateBusinessRules } = useBusinessRules();
  const { notification, setNotification } =
    React.useContext(NotificationContext);
  const [oldStatus, setOldStatus] = React.useState<number>(0);

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-or?transId=${transID}&id=${docId}`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const data = res.data.data;
            const statusOptions: any = {
              0: null,
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };
            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });
            setOldStatus(data?.status);

            // createAuditTrailData(
            //   globalData!.accessToken!,
            //   {
            //   transId: transID,
            //   taskType: "document_validation",
            //   event: "view_form",
            //   description: `View LTO official receipt form with document id #${docId}`,
            //   user: globalData.username,
            //   fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
            //   roleLevel: globalData.role
            // });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;

            setNotification({
              level: "error",
              showAlert: true,
              message: errorMessage,
            });
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // TODO update the endpoint and API
  const updateData = async () => {
    try {

      orSchema.parse(mainState.data);

      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-or?transId=${transID}&id=${docId}`,
        mainState.data,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const data = res.data.data;
      setOldStatus(data?.status)

      validateBusinessRules(globalData!.accessToken!,
        `/api/acap/document-validation/lto-or/business-rules?transId=${transID}&id=${docId}&taskType=${"document_validation"}`,
        mainState?.data,
        transID,
        "document_validation"
      );

      if (mainState?.data?.status === 2 && oldStatus !== 2) {
        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: transID,
          taskType: "document_validation",
          event: "approve_form",
          description: `Approve LTO official receipt form with document id #${docId}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        });
      }

      createAuditTrailData(
        globalData!.accessToken!,
        {
        transId: transID,
        taskType: "document_validation",
        event: "update_form",
        description: `Update LTO official receipt form with document id #${docId}`,
        user: globalData.username,
        fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
        roleLevel: globalData.role
      });

      setMainState((prev: any) => ({ ...prev, formError: {} }));

      setNotification({
        level: "success",
        showAlert: true,
        message: data.message || "Update Success",
      });

    } catch (error: any) {
      if (error.errors) {
        error.errors.forEach((err: any) => {
          alert(`${err.path.join(".")}: ${err.message}`);
        });
      } else {
        alert(error?.response?.data || "An unknown error occurred.");
      }
      setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
    }
  };

  // const isAnyFieldEmpty = () => {

  //   for (const key in mainState?.data) {
  //     const fieldValue = mainState?.data[key];
  //     if (
  //       key !== "remarks" &&
  //       key !== "id" &&
  //       key !== "status" &&
  //       (typeof fieldValue !== "string" || !fieldValue.trim())
  //     ) {

  //       return true;
  //     } else if (
  //       key === "status" &&
  //       (typeof fieldValue !== "number" || fieldValue === 0)
  //     ) {

  //       return true;
  //     }
  //   }
  //   return false;
  // };

  React.useEffect(() => {
    //apiGetDocumentStatus();
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [id]: value },
    });
  };

  const handleSelectChange = (selection: any) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  // const handleBlur = (id: any) => {
  //   if (changed[id]) { //check if have any changes in the value and then data will reflect on the Database via PUT API.
  //     updateData(transID, 'LtoOr', false)
  //   }

  // }

  //#endregion Control Methods

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={9}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Official Receipt
                </Typography>
                <OutlinedInput
                  id="orNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Official Receipt Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.orNoConf + "%"} size="small" className={data.orNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.orNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('or_no')}
                  inputProps={{
                    "aria-label": "orNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.orNo === ""}
                />
                {mainState?.data?.orNo === "" && (
                  <FormHelperText error id="orNo">
                    {/* {mainState?.formError?.orNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <Tooltip
                title="Ex. 12/21/2012"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Stack direction={"row"}>
                    <Typography variant="body1" className="tp-text-bold">
                      Date <span className="tp-text-required">*</span>
                    </Typography>
                  </Stack>
                  <OutlinedInput
                    id="orDate"
                    size="small"
                    type="text"
                    placeholder={`Enter Date Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={data.orDateConf + "%"} size="small" className={data.orDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.orDate || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('or_date')}
                    inputProps={{
                      "aria-label": "orDate",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.orDate === "" ||
                      mainState?.formError?.orDate ||
                      !isValidDate(mainState?.data?.orDate)
                    }
                  />
                  {(mainState?.data?.orDate === "" ||
                    mainState?.formError?.orDate ||
                    !isValidDate(mainState?.data?.orDate)) && (
                      <FormHelperText error id="orDate">
                        {!isValidDate(mainState?.data?.orDate) && "Invalid Date"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Received From
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="receivedFrom"
                  size="small"
                  type="text"
                  placeholder={`Enter Received From Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.receivedFrom || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "receivedFrom",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.receivedFrom === ""}
                />
                {mainState?.data?.receivedFrom === "" && (
                  <FormHelperText error id="receivedFrom">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Address
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                </Typography>
                <OutlinedInput
                  id="address"
                  size="small"
                  type="text"
                  placeholder={`Enter Address Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.address || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "address",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.address === ""}
                />
                {mainState?.data?.address === "" && (
                  <FormHelperText error id="address">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  File No.
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="fileNo"
                  size="small"
                  type="text"
                  placeholder={`Enter File No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.fileNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "fileNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.fileNo === ""}
                />
                {mainState?.data?.fileNo === "" && (
                  <FormHelperText error id="name">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Engine No.
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="engineNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Engine No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.engineNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "engineNo",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.engineNo === ""}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Chassis No.
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="chassisNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Chassis No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.chassisNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "chassisNo",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.chassisNo === ""}
                />

              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="h6" className="tp-text-header-primary">
            Payment Details
          </Typography>
          <hr />
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Plate No.
                </Typography>
                <OutlinedInput
                  id="plateNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Plate No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.plateNoConf + "%"} size="small" className={data.plateNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.plateNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('plate_no')}
                  inputProps={{
                    "aria-label": "plateNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.plateNo === ""}
                />
                {mainState?.data?.plateNo === "" && (
                  <FormHelperText error id="plate_no">
                    {/* {!!mainState?.formError?.plateNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Total Amount Paid
                </Typography>
                <OutlinedInput
                  id="totalAmount"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Amount Paid Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.totalAmountConf + "%"} size="small" className={data.totalAmountConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalAmount || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('total_amount')}
                  inputProps={{
                    "aria-label": "totalAmount",
                  }}
                  disabled={disabled}
                // error={
                //   mainState?.data?.totalAmount === "" ||
                //   mainState?.formError?.totalAmount ||
                //   !isValidNumber(mainState?.data?.totalAmount)
                // }
                />
                {/* {(mainState?.data?.totalAmount === "" ||
                  mainState?.formError?.totalAmount ||
                  !isValidNumber(mainState?.data?.totalAmount)) && (
                  <FormHelperText error id="totalAmount">
                    {!isValidNumber(mainState?.data?.totalAmount) && "Invalid Date"}
                  </FormHelperText>
                )} */}
              </FormControl>
            </Grid>
          </Grid>
          <br />
          {/* 
            // #region BusinessRules
          */}
          <BusinessRules docId={docId} transId={Number(transID)} taskType={"document_validation"} documentType={"official_receipt"} />
          <br />
          {/* 
            // #region ForProcessor
          */}
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status === 38 ? 1 : mainState?.selections?.status || null}
              isClearable
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                {/* {mainState?.formError?.status} */}
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
            // error={!!formError?.remarks}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/document-validation/lto-or"}
                    documentType={"Official Receipt"}
                    close={close}
                    modalRef={modalRef}
                  />
                  &emsp;
                  <Button
                    // disabled={isAnyFieldEmpty()}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    variant="contained"
                    size="medium"
                    color="error"
                    disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center","Canvasser"].includes(globalData?.role)}
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
