import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridExpandMoreIcon } from '@mui/x-data-grid';
import fontkit from '@pdf-lib/fontkit';
import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import insurerPdf from '../assets/CNCDraft.pdf';
import boldFontUrl from '../assets/arial-bold.ttf';
import regularFontUrl from '../assets/arial.ttf';
import ximage from '../assets/x_image.png';
import { IClaimHistory } from '../types/ClaimHistory';
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import italicFontUrl from '../assets/ArialCEItalic.ttf';
import { formatDateToYYYYMMDD, normalizeDate } from '../utils/stringUtils';


// Utility function to format the current date
function getCurrentFormattedDate(): string {
  const currentDate = new Date();
  return currentDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

// TypeScript interface for the data rows
interface DataRow {
  id: number;
  transId: number;
  saveDate: string;
  policyNo: string;
  sender: string;
  status: number;
  emailBody: string | null;
  type: string;
  submittedDate: string | null;
}


// TypeScript interface for the API response
interface ResponseData {
  success: boolean;
  data: DataRow[];
}

const GenerateCNC = ({
  policyNo,
  longName,
  inceptionDate,
  expiryDate,
  model,
  motorMaker,
  interestDescription,
  sender,
  motorNo,
  vehicleChassis,
  vehicleRegistrationNumber,
  transId,
}: any) => {
  // State variables
  const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
  const [isRendering, setIsRendering] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [claimHistory, setClaimHistory] = useState<IClaimHistory[]>([])


  const [isPdfPreviewed, setIsPdfPreviewed] = useState(false); // New state to track PDF preview
  const [data, setData] = useState<DataRow[]>([]); // Correctly initialized as an array
  const [claims, setClaims] = useState<any[]>([]);
  const { globalData } = React.useContext(GlobalDataContext);

  const [formData, setFormData] = useState({
    insurance: 'Adverse Insurance',
    dateOfLoss: formatDateToYYYYMMDD(new Date()),
    placeIncident:
      'PGA MOTORCARS INC 201 EDSA, Mandaluyong City 1554 Philippines',
    claimNumber: '',
    damagePortion: '',
    status: 'Settled and closed',
  });


  // Columns definition for DataGrid
  const columns: GridColDef<DataRow>[] = [
    {
      field: 'transId',
      headerName: 'Trans ID',
      editable: false,
    },
    {
      field: 'saveDate',
      headerName: 'Save Date',
      sortable: false,
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value as string);
        return !params.value || isNaN(date.getTime())
          ? ''
          : date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
      },
    },
    {
      field: 'policyNo',
      headerName: 'Policy No',
      width: 100,
      editable: false,
    },
    {
      field: 'sender',
      headerName: 'Sender',
      sortable: false,
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 200,
      renderCell: (params: any) => {
        return (
          <FormControl variant="outlined" size="small" sx={{ width: 400 }}>
            <Select
              value={params.value}
              onChange={(event) => handleStatusChange(event, params)}
              size="small"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ minWidth: 120 }}
              disabled={params.value === 4} // Disable if status is 'Cancelled'
            >
              <MenuItem value={3}>Submitted</MenuItem>
              <MenuItem value={4}>Cancelled</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      width: 100,
    },
    {
      field: 'dateLoss',
      headerName: 'Date of Loss',
      sortable: false,
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value as string);
        return !params.value || isNaN(date.getTime())
          ? ''
          : date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
      },
    },
    {
      field: 'submittedDate',
      headerName: 'Submitted Date',
      sortable: false,
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value as string);
        return !params.value || isNaN(date.getTime())
          ? ''
          : date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
      },
    },
  ];

  // useEffect to fetch data when transId and policyNo are available
  useEffect(() => {
    if (transId && policyNo) {
      fetchData(
        `/api/acap/cnn-ncc-history/by-policy-no?policyNo=${policyNo}`
      );

    }

  }, [transId, policyNo]);

  // Function to fetch data
  const fetchData = (endpoint: string) => {
    setData([]); // Initialize as an empty array for DataGrid
    apiHandler(endpoint);
  };

  // Function to handle API calls
  const apiHandler = async (endpoint: string) => {
    try {
      const res = await axios.get<ResponseData>(
        `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

      const responseData = res.data.data; // Adjust based on actual API response structure
      const filteredData = responseData.filter((el: any) => el.type === "CNC")

      if (Array.isArray(responseData)) {
        setData(
          filteredData.map((item: any, index: number) => ({
            id: item.id || index, // Ensure each row has a unique id
            transId: item.transId,
            saveDate: item.saveDate,
            policyNo: item.policyNo,
            sender: item.sender,
            status: item.status,
            emailBody: item.emailBody,
            type: item.type,
            submittedDate: item.submittedDate,
            dateLoss: item.dateLoss
          }))
        );
      } else {
        console.error('Expected responseData to be an array', responseData);
        setData([]);
      }
    } catch (err: any) {
      console.error('Error fetching data:', err);
      setData([]); // Optionally reset data on error
    }
  };

  // Handle status change in DataGrid
  const handleStatusChange = (
    event: SelectChangeEvent<number>,
    params: any
  ) => {
    const newStatus = event.target.value as number;

    // Update the row locally in the state
    setData((prevData) =>
      prevData.map((item) =>
        item.id === params.id ? { ...item, status: newStatus } : item
      )
    );

    // Send updated data to API
    const updatedRow = {
      ...params.row,
      status: newStatus,
    };
    updateData(updatedRow);
  };

  // Function to update data via API
  const updateData = async (updatedData: any) => {
    try {
      const currentDateTime = new Date();
      currentDateTime.setHours(currentDateTime.getHours() + 8);
      const formattedDateTime = currentDateTime
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');

      const payload = {
        transId: updatedData.transId,
        type: updatedData.type,
        saveDate: updatedData.saveDate,
        sender: updatedData.sender,
        status: updatedData.status,
        emailBody: updatedData.emailBody,
        policyNo: updatedData.policyNo,
        submittedDate: formattedDateTime,
      };

      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/cnn-ncc-history?Id=${updatedData.id}`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );


    } catch (err: any) {

    }
  };

  // Handle form input changes
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Log to verify value
    console.log(`Name: ${name}, Value: ${value}`);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {

    console.log("handleDateChange: ", e.target.value)

    setFormData({
      ...formData,
      dateOfLoss: e.target.value, // Keep date in 'YYYY-MM-DD' format
    });
  };

  // Handle select input changes in the form
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Add a new claim to the claims array
  const addClaim = () => {
    setClaims((prev) => [...prev, { ...formData }]);
    setFormData({
      ...formData,
      claimNumber: '',
      dateOfLoss: '',
      damagePortion: '',
      status: 'Settled and closed',
    });
  };


  React.useEffect(() => {
    const fetchClaimHistory = async () => {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/history?policyNo=${policyNo}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      ).then((res: any) => {

        setClaimHistory(res.data.data)
      }).catch((err: any) => {

      })
    }

    fetchClaimHistory();
  }, [policyNo])


  // Function to generate PDF bytes

  const generatePdfBytes = async () => {
    try {
      const existingPdfBytes = await fetch(insurerPdf).then((res) =>
        res.arrayBuffer()
      );
      const boldFontBytes = await fetch(boldFontUrl).then((res) =>
        res.arrayBuffer()
      );
      const regularFontBytes = await fetch(regularFontUrl).then((res) =>
        res.arrayBuffer()
      );

      const imageBytes = await fetch(ximage).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);
      const italicFontBytes = await fetch(italicFontUrl).then((res) => res.arrayBuffer());
      const italicFont = await pdfDoc.embedFont(italicFontBytes);


      pdfDoc.registerFontkit(fontkit);

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const boldFont = await pdfDoc.embedFont(boldFontBytes);
      const regularFont = await pdfDoc.embedFont(regularFontBytes);
      const { width, height } = firstPage.getSize();

      const image = await pdfDoc.embedPng(imageBytes);
      const imageDims = image.scale(0.3);

      const textDate = getCurrentFormattedDate();

      const text1 = 'This is to certify that ';
      const text2 = longName;
      const text3 = ', whose vehicle described below, is insured with us under';

      const textWidth1 = regularFont.widthOfTextAtSize(text1, 10);
      const textWidth2 = boldFont.widthOfTextAtSize(text2, 10);
      const textWidth3 = regularFont.widthOfTextAtSize(text3, 10);

      firstPage.drawText(textDate, {
        x: 452,
        y: height - 153,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text1, {
        x: 65,
        y: height - 219,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text2, {
        x: 65 + textWidth1,
        y: height - 219,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text3, {
        x: 65 + textWidth1 + textWidth2,
        y: height - 219,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      const text4 = 'Motor Policy No. ';
      const text5 = policyNo;
      const text6 = ` from ${inceptionDate} to ${expiryDate}.`;

      const textWidth4 = regularFont.widthOfTextAtSize(text4, 10);
      const textWidth5 = boldFont.widthOfTextAtSize(text5, 10);

      firstPage.drawText(formData.insurance, {
        x: 67,
        y: height - 178,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text4, {
        x: 66,
        y: height - 231,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text5, {
        x: 66 + textWidth4,
        y: height - 231,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text6, {
        x: 66 + textWidth4 + textWidth5,
        y: height - 231,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(
        `${model} ${motorMaker} ${interestDescription}`,
        {
          x: 148,
          y: height - 260,
          size: 10,
          font: regularFont,
          color: rgb(0, 0, 0),
        }
      );

      firstPage.drawText(vehicleChassis, {
        x: 148,
        y: height - 274,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(motorNo, {
        x: 148,
        y: height - 288,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(vehicleRegistrationNumber, {
        x: 148,
        y: height - 302,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      let currentHeight = height - 520;

      if (claims.length > 0) {
        // Draw the 'X'
        firstPage.drawText('X', {
          x: 69,
          y: currentHeight + 23,
          size: 10,
          font: regularFont,
          color: rgb(0, 0, 0),
        });

        // Draw table headers
        const headers = [
          'Claim Number',
          'Date of Loss',
          'Damage Portion',
          'Status',
        ];
        const headerXPositions = [101, 201, 301, 401];
        headers.forEach((header, index) => {
          firstPage.drawText(header, {
            x: headerXPositions[index],
            y: currentHeight,
            size: 9,
            font: boldFont,
            color: rgb(0, 0, 0),
          });
        });

        // const baseRowOffset = 10; // space above each row
        // const rowLineHeight = 20; 

        // Draw table rows
        claims.forEach((claim, rowIndex) => {
          const rowYPosition = currentHeight - 9 - rowIndex * 9;
          const values = [
            claim.claimNumber,
            claim.dateOfLoss,
            claim.damagePortion
              .split(',')
              .map((portion: string) => portion.trim())
              .join('\n'),
            claim.status,
          ];

          values.forEach((value, colIndex) => {
            firstPage.drawText(value, {
              x: headerXPositions[colIndex],
              y: rowYPosition,
              size: 9,
              font: regularFont,
              color: rgb(0, 0, 0),
            });
          });

          const numLines = claim.damagePortion.split(',').length;
          currentHeight -=
            numLines > 1 ? 9 + (numLines - 1) * 9 : 9;
        });

        currentHeight -= 15; // Additional space after the table
      }

      // Adjust other elements based on the table height
      firstPage.drawImage(image, {
        x: 65,
        y: currentHeight - imageDims.height - 20,
        width: imageDims.width,
        height: imageDims.height,
      });

      firstPage.drawText(
        'did not file a claim with us in connection with the accident occur on',
        {
          x: 97,
          y: currentHeight - imageDims.height - 15,
          size: 9,
          font: regularFont,
          color: rgb(0, 0, 0),
        }
      );

      firstPage.drawText(`${formData.dateOfLoss}`, {
        x: 372,
        y: currentHeight - imageDims.height - 15,
        size: 9,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`along ${formData.placeIncident}`, {
        x: 97,
        y: currentHeight - imageDims.height - 25,
        size: 9,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`Very Truly Yours,`, {
        x: 442,
        y: currentHeight - imageDims.height - 60,
        size: 9,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`BPI MS Insurance`, {
        x: 442,
        y: currentHeight - imageDims.height - 75,
        size: 9,
        font: regularFont,
        color: rgb(0, 0, 0),
      });


      firstPage.drawLine({
        start: { x: 442, y: currentHeight - imageDims.height - 95 },
        end: { x: 550, y: currentHeight - imageDims.height - 95 },
        // end: { x: 580, y: lineYPosition },
        thickness: 1,
        color: rgb(0, 0, 0),
      });

      // firstPage.drawText(`${globalData.firstName} ${globalData.lastName}`, {
      //   x: 442,
      //   y: currentHeight - imageDims.height - 105,
      //   size: 9,
      //   font: regularFont,
      //   color: rgb(0, 0, 0),
      // });

      firstPage.drawText(`This is system generated, wet signature not required.`, {
        x: 442,
        y: currentHeight - imageDims.height - 113,
        size: 6,
        font: italicFont,
        color: rgb(0, 0, 0),
      });

      const finalPdfBytes = await pdfDoc.save();
      setPdfBytes(new Uint8Array(finalPdfBytes));
      await previewPdf(new Uint8Array(finalPdfBytes), 1);
      setIsPdfPreviewed(true); // Enable the submit button
    } catch (error) {
      console.error('Error generating PDF bytes:', error);
      setIsPdfPreviewed(false); // Disable the submit button on error
    }
  };

  // Function to preview the PDF on the canvas
  const previewPdf = async (bytes: Uint8Array, pageNum: number) => {
    if (!bytes) return;
    if (isRendering) return;

    setIsRendering(true);
    const loadingTask = pdfjsLib.getDocument({ data: bytes });
    try {
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(pageNum);

      const viewport = page.getViewport({ scale: 1 });
      const canvas = canvasRef.current;
      const context = canvas?.getContext('2d');

      if (canvas && context) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;
      }
    } catch (err: any) {
      console.error('Error rendering PDF:', err);
    } finally {
      setIsRendering(false);
    }
  };

  // Effect to preview PDF when pdfBytes change
  useEffect(() => {
    if (pdfBytes) {
      previewPdf(pdfBytes, currentPage);
    }
  }, [pdfBytes, currentPage]);

  // Function to save the PDF file
  const savePdf = () => {
    if (pdfBytes) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .replace(/[-:.TZ]/g, '');
      const fileName = `CNC Report_${formattedDate}.pdf`;
      saveAs(blob, fileName);
    } else {
      console.error('No PDF bytes to save.');
    }
  };

  // Function to submit data and save PDF
  const submitData = async () => {
    if (!isPdfPreviewed) {
      alert('Please preview the PDF before submitting.');
      return;
    }

    try {
      const currentDateTime = new Date();
      currentDateTime.setHours(currentDateTime.getHours() + 8);
      const formattedDateTime = currentDateTime
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');

      if (!formData.dateOfLoss || formData.dateOfLoss === undefined) {
        alert("Please input date of loss")
        return;
      }

      const formattedDateLoss = formData.dateOfLoss;

      const payload = {
        transId: transId,
        type: 'CNC',
        saveDate: formattedDateTime,
        sender: sender,
        status: 3, // Assuming 3 corresponds to 'Submitted'
        policyNo: policyNo,
        submittedDate: formattedDateTime,
        dateLoss: formattedDateLoss
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/cnn-ncc-history`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

      savePdf();
      setIsPdfPreviewed(false); // Reset the preview state after submission

      fetchData(
        `/api/acap/cnn-ncc-history/by-policy-no?policyNo=${policyNo}`
      );
    } catch (err: any) {
      console.error(`Error submitting data for Trans ID ${transId}:`, err);
    }
  };

  return (
    <div>
      {/* Form for adding claims */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Insurance Third Party"
            name="insurance"
            value={formData.insurance}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Date of Loss"
            type="date"
            name="dateOfLoss"
            value={formData.dateOfLoss}
            onChange={handleDateChange}
            fullWidth
          />
        </Grid>
      </Grid>

      <br />
      <TextField
        label="Place Incident"
        name="placeIncident"
        value={formData.placeIncident}
        onChange={handleChange}
        fullWidth
      />
      <br /><br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label="Claim Number"
            name="claimNumber"
            value={formData.claimNumber}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label="Damage Portion"
            name="damagePortion"
            value={formData.damagePortion}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleSelectChange}
            >
              <MenuItem value="Settled and closed">
                Settled and closed
              </MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Button variant="contained" onClick={addClaim} style={{ float: "right" }}>
        Add Claim
      </Button>
      <br /><br />
      {/* Claims Table */}
      {claims ?
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Claim Number</TableCell>
                <TableCell>Date of Loss</TableCell>
                <TableCell>Damage Portion</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {claims.map((claim, index) => (
                <TableRow key={index}>
                  <TableCell>{claim.claimNumber}</TableCell>
                  <TableCell>{claim.dateOfLoss}</TableCell>
                  <TableCell>
                    {claim.damagePortion
                      .split(',')
                      .map((portion: string, i: number) => (
                        <div key={i}>{portion.trim()}</div>
                      ))}
                  </TableCell>
                  <TableCell>{claim.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        : ""}
      <br /><br />
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          {isRendering ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" color="primary" onClick={generatePdfBytes}>
              Preview PDF
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={submitData}>
            Save PDF
          </Button>
        </Grid>
      </Grid>
      <center>
        <canvas ref={canvasRef} style={{ border: '1px solid black', marginTop: '20px' }}></canvas>
      </center>
      <br /><br /> <br />
      <Box sx={{ height: 320, width: '100%' }}>
        <DataGrid
          rows={data} // Pass the updated data state here
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
      <br /><br />
      {claimHistory && (
        <div style={{ justifyItems: "center" }}>
          <Accordion style={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "10px", paddingBottom: "10px", width: "50%" }}>
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h5" className="tp-text-header-primary">Claim History</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingLeft: "40px", paddingRight: "40px" }}>
              <>
                <Grid container>
                  <Grid item sm={2} md={2} lg={2} />
                  <Grid item sm={4} md={4} lg={4}>
                    <Typography variant="h6" color="error">Claim Number</Typography>
                  </Grid>
                  <Grid item sm={4} md={4} lg={4} textAlign="right">
                    <Typography variant='h6' color="error">Date Loss</Typography>
                  </Grid>
                </Grid>
                <br />
                <div style={{ height: "200px", overflow: "scroll" }}>
                  {claimHistory.map((row, idx) => (
                    <div key={row.id}>
                      <Grid container>
                        <Grid item sm={2} md={2} lg={2} />
                        <Grid item sm={4} md={4} lg={4}>
                          <Typography variant="body1" className="tp-text-header-primary">{row.claimNumber}</Typography>
                        </Grid>
                        <Grid item sm={4} md={4} lg={4} textAlign="right">
                          <Typography variant='body1'>{format(new Date(row.dateLoss), "yyyy-MM-dd")}</Typography>
                        </Grid>
                      </Grid>
                      {idx < claimHistory.length - 1 && <hr style={{ width: "400px" }} />}
                    </div>
                  ))}
                </div>
              </>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default GenerateCNC;
