import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import * as React from "react";
import EditIcon from '@mui/icons-material/Edit';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../api/FormatDate";
import { toTitleCase } from "../api/utils";
import FieldChecklist from "../components/processor_validation/FieldChecklist";
import { useBusinessRules } from "../contexts/BusinessRulesContext";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { IAcapTask, initialAcapTask } from "../types/AcapTask";
import {
  IFieldCheckList,
  IManualCheckList,
  initChecklistComments,
  initManualCheckList,
  ITempCheckListComment,
} from "../types/FieldCheckList";
import { PolicyData } from "../types/PolicyData";
import { IWebFormDetails } from "../types/WebFormDetails";
import AuditTrail from "./activity_logs/AuditTrail";
import TaskRemarks from "./activity_logs/TaskRemarks";
import ClaimRegistration from "./ClaimRegistration";
import ClientList from "./clientList";
import BusinessRulesTask from "./document_validation/checklist/BusinessRulesTask";
import MainModal from "./document_validation/MainModal";
import WebFormDetails from "./document_validation/WebFormDetails";
import EmailMissing from "./EmailMissing"; // Import the new component
import PolicyChecker from "./PolicyChecker"; // Import the new component
import UploadMissing from "./UploadMissing";

import { NotificationContext } from "../contexts/NotificationContext";
import { IDeTask } from "../types/DeTask";
import { IUserDetail } from "../types/UserDetail";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IRequiredDocStatus } from "../types/RequiredDocStatus";
import AcapTaskStatusUpdater from "./AcapTaskStatusUpdater";
import FileUploadCliamType from "./FileUploadCliamType";
import RequiredDocStatus from "./processor_validation/RequiredDocStatus";
import { ISfManualChecklist } from "../types/SfManualChecklist";
import { updateSfManualChecklist } from "../services/SalesforceApi";
import { IClaimHistory } from "../types/ClaimHistory";
import { initialUserTask, IUserTask } from "../types/UserTask";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";
import { capitalizeAndReplaceUnderscore } from "../utils/stringUtils";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   ...theme.applyStyles("dark", {
//     backgroundColor: "#1A2027",
//   }),
// }));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type EmailOption = 'sender' | 'client' | 'manual';


export default function DocumentValidationTask() {
  const location = useLocation();
  const { sender } = location.state || {};
  const [open, setOpen] = React.useState(false);
  const [disabled, setIsDisabled] = React.useState(false);
  const [forSubmit, setForSubmit] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [selectedForm, setSelectedForm] = React.useState(0);
  const [selectedDocId, setSelectedDocId] = React.useState(0);
  const [policyNoFromDB, setPolicyFromDB] = React.useState("");
  // const [status, setStatus] = React.useState("Open");
  const [emailBody, setEmailBody] = React.useState("");
  const [syncAW, setSyncAW] = React.useState<boolean>(false);
  const [userTask, setUserTask] = React.useState<IUserTask>(initialUserTask);
  const [userId, setUserId] = React.useState<number>(0);
  const [createDeTaskLoading, setCreateDeTaskLoading] = React.useState<boolean>(false);
  // const handleStatusChange = (event: SelectChangeEvent<string>) => {
  //   setStatus(event.target.value as string);
  // };
  // const [tagSelect, setTagSelect] = React.useState("Open");
  const handleTagsChange = (event: SelectChangeEvent<string>) => {
    setFormData((prev: any) => ({
      ...prev,
      tag: event.target.value,
    }));
  };
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    attachments: [],
    docStatus: {},
    response: "",
    isLoading: false,
    error: "",
  });
  const [mvfDoc, setMvfDoc] = React.useState<any>(null);
  const { globalData } = React.useContext(GlobalDataContext);
  const [policyNoField, setPolicyNoField] = React.useState("");
  const { transId } = useParams();

  const [clientCode, setClientCode] = React.useState<string | null>(null);
  const [claimRegistrationData, setClaimRegistrationData] =
    React.useState<any>(null); // State to store claim registration data

  const [clientValidated, setClientValidated] = React.useState(false);
  const [policyValidated, setPolicyValidated] = React.useState(false);
  const [policyStatus, setPolicyStatus] = React.useState<number>(0);
  const [dateOfLoss, setDateOfLoss] = React.useState({
    mvf: null,
    police: null,
    affidavit: null,
  });
  const [timeOfLoss, setTimeOfLoss] = React.useState({
    mvf: null,
    police: null,
    affidavit: null,
  });

  const [claimParticipant, setClaimParticipant] = React.useState({
    mvf: null,
    police: null,
    affidavit: null,
  });
  const { businessRules, setBusinessRules } = useBusinessRules();
  const [webForm, setWebForm] = React.useState<IWebFormDetails | null>(null);
  const [policyData, setPolicyData] = React.useState<PolicyData | null>(null);
  const [manualCheckList, setManualCheckList] =
    React.useState<IManualCheckList>(initManualCheckList);
  const [checklistComments, setChecklistComments] = React.useState<
    ITempCheckListComment[]
  >(initChecklistComments);
  const [acapTask, setAcapTask] = React.useState<IAcapTask>(initialAcapTask);
  const { notification, setNotification } =
    React.useContext(NotificationContext);
  const [duplicateLoader, setDuplicateLoader] = React.useState<boolean>(false);
  const [isDocsApproved, setIsDocsApproved] = React.useState<boolean>(false);
  const [clecUsers, setClecUsers] = React.useState<IUserDetail[] | null>(null);
  const [assignedClec, setAssignedClec] = React.useState<
    IUserDetail | null | undefined
  >(null);
  const [deTasks, setDeTasks] = React.useState<IDeTask[]>([]);

  const [formData, setFormData] = React.useState<any>({
    id: 0,
    fileName: "",
    tag: "",
  });
  const [isEditing, setIsEditing] = React.useState<any>(false);
  const [requiredDocStatus, setRequiredDocStatus] =
    React.useState<IRequiredDocStatus>({
      override: false,
      approve: false,
      comment: "",
    });
  const [claimHistory, setClaimHistory] = React.useState<IClaimHistory[]>([]);
  const [isEditingEmail, setIsEditingEmail] = React.useState(false);
  const [senderEmail, setSenderEmail] = React.useState<string>("");
  const [claimEmail, setClaimEmail] = React.useState<string>("");
  const [manualEmail, setManualEmail] = React.useState<string>("");
  const [currClientEmail, setCurrClientEmail] = React.useState<string>("");
  const [selectedOption, setSelectedOption] = React.useState<EmailOption>('sender');
  const [emails, setEmails] = React.useState<Record<EmailOption, string>>({
    sender: '',
    client: '',
    manual: ''
  });
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const [assignTaskLoading, setAssignTaskLoading] = React.useState(false);

  const options = [
    { key: 'sender' as EmailOption, placeholder: 'Enter Sender Email' },
    { key: 'client' as EmailOption, placeholder: 'Enter Claim Email' },
    { key: 'manual' as EmailOption, placeholder: 'Enter Manual Email' }
  ];

  const handleSave = (optionKey: EmailOption) => {
    changeClientEmail(emails[optionKey]);
    setIsEditingEmail(false);
  };

  const navigate = useNavigate();

  const transformChecklistToApiRequest = (
    manualCheckList: IManualCheckList
  ): {
    id: number; // Checklist ID
    transId: number; // Transaction ID
  } & {
    [key: string]: number | string; // Dynamically added fraud_x and comment_x fields
  } => {
    // Reduce the fieldCheckList array into an object with separate keys for 'fraud_x' and 'comment_x'
    const fraudFields = manualCheckList.fieldCheckList.reduce<{
      [key: string]: number | string;
    }>((acc, item) => {
      acc[item.id] = item.value; // Add fraud_x (e.g., fraud_1)
      acc[`comment_${item.id.split("_")[1]}`] = item.comment || ""; // Add comment_x (e.g., comment_1)
      return acc;
    }, {}); // Initialize the accumulator as an empty object

    // Return the final object including id and transId
    return {
      id: manualCheckList.id, // Assuming you have an id field in manualCheckList
      transId: manualCheckList.transId,
      ...fraudFields,
    };
  };

  // region updateFieldCheckList
  const updateFieldCheckList = async (id: string, newValue: number) => {
    // First, update the state
    setManualCheckList((prevManualCheckList) => {
      const updatedFieldCheckList = prevManualCheckList.fieldCheckList.map(
        (item) => (item.id === id ? { ...item, value: newValue } : item)
      );

      return { ...prevManualCheckList, fieldCheckList: updatedFieldCheckList };
    });

    // Now transform the updated checklist into the format needed for the database request
    const transformedRequest = transformChecklistToApiRequest({
      ...manualCheckList,
      fieldCheckList: manualCheckList.fieldCheckList.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      ),
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/fraud-checklist?transId=${transId}`,
        {
          ...transformedRequest,
          override_12: manualCheckList.override_12 === true ? 1 : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );

      // Update Salesforce Claim Object
      const sfdata: ISfManualChecklist = {
        Suspicious_Behavior__c:
          transformedRequest["fraud_1"] === 1 ? "true" : "false",
        Unmatched_Signature_on_Documents__c:
          transformedRequest["fraud_2"] === 1 ? "true" : "false",
        Inconsistent_Damage__c:
          transformedRequest["fraud_3"] === 1 ? "true" : "false",
        Doc_Submitted_blacklisted_shop_person__c:
          transformedRequest["fraud_4"] === 1 ? "true" : "false",
        Claim_Follow_Up_blacklisted_shop_person__c:
          transformedRequest["fraud_5"] === 1 ? "true" : "false",
        Insured_is_Hostile_When_Questioned__c:
          transformedRequest["fraud_6"] === 1 ? "true" : "false",
        Financial_Problem__c:
          transformedRequest["fraud_7"] === 1 ? "true" : "false",
        Will_Accept_Smaller_Settlement_Than_Full__c:
          transformedRequest["fraud_8"] === 1 ? "true" : "false",
        No_Proof_of_Ownership_Lost_Papers__c:
          transformedRequest["fraud_9"] === 1 ? "true" : "false",
        Keys_Found_in_Recovered_Vehicle__c:
          transformedRequest["fraud_10"] === 1 ? "true" : "false",
        Insured_has_No_Insurance_History__c:
          transformedRequest["fraud_11"] === 1 ? "true" : "false",
        Remarks_SuspiciousBehavior__c: transformedRequest[
          "comment_1"
        ] as string,
        Remarks_UnmatchedSignatureonDocuments__c: transformedRequest[
          "comment_2"
        ] as string,
        Remarks_InconsistentDamage__c: transformedRequest[
          "comment_3"
        ] as string,
        Remarks_DocSubmitted__c: transformedRequest["comment_4"] as string,
        Remarks_ClaimFollowUp__c: transformedRequest["comment_5"] as string,
        Remarks_InsuredisHostileWhenQuestion__c: transformedRequest[
          "comment_6"
        ] as string,
        Remarks_FinancialProblem__c: transformedRequest["comment_7"] as string,
        Remarks_WillAcceptSmallerSettlement__c: transformedRequest[
          "comment_8"
        ] as string,
        Remarks_NoProofofOwnershipLostPaper__c: transformedRequest[
          "comment_9"
        ] as string,
        Remarks_KeysFoundinRecoveredVehicle__c: transformedRequest[
          "comment_10"
        ] as string,
        Remarks_InsuredhasNoInsuranceHistory__c: transformedRequest[
          "comment_11"
        ] as string,
        Claim_History__c:
          transformedRequest["fraud_12"] === 1 ? "true" : "false",
        Claim_History_Remarks__c: transformedRequest["comment_12"] as string,
      };
      await updateSfManualChecklist(sfdata, acapTask.caseId);

      getFieldCheckList();
    } catch (error) {
      console.error("Error updating database:", error);
    }
  };

  const setFieldCheckListComment = async (id: string, comment: string) => {
    setChecklistComments((prevChecklistComments) => {
      const updatedFieldCheckList = prevChecklistComments.map((item) =>
        item.id === id ? { ...item, comment } : item
      );

      return updatedFieldCheckList;
    });
  };

  // region updateFieldCheckListComments
  const updateFieldCheckListComments = async () => {
    const mergedChecklist = manualCheckList.fieldCheckList.map((item) => {
      const matchingComment = checklistComments.find(
        (comment) => comment.id === item.id
      );
      return {
        ...item,
        comment: matchingComment ? matchingComment.comment : item.comment,
      };
    });

    // Now transform the updated checklist into the format needed for the database request
    const transformedRequest: {
      id: number;
      transId: number;
    } & {
      [key: string]: number | string; // Dynamically added fraud_x and comment_x fields
    } = transformChecklistToApiRequest({
      ...manualCheckList,
      fieldCheckList: mergedChecklist,
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/fraud-checklist?transId=${transId}`,
        {
          ...transformedRequest,
          override_12: manualCheckList.override_12 === true ? 1 : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );

      // Update Salesforce Claim Object
      const sfdata: ISfManualChecklist = {
        Suspicious_Behavior__c:
          transformedRequest["fraud_1"] === 1 ? "true" : "false",
        Unmatched_Signature_on_Documents__c:
          transformedRequest["fraud_2"] === 1 ? "true" : "false",
        Inconsistent_Damage__c:
          transformedRequest["fraud_3"] === 1 ? "true" : "false",
        Doc_Submitted_blacklisted_shop_person__c:
          transformedRequest["fraud_4"] === 1 ? "true" : "false",
        Claim_Follow_Up_blacklisted_shop_person__c:
          transformedRequest["fraud_5"] === 1 ? "true" : "false",
        Insured_is_Hostile_When_Questioned__c:
          transformedRequest["fraud_6"] === 1 ? "true" : "false",
        Financial_Problem__c:
          transformedRequest["fraud_7"] === 1 ? "true" : "false",
        Will_Accept_Smaller_Settlement_Than_Full__c:
          transformedRequest["fraud_8"] === 1 ? "true" : "false",
        No_Proof_of_Ownership_Lost_Papers__c:
          transformedRequest["fraud_9"] === 1 ? "true" : "false",
        Keys_Found_in_Recovered_Vehicle__c:
          transformedRequest["fraud_10"] === 1 ? "true" : "false",
        Insured_has_No_Insurance_History__c:
          transformedRequest["fraud_11"] === 1 ? "true" : "false",
        Remarks_SuspiciousBehavior__c: transformedRequest[
          "comment_1"
        ] as string,
        Remarks_UnmatchedSignatureonDocuments__c: transformedRequest[
          "comment_2"
        ] as string,
        Remarks_InconsistentDamage__c: transformedRequest[
          "comment_3"
        ] as string,
        Remarks_DocSubmitted__c: transformedRequest["comment_4"] as string,
        Remarks_ClaimFollowUp__c: transformedRequest["comment_5"] as string,
        Remarks_InsuredisHostileWhenQuestion__c: transformedRequest[
          "comment_6"
        ] as string,
        Remarks_FinancialProblem__c: transformedRequest["comment_7"] as string,
        Remarks_WillAcceptSmallerSettlement__c: transformedRequest[
          "comment_8"
        ] as string,
        Remarks_NoProofofOwnershipLostPaper__c: transformedRequest[
          "comment_9"
        ] as string,
        Remarks_KeysFoundinRecoveredVehicle__c: transformedRequest[
          "comment_10"
        ] as string,
        Remarks_InsuredhasNoInsuranceHistory__c: transformedRequest[
          "comment_11"
        ] as string,
        Claim_History__c:
          transformedRequest["fraud_12"] === 1 ? "true" : "false",
        Claim_History_Remarks__c: transformedRequest["comment_12"] as string,
      };
      await updateSfManualChecklist(sfdata, acapTask.caseId);

      getFieldCheckList();
    } catch (error) {
      console.error("Error updating database:", error);
    }
  };

  const handleOverride = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/fraud-checklist/override?transId=${manualCheckList.transId}`,
      { override: checked },
      {
        headers: {
          Authorization: `Bearer ${globalData?.accessToken}`,
        },
      }
    );

    getFieldCheckList();
  };

  const getPolicyAsiaNumber = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transId}`,
      {
        headers: {
          Authorization: `Bearer ${globalData?.accessToken}`,
        },
      }
    );
    return response.data?.data?.policyNumber;
  };

  const getFieldCheckList = async () => {
    try {
      const resPolicyNo = await getPolicyAsiaNumber();

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/fraud-checklist?transId=${transId}&policyNo=${resPolicyNo}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      );

      const data = response?.data?.data;

      if (data) {
        const fraudFields = Array.from({ length: 12 }, (_, index) => ({
          id: `fraud_${index + 1}`,
          value: data[`fraud_${index + 1}`],
          comment: data[`comment_${index + 1}`],
        }));

        fraudFields.forEach(({ id, comment }) =>
          setFieldCheckListComment(id, comment)
        );

        const updatedFieldCheckList = manualCheckList.fieldCheckList.map(
          (item: IFieldCheckList) => {
            const matchingFraud = fraudFields.find(
              (fraud) => fraud.id === item.id
            );
            return {
              ...item,
              value: matchingFraud?.value ?? item.value,
              comment: matchingFraud?.comment ?? item.comment,
            };
          }
        );

        const updatedManualCheckList: IManualCheckList = {
          id: data.id,
          transId: data.transId,
          fieldCheckList: updatedFieldCheckList,
          override_12: data.override_12 === 1 ? true : false,
        };

        setManualCheckList(updatedManualCheckList);

        const transformedRequest = transformChecklistToApiRequest(
          updatedManualCheckList
        );

        const sfData: ISfManualChecklist = {
          Suspicious_Behavior__c:
            transformedRequest["fraud_1"] === 1 ? "true" : "false",
          Unmatched_Signature_on_Documents__c:
            transformedRequest["fraud_2"] === 1 ? "true" : "false",
          Inconsistent_Damage__c:
            transformedRequest["fraud_3"] === 1 ? "true" : "false",
          Doc_Submitted_blacklisted_shop_person__c:
            transformedRequest["fraud_4"] === 1 ? "true" : "false",
          Claim_Follow_Up_blacklisted_shop_person__c:
            transformedRequest["fraud_5"] === 1 ? "true" : "false",
          Insured_is_Hostile_When_Questioned__c:
            transformedRequest["fraud_6"] === 1 ? "true" : "false",
          Financial_Problem__c:
            transformedRequest["fraud_7"] === 1 ? "true" : "false",
          Will_Accept_Smaller_Settlement_Than_Full__c:
            transformedRequest["fraud_8"] === 1 ? "true" : "false",
          No_Proof_of_Ownership_Lost_Papers__c:
            transformedRequest["fraud_9"] === 1 ? "true" : "false",
          Keys_Found_in_Recovered_Vehicle__c:
            transformedRequest["fraud_10"] === 1 ? "true" : "false",
          Insured_has_No_Insurance_History__c:
            transformedRequest["fraud_11"] === 1 ? "true" : "false",
          Remarks_SuspiciousBehavior__c: transformedRequest[
            "comment_1"
          ] as string,
          Remarks_UnmatchedSignatureonDocuments__c: transformedRequest[
            "comment_2"
          ] as string,
          Remarks_InconsistentDamage__c: transformedRequest[
            "comment_3"
          ] as string,
          Remarks_DocSubmitted__c: transformedRequest["comment_4"] as string,
          Remarks_ClaimFollowUp__c: transformedRequest["comment_5"] as string,
          Remarks_InsuredisHostileWhenQuestion__c: transformedRequest[
            "comment_6"
          ] as string,
          Remarks_FinancialProblem__c: transformedRequest[
            "comment_7"
          ] as string,
          Remarks_WillAcceptSmallerSettlement__c: transformedRequest[
            "comment_8"
          ] as string,
          Remarks_NoProofofOwnershipLostPaper__c: transformedRequest[
            "comment_9"
          ] as string,
          Remarks_KeysFoundinRecoveredVehicle__c: transformedRequest[
            "comment_10"
          ] as string,
          Remarks_InsuredhasNoInsuranceHistory__c: transformedRequest[
            "comment_11"
          ] as string,
          Claim_History__c:
            transformedRequest["fraud_12"] === 1 ? "true" : "false",
          Claim_History_Remarks__c: transformedRequest["comment_12"] as string,
        };

        if (acapTask.caseId) {
          await updateSfManualChecklist(sfData, acapTask.caseId);
        }
      }
    } catch (err) {
      console.error("Error in getFieldCheckList:", err);
    }
  };

  const getPolicyAsiaRecord = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        const policyDataRes = res?.data?.data;
        setPolicyData(policyDataRes);
      })
      .catch((err: any) => {
        // if motor vehicle has policy no but not validated
        // set validate to false
        console.error(err);
      });
  };

  const getClientList = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        const emailAddress = res.data.data.emailAddress || "";
        const currClientEmail = res.data.data.manualEmail || "";
        setClaimEmail(emailAddress);
        setCurrClientEmail(currClientEmail)
      })
      .catch((err: any) => console.log(err))
  }

  const apiHandler = async (endpoint: String) => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,

      headers: {
        Authorization: `Bearer ${globalData?.accessToken}`,
      },
    })
      .then((res: any) => {
        const data = res.data;
        const attachmentsData = data["attachments"];

        setMainState({
          ...mainState,
          data: data,
          attachments: attachmentsData,
        });

        const mvfData = attachmentsData.find(
          (doc: any) => doc.documentType === "Motor Vehicle"
        );
        fetchPolicyNo(mvfData?.docId);

        const policeData = attachmentsData.find(
          (doc: any) => doc.documentType === "Police Report"
        );
        fetchPolice(policeData?.docId);

        const affidavitData = attachmentsData.find(
          (doc: any) => doc.documentType === "Affidavit"
        );
        fetchAffidavit(affidavitData?.docId);

        const forSubmission = attachmentsData.every(
          (item: any) => item?.status === "Approved"
        );
        setForSubmit(forSubmission);

        const forDisable = attachmentsData.every(
          (item: any) => item?.status === "For Policy Checking"
        );
        setIsDisabled(forDisable);
      })
      .catch((err: any) => {
        if (err?.response?.status === 401 || 400) {
          console.error(err);
        }
        navigate("not-found")
      });
  };

  // #region fetchPolicyNo
  const fetchPolicyNo = async (docId: any) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/motor-vehicle-form?transId=${transId}&id=${docId}`,
          {
            headers: {
              Authorization: `Bearer ${globalData?.accessToken}`,
            },
          }
        )
        .then((res: any) => {
          const data = res?.data?.data;
          setPolicyNoField(data?.policyNo);
          setDateOfLoss((prev: any) => ({
            ...prev,
            mvf: data?.adDate,
          }));
          // setClaimParticipant((prev: any) => ({
          //   ...prev,
          //   mvf: data?.tableVictims,
          // }));
          setTimeOfLoss((prev: any) => ({
            ...prev,
            mvf: data?.adTime,
          }));
        });
    } catch (error) {
      console.error("Error fetching policy number:", error);
    }
  };

  const getWebFormDetails = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/form-builder-details?transId=${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        setWebForm(res?.data?.data);
      })
      .catch((err: any) => { });
  };

  // #region fetchMotorVehicle
  const fetchPolice = async (docId: any) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/police-report?transId=${transId}&id=${docId}`,
          {
            headers: {
              Authorization: `Bearer ${globalData?.accessToken}`,
            },
          }
        )
        .then((res: any) => {
          const data = res?.data?.data;

          setDateOfLoss((prev: any) => ({
            ...prev,
            police: data?.accidentDate,
          }));

          setTimeOfLoss((prev: any) => ({
            ...prev,
            police: data?.accidentTime,
          }));

          setClaimParticipant((prev: any) => ({
            ...prev,
            police: data?.claimParticipant,
          }));
        });
    } catch (error) {
      console.error("Error fetching police report:", error);
    }
  };

  // #region fetchAffidavit
  const fetchAffidavit = async (docId: any) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/affidavit?transId=${transId}&id=${docId}`,
          {
            headers: {
              Authorization: `Bearer ${globalData?.accessToken}`,
            },
          }
        )
        .then((res: any) => {
          const data = res?.data?.data;

          setDateOfLoss((prev: any) => ({
            ...prev,
            affidavit: data?.incidentDate,
          }));
          setTimeOfLoss((prev: any) => ({
            ...prev,
            affidavit: data?.incidentTime,
          }));
          setClaimParticipant((prev: any) => ({
            ...prev,
            affidavit: data?.claimParticipant,
          }));
        });
    } catch (error) {
      console.error("Error fetching police report:", error);
    }
  };

  // #region getAcapTask
  const getAcapTask = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/task/${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        setEmailBody(res.data.data.emailBody);
        if (res.data.data.caseId !== null) {
          setSyncAW(true);
        }

        setAcapTask(res.data.data);

        const emailSender = res.data.data.sender;
        setSenderEmail(emailSender);

        const userId = res.data.data.userId;
        setUserId(userId);
        getUserTask(userId);

        setRequiredDocStatus({
          override: res.data.data.requiredDocOverride,
          approve: res.data.data.requiredDocApprove,
          comment: res.data.data.requiredDocComment,
        });
        console.error("acap-task", "success");
      })
      .catch((err: any) => {
        console.error("acap-task", err);
      });
  };

  // #region getUserTask
  const getUserTask = async (userId: number) => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/accounts/tasks/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        console.log("user-task:", res.data)
        setUserTask(res.data.data);
      })
      .catch((err: any) => {
        console.error("user-task:", err)
      });
  };

  // region duplicateTransaction
  const duplicateTransaction = async () => {
    setDuplicateLoader(true);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/duplicate/${transId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        setDuplicateLoader(false);

        setNotification({
          level: "success",
          showAlert: true,
          message: res.data.message,
        });

        getAcapTask();
      })
      .catch((err: any) => {
        console.error(err);
        setDuplicateLoader(false);
        setNotification({
          level: "error",
          showAlert: true,
          message: err?.response?.data || "Error on duplicating transaction",
        });
      });
  };

  // #region fetchRequiredDocStatus
  const fetchRequiredDocStatus = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/required-documents/status?transId=${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        setIsDocsApproved(res.data.data.requiredDocStatus);
        setRequiredDocStatus((prevData: any) => ({
          ...prevData,
          approve: res.data.data.requiredDocStatus,
        }));
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // #region fetchData
  const fetchData = (endpoint: string) => {
    setMainState({
      data: {},
      attachments: [],
      docStatus: {},
      response: "",
      isLoading: false,
      error: "",
    });
    apiHandler(endpoint);
  };

  // #region useEffect
  React.useEffect(() => {
    fetchData(
      `/api/acap/document-validation/document-tracker?transId=${transId}`
    );
    getPolicyAsiaRecord();
    getFieldCheckList();
    fetchRequiredDocStatus();
    fetchAllClecUsers();
    getAllDeTaskByTransId();
  }, []);

  React.useEffect(() => {
    getWebFormDetails();
  }, []);

  React.useEffect(() => {
    getAcapTask();
    getClientList();
  }, [transId]);

  // region columns
  const columns = [
    {
      field: "docId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      editable: false,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.docId === null || params.row.docId === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.docId
            )}
          </Stack>
        );
      },
    },
    {
      field: "fileName",
      headerClassName: "super-app-theme--header",
      headerName: "File Name",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return isEditing && formData.id === params.row.id ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              isEditing && saveDetails();
            }}
          >
            <OutlinedInput
              size="small"
              placeholder="Please enter filename"
              value={formData.fileName}
              onChange={(e) => handleFileNameChange(e)}
            />
          </form>
        ) : (
          <Stack textAlign={"center"}>
            {params.row.fileName === null || params.row.fileName === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.fileName
            )}
          </Stack>
        );
      },
    },
    {
      field: "tag",
      headerClassName: "super-app-theme--header",
      headerName: "Tag",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return isEditing && formData.id === params.row.id ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              isEditing && saveDetails();
            }}
          >
            {/* <form
            onSubmit={(e) => {
              e.preventDefault();
              isEditing ? saveDetails() : "";
            }}
          > */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={formData.tag}
                onChange={handleTagsChange}
                size="small"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{ minWidth: 120 }}
              >
                <MenuItem value="insured">insured</MenuItem>
                <MenuItem value="tp1">tp1</MenuItem>
                <MenuItem value="tp2">tp2</MenuItem>
                <MenuItem value="tp3">tp3</MenuItem>
                <MenuItem value="tp4">tp4</MenuItem>
                <MenuItem value="tp5">tp5</MenuItem>
                <MenuItem value="cp1">cp1</MenuItem>
                <MenuItem value="cp2">cp2</MenuItem>
                <MenuItem value="cp3">cp3</MenuItem>
                <MenuItem value="cp4">cp4</MenuItem>
                <MenuItem value="cp5">cp5</MenuItem>
                <MenuItem value="p1">p1</MenuItem>
                <MenuItem value="p2">p2</MenuItem>
                <MenuItem value="p3">p3</MenuItem>
                <MenuItem value="p4">p4</MenuItem>
                <MenuItem value="p5">p5</MenuItem>
              </Select>
            </FormControl>
          </form>
        ) : (
          <Stack textAlign={"center"}>
            {params.row.tag === null || params.row.tag === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.tag
            )}
          </Stack>
        );
      },
    },
    {
      field: "documentType",
      headerClassName: "super-app-theme--header",
      headerName: "Document Type",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.documentType === null ||
              params.row.documentType === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.documentType
            )}
          </Stack>
        );
      },
    },
    {
      field: "dateReceived",
      headerClassName: "super-app-theme--header",
      headerName: "Date Received",
      sortable: true,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.dateReceived === " " ||
              params.row.dateReceived === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              formatDate(params.row.dateReceived)
            )}
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        let statusChip = <Chip label="Small" size="small" />;
        if (params.row.status === "1") {
          statusChip = (
            <Chip
              label="Open"
              size="small"
              variant="outlined"
              color="primary"
            />
          );
        } else if (params.row.status === "38") {
          statusChip = (
            <Chip
              label="From Claimant"
              size="small"
              variant="filled"
              color="success"
            />
          );
        } else if (params.row.status === "2") {
          statusChip = (
            <Chip
              label="Approve"
              size="small"
              variant="filled"
              color="success"
            />
          );
        } else if (params.row.status === "3") {
          statusChip = (
            <Chip
              label="Rejected"
              size="small"
              variant="filled"
              color="error"
            />
          );
        } else if (params.row.status === "32") {
          statusChip = (
            <Chip label="AW File" size="small" variant="filled" color="error" />
          );
        } else if (params.row.status === "39") {
          statusChip = (
            <Chip label="Imsi File" size="small" variant="filled" color="warning" />
          );
        } else if (
          params.row.status === "For Email" ||
          params.row.status === "Emailed"
        ) {
          statusChip = (
            <Chip
              label="Emailed"
              size="small"
              variant="filled"
              color="warning"
            />
          );
        } else if (params.row.status === "11") {
          statusChip = (
            <Chip
              label="Open"
              size="small"
              variant="outlined"
              color="primary"
            />
          );
        } else if (
          params.row.status === "Submitted" ||
          params.row.status === "For Damage Evaluation" ||
          params.row.status === "For Policy Checking"
        ) {
          statusChip = (
            <Chip
              label="Submitted"
              size="small"
              variant="filled"
              color="primary"
            />
          );
        } else if (params.row.status === "Completed") {
          statusChip = (
            <Chip
              label="Completed"
              size="small"
              variant="filled"
              color="primary"
            />
          );
        } else {
          statusChip = <></>;
        }

        return <Stack textAlign={"center"}>{statusChip}</Stack>;
      },
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Actions",
      width: 400,
      sortable: false,
      renderCell: (params: any) => {
        const handleClickOpen = (
          scrollType: DialogProps["scroll"],
          documentType: any,
          formData: any
        ) => {
          const isCompleted =
            params.row.status === "Approved" ||
              params.row.status === "Submitted" ||
              params.row.status === "For Damage Evaluation" ||
              params.row.status === "For Policy Checking" ||
              params.row.status === "AW File" ||
              params.row.status === "Imsi File" ||
              params.row.status === "Completed"
              ? true
              : false;

          if (documentType === "Official Receipt") {
            setSelectedForm(0);
          } else if (documentType === "Certificate of Registration") {
            setSelectedForm(1);
          } else if (documentType === "Driver License") {
            setSelectedForm(2);
          } else if (documentType === "Motor Vehicle") {
            setSelectedForm(3);
          } else if (documentType === "Police Report") {
            setSelectedForm(4);
          } else if (documentType === "Shop Estimate") {
            setSelectedForm(5);
          } else if (documentType === "Affidavit") {
            setSelectedForm(6);
          } else if (documentType === "Other Documents") {
            setSelectedForm(7);
          } else if (documentType === "Driver License OR") {
            setSelectedForm(8);
          }
          setOpen(true);
          setScroll(scrollType);
        };

        let actionButton = (
          <Button
            variant="contained"
            className="tp-custom-button-primary"
            size="small"
            onClick={() => {
              handleClickOpen("paper", params.row.documentType, formData);
              setSelectedDocId(params.row.docId);
            }}
            disabled={[
              "Clec",
              "Jr Clec",
              "Claims Admin",
              "Head Motor Claims",
              "Head Claims",
              "Head Clec",
              "Call Center",
              "Canvasser"
            ].includes(globalData?.role)}
          >
            Update
          </Button>
        );

        if (
          params.row.status === "2" ||
          params.row.status === "5" ||
          params.row.status === "1" ||
          params.row.status === "32" ||
          params.row.status === "39" ||
          params.row.status === "11" ||
          params.row.status === "38"
        ) {
          actionButton = (
            <>
              {isEditing && formData.id === params.row.id ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={patchTagFileName}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                ""
              )}
              {isEditing ? (
                ""
              ) : (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      editDetails(params.row);
                      setSelectedDocId(params.row.docId);
                    }}
                    disabled={[
                      "Clec",
                      "Jr Clec",
                      "Claims Admin",
                      "Head Motor Claims",
                      "Head Claims",
                      "Head Clec",
                      "Call Center",
                      "Canvasser"
                    ].includes(globalData?.role)}
                  >
                    Update
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleClickOpen(
                        "paper",
                        params.row.documentType,
                        formData
                      );
                      setSelectedDocId(params.row.docId);
                    }}
                  >
                    View Details
                  </Button>
                </Box>
              )}
            </>
          );
        } else if (
          params.row.status === "11" ||
          params.row.status === "12" ||
          params.row.status === "10"
        ) {
          actionButton = (
            <Button
              variant="contained"
              className="tp-custom-button-primary"
              size="small"
              disabled
            >
              View Details
            </Button>
          );
        } else if (
          params.row.status === "2" ||
          params.row.status === "3" ||
          params.row.status === "5" ||
          params.row.status === "9" ||
          params.row.status === "8"
        ) {
          actionButton = (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => {
                handleClickOpen("paper", params.row.documentType, formData);
                setSelectedDocId(params.row.docId);
              }}
            >
              View Details
            </Button>
          );
        } else {
          actionButton = <></>;
        }

        return <Stack textAlign={"center"}>{actionButton}</Stack>;
      },
    },
  ];

  // #region handleClose
  const handleClose = () => {
    setOpen(!open);
    setBusinessRules((prev: any) => ({
      ...prev,
      rules: [],
      isLoading: false,
    }));
    fetchData(
      `/api/acap/document-validation/document-tracker?transId=${transId}`
    );
    fetchRequiredDocStatus();
    fetchPolicyNo(mvfDoc?.docId);
  };

  // region fetchAllClecUsers
  const fetchAllClecUsers = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/clec-users`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        const data: IUserDetail[] = res?.data;
        setClecUsers(data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleClecUserChange = (event: SelectChangeEvent<string>) => {
    const selectedUserId = event.target.value;
    const selectedUser: IUserDetail | undefined = clecUsers?.find(
      (user: IUserDetail) => user?.id === Number(selectedUserId)
    );

    setAssignedClec(selectedUser);
  };

  const createDeTask = async () => {

    if (assignedClec === null || assignedClec === undefined) {
      alert("Select user before submit");
      setCreateDeTaskLoading(false);
      return;
    }

    setCreateDeTaskLoading(true);

    const reqPayload: IDeTask = {
      id: null,
      transId: Number(transId),
      sender: sender,
      taskType: null,
      peril: null,
      channel: Number(mainState?.attachments[0]?.channel | 1),
      priority: 0,
      priorityDesc: null,
      policyNo: null,
      claimNo: null,
      status: 21,
      submitted: null,
      userId: assignedClec?.id,
      username: assignedClec?.userName,
    };

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/custom/task`,

        reqPayload,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: Number(transId),
          taskType: "damage_evaluation",
          event: "assign_damage_evaluation",
          description: `Damage Evaluation with trans-id #${transId} is assigned to ${assignedClec.firstName} ${assignedClec.lastName} (${assignedClec.userName})`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        })

        getAllDeTaskByTransId();
        setCreateDeTaskLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setCreateDeTaskLoading(false);
      });
  };

  const getAllDeTaskByTransId = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/all?transId=${transId}`,
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        setDeTasks(res?.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // Edit Details
  const editDetails = (e: any) => {
    setFormData(e);
    setIsEditing(true);
  };

  const saveDetails = () => {
    setMainState(
      mainState.attachment.map((attachment: any) =>
        attachment.id === formData.id ? formData : attachment
      )
    );
    setFormData({ id: null, tag: "", fileName: "" });
    setIsEditing(false);
  };

  const patchTagFileName = async () => {
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/patch?id=${formData.id}`,

        {
          tag: formData.tag,
          fileName: formData.fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        fetchData(
          `/api/acap/document-validation/document-tracker?transId=${transId}`
        );
        setIsEditing(false);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleFileNameChange = (e: any) => {
    const { value } = e.target;
    setFormData({ ...formData, fileName: value });
  };

  const changeClientEmail = async (emailAdd: string) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list/change-email?transId=${transId}`,
        { email: emailAdd },
        {
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        console.log(res);
        setCurrClientEmail(res.data.data.newEmail);
      })
      .catch((err: any) => console.log(err))
  }

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item md={9}>
          <Typography variant="h4" className="tp-text-header-primary">
            Policy/Document Validation
          </Typography>
        </Grid>
        <Grid item md={3} textAlign={"center"}>
          {/* <img src={Image.logo} width={180} height={60} alt='img' /> */}
        </Grid>
      </Grid>
      <hr className="tp-border-hr_primary" />
      <br />
      <Box>
        <Card
          sx={{
            boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
            borderRadius: 2,
          }}
        >
          <Grid container marginTop="35px">
            <Grid item sm={0} md={2} lg={2} />
            <Grid item sm={12} md={8} lg={8} style={{ justifyItems: "center" }}>
              <div>Transaction No.:</div>
              <Typography variant="h3" className="tp-text-header-primary">
                ACAP-{transId}
                {acapTask.duplicatedId !== null && (
                  <Chip
                    label="Duplicated"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                )}
                {syncAW && (
                  <Chip label="Synced with AW" color="error" size="small" />
                )}
              </Typography>
              {![
                "Clec",
                "Jr Clec",
                "Claims Admin",
                "Head Motor Claims",
                "Head Claims",
                "Head Clec",
                "Call Center",
                "Canvasser"
              ].includes(globalData?.role) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={duplicateTransaction}
                    disabled={duplicateLoader}
                  >
                    {duplicateLoader ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Duplicate"
                    )}
                  </Button>
                )}
              <br />
              <div>Sender:</div>
              <div style={{ fontWeight: "bold", color: "navy" }}>
                {acapTask.sender}
              </div>
              <br />
              {acapTask.emailBody && (
                <>
                  <Accordion style={{ width: "500px" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"panel-a-content"}
                      id={"panel-a-header"}
                    >
                      <Typography color="primary">Email Body</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ color: "gray" }}>{acapTask.emailBody}</div>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              {/* 
              // #region WebFormDetails 
              */}
              {webForm && (
                <React.Fragment>
                  <WebFormDetails data={webForm} />
                  <br />
                  <br />
                </React.Fragment>
              )}
              <br />
              <br />
              {/* 
              // #region AcapTaskStatusUpdater
              */}
              <AcapTaskStatusUpdater transId={transId} syncAW={syncAW} acapTask={acapTask} userTask={userTask} setUserTask={setUserTask} getUserTask={getUserTask} userId={userId}/>
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={6}>
                  {/* 
                    // region PolicyChecker 
                  */}
                  <PolicyChecker
                    setClientCode={setClientCode}
                    policyNo={policyNoField}
                    setPolicyNoField={setPolicyNoField}
                    setPolicyFromDB={setPolicyFromDB}
                    claimRegistrationData={claimRegistrationData}
                    setClientValidated={setClientValidated}
                    policyValidated={policyValidated}
                    setPolicyValidated={setPolicyValidated}
                    getFieldCheckList={getFieldCheckList}
                    setPolicyData={setPolicyData}
                    policyData={policyData}
                    acapTask={acapTask}
                    policyStatus={policyStatus}
                    setPolicyStatus={setPolicyStatus}
                  />

                  <br />
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                  {/* <br /><br /> */}
                  {/* 
                  // #region ClientList 
                  // */}
                  <ClientList
                    clientCodeField={clientCode || ""}
                    claimRegistrationData={claimRegistrationData}
                    clientValidated={clientValidated}
                    setClientValidated={setClientValidated}
                  />
                  <br />
                  {/* 
                    // #region Email Prefer
                  */}
                  <Stack direction="column" alignItems="center" justifyContent="center">
                  {!isEditingEmail ? (
                    <>
                      <FormLabel id="email-label">
                        Preferred Claimant's Email: <span style={{ fontWeight: "bold", color: "navy" }}>{currClientEmail ? `${currClientEmail}` : ""}</span>
                        {/* <Button variant="outlined" style={{ marginLeft: '10px' }} onClick={() => setIsEditingEmail(true)}> */}
                        <IconButton
                color="primary"
                onClick={() => setIsEditingEmail(true)}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
                     
                      </FormLabel>
                      <br />
                   
                    </>
                  ) : (
                    <FormControl>
                      <FormLabel id="edit-email-label">Edit Claim Email</FormLabel>
                      <RadioGroup
                        aria-labelledby="edit-email-label"
                        name="email-options"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value as EmailOption)}
                      >
                        <FormControlLabel
                          value="sender"
                          control={<Radio />}
                          label={
                            <Stack direction="row" gap={2} alignItems="center">
                              <TextField
                                variant="outlined"
                                size="small"
                                disabled
                                placeholder="Enter Sender Email"
                                value={senderEmail}
                                onChange={(e) => setSenderEmail(e.target.value)}
                              />
                              <Button
                                variant="outlined"
                                disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center","Canvasser"].includes(globalData?.role)}
                                onClick={() => {
                                  changeClientEmail(senderEmail);
                                  setIsEditingEmail(false);
                                }}
                              >
                                Save
                              </Button>
                            </Stack>
                          }
                        />
                        <FormControlLabel
                          value="client"
                          control={<Radio />}
                          label={
                            <Stack direction="row" gap={2} alignItems="center">
                              <TextField
                              variant="outlined"
                              size="small"
                              placeholder="Enter Claim Email"
                              value={claimEmail}
                              disabled
                              onChange={(e) => setClaimEmail(e.target.value)}
                              />
                              <Button
                              variant="outlined"
                              disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center","Canvasser"].includes(globalData?.role)}
                              onClick={() => {
                                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                if (claimEmail === "" || emailRegex.test(claimEmail)) {
                                changeClientEmail(claimEmail);
                                setIsEditingEmail(false);
                                } else {
                                alert("Please enter a valid email address");
                                }
                              }}
                              >
                              Save
                              </Button>
                            </Stack>
                          }
                        />
                        <FormControlLabel
                          value="manual"
                          control={<Radio />}
                          label={
                            <Stack direction="row" gap={2} alignItems="center">
                              <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Enter Manual Email"
                                value={manualEmail}
                                onChange={(e) => setManualEmail(e.target.value)}
                              />
                              <Button
                                variant="outlined"
                                disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center","Canvasser"].includes(globalData?.role)}
                                onClick={() => {
                                  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                  if (emailRegex.test(manualEmail)) {
                                    changeClientEmail(manualEmail);
                                    setIsEditingEmail(false);
                                  } else {
                                    alert("Please enter a valid email address");
                                  }
                                }}
                              >
                                Save
                              </Button>
                            </Stack>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Stack>
                </Grid>
               
              </Grid>
              <br />
              <br />
            </Grid>
            <Grid item sm={0} md={2} lg={2} />
          </Grid>

          {/* 
            TODO: need api to work
          */}



          <br />
          <div style={{ margin: "25px" }}>
            <Grid container>
              {/*
                // #region EmailMissing
              */}
              <Grid item sm={12} md={8} lg={8}>
                <EmailMissing transId={transId} sender={sender} />
              </Grid>
              {/*
                // #region UploadMissing
              */}
              <Grid item sm={12} md={4} lg={4} textAlign="right">
                <Grid container>
                  <Grid item sm={12} md={6} lg={6} justifyContent="center">
                    <UploadMissing transId={transId || ""} />
                  </Grid>
                  {syncAW && (
                    <Grid item sm={12} md={6} lg={6} justifyContent="center">
                      <FileUploadCliamType
                        transId={transId || ""}
                        claimRegistrationData={claimRegistrationData}
                        onFileUpload={() => {
                          fetchData(
                            `/api/acap/document-validation/document-tracker?transId=${transId}`
                          );
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                height: 424,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#130a68",
                  color: "#ffffff",
                  marginTop: "15px",
                },
                "& .MuiDataGrid-columnHeaderDraggableContainer": {
                  backgroundColor: "#130a68",
                },
                "&. MuiDataGrid-footerContainer": {
                  display: "none !important",
                },
              }}
            >
              {/* 
                // #region DocumentTracker 
              */}
              {mainState?.attachments ? (
                <DataGrid
                  rows={mainState?.attachments}
                  columns={columns}
                  disableRowSelectionOnClick
                  disableColumnMenu
                />
              ) : (
                <></>
              )}
            </Box>
          </div>
        </Card>
      </Box>
      <br />
      <RequiredDocStatus
        requiredDocStatus={requiredDocStatus}
        setRequiredDocStatus={setRequiredDocStatus}
        transId={transId}
        fetchRequiredDocStatus={fetchRequiredDocStatus}
      />
      <br />
      {/* 
            // #region FieldCheckList
          */}
      <FieldChecklist
        manualCheckList={manualCheckList}
        setManualCheckList={setManualCheckList}
        updateFieldCheckList={updateFieldCheckList}
        setFieldCheckListComment={setFieldCheckListComment}
        checklistComments={checklistComments}
        updateFieldCheckListComments={updateFieldCheckListComments}
        acapTask={acapTask}
        claimHistory={claimHistory}
        handleOverride={handleOverride}
      />
      <hr className="tp-border-hr_primary" />
      <br />
      <br />
      {/* 
            // #region BusinessRules
          */}
      <BusinessRulesTask transId={transId} taskType={"document_validation"} />
      <br />
      <br />
      {/* 
            // #region ClaimRegistration
          */}
      <br />
      <ClaimRegistration
        transId={transId}
        policyNo={policyNoField}
        claimRegistrationData={claimRegistrationData}
        setClaimRegistrationData={setClaimRegistrationData}
        isValidated={policyValidated === true && clientValidated === true}
        dateOfLoss={dateOfLoss}
        setDateOfLoss={dateOfLoss}
        timeOfLoss={timeOfLoss}
        setTimeOfLoss={setTimeOfLoss}
        policyData={policyData}
        sender={sender}
        policyValidated={policyValidated}
        caseId={acapTask.caseId}
        claimHistory={claimHistory}
        setClaimHistory={setClaimHistory}
        claimParticipant={claimParticipant}
        setClaimParticipant={setClaimParticipant}
        syncAW={syncAW}
      // businessRules={businessRules}
      />
      <br />
      <br />
      <br />
      {/* 
            // region Create Damage Evaluation
          */}
      <Typography variant="h6" className="tp-text-header-primary">
        Create Damage Evaluation
      </Typography>
      <hr className="tp-border-hr_primary" />
      <FormControl fullWidth>
        <InputLabel>Assign User</InputLabel>
        <Select
          value={assignedClec?.userName}
          onChange={handleClecUserChange}
          label="Assign User"
          fullWidth
        >
          {clecUsers &&
            clecUsers.map((item: IUserDetail) => (
              <MenuItem key={item.id} value={item.id}>
                {item.userName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl>
        <Button
          className="tp-custom-button-primary"
          sx={{ mt: 2 }}
          variant="contained"
          onClick={createDeTask}
          disabled={[
            "Clec",
            "Jr Clec",
            "Claims Admin",
            "Head Motor Claims",
            "Head Claims",
            "Head Clec",
            "Claims Admin",
            "Call Center",
            "Canvasser"
          ].includes(globalData?.role) || createDeTaskLoading}
        >
          {createDeTaskLoading ? <CircularProgress color="inherit" size={24} /> : "Create Task"}
        </Button>
      </FormControl>
      {deTasks.map((item: IDeTask, idx: number) => (
        <Typography sx={{ mt: 2 }} variant="body1">
          Damage evaluation task{" "}
          <span style={{ fontWeight: "bold" }}>{`#${item.id}`}</span> with
          transaction id:{" "}
          <span style={{ fontWeight: "bold" }}>{`ACAP-${item.transId}`}</span>{" "}
          assigned to{" "}
          <span
            style={{ fontWeight: "bold" }}
          >{`${item?.userDetails?.username}`}</span>{" "}
          at{" "}
          <span style={{ fontWeight: "bold" }}>{`${formatDate(
            item.saveDate
          )}`}</span>
        </Typography>
      ))}

      {/* 
            // #region Login As
          */}

      <br />
      <br />
      <Typography variant="h6" className="tp-text-header-primary">
        Remarks & Logs
      </Typography>
      <hr className="tp-border-hr_primary" />
      <TaskRemarks />
      <AuditTrail />
      <br />
      <br />
      <br />
      <hr className="tp-border-hr_primary" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Typography variant="h6" className="tp-text-header-primary">
          Login As User: &ensp;
          <span style={{ color: "#525252" }}>
            {toTitleCase(globalData?.firstName)}{" "}
            {toTitleCase(globalData?.lastName)}
          </span>
        </Typography>
      </Box>
      <br />
      <br />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll={scroll}
        TransitionComponent={Transition}
      >
        <AppBar color="error" sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Validation Process
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MainModal
          data={mainState.data}
          attachments={mainState.attachments}
          transID={transId}
          scroll={scroll}
          selectedForm={selectedForm}
          disabled={disabled}
          setIsDisabled={setIsDisabled}
          userName={globalData}
          refereshList={fetchData}
          close={handleClose}
          docId={selectedDocId}
        />
      </Dialog>
    </Container>
  );
}
