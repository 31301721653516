import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import * as React from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import reserveCodes from "../reserveCode";
import { ReserveCodeKey, reserveCodeMapping } from "../reserveCodeMapping";
// import { convertToMilitaryTime } from '../../utils/stringUtils';
import { format } from 'date-fns';


interface ClaimRegistrationProps {
  transId: any,
  claimRegistrationData: any,
  setClaimRegistrationData: any,
  tag: string,
  entries: { id: string; premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[];
  oldEntries: { id: string; premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[];
  setEntries: React.Dispatch<any>;
  fetchClaimRegistrationData: () => void;
  claimsModificationCreate: (entries: { premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[]) => void;
  claimLoading: boolean;
}

interface IClaimEntry {
  value: string;
  label: string;
  premiumClass: string;
}

const ClaimRegistrationDetail = ({
  transId,
  tag,
  claimRegistrationData,
  setClaimRegistrationData,
  entries,
  oldEntries,
  setEntries,
  fetchClaimRegistrationData,
  claimsModificationCreate,
  claimLoading
  // businessRules
}: ClaimRegistrationProps) => {
  const [premiumClass, setPremiumClass] = React.useState("202");
  const [reserveCode, setReserveCode] = React.useState("");
  const [estimationAmount, setEstimationAmount] = React.useState("");
  const [newPremiumClass, setNewPremiumClass] = React.useState("202");
  const [newReserveCode, setNewReserveCode] = React.useState("");
  const [newEstimationAmount, setNewEstimationAmount] = React.useState("");
  const [claimTag, setClaimTag] = React.useState("");
  const [newTagClaim, setNewTagClaim] = React.useState("");
  const { globalData } = React.useContext(GlobalDataContext);
  const [editIndex, setEditIndex] = React.useState<number>(0);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  // const [claimLoading, setClaimLoading] = React.useState<boolean>(false);

  const premiumClasses = [

    // { value: 201, label: "201 - COMPULSORY TPL - BODILY INJURY" },
    { value: "202", label: "202 - OWN DAMAGE" },
    // { value: 203, label: "203 - THEFT - Carnap" },
    { value: "203", label: "203 - THEFT - Partial Theft" },
    // { value: 205, label: "205 - VTPL - BODILY INJURY" },
    { value: "206", label: "206 - VTPL - PROPERTY DAMAGE" },
    // { value: 2061, label: "206 - VTPL - PROPERTY DAMAGE Other than Vehicle" },
    // { value: 207, label: "207 - AUTO PERSONAL ACCIDENT" },
    { value: "209", label: "209 - ACTS OF NATURE" },
    // { value: 211, label: "211 - STRIKES, RIOTS, CIVIL COMMOTIONS" },
    // { value: 212, label: "212 - 24-HOUR ROADSIDE ASSISTANCE" },
    // { value: 214, label: "214 - ALTERNATIVE TRANSPO ALLOWANCE" },
    // { value: 216, label: "216 - CSL VTPL-BI/PD" },
  ];

  const handlePremiumClassChange = (event: any) => {
    // console.log(typeof event.target.value)
    setPremiumClass(event.target.value);
    setReserveCode(""); // Reset reserve code when premium class changes
  };

  // alert(tag)

  const handleReserveCodeChange = (event: any) => {
    setReserveCode(event.target.value);
  };

  const handleEstimationAmountChange = (event: any) => {
    setEstimationAmount(event.target.value);
  };

  // region for existing entries

  

  const toggleEditDialog = (open: boolean, index: number) => {
    setOpenDialog(open);
    setEditIndex(index);
    if (open === true) {
      setNewPremiumClass(entries[index]?.premiumClass);
      setNewReserveCode(entries[index]?.reserveCode);
      setNewEstimationAmount(entries[index]?.estimationAmount);
      setNewTagClaim(entries[index]?.tag)
    } else {
      setNewPremiumClass("");
      setNewReserveCode("");
      setNewEstimationAmount("");
      setNewTagClaim("insured");
    }
  };

  const handleNewPremiumClassChange = (event: any, index: number) => {
    setNewPremiumClass(event.target.value)
  };

  const handleNewReserveCodeChange = (event: any, index: number) => {
    setNewReserveCode(event.target.value)
  };

  const handleNewEstimationAmountChange = (event: any, index: number) => {
    setNewEstimationAmount(parseFloat(event.target.value).toLocaleString(
      "en-PH",
      { style: "currency", currency: "PHP" }
    ))
  };

  const handleSaveEditedEntry = (index: number) => {
    setEntries((prevEntries: { id: string; premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[]) => {
      // Create a new array copy
      const updatedEntries = [...prevEntries];

      // Update the specific attribute in the object at the given index
      updatedEntries[index] = {
        id: updatedEntries[index].id,
        premiumClass: newPremiumClass,
        reserveCode: newReserveCode,
        tag: newTagClaim,
        estimationAmount: newEstimationAmount,
        codeDesc: newReserveCode ? (reserveCodes.find(
          (rc) => rc.value === newReserveCode
        )?.label || "") : ""
      };

      // Disabled for now
      // claimsModificationCreate(updatedEntries);

      return updatedEntries;
    });

    toggleEditDialog(false, 0);

  }

  const getLatestIndex = (entriesPayload: { id: string; premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[]) => {
    const sortedData = entriesPayload.sort((a, b) => parseInt(a.id) - parseInt(b.id));
    const lastIndex = parseInt(sortedData[sortedData.length - 1].id);
    return lastIndex;
  }

  const handleAddNewEntry = () => {
    // console.log(premiumClass,reserveCode,claimTag,estimationAmount)
    if (entries.length < 10) {
      setEntries((prevEntries: any) => {
        // Create a new array copy
        const updatedEntries = [...prevEntries, {
          id: String(getLatestIndex(entries) + 1).padStart(2, '0'),
          premiumClass: premiumClass,
          reserveCode: reserveCode,
          tag: claimTag,
          estimationAmount: parseFloat(estimationAmount).toLocaleString(
            "en-PH",
            { style: "currency", currency: "PHP" }
          ),
          codeDesc: reserveCode ? (reserveCodes.find(
            (rc) => rc.value === reserveCode && rc.premiumClass === premiumClass
          )?.label || "") : ""
        }];

        console.log("handleAddNewEntry: ",updatedEntries)

        // Disabled for now
        // claimsModificationCreate(updatedEntries);
        
        return updatedEntries;
      });
      setPremiumClass("");
      setReserveCode("");
      setEstimationAmount("");
    }
  };

  const handleDeleteNewEntry = (index: any) => {
    setEntries((prevEntries: any) => {
      // Create a new array copy
      const updatedEntries = prevEntries.filter((entry: any, i: any) => i !== index);

      // Disabled for now
      // claimsModificationCreate(updatedEntries);

      return updatedEntries;
    });
    // setEntries(entries.filter((entry, i) => i !== index));
  };

  const filteredExistingReserveCode = (
    premiumClassCode: string
  ): IClaimEntry[] => {
    // console.log(premiumClassCode)
    return reserveCodes.filter(
      (code) => code.premiumClass === premiumClassCode
    );
  };

  const isDisabled = entries.length >= 10;

  return (
    <>
      <Typography variant="body2" color="error" marginBottom="20px" textAlign="center">
        Maximum Entry: {10 - entries.length}
      </Typography>
      <Typography variant="body1" color="primary" className="tp-text-bold">
        Loss Reserve:
      </Typography>
      <Grid container spacing={2} marginTop="5px">
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            select
            label="Premium Class"
            value={premiumClass}
            size="small"
            onChange={handlePremiumClassChange}
            fullWidth
            disabled={isDisabled}
          >
            {premiumClasses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            select
            label="Reserve Code"
            value={reserveCode}
            onChange={handleReserveCodeChange}
            fullWidth
            size="small"
            disabled={!premiumClass || isDisabled}
          >
            {filteredExistingReserveCode(premiumClass).map(
              (option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            label="Estimation Amount"
            type="number"
            fullWidth
            size="small"
            value={estimationAmount}
            onChange={handleEstimationAmountChange}
            disabled={!premiumClass || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Tag</InputLabel>
            <Select
              label="Tag"
              value={claimTag}
              onChange={(e) => setClaimTag(e.target.value)}
              defaultValue="insured"
            >
              {[
                "insured",
                "tp1",
                "tp2",
                "tp3",
                "tp4",
                "tp5",
                "p1",
                "p2",
                "p3",
                "p4",
                "p5",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1} lg={1} textAlign="center">
          <Button
            color="error"
            onClick={handleAddNewEntry}
            disabled={
              !premiumClass ||
              !reserveCode ||
              !estimationAmount ||
              isDisabled
            }
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Premium Class</TableCell>
              <TableCell>Reserve Code</TableCell>
              <TableCell>Estimation Amount</TableCell>
              <TableCell>Tag</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry, index) => (
              <TableRow key={index}>
                <TableCell>
                  {
                    premiumClasses.find(
                      (pc) => pc.value === entry.premiumClass
                    )?.label
                  }
                </TableCell>
                <TableCell>{entry.codeDesc}</TableCell>
                <TableCell>{entry.estimationAmount}</TableCell>
                <TableCell>{entry.tag}</TableCell>
                <TableCell>
                  <TableCell>
                    <>
                      <IconButton color="primary" onClick={() => toggleEditDialog(true, index)}>
                        <EditIcon />
                      </IconButton>
                      {/* <IconButton color="error" onClick={() => handleDeleteNewEntry(index)}>
                        <DeleteIcon />
                      </IconButton> */}
                    </>
                  </TableCell>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={(event, reason) => toggleEditDialog(false, 0)}
      >
        <DialogTitle>Edit Entry #{editIndex}</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            fullWidth
            select
            label="Premium Class"
            value={
              premiumClasses.find((pc) => pc.value === newPremiumClass)?.value
            }
            onChange={(event) => handleNewPremiumClassChange(event, editIndex)}
          >
            {premiumClasses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <TextField
            select
            fullWidth
            label="Reserve Code"
            value={
              reserveCodes.find((rc) => rc.value === newReserveCode)?.value
            }
            onChange={(event) => handleNewReserveCodeChange(event, editIndex)}
            disabled={!newPremiumClass}
          >
            {filteredExistingReserveCode(newPremiumClass).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <TextField
            fullWidth
            label="Estimation Amount"
            type="number"
            value={parseFloat(newEstimationAmount.replace(/[₱,]/g, ""))}
            onChange={(event) =>
              handleNewEstimationAmountChange(event, editIndex)
            }
          />
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel>Tag</InputLabel>
            <Select
              label="Tag"
              value={newTagClaim}
              onChange={(e) => setNewTagClaim(e.target.value)}
              defaultValue="insured"
            >
              {[
                "insured",
                "tp1",
                "tp2",
                "tp3",
                "tp4",
                "tp5",
                "p1",
                "p2",
                "p3",
                "p4",
                "p5",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSaveEditedEntry(editIndex)}
          >
            Save
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => toggleEditDialog(false, 0)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={claimLoading === true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ClaimRegistrationDetail;
