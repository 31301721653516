import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Select from "react-select";
import { toTitleCase } from "../../api/utils";
import { formatDate } from "../../api/FormatDate";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ITaskRemarksProps {
  fetchtypes: string[];
  type: string; 
}

const GenericTaskRemarks: React.FC<ITaskRemarksProps> = ({ fetchtypes, type }) => {
  const [mainState, setMainState] = React.useState<any>({
    data: [],
    newData: {
      remarks: "",
      type
    },
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
    pagination: {
      page: 1,
      count: 0,
      size: 5,
    },
  });
  const { globalData } = React.useContext(GlobalDataContext);
  const { transId } = useParams();

  const handleInputChange = (e: any) => {
    console.log(globalData)
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      newData: {
        transId: transId,
        userId: globalData?.id,
        type,
        [id]: value,
      },
    });
  };

  const handleSubmit = () => {

    createData();
  };

  const handleChange = (event: any, value: any) => {
    setMainState((prev: any) => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        page: value,
      },
    }));


    fetchData(value, 5);
  };

  const fetchData = async (page: number, size: number) => {
    try {
      // Set the initial loading state and reset newData
      setMainState({
        ...mainState,
        newData: {
          remarks: "",
        },
        isLoading: true,
      });

      // Fetch paginated data
      const paginatedResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/task-remarks/pagination/${transId}?page=${page - 1}&size=${size}`,
        {
          types: fetchtypes
        },
        {
          headers: { 'Authorization': `Bearer ${globalData?.accessToken}` },
        }
      );

      const paginatedData = paginatedResponse?.data?.data?.content;
      const totalPages = paginatedResponse?.data?.data?.totalPages;

      // Update state with new data and pagination count
      setMainState({
        ...mainState,
        data: paginatedData,
        newData: {
          remarks: "",
        },
        isLoading: false,
        pagination: {
          ...mainState?.pagination,
          page: page,
          count: totalPages,
        },
      });


    } catch (err) {
      console.error(err);
    }
  };

  const createData = async () => {
    setMainState({
      ...mainState,
      isLoading: true,
    });
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/task-remarks/`,
        mainState?.newData,
        {
          headers: { 'Authorization': `Bearer ${globalData?.accessToken}` },
        }
      )
      .then((res: any) => {

        fetchData(1, 5);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    fetchData(1, 5);
  }, []);

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="body1" className="tp-text-bold">
            Remarks History
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!mainState?.isLoading ? (
            <>
              <List aria-label="remarks-list">
                {mainState?.data.map((item: any, idx: number) => (
                  <React.Fragment key={idx}>
                    <ListItem>
                      <Box sx={{ width: "100%" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            justifyItems={"center"}
                            alignItems="center"
                          >
                            <Typography
                              color={"primary"}
                              variant="body1"
                              component="div"
                            >
                              {toTitleCase(item?.userDetails?.firstName)}{" "}
                              {toTitleCase(item?.userDetails?.lastName)}
                            </Typography>
                            &nbsp; &nbsp;
                            <Chip
                              label={`${item?.userDetails?.roleName}`}
                              color="primary"
                              size="small"
                              variant="outlined"
                            />
                          </Stack>

                          <Typography
                            gutterBottom
                            color="text.secondary"
                            variant="body2"
                            component="div"
                          >
                            {formatDate(item?.saveDate)}
                          </Typography>
                        </Stack>
                        <Typography variant="body1" gutterBottom>
                          {item?.remarks}
                        </Typography>
                      </Box>
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))}
              </List>

              {mainState?.data.length > 0 ? (
                <React.Fragment>
                  <br />
                  <Pagination
                    count={mainState?.pagination?.count}
                    page={mainState?.pagination?.page}
                    onChange={handleChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </>
          ) : (
            <CircularProgress />
          )}

          <br />
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Add Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              minRows={4}
              placeholder={`Enter Remarks Here...`}
              value={mainState.newData.remarks}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
            
            />
         
            <br />
            <Button
              className="tp-custom-button-primary"
              variant="contained"
              sx={{ ml: "auto" }}
              onClick={handleSubmit}
              disabled={mainState.newData.remarks.length === 0 || mainState?.isLoading === true}
            >
              {mainState?.isLoading === true ? (
                <>
                  <CircularProgress color="inherit" size="1.5em" />
                  &emsp;
                </>
              ) : (
                <></>
              )}
              Save remarks
            </Button>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default GenericTaskRemarks;
