// ListOfLabors.tsx
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Card,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState, useContext, Dispatch } from 'react';
import { useParams } from 'react-router-dom';
import {
    deleteLaborById,
    getDedetailsByDeId,
    getLaborsByDeId,
    postLabor,
    updateDedetailsByField,
    updateLaborById
} from './ApiServiceLabor';
import { GlobalDataContext } from "../../contexts/GlobalDataContext";

interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    laborRemarks: string;
    saveDate?: string;
    transId?: string;
    deId?: string;
}

interface AdditionalCosts {
    tinsmith: number;
    paintwork: number;
    materials: number;
    reform: number;
    outsideWorks: number;
    airconWorks: number;
    glassWorks: number;
    upholsteryWorks: number;
    mechanicalWorks: number;
    electricalWorks: number;
    laborAdjustment: number;
    miscellaneousCost: number;
}

interface LaborRateOption {
    value: number;
    label: string;
}

interface ListOfLaborProps {
    onLaborPlusVatChange: (value: number) => void;
    onLaborDataChange: (data: {
        rows: LaborRow[],

        laborRate: number,
        totalTimeHours: number,
        calculatedLaborCost: number,
        paintWorkMaterials: number,
        totalLaborCost: number,
        additionalCosts: AdditionalCosts
    }) => void;
    laborRate: number;
    onLaborRateChange: (newRate: number) => void;
    laborPlusVatCT: number;
    setLaborPlusVatCT: Dispatch<any>
}

const formatNumber = (value: number) => {
    return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export default function ListOfLabors({ onLaborPlusVatChange, onLaborDataChange, laborRate, onLaborRateChange, laborPlusVatCT, setLaborPlusVatCT }: ListOfLaborProps) {

    const repairConditions = ["X - Replace", "R - Repair", "P - Polish", "A - Align"];
    const [rows, setRows] = useState<LaborRow[]>([]);
    // const [laborRate, setLaborRate] = useState<number>(500);
    const [scopeOfWorks, setScopeOfWorks] = useState<string>('');
    const [repairCondition, setRepairCondition] = useState<string>('');
    const [repair, setRepair] = useState<number>(0);
    const [replace, setReplace] = useState<number>(0);
    const [painting, setPainting] = useState<number>(0);
    const [laborRemarks, setLaborRemarks] = useState<string>('');
    const { deId } = useParams<{ deId: string }>();
    const { transId } = useParams<{ transId: string }>();
    const { globalData } = useContext(GlobalDataContext);
    const accessToken = globalData.accessToken;

    // State for additional costs
    const [additionalCosts, setAdditionalCosts] = useState<AdditionalCosts>({
        tinsmith: 0,
        paintwork: 0,
        materials: 0,
        reform: 0,
        outsideWorks: 0,
        airconWorks: 0,
        glassWorks: 0,
        upholsteryWorks: 0,
        mechanicalWorks: 0,
        electricalWorks: 0,
        laborAdjustment: 0,
        miscellaneousCost: 0
    });

    // State to track which field is being edited
    const [editingField, setEditingField] = useState<keyof AdditionalCosts | null>(null);
    const [tempFieldValue, setTempFieldValue] = useState<number>(0);

    // New state variables for editing
    const [editingRowId, setEditingRowId] = useState<number | null>(null);
    const [editingRowData, setEditingRowData] = useState<LaborRow | null>(null);


    const [laborRateOptions, setLaborRateOptions] = useState<LaborRateOption[]>([]);
    const [loadingLaborRates, setLoadingLaborRates] = useState<boolean>(false);
    const [laborRateError, setLaborRateError] = useState<string | null>(null);
    const [updatingLaborRate, setUpdatingLaborRate] = useState<boolean>(false);
    const [updateLaborRateError, setUpdateLaborRateError] = useState<string | null>(null);

    // Fetch labor rate options from API
    useEffect(() => {
        const fetchLaborRateOptions = async () => {
            setLoadingLaborRates(true);
            setLaborRateError(null);
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/labor-rates`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error fetching labor rates: ${response.statusText}`);
                }

                const data = await response.json();

                // Assuming data is an array of objects with 'amount' and 'name'
                const options: LaborRateOption[] = data.data.map((item: any) => ({
                    value: item.amount,
                    label: `${item.amount} - ${item.name}`
                }));

                setLaborRateOptions(options);

                // Optionally set default laborRate based on fetched options
                if (options.length > 0) {
                    // Removed setLaborRate since laborRate is now coming from the parent component
                }
            } catch (error: any) {
                console.error("Failed to fetch labor rate options:", error);
                setLaborRateError(error.message || "Failed to load labor rates.");
            } finally {
                setLoadingLaborRates(false);
            }
        };

        fetchLaborRateOptions();
    }, [accessToken]);

    const laborData = useMemo(() => {
        const totalTimeHours = rows.reduce((acc, row) => acc + row.repair + row.replace + row.painting, 0);
        const calculatedLaborCost = totalTimeHours * laborRate;
        const paintingLabor = rows.reduce((acc, row) => acc + row.painting, 0) * laborRate;

        const paintWorkMaterials = laborRate === 370
            ? (paintingLabor * 0.75) / 1.12
            : (paintingLabor * 0.85 * 1.35) / 1.12;

        const additionalCostsTotal = Object.values(additionalCosts).reduce((acc, val) => acc + val, 0);

        const totalLaborCost = calculatedLaborCost + paintWorkMaterials + additionalCostsTotal;
        const repairLabor = rows.reduce((acc, row) => acc + row.repair + row.replace, 0) * laborRate;
        const laborPlusVAT = (repairLabor + paintingLabor + paintWorkMaterials + additionalCostsTotal);
        setLaborPlusVatCT((repairLabor + paintingLabor + paintWorkMaterials + additionalCostsTotal));

        return {
            rows,
            laborRate,
            totalTimeHours,
            calculatedLaborCost,
            paintWorkMaterials,
            totalLaborCost,
            additionalCosts,
            laborPlusVAT,
            repairLabor,
            paintingLabor,
            shopMaterials: paintWorkMaterials,
            additionalCostsTotal
        };
    }, [rows, laborRate, additionalCosts]);

    useEffect(() => {
        onLaborDataChange(laborData);
        onLaborPlusVatChange(laborData.laborPlusVAT);
    }, [rows, laborData, onLaborDataChange, onLaborPlusVatChange]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                if (deId) {
                    // Fetch Labors
                    const laborsResponse = await getLaborsByDeId(accessToken, Number(deId));
                    const labors = Array.isArray(laborsResponse.data) ? laborsResponse.data : [];
                    setRows(labors.map((labor: any) => ({
                        ...labor,
                        repair: labor.repair ? parseFloat(labor.repair) : 0,
                        replace: labor.replace ? parseFloat(labor.replace) : 0,
                        painting: labor.painting ? parseFloat(labor.painting) : 0,
                        laborRemarks: labor.laborRemarks ? labor.laborRemarks : ''
                    })));

                    // Fetch Additional Costs
                    const dedetailsResponse = await getDedetailsByDeId(accessToken, Number(deId));
                    const dedetails = dedetailsResponse;

                    setAdditionalCosts({
                        tinsmith: dedetails.tinsmith ? parseFloat(dedetails.tinsmith) : 0,
                        paintwork: dedetails.paintwork ? parseFloat(dedetails.paintwork) : 0,
                        materials: dedetails.materials ? parseFloat(dedetails.materials) : 0,
                        reform: dedetails.reform ? parseFloat(dedetails.reform) : 0,
                        outsideWorks: dedetails.outsideWorks ? parseFloat(dedetails.outsideWorks) : 0,
                        airconWorks: dedetails.airconWorks ? parseFloat(dedetails.airconWorks) : 0,
                        glassWorks: dedetails.glassWorks ? parseFloat(dedetails.glassWorks) : 0,
                        upholsteryWorks: dedetails.upholsteryWorks ? parseFloat(dedetails.upholsteryWorks) : 0,
                        mechanicalWorks: dedetails.mechanicalWorks ? parseFloat(dedetails.mechanicalWorks) : 0,
                        electricalWorks: dedetails.electricalWorks ? parseFloat(dedetails.electricalWorks) : 0,
                        laborAdjustment: dedetails.laborAdjustment ? parseFloat(dedetails.laborAdjustment) : 0,
                        miscellaneousCost: dedetails.miscellaneousCost ? parseFloat(dedetails.miscellaneousCost) : 0,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [deId]);

    const handleLaborRateChange = async (event: SelectChangeEvent<number>) => {
        const newRate = parseFloat(event.target.value as string);
        // setLaborRate(newRate);
        setUpdatingLaborRate(true);
        setUpdateLaborRateError(null);

        if (deId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        laborRate: newRate.toFixed(2),
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Error updating labor rate: ${response.statusText}`);
                }
                onLaborRateChange(newRate);

                // Optionally handle response data
            } catch (error: any) {
                console.error("Failed to update labor rate:", error);
                setUpdateLaborRateError(error.message || "Failed to update labor rate.");
                // Optionally revert the labor rate change
            } finally {
                setUpdatingLaborRate(false);
            }
        } else {
            setUpdateLaborRateError("deId is not available.");
            setUpdatingLaborRate(false);
        }
    };


    const handleAddLabor = useCallback(() => {
        const newLabor: LaborRow = {
            scopeOfWorks: scopeOfWorks.trim(),
            repairCondition,
            repair,
            replace,
            painting,
            laborRemarks,
            deId: deId,
            transId: transId,
            saveDate: new Date().toISOString().slice(0, -1)
        };

        postLabor(accessToken, newLabor)
            .then(response => {
                const savedLabor = response?.data?.data;
                setRows(prevRows => [...prevRows, { ...newLabor, id: savedLabor.id }]);
                // Reset input fields
                setScopeOfWorks('');
                setRepairCondition('');
                setRepair(0);
                setReplace(0);
                setPainting(0);
                setLaborRemarks('');
            })
            .catch(error => {
                console.error("Error adding labor:", error);
            });
    }, [scopeOfWorks, repairCondition, repair, replace, painting, deId, transId, laborRemarks]);

    const handleDeleteRow = useCallback((index: number) => {
        const rowToDelete = rows[index];

        if (rowToDelete.id) {
            deleteLaborById(accessToken, rowToDelete.id)
                .then(() => {
                    setRows(prevRows => prevRows.filter((_, i) => i !== index));
                })
                .catch(error => {
                    console.error("Error deleting labor:", error);
                });
        } else {
            console.error("Cannot delete item without an id");
        }
    }, [rows]);

    // Handlers for editing labor rows
    const handleEditRow = (row: LaborRow) => {
        setEditingRowId(row.id ?? null);
        setEditingRowData({ ...row });
    };

    const handleCancelEdit = () => {
        setEditingRowId(null);
        setEditingRowData(null);
    };

    const handleSaveEdit = () => {
        if (editingRowId !== null && editingRowData) {
            const updatedLabor = {
                ...editingRowData,
                scopeOfWorks: editingRowData.scopeOfWorks.trim()
            };

            updateLaborById(accessToken, editingRowId, updatedLabor)
                .then(response => {
                    setRows(prevRows => prevRows.map(row => row.id === editingRowId ? { ...updatedLabor, id: editingRowId } : row));
                    setEditingRowId(null);
                    setEditingRowData(null);
                })
                .catch(error => {
                    console.error("Error updating labor:", error);
                });
        }
    };

    const renderTableRows = useCallback(() => {
        return rows.map((row, index) => {
            const total = row.repair + row.replace + row.painting;
            const isEditing = row.id === editingRowId;

            return (
                <TableRow key={row.id ?? index}>
                    <TableCell>
                        {isEditing ? (
                            <TextField
                                size='small'
                                value={editingRowData?.scopeOfWorks}
                                onChange={(e) => setEditingRowData(prev => prev ? { ...prev, scopeOfWorks: e.target.value } : prev)}
                                fullWidth
                            />
                        ) : (
                            row.scopeOfWorks
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <FormControl fullWidth>
                                <Select
                                    size='small'
                                    value={editingRowData?.repairCondition}
                                    onChange={(e) => setEditingRowData(prev => prev ? { ...prev, repairCondition: e.target.value } : prev)}
                                >
                                    {repairConditions.map(condition => (
                                        <MenuItem key={condition} value={condition}>{condition}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <Typography variant="body1" textAlign="left">
                                {row.repairCondition}
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <TextField
                                type="number"
                                size='small'
                                value={editingRowData?.repair ?? 0}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const newValue = inputValue.trim() === "" ? 0 : parseFloat(inputValue);
                                    setEditingRowData(prev => prev ? { ...prev, repair: newValue } : prev);
                                }}
                                fullWidth
                            />
                        ) : (
                            <Typography variant="body1" textAlign="left">
                                {row.repair.toFixed(2)}
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <TextField
                                type="number"
                                size='small'
                                value={editingRowData?.replace ?? 0}
                                onChange={(e) => {
                                    const newValue = e.target.value === '' ? 0 : parseFloat(e.target.value);
                                    setEditingRowData(prev => prev ? { ...prev, replace: newValue } : prev);
                                }}
                                fullWidth
                            />
                        ) : (
                            <Typography variant="body1" textAlign="left">
                                {row.replace.toFixed(2)}
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <TextField
                                type="number"
                                size="small"
                                value={editingRowData?.painting}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    const newValue = input.trim() === "" ? 0 : parseFloat(input);
                                    setEditingRowData(prev => prev ? { ...prev, painting: newValue } : prev);
                                }}
                                fullWidth
                            />
                        ) : (
                            <Typography variant="body1" textAlign="left">
                                {row.painting.toFixed(2)}
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <TextField

                                size='small'
                                value={editingRowData?.laborRemarks}
                                onChange={(e) => setEditingRowData(prev => prev ? { ...prev, laborRemarks: e.target.value } : prev)}
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                            />
                        ) : (
                            <Typography variant="body1" textAlign="left">
                                {row.laborRemarks}
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>
                        {total.toFixed(2)}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <>
                                <IconButton color="primary" onClick={handleSaveEdit}>
                                    <CheckIcon />
                                </IconButton>
                                <IconButton color="error" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <IconButton color="primary" onClick={() => handleEditRow(row)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton color="error" onClick={() => handleDeleteRow(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        )}
                    </TableCell>
                </TableRow>
            );
        });
    }, [rows, editingRowId, editingRowData, handleDeleteRow]);

    // Handlers for editing additional cost fields
    const handleEditAdditionalField = (field: keyof AdditionalCosts) => {
        setEditingField(field);
        setTempFieldValue(additionalCosts[field]);
    };

    const handleCancelAdditionalEdit = () => {
        setEditingField(null);
        setTempFieldValue(0);
    };

    const handleSaveAdditionalEdit = async () => {
        if (editingField !== null && deId) {
            try {
                await updateDedetailsByField(accessToken, Number(deId), editingField, tempFieldValue);
                setAdditionalCosts(prev => ({
                    ...prev,
                    [editingField]: tempFieldValue,
                }));
                setEditingField(null);
                setTempFieldValue(0);
            } catch (error) {
                console.error(`Error updating ${editingField}:`, error);
            }
        }
    };

    return (
        <div>
            <Card style={{ width: "100%", padding: "20px" }}>
                <br />
                <Typography variant="h5" className="tp-text-header-primary" textAlign="center">
                    List of Labors
                </Typography>
                <br />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <TextField
                            label="Scope of Work"
                            value={scopeOfWorks}
                            onChange={(e) => setScopeOfWorks(e.target.value)}
                            variant="outlined"
                            size='small'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel>Repair Condition</InputLabel>
                            <Select
                                size='small'
                                value={repairCondition}
                                label="Repair Condition"
                                onChange={e => setRepairCondition(e.target.value)}
                            >
                                {repairConditions.map(condition => (
                                    <MenuItem key={condition} value={condition}>{condition}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Repair"
                            type="number"
                            size="small"
                            value={repair}
                            onChange={e => {
                                const value = e.target.value.trim();
                                setRepair(value === '' ? 0 : parseFloat(value));
                            }}
                            fullWidth
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Replace"
                            type="number"
                            size='small'
                            value={replace}
                            onChange={e => {
                                const value = e.target.value.trim();
                                setReplace(value === '' ? 0 : parseFloat(value));
                            }}
                            fullWidth
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            label="Painting"
                            type="number"
                            size='small'
                            value={painting}
                            onChange={e => {
                                const value = e.target.value.trim();
                                setPainting(value === '' ? 0 : parseFloat(value));
                            }}
                            fullWidth
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Remarks"
                            size='small'
                            value={laborRemarks}
                            onChange={e => setLaborRemarks(e.target.value)}
                            fullWidth

                        />
                    </Grid>
                    <Grid item xs={1} style={{ alignItems: "center", textAlign: "right" }}>
                        {/* Updated Add Labor Button with Disabled Prop */}
                        <Button
                            variant="contained"
                            size='small'
                            color='primary'
                            onClick={handleAddLabor}
                            disabled={!scopeOfWorks.trim() || !repairCondition} // Disable when fields are empty
                        >
                            Add Labor
                        </Button>
                    </Grid>
                </Grid>
                <hr /><br />
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Scope of Works</TableCell>
                                <TableCell>Repair Condition</TableCell>
                                <TableCell>Repair</TableCell>
                                <TableCell>Replace</TableCell>
                                <TableCell>Painting</TableCell>
                                <TableCell>Remarks</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderTableRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                {/* Additional Costs Section */}
                <Accordion sx={{ marginTop: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Additional Costs</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {Object.entries(additionalCosts).map(([field, value]) => {
                                const fieldKey = field as keyof AdditionalCosts;
                                const isEditingCurrentField = editingField === fieldKey;
                                const labelMap: { [key in keyof AdditionalCosts]: string } = {
                                    tinsmith: "Tinsmith Cost",
                                    paintwork: "Paintwork Cost",
                                    materials: "Materials Cost",
                                    reform: "Reform Cost",
                                    outsideWorks: "Outside Work Cost",
                                    airconWorks: "Aircon Works Cost",
                                    glassWorks: "Glass Works Cost",
                                    upholsteryWorks: "Upholstery Works Cost",
                                    mechanicalWorks: "Mechanical Cost",
                                    electricalWorks: "Electrical Cost",
                                    laborAdjustment: "Price Adjustment (Labor)",
                                    miscellaneousCost: "Miscellaneous Cost"
                                };

                                return (
                                    <Grid item xs={12} sm={6} md={6} key={field}>
                                        <Box display="flex" alignItems="center">
                                            {isEditingCurrentField ? (
                                                <>
                                                    <Typography variant="body1" color="primary" sx={{ flexGrow: 1 }}>
                                                        {labelMap[fieldKey]}:
                                                    </Typography>
                                                    <TextField
                                                        type="number"
                                                        value={tempFieldValue}
                                                        onChange={(e) => setTempFieldValue(parseFloat(e.target.value))}
                                                        size="small"
                                                        sx={{ width: '300px', marginLeft: '15px', marginRight: '8px' }}
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                    />
                                                    <IconButton color="primary" onClick={handleSaveAdditionalEdit}>
                                                        <CheckIcon />
                                                    </IconButton>
                                                    <IconButton color="error" onClick={handleCancelAdditionalEdit}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                                                        {labelMap[fieldKey]}: ₱ {formatNumber(value)}
                                                    </Typography>
                                                    <IconButton color="primary" onClick={() => handleEditAdditionalField(fieldKey)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <br />
                <FormControl fullWidth>
                    <InputLabel>Labor Rate</InputLabel>
                    <Select
                        value={laborRate || 500}
                        onChange={handleLaborRateChange}
                        label="Labor Rate"
                        disabled={loadingLaborRates || updatingLaborRate}
                        startAdornment={
                            (loadingLaborRates || updatingLaborRate) && <CircularProgress size={20} />
                        }
                    >
                        {laborRateOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {laborRateError && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {laborRateError}
                    </Alert>
                )}
                {updateLaborRateError && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {updateLaborRateError}
                    </Alert>
                )}
                <Grid container marginTop="25px">
                    <Grid item md={5} />
                    <Grid item md={2}>
                        <Typography variant="body1">
                            Repair Labor :
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant="body1">
                            <b style={{ color: "navy" }}>₱ {formatNumber(laborData.repairLabor)}</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={5} />
                    <Grid item md={2}>
                        <Typography variant="body1">
                            Painting Labor :
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant="body1">
                            <b style={{ color: "navy" }}>₱ {formatNumber(laborData.paintingLabor)}</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={5} />
                    <Grid item md={2}>
                        <Typography variant="body1">
                            Shop Materials :
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant="body1">
                            <b style={{ color: "navy" }}>₱ {formatNumber(laborData.shopMaterials)}</b>
                        </Typography>
                    </Grid>
                </Grid>



                <Grid container>
                    <Grid item md={5} />
                    <Grid item md={2}>
                        <Typography variant="body1">
                            Additional Costs :
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant="body1">
                            <b style={{ color: "navy" }}>₱ {formatNumber(laborData.additionalCostsTotal)}</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={5} />
                    <Grid item md={2}>
                        <Typography variant="body1">
                            <b>Total Labor :</b>
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant="body1">
                            <b style={{ color: "blue" }}>₱ {formatNumber(laborData.laborPlusVAT)}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}
