import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";
import { capitalizeAndReplaceUnderscore } from "../utils/stringUtils";

const themeLight = createTheme({
  palette: {
    background: { default: "#F7FBFC" },
    primary: { main: "#1C1364", contrastText: "#fff" },
    warning: { main: "#ddbc19", contrastText: "#fff" },
    error: { main: "#a42a25", contrastText: "#fff" },
  },
  typography: {
    fontFamily: '"Poppins", "system-ui", "sans-serif"',
  },
});

interface MainState {
  apiError: string;
  formError: string;
  isLoading: boolean;
}

interface User {
  id: string;
  name?: string;
  firstName: string;
  lastName: string;
  username?: string;
}

export default function TaskFormPage() {
  const navigate = useNavigate();
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [mainState, setMainState] = useState<MainState>({
    apiError: "",
    formError: "",
    isLoading: false,
  });
  const [formValues, setFormValues] = useState({
    date: "",
    assignedTo: "",
    description: "",
    status: "",
    priority: "",
    subject: "",
    role: "",
    acap_task_id: "",
    doc_validation_id: "",
    name: globalData.firstName + " " + globalData.lastName,
  });
  const { createAuditTrailData } =
      React.useContext(AuditTrailDataContext);

  const [users, setUsers] = useState<User[]>([]);
  const [acapIds, setAcapIds] = useState([]);
  const [docValidationStatus, setDocValidationStatus] = useState([
    {
      id: 115,
      transId: 533,
      perilCode: 201,
      perilType: "201 - COMPULSORY TPL - BODILY INJURY",
      docComplete: 0,
      status: 18,
    },
  ]);
  const todayDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const fetchUsersByRole = async () => {
      if (formValues.role) {
        let selfData: any = JSON.parse(
          secureLocalStorage.getItem("me") as string
        );

        if (formValues.role === "SELF") {
          let selfData: any = JSON.parse(
            secureLocalStorage.getItem("me") as string
          );
          setUsers([
            {
              id: selfData.id,
              firstName: selfData.firstName,
              lastName: selfData.lastName,
            },
          ]);
        } else {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/accounts/all?rolename=${formValues.role}`,
              {
                headers: {
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            setUsers(response.data.data);
          } catch (error) {
            setMainState((prevState) => ({
              ...prevState,
              apiError: "Error fetching users. Please try again later.",
            }));
          }
        }
      } else {
        setUsers([]);
      }
    };

    fetchUsersByRole();
  }, [formValues.role]);

  const getACAPIds = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/allids`,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    );
    if (res?.data?.status) {
      setAcapIds(res?.data?.data);
    }
  };

  const getTransIds = async () => {
    const res: any = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${formValues.acap_task_id}`,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    );



    setDocValidationStatus(res?.data?.data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      !formValues.date ||
      !formValues.assignedTo ||
      !formValues.status ||
      !formValues.priority ||
      !formValues.subject ||
      !formValues.role
    ) {
      setMainState((prevState) => ({
        ...prevState,
        formError: "All fields are required",
      }));
      return;
    }

    setMainState((prevState) => ({
      ...prevState,
      isLoading: true,
      formError: "",
      apiError: "",
    }));

    const payload = {
      assignTo: formValues.assignedTo,
      dueDate: formValues.date,
      description: formValues.description,
      subject: formValues.subject,
      status: formValues.status,
      priority: formValues.priority,
      role: formValues.role,
      acap_task_id: formValues.acap_task_id,
      doc_validation_id: formValues.doc_validation_id,
      name: globalData.firstName + " " + globalData.lastName,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/open-task`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      alert("Form submitted successfully!");
      setFormValues({
        date: "",
        assignedTo: "",
        description: "",
        status: "",
        priority: "",
        subject: "",
        role: "",
        acap_task_id: "",
        doc_validation_id: "",
        name: globalData.firstName + " " + globalData.lastName,
      });

      const getAssignedTo = users.find((el) => el.id === formValues.assignedTo);

      createAuditTrailData(
        globalData!.accessToken!,
        {
        transId: Number(formValues.acap_task_id),
        taskType: "document_validation",
        event: "add_open_task",
        description: `Task trans-id #${formValues.acap_task_id} is assigned to ${getAssignedTo?.firstName} ${getAssignedTo?.lastName} (${getAssignedTo?.username}) with description of "${formValues.description}"`,
        user: globalData.username,
        fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
        roleLevel: globalData.role
      })

      // Navigate to root route
      navigate("/");
    } catch (error) {
      setMainState((prevState) => ({
        ...prevState,
        apiError: "Error submitting form. Please try again later.",
      }));
    } finally {
      setMainState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleChange = (field: keyof typeof formValues, value: any) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    getACAPIds();
  }, []);

  useEffect(() => {
    if (formValues.acap_task_id) {
      getTransIds();
    }
  }, [formValues?.acap_task_id]);

  return (
    <ThemeProvider theme={themeLight}>
      <Container component="main" maxWidth="xl" sx={{ height: "110vh" }}>
        <CssBaseline />
        {/* Back Button */}
        <IconButton
          onClick={() => window.history.back()} // Navigate back
          disableRipple={true}
          aria-label="open"
          sx={{
            my: 2,
            padding: 0,
            paddingTop: 12,
          }}
        >
          <ArrowBackIcon sx={{ height: 28, width: 28 }} />
          <Typography sx={{ ml: 1 }}>Back</Typography>
        </IconButton>

        {/* Card Layout */}
        <Card sx={{ minWidth: 275, my: 2 }}>
          <CardContent className="tp-padding-50px">
            <Typography variant="h4" className="tp-text-header-primary">
              Task Form
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 2 }}
            >
              {mainState.formError && (
                <Alert severity="error" sx={{ width: "100%" }}>
                  {mainState.formError}
                </Alert>
              )}
              <Grid container spacing={2} className="tp-mb-20px">
                <Grid item md={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Due Date
                      <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      type="date"
                      value={formValues.date}
                      onChange={(e) => handleChange("date", e.target.value)}
                      error={!formValues.date}
                      inputProps={{
                        "aria-label": "Select Date",
                        min: todayDate,
                      }}
                    />
                    {!formValues.date && (
                      <FormHelperText error>Select a date</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Role
                      <span className="tp-text-required">*</span>
                    </Typography>
                    <Select
                      value={formValues.role}
                      onChange={(e) => handleChange("role", e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Role",
                      }}
                      error={!formValues.role}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {/* <MenuItem value="SELF">SELF</MenuItem> */}
                      <MenuItem value={"Processor"}>
                        Registration Processor
                      </MenuItem>
                      <MenuItem value={"Admin"}>System Admin</MenuItem>
                      <MenuItem value={"Clec"}>Senior Clec</MenuItem>
                      {/* <MenuItem value={"Isip Processor"}>Isip Processor</MenuItem>
                                          <MenuItem value={"Isip Manager"}>Isip Manager</MenuItem> */}
                      <MenuItem value={"Jr Clec"}>Jr Clec</MenuItem>
                      <MenuItem value={"Head Clec"}>Head Clec</MenuItem>
                      <MenuItem value={"Imsi Encoder"}>Imsi Encoder</MenuItem>
                      <MenuItem value={"Imsi Admin"}>Imsi Admin</MenuItem>
                      <MenuItem value={"Canvasser"}>Canvasser</MenuItem>
                      <MenuItem value={"Claims Assistant"}>
                        Claims Assistant
                      </MenuItem>
                      <MenuItem value={"Claims Officer Approver"}>
                        Claims Officer Approver
                      </MenuItem>
                      <MenuItem value={"Claims Head Approver"}>
                        Claims Head Approver
                      </MenuItem>
                      <MenuItem value={"Head Motor Claims"}>
                        Head Motor Claims
                      </MenuItem>
                      <MenuItem value={"Head Claims"}>Head Claims</MenuItem>
                      <MenuItem value={"Call Center"}>Call Center</MenuItem>
                      <MenuItem value={"Help Desk"}>Help Desk</MenuItem>
                      <MenuItem value={"Claims Admin"}>Claims Admin</MenuItem>
                    </Select>
                    {!formValues.role && (
                      <FormHelperText error>Select a role</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Assign To
                      <span className="tp-text-required">*</span>
                    </Typography>
                    <Select
                      value={formValues.assignedTo}
                      onChange={(e) =>
                        handleChange("assignedTo", e.target.value)
                      }
                      displayEmpty
                      inputProps={{
                        "aria-label": "Assign To",
                      }}
                      error={!formValues.assignedTo}
                      disabled={!formValues.role}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {`${user.firstName} ${user.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                    {!formValues.assignedTo && (
                      <FormHelperText error>Select an assignee</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="tp-mb-20px">
                <Grid item md={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Status
                      <span className="tp-text-required">*</span>
                    </Typography>
                    <Select
                      value={formValues.status}
                      onChange={(e) => handleChange("status", e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Status",
                      }}
                      error={!formValues.status}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Complited">Completed</MenuItem>
                    </Select>
                    {!formValues.status && (
                      <FormHelperText error>Select a status</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Subject
                      <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      multiline
                      rows={1}
                      value={formValues.subject}
                      onChange={(e) => handleChange("subject", e.target.value)}
                      placeholder="Enter Subject here..."
                      inputProps={{
                        "aria-label": "Subject",
                      }}
                    />
                    {!formValues.subject && (
                      <FormHelperText error>Select a subject</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Priority
                      <span className="tp-text-required">*</span>
                    </Typography>

                    <Select
                      value={formValues.priority}
                      onChange={(e) => handleChange("priority", e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Priority",
                      }}
                      error={!formValues.priority}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Normal">Normal</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                    </Select>
                    {!formValues.priority && (
                      <FormHelperText error>Select a priority</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={4}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Trans - ID
                      {/* <span className="tp-text-required">*</span> */}
                    </Typography>
                    <Autocomplete
                      disablePortal
                      style={{ width: "100%" }}
                      options={acapIds}
                      sx={{ width: 300 }}
                      onChange={(e, newValue) => handleChange("acap_task_id", newValue)}
                      popupIcon={null}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {/* {!formValues.acap_id && (
                      <FormHelperText error>Search a Trans ID</FormHelperText>
                    )} */}
                  </FormControl>
                </Grid>

                {formValues.acap_task_id && (
                  <Grid item md={4}>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <Typography variant="body1" className="tp-text-bold">
                        Document Validation Status
                        <span className="tp-text-required">*</span>
                      </Typography>
                      <Select
                        value={formValues.doc_validation_id}
                        onChange={(e) =>
                          handleChange("doc_validation_id", e.target.value)
                        }
                        displayEmpty
                        inputProps={{
                          "aria-label": "doc_validation_id",
                        }}
                        error={!formValues.doc_validation_id}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {docValidationStatus.length > 0 &&
                          docValidationStatus.map(
                            (docValStatus: any, index) => {
                              return (
                                <MenuItem value={docValStatus?.id}>
                                  {docValStatus?.perilType}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                      {!formValues.doc_validation_id && (
                        <FormHelperText error>
                          Select a document validation id
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              <FormControl variant="outlined" fullWidth margin="normal">
                <Typography variant="body1" className="tp-text-bold">
                  Description
                </Typography>
                <OutlinedInput
                  multiline
                  rows={4}
                  value={formValues.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  placeholder="Enter description here..."
                  inputProps={{
                    "aria-label": "Description",
                  }}
                />
              </FormControl>

              <Button
                type="submit"
                fullWidth
                disabled={mainState.isLoading}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                {mainState.isLoading ? (
                  <CircularProgress color="inherit" size="1.5em" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}


// {"data":{"claims":{"claimRiskNumber":"1","policyNumber":"DP742960","claimStatus":"Pending In-house Survey (Clec)","dateOccurs":"2021-10-13","claimDescription":"Bumped a pedestrian","dateReported":"2025-02-26","subrogation":"N","claimMajoreventCode":"","balanceOutstanding01":"₱2.00","balanceOutstanding02":"₱4.00","balanceOutstanding03":"₱26.00","balanceOutstanding04":"₱2254.00","balanceOutstanding05":"","balanceOutstanding06":"","balanceOutstanding07":"","balanceOutstanding08":"","balanceOutstanding09":"","balanceOutstanding10":"","premiumClass01":"202","premiumClass02":"202","premiumClass03":"206","premiumClass04":"206","premiumClass05":"","premiumClass06":"","premiumClass07":"","premiumClass08":"","premiumClass09":"","premiumClass10":"","reserveCode01":"LA","reserveCode02":"PA","reserveCode03":"LA","reserveCode04":"PA","reserveCode05":"","reserveCode06":"","reserveCode07":"","reserveCode08":"","reserveCode09":"","reserveCode10":"","claimFilingapprovaltime":"2025-02-26 13:40:27"}},"signature":"f3oLB+9w19WnBFl8LE7NkdLg5klRZPK0X8WmtS4sL0yItGwxyRSHASAdQ4k9mmnC1rIai/jvRiWumqBp4baIT7Kd+gnKh/IGBq2yZxRAix/rNgQNazsQlSHVHRQEGfRmh3VwJNLkFAirGyrYQiZr6FqhbMqcA4blOB5a0gQjE0Ng1OBB+pHP7bfICuQzQO4GXUN+SdWm++kUDe8/ul+2YgVP+ApDBC7yyD+yZSlsArlsedANVzxEHhj9oesENjY5rLwLSwq3AQZzNGvb/r3tBMoAQeQH5Ej+0V4Nc3SNbd6azRhIQpOdX+tEUWUEILanx6xo3CSJu8JquuzBzOKZyw=="}