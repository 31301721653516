import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CNCValidationTask from "./components/CNCValidationTask";
// import DamageEvaluationLink from "./components/DamageEvaluationLink";
import DamageEvaluationTaskWithProvider from "./components/DamageEvaluationTaskWithProvider";
import DocumentValidationTask from "./components/DocumentValidationTask";
import Header from "./components/Header";
import AddUser from "./components/user_management/AddUser";
import EditUser from "./components/user_management/EditUser";
import ForgotPassword from "./components/user_management/ForgotPassword";
import ResetPassword from "./components/user_management/ResetPassword";
import { GlobalDataContext } from "./contexts/GlobalDataContext";
import CNCPage from "./pages/CNCPage";
import DECreateTask from "./pages/DECreateTask";
import DEPage from "./pages/DEPage";
import DocumentValidationPage from "./pages/DocumentValidationPage";
import HomePage from "./pages/HomePage";
import HomePageIsip from "./pages/HomePageIsip";
import HomePageIsipManager from "./pages/HomePageIsipManager";
import InvoiceApprovalPage from "./pages/InvoiceApprovalPage";
import InvoiceValidationPage from "./pages/InvoiceValidationPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import PolicyEnquiryPage from "./pages/PolicyEnquiryPage";
import PrivateRoute from './pages/PrivateRoute';
import TaskPageApproval from "./pages/TaskPageApproval";
import WebFormPage from "./pages/WebFormPage";
import UserManagementPage from "./pages/UserManagementPage";
import HomePageAdmin from "./pages/HomePageAdmin";
import IsipDocumentValidationTask from "./components/isip_components/IsipDocumentValidationTask";
import DoVerificationList from "./pages/DoVerificationList";

// import { useCheckSessionOnNavigation } from './pages/useCheckSessionOnNavigation';

import RoleNotAuthorized from "./components/RoleNotAuthorized"; // Import the new component
import TaskDetailPage from "./components/TaskDetailPage";
import TaskFormPage from "./pages/Task";
import TaskList from "./pages/TaskList";
// import TaskPage from "./pages/TaskPage";
import TaskTable from "./components/TaskTable";
// import SelfCreateUser from "./components/user_management/SelfCreateUser";
import ActivityTrailPage from "./pages/ActivityTrailPage";
import BusinessRulePage from "./pages/BusinessRulePage";
import HomePageImsi from "./pages/HomePageImsi";
import ImsiApprovalPage from "./pages/ImsiApprovalPage";
import ImsiTaskWithProvider from "./components/ImsiTaskWithProvider";
import HomePageCanvasser from "./pages/HomePageCanvasser";
import CanvasserTaskWithProvider from "./components/CanvasserTaskWithProvider";
import DocumentValidationNoPolicyPage from "./pages/DocumentValidationNoPolicyPage";
import Notification from "./pages/Notification";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./pages/ChangePassword";
import ValidateMfa from "./pages/ValidateMfa";
import SetupMfa from "./pages/SetupMfa";
import UserTaskManagementPage from "./pages/UserTaskManagementPage";
import EditUserTask from "./components/user_management/EditUserTask";
import RepairShopManagementPage from "./pages/RepairShopManagementPage";
import EditDealer from "./components/repair_shop_management/EditDealer";
import AddDealer from "./components/repair_shop_management/AddDealer";
// import ShopListManagement from "./components/repair_shop_management/ShopListManagement";
import EditShop from "./components/repair_shop_management/EditShop";
import AddShop from "./components/repair_shop_management/AddShop";
import LaborRatesManagementPage from "./pages/LaborRatesManagementPage";
import EditLabor from "./components/labor_rate_management/EditLabor";
import AddLabor from "./components/labor_rate_management/AddLabor";
import AcapTaskManagementPage from "./pages/AcapTaskManagementPage";
import InsurerReportPage from "./pages/InsurerReportPage";
import axios from "axios";
import CncHistoryPublicPage from "./pages/CncHistoryPublicPage";

function App() {
  const { globalData, updateGlobalData } = React.useContext(GlobalDataContext);
  // const [totalRepairersAmount, setTotalRepairersAmount] =
  //   React.useState(3900.0);
  // const [totalLaborAmount, setTotalLaborAmount] = React.useState(5000.0);

  const [isLoading, setIsLoading] = React.useState(true);
  const idTokenlocal = secureLocalStorage.getItem('idToken') as string;
  const role = JSON.parse(secureLocalStorage.getItem('role') as string);
  const firstName = JSON.parse(secureLocalStorage.getItem('firstName') as string);
  const lastName = JSON.parse(secureLocalStorage.getItem('lastName') as string);
  const username = JSON.parse(secureLocalStorage.getItem('username') as string);
  const accessTokenlocal = JSON.parse(secureLocalStorage.getItem('accessToken') as string);
  const refreshTokenlocal = JSON.parse(secureLocalStorage.getItem('refreshToken') as string);
  const lastLogin = JSON.parse(secureLocalStorage.getItem('lastLogin') as string);
  const lastLogout = JSON.parse(secureLocalStorage.getItem('lastLogout') as string);
  const status = JSON.parse(secureLocalStorage.getItem('status') as string);
  const tokenExpirationlocal = JSON.parse(secureLocalStorage.getItem('tokenExpiration') as string);

  const navigate = useNavigate();

  const getNewToken = async () => {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/authentication/refresh/token`,
      {
        username: username,
        refreshToken: refreshTokenlocal
      })
      .then((res: any) => {
        // if success then replace existing token with the new
        console.log("getNewToken",res)
        const { idToken, accessToken, refreshToken } = res?.data?.data;
        const arrayToken = idToken?.split('.');
        const userData = JSON.parse(atob(arrayToken[1]));
        const tokenExpiration = userData['exp'];

        console.log("newGlobalData: ", {
          ...globalData,
          idToken: null,
          accessToken: accessToken,
          refreshToken: refreshToken,
          tokenExpiration: tokenExpiration
        })

        updateGlobalData({
          ...globalData,
          idToken: null,
          accessToken: accessToken,
          refreshToken: refreshToken,
          tokenExpiration: tokenExpiration
        });

        secureLocalStorage.setItem('idToken', JSON.stringify(idToken));
        secureLocalStorage.setItem('accessToken', JSON.stringify(accessToken));
        secureLocalStorage.setItem('refreshToken', JSON.stringify(refreshToken));
        secureLocalStorage.setItem('tokenExpiration', JSON.stringify((tokenExpiration)));
      })
      .catch((err: any) => {
        // if not successful then force logout and redirect to login
        updateGlobalData({
          firstName: null,
          lastName: null,
          role: "",
          username: null,
          idToken: null,
          isUserLogOut: true,
          lastLogin: null,
          lastLogout: null,
          tokenExpiration: null
        });

        secureLocalStorage.removeItem('username');
        secureLocalStorage.removeItem('role');
        secureLocalStorage.removeItem('idToken');
        secureLocalStorage.removeItem('accessToken');
        secureLocalStorage.removeItem('lastLogin');
        secureLocalStorage.removeItem('lastLogout');
        secureLocalStorage.removeItem('status');
        secureLocalStorage.removeItem('tokenExpiration');

        navigate('/');
      })
  }

  React.useEffect(() => {
    const fetchUser = () => {
      updateGlobalData({
        firstName,
        lastName,
        role,
        username,
        idToken: idTokenlocal,
        accessToken: accessTokenlocal,
        refreshToken: refreshTokenlocal,
        lastLogin,
        lastLogout,
        status,
        tokenExpiration: tokenExpirationlocal
      });
      setIsLoading(false);
    };



    fetchUser();

    const checkTokenExpiration = () => {
      const currentTime = Math.floor(new Date().getTime() / 1000);

      if (tokenExpirationlocal && currentTime > tokenExpirationlocal) {
        
        // get new tokens and replace existing data
        // so that it does not force logout
        getNewToken()
      }
    }

    const intervalId = setInterval(checkTokenExpiration, 60000);
    checkTokenExpiration();

    return () => clearInterval(intervalId);
  }, [tokenExpirationlocal, navigate, updateGlobalData]);



  const themeLight = createTheme({
    palette: {
      background: {
        default: "#F7FBFC",
      },
      primary: {
        main: "#1C1364", // Replace this with your desired primary color
        contrastText: "#fff", // This is the text color that appears on primary buttons
      },
      warning: {
        main: "#ddbc19", // Replace this with your desired primary color
        contrastText: "#fff", // This is the text color that appears on primary buttons
      },
      error: {
        main: "#a42a25", // Replace this with your desired primary color
        contrastText: "#fff", // This is the text color that appears on primary buttons
      }
    },
    typography: {
      fontFamily: '"Poppins", "system-ui", "sans-serif"', // Use System-ui with sans-serif as fallback
    },
  });

  // Helper function to check authentication
  // const isAuthenticated = () => {
  //   return globalData?.token && globalData.role;
  // };

  // Get the current location to use for redirection after login
  const location = useLocation();

  // Redirect to login page if not authenticated
  if (!globalData.role && !isLoading && !globalData.accessToken) {
    return (
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="validate-mfa" element={<ValidateMfa />} />
        <Route path="setup-mfa" element={<SetupMfa />} />
        <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
        {/* <Route path="self-create-user" element={<SelfCreateUser />} /> */}
        <Route path="bpims-web-form" element={<WebFormPage />} />
        <Route path="business-rules" element={<BusinessRulePage />} />
        <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
        <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
        {/* Redirect to login and save the current path to redirect after login */}
        <Route
          path="*"
          element={<Navigate to="/" state={{ from: location }} />}
        />
      </Routes>
    );
  }

  const renderRoutesForRole = (role: string) => {
    switch (role) {
      case "Processor":
        return (
          <>
            <Route index element={<HomePage exclude={["imsi-approval"]} />} />
            <Route path="add-task" element={<TaskFormPage />} />
            <Route path="task-list" element={<TaskList />} />
            <Route path="task-table" element={<TaskTable task="taskType" />} />
            <Route path="/task/:id" element={<TaskDetailPage />} />
            <Route path="/notification" element={<Notification />} />


            <Route path="/verification-status" element={<DoVerificationList />} />
            <Route
              path="document-validation"
              element={<DocumentValidationPage />}
            />
            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />
            <Route path="damage-evaluation" element={<DEPage />} />
            <Route
              path="damage-evaluation/:transId/:deId"
              element={<DamageEvaluationTaskWithProvider />}
            />
            <Route path="cnc" element={<CNCPage />} />
            <Route path="cnc/:transId" element={<CNCValidationTask />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Call Center":
        return (
          <>
            <Route index element={<HomePage exclude={["damage-evaluation", "imsi-approval", "cnc"]} />} />
            <Route path="add-task" element={<TaskFormPage />} />
            <Route path="task-list" element={<TaskList />} />
            <Route path="task-table" element={<TaskTable task="taskType" />} />
            <Route path="/task/:id" element={<TaskDetailPage />} />
            <Route path="/notification" element={<Notification />} />


            <Route path="/verification-status" element={<DoVerificationList />} />
            <Route
              path="document-validation"
              element={<DocumentValidationPage />}
            />
            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />
            {/* <Route path="damage-evaluation" element={<DEPage />} />
            <Route
              path="damage-evaluation/:transId/:deId"
              element={<DamageEvaluationTaskWithProvider />}
            /> */}
            {/* <Route path="cnc" element={<CNCPage />} />
            <Route path="cnc/:transId" element={<CNCValidationTask />} /> */}
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Claims Assistant":
        return (
          <>
            <Route index element={<HomePage exclude={["damage-evaluation", "imsi-approval", "cnc"]} />} />
            <Route
              path="document-validation"
              element={<DocumentValidationNoPolicyPage />}
            />
            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Claims Officer Approver":
        return (
          <>
            <Route index path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Admin":
        return (
          <>
            <Route index element={<HomePageAdmin />} />

            <Route path="user-management" element={<UserManagementPage />} />
            <Route path="user-management/add-user" element={<AddUser />} />
            <Route path="user-management/edit-user/:username" element={<EditUser />} />

            <Route path="activity-trail" element={<ActivityTrailPage />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Claims Admin":
        return (
          <>
            <Route index element={<HomePageAdmin />} />

            <Route
              path="document-validation"
              element={<DocumentValidationPage />}
            />

            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />

            <Route path="user-task-management" element={<UserTaskManagementPage />} />
            <Route path="user-task-management/edit-user-task/:userId" element={<EditUserTask />} />
            <Route path="add-task" element={<TaskFormPage />} />
            <Route path="task-list" element={<TaskList />} />
            <Route path="task-table" element={<TaskTable task="taskType" />} />
            <Route path="/task/:id" element={<TaskDetailPage />} />
            <Route path="/notification" element={<Notification />} />


            <Route path="/verification-status" element={<DoVerificationList />} />

            <Route path="user-management" element={<UserManagementPage />} />
            <Route path="user-management/add-user" element={<AddUser />} />
            <Route path="user-management/edit-user/:username" element={<EditUser />} />

            <Route path="repair-shop-management" element={<RepairShopManagementPage />} />
            <Route path="repair-shop-management/edit-dealer/:id" element={<EditDealer />} />
            <Route path="repair-shop-management/add-dealer" element={<AddDealer />} />
            <Route path="repair-shop-management/edit-shop/:id" element={<EditShop />} />
            <Route path="repair-shop-management/add-shop" element={<AddShop />} />

            <Route path="labor-rates-management" element={<LaborRatesManagementPage />} />
            <Route path="labor-rates-management/edit-labor/:id" element={<EditLabor />} />
            <Route path="labor-rates-management/add-labor" element={<AddLabor />} />

            <Route path="acap-task-management" element={<AcapTaskManagementPage />} />

            <Route path="activity-trail" element={<ActivityTrailPage />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Help Desk":
        return (
          <>
            <Route index element={<HomePageAdmin />} />

            <Route path="user-task-management" element={<UserTaskManagementPage />} />

            <Route path="user-management" element={<UserManagementPage />} />

            <Route path="repair-shop-management" element={<RepairShopManagementPage />} />

            <Route path="labor-rates-management" element={<LaborRatesManagementPage />} />

            <Route path="acap-task-management" element={<AcapTaskManagementPage />} />

            <Route path="activity-trail" element={<ActivityTrailPage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Jr Clec":
      case "Clec":
      case "Head Clec":
      case "Head Motor Claims":
      case "Head Claims":
        return (
          <>
            <Route index element={<HomePage exclude={["cnc"]} />} />
            <Route
              path="document-validation"
              element={<DocumentValidationPage />}
            />
            <Route
              path="imsi-approval"
              element={<ImsiApprovalPage />}
            />
            <Route
              path="imsi-approval/:transId/:deId"
              element={<ImsiTaskWithProvider />}
            />
            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />
            <Route path="damage-evaluation" element={<DEPage />} />
            <Route path="/create-task" element={<DECreateTask />} />
            <Route
              path="damage-evaluation/:transId/:deId"
              element={<DamageEvaluationTaskWithProvider />}
            />
            <Route path="/notification" element={<Notification />} />
            <Route
              path="damage-evaluation-link/:transId"
            // element={
            //   <PrivateRoute
            //     element={
            //       <DamageEvaluationLink
            //         // totalRepairersAmount={totalRepairersAmount}
            //         // setTotalRepairersAmount={setTotalRepairersAmount}
            //         // setTotalLaborAmount={setTotalLaborAmount}
            //         // totalLaborAmount={totalLaborAmount}
            //         selectedBrand={selectedBrand}
            //         selectedModel={selectedModel}
            //         onBrandModelUpdate={handleBrandModelUpdate}
            //       />
            //     }
            //   />
            // }
            />
            <Route path="add-task" element={<TaskFormPage />} />
            <Route path="task-list" element={<TaskList />} />
            <Route path="/task/:id" element={<TaskDetailPage />} />
            <Route path="/verification-status" element={<DoVerificationList />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="bpims-web-form-internal" element={<BusinessRulePage />} />
            <Route path="bpims-web-form" element={<WebFormPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Isip Processor":
        return (
          <>
            <Route index element={<HomePageIsip exclude={[]} />} />
            <Route
              path="invoice-validation"
              element={<InvoiceValidationPage />}
            />
            <Route
              path="invoice-validation/:transId"
              element={<IsipDocumentValidationTask />}
            />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Isip Manager":
        return (
          <>
            <Route index element={<HomePageIsipManager />} />
            <Route path="invoice-approval" element={<InvoiceApprovalPage />} />
            <Route
              path="invoice-approval/:transId"
              element={<TaskPageApproval />}
            />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Imsi Encoder":
        return (
          <>
            <Route index element={<HomePageImsi exclude={[]} />} />
            <Route
              path="imsi-approval"
              element={<ImsiApprovalPage />}
            />
            <Route
              path="imsi-approval/:transId/:deId"
              element={<ImsiTaskWithProvider />}
            />
            <Route path="add-task" element={<TaskFormPage />} />
            <Route path="task-list" element={<TaskList />} />
            <Route path="/task/:id" element={<TaskDetailPage />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Imsi Admin":
        return (
          <>
            <Route index element={<HomePageImsi exclude={[]} />} />
            <Route
              path="imsi-approval"
              element={<ImsiApprovalPage />}
            />
            <Route
              path="imsi-approval/:transId/:deId"
              element={<ImsiTaskWithProvider />}
            />
            <Route path="add-task" element={<TaskFormPage />} />
            <Route path="task-list" element={<TaskList />} />
            <Route path="/task/:id" element={<TaskDetailPage />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      case "Canvasser":
        return (
          <>
            <Route index element={<HomePageCanvasser exclude={["imsi-approval", "cnc"]} />} />
            <Route path="/verification-status" element={<DoVerificationList />} />
            <Route
              path="document-validation"
              element={<DocumentValidationPage />}
            />
            <Route
              path="document-validation/:transId"
              element={<DocumentValidationTask />}
            />
            <Route path="damage-evaluation" element={<DEPage />} />
            <Route
              path="damage-evaluation/:transId/:deId"
              element={<CanvasserTaskWithProvider />}
            />
            <Route path="add-task" element={<TaskFormPage />} />
            <Route path="task-list" element={<TaskList />} />
            <Route path="/task/:id" element={<TaskDetailPage />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="policyenquiry" element={<PolicyEnquiryPage />} />
            <Route path="business-rules" element={<BusinessRulePage />} />
            <Route path="completed-in-house-survey" element={<InsurerReportPage />} />
            <Route path="bpims-web-form-internal" element={<WebFormPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="not-found" element={<NotFoundPage />} />
            <Route path="cnc-history-public" element={<CncHistoryPublicPage />} />
          </>
        );
      default:
        return <Route path="*" element={<RoleNotAuthorized />} />;
    }
  };

  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <Header />
      <Routes>
        {!isLoading && globalData?.role && globalData?.status === "1" ? (
          renderRoutesForRole(globalData.role)
        ) : (
          <Route path="/" element={<RoleNotAuthorized />} />
        )}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
