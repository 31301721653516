import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Menu,
  MenuItem,
  Modal,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Select as MuiSelect,
} from "@mui/material";
import axios from "axios";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ActionMeta, SingleValue } from "react-select";
import { formatDate } from "../api/FormatDate";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
// import FieldChecklistClec from "./processor_validation/FieldChecklistClec";
import { DEDetails as DEDetailsType } from "./DEDetails";


import { IImsiTask } from "../types/ImsiTask";
import { IRepairShop } from "../types/RepairShops";
import { IUserDetail } from "../types/UserDetail";
import TaskRemarks from "./activity_logs/TaskRemarks";
import CanvasserClientTransaction from "./damage_evaluation/CanvasserClientTransaction";
import DocumentViewer from "./document_validation/DocumentViewer";
import { IOpenTask } from "../types/OpenTask";
import InfoIcon from '@mui/icons-material/Info';
import DocumentTable from "./DocumentTable";
import { CartItemCT } from "../types/Cart";


// Utility function
function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DamageEvaluationTask({
  // totalRepairersAmount,
  // setTotalRepairersAmount,
  // totalLaborAmount,
  // setTotalLaborAmount,
  selectedBrand,
  selectedModel,
  onBrandModelUpdate,
}: {
  // totalRepairersAmount: number;
  // setTotalRepairersAmount: React.Dispatch<React.SetStateAction<number>>;
  // setTotalLaborAmount: React.Dispatch<React.SetStateAction<number>>;
  // totalLaborAmount: number;
  selectedBrand: string | null;
  selectedModel: string | null;
  onBrandModelUpdate: (brand: string | null, model: string | null) => void;

}) {
  const { deId } = useParams();
  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(parsedValue)) {
      setter(parsedValue);
    }
  };

  type OptionType = { value: number; label: string };
  type CartItem = {
    id?: number;
    deId?: number;
    transId?: number;
    name: string;
    amount: number;
    qty: number;
    condition: 'Original' | 'Replacement Parts' | 'Surplus' | 'Taiwan Parts' | 'Not Standard Part' | 'No Price Established' | 'Estimated Price' | 'Subject to Availability' | 'As per sample' | 'No stock' | 'Standard operating procedure when replacing parts' | '';
    globalDepreciation: boolean;
    shopPrice: number;
    totalAmount: number;
    depreciation: number;
    discount: number;
    netAmount: number;
    shopAmount: boolean;
    remarks: string;
    saveDate?: string;
  };

  interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    saveDate?: string;
    transId?: string;
  }

  interface IShopItemTable {
    shopEstimate: {
      totalParts: string | null;
      totalLabors: string | null;
    } | null;
    headers: string[] | null;
    rows: Object[] | null;
  }

  interface SEDetails {
    data: {
      id: number;
      transId: number;
      vendorName: string;
      customerName: string | null;
      url: string;

      totalLabor: string | null;
      totalParts: string | null;
    }
  }

  interface IRepairShopDetails {
    data: string[];
    isShow: boolean;
  }

  const [ourOfferGrossTotal, setOurOfferGrossTotal] = React.useState<number>(0);
  const [totalDeductibleAmount, setTotalDeductibleAmount] =
    React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [laborRows, setLaborRows] = React.useState<LaborRow[]>([]);
  const [cart, setCart] = React.useState<CartItem[]>([]);
  const laborAndMaterials = laborPlusVat;
  const regularParts = totalAmount;
  const [lossReserveParts, setLossReserveParts] = React.useState<number>(0);
  const [lossReserveLabor, setLossReserveLabor] = React.useState<number>(0);
  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const { claimNo, docId } = location.state || {};
  const [vatValue, setVatValue] = React.useState<number>(0);
  const [claimNumber, setClaimNumber] = React.useState<string | null>(null);
  const [perilType, setPerilType] = React.useState<string | null>(null);
  const [deFromDb, setDeFromDb] = React.useState<any>({
    data: {},
  });
  const [shopItemTable, setShopItemTable] = React.useState<IShopItemTable>();
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [totalRepairersAmount, setTotalRepairersAmount] = React.useState(0);
  const [totalLaborAmount, setTotalLaborAmount] = React.useState(0);
  const [imsiAdminUsers, setImsiAdminUsers] = React.useState<IUserDetail[] | null>(null);
  const [assignedImsiAdmin, setAssignedImsiAdmin] = React.useState<IUserDetail | null | undefined>(null);
  const [imsiTask, setImsiTask] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openTaskHistoryAnchor = Boolean(anchorEl);
  const handleOpenTaskHistoryClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenTaskHistoryClose = () => {
    setAnchorEl(null);
  };
  const [openTasks, setOpenTasks] = React.useState<IOpenTask[]>([]);
  const [assignedUser, setAssignedUser] = React.useState<IUserDetail | undefined>({
    id: 0,
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    roleName: ""
  });
  const [users, setUsers] = React.useState<IUserDetail[]>([]);

  const [laborData, setLaborData] = React.useState({
    rows: [],
    laborRate: 410,
    totalTimeHours: 0,
    calculatedLaborCost: 0,
    paintWorkMaterials: 0,
    totalLaborCost: 0,
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [cartCTrans, setCartCTrans] = React.useState<CartItemCT[]>([]);
  const [vatCTrans, setVatCTrans] = React.useState<number>(0);
  const [totalDiscountCTrans, setTotalDiscountCTrans] = React.useState<number>(0);


  const insurerRef = React.useRef<any>(null);

  const handleExternalCall = () => {

    if (insurerRef.current) {
      insurerRef.current.generateAndUploadPdf(); // Call the exposed method
    }
  };

  const handleLaborDataChange = (newLaborData: any) => {
    setLaborData(newLaborData);
  };
  // const formatNumber = (value: number) => {
  //   return value.toLocaleString(undefined, {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
  // };

  const parseNumber = (value: string) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const [initialTotalParts, setTotalParts] = React.useState<number>(0);
  const [initialTotalLabor, setTotalLabor] = React.useState<number>(0);
  const [deDetails, setDeDetails] = React.useState<DEDetailsType | null>(null);
  const [shopEstimate, setShopEstimate] = React.useState<SEDetails | null>(null);
  const [dol, setDol] = React.useState<string | null>(null);

  // const handleUpdate = (parts: number, labor: number) => {
  //   setTotalParts(parts);
  //   setTotalLabor(labor / 1.12);
  // };

  const [isTotalLoss, setIsTotalLoss] = React.useState<boolean>(false);

  const handleIsTotalLossChange = (percentLoss: number) => {
    const newIsTotalLoss = percentLoss >= 75;
    setIsTotalLoss(newIsTotalLoss);
  };

  const updateTotalDeductible = (amount: number, totalNumber: number) => {
    const totalDeductible = totalNumber * 1;
    setTotalDeductibleAmount(totalDeductible);
  };

  const handleUpdateCart = (newCart: CartItem[]) => {
    setCart(newCart);
  };

  const updateGrossTotal = (amount: number, laborVat: number) => {
    setTotalAmount(amount);
    setLaborPlusVat(laborVat);
    const grossTotal = amount + laborVat;
    setOurOfferGrossTotal(grossTotal);
  };

  const [repairShopDetails, setRepairShopDetails] =
    React.useState<IRepairShopDetails>({
      data: [],
      isShow: false,
    });

  const [repairShops, setRepairShops] = React.useState<string[]>([]);
  const [vendorList, setVendorList] = React.useState<IRepairShop[]>([]);

   React.useEffect(() => {
      const fetchClaimRegistrationData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`,
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
          const data = await response.json();
          const premiumClasses = [
            data.data.premiumClass01,
            data.data.premiumClass02,
            data.data.premiumClass03,
            data.data.premiumClass04,
            data.data.premiumClass05,
            data.data.premiumClass06,
            data.data.premiumClass07,
            data.data.premiumClass08,
            data.data.premiumClass09,
            data.data.premiumClass10,
          ];
  
          setClaimNumber(data.data.claimNumber);
  
  
  
          if (premiumClasses.includes("202")) {
            setPerilType("Own Damage");
          } else if (premiumClasses.includes("203")) {
            setPerilType("Partial Theft");
          } else if (premiumClasses.includes("209")) {
            setPerilType("Acts Of Nature");
          } else if (premiumClasses.includes("206")) {
            setPerilType("Third Party");
          } else {
            setPerilType("N/A");
          }
  
  
  
  
        } catch (error) {
          console.error("Error fetching claim registration data:", error);
        }
      };
      fetchClaimRegistrationData();
    }, []);

  const handleShowRepairShopDetails = () => {

    const dataFound = vendorList.find((item: IRepairShop) => item.name === mainState?.data?.vendorName) || null;

    const dataList = dataFound !== null ? dataFound.remarks.split("\n") : [];

    setRepairShopDetails({
      data: dataList,
      isShow: true,
    });
  };

  // #region fetchClaimData



  // #region fetchRepairShops
  const fetchRepairShops = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/repair-shops`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {
            const data = res.data.data;

            const tdata = data.map((el: any) => {
              return el.name;
            });
            setRepairShops(tdata);
            setVendorList(data)
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // region fetchAllImsiAdminUsers
  // region fetchAllImsiAdminUsers
  const fetchAllUsers = async () => {
    try {
      const usersResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/available-users?userId=${globalData?.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );
      const usersData: IUserDetail[] = usersResponse?.data.data;

      setUsers(usersData);

      const openTasksResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/open-task/?transId=${transId}&deId=${deId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );
      const openTasksData: IOpenTask[] = openTasksResponse?.data?.data;
      setOpenTasks(openTasksData)


      if (openTasksData && usersData) {
        const selectedUserId = openTasksData[0].assignTo;
        const selectedUser = usersData?.find(
          (user: IUserDetail) => user?.id === Number(selectedUserId)
        );

        setAssignedUser(selectedUser);
      }
    } catch (err) {
      console.error("Error fetching Users:", err);
    }
  };

  const handleImsiAdminUserChange = (event: SelectChangeEvent<string>) => {
    const selectedUserId = event.target.value;
    const selectedUser: IUserDetail | undefined = users?.find((user: IUserDetail) => user?.id === Number(selectedUserId));


    setAssignedUser(selectedUser);
  };

  const createImsiTask = async () => {
    if (assignedImsiAdmin === null || assignedImsiAdmin === undefined) {
      alert("Select IMSI Admin user before submit");
      return;
    }

    const reqPayload: IImsiTask = {
      id: null,
      deId: Number(deId),
      transId: Number(transId),
      sender: null,
      taskType: null,
      peril: null,
      channel: null,
      priority: 0,
      priorityDesc: null,
      policyNo: null,
      claimNo: null,
      status: 1,
      submitted: null,
      userId: assignedImsiAdmin?.id
    }

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/imsi/custom/task`,
      reqPayload,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalData?.accessToken}`
        },
      }
    ).then((res: any) => {

      getImsiTask();
    }).catch((err: any) => {
      console.error(err);
    })
  }

  const getImsiTask = async () => {
    await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/imsi/task/${transId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalData?.accessToken}`
        },
      }
    ).then((res: any) => {

      setImsiTask(res?.data?.data)
    }).catch((err: any) => {
      console.error(err)
    })
  }

  React.useEffect(() => {
    const fetchDEDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data: DEDetailsType = await response.json();

        setDeDetails(data);
      } catch (err: any) {
        setError(err.message || 'An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchDEDetails();
    getImsiTask();
    fetchAllUsers();
  }, []);

  React.useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const claimResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`);
        // const claimData = claimResponse.data.data; // Removed unused variable
      } catch (error) {
        console.error("Error fetching claim data:", error);
      }
    };
    fetchClaimData();
  }, []);


  React.useEffect(() => {
    const fetchShopEstimate = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data: SEDetails = await response.json();

        setTotalRepairersAmount(parseNumber(data.data.totalParts || '0'));
        setTotalLaborAmount(parseNumber(data.data.totalLabor || '0'));
        setShopEstimate(data);
      } catch (err: any) {
        setError(err.message || 'An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };
    fetchRepairShops();

    fetchShopEstimate();
  }, []);



  // #region mainstate
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    statusOptions:
      globalData?.role === "Head Clec"
        ? [
          { value: 24, label: "Approve" },
          { value: 5, label: "For Review By Clec" },
          { value: 18, label: "Pending Adjuster's Report" },
        ]
        : isTotalLoss
          ? [
            { value: 4, label: "Forward To Head CLEC" },
            { value: 18, label: "Pending Adjuster's Report" }

          ]
          : [
            { value: 3, label: "Open" },
            { value: 9, label: "Pending for Offer" },
            // { value: 4, label: "Forward To Head CLEC" },
            { value: 18, label: "Pending Adjuster's Report" },

          ],
  });

  const [totalDepreciation, setTotalDepreciation] = React.useState(0);
  const [totalPartsAmount, setTotalOfferAmount] = React.useState(0);

  const handleTotalDepreciationChange = (newTotalDepreciation: number) => {
    setTotalDepreciation(newTotalDepreciation);
  };

  const handleTotalOfferAmountChange = (newTotalOfferAmount: number) => {
    setTotalOfferAmount(newTotalOfferAmount);
  };

  const [userData, setUserData] = React.useState<any>({});
  const [isDisable, setIsDisable] = React.useState<boolean>(false);
  const { transId } = useParams();
  const navigate = useNavigate();
  const [fmv, setFmv] = React.useState<number>(0);
  const [salvage, setSalvage] = React.useState<number>(100000);

  const handleSelectChange = (
    selection: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {


    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection?.value },
      selections: { status: selection },
    });
  };

  const fetchDeTaskData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/${transId}`
      );
      const data = res.data.data;
      const statusOptions =
        globalData?.role === "Head Clec"
          ? [
            { value: 9, label: "Pending for Offer" },
            { value: 18, label: "Pending Adjuster's Report" },
            { value: 5, label: "Reject" },
          ]
          : [
            { value: 9, label: "Pending for Offer" },
            { value: 18, label: "Pending Adjuster's Report" },
            { value: 4, label: "Forward To Head CLEC" },
            { value: 5, label: "Reject" },
          ];
      setDeFromDb({
        ...deFromDb,
        data: data,
      });
      setIsDisable(globalData?.role === "Clec" && data?.status === 4);
      setIsDisable(data?.submitted === 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const apiSubmitEmail = async (endpoint: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`);
    } catch (err: any) {
      console.error(err?.response?.data);
    }
  };



  // #region fetchShopItems
  // const fetchShopItems = async () => {
  //   try {
  //     (async () => {
  //       axios({
  //         method: "get",
  //         url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`,
  //         // headers: {
  //         //   'Authorization': `Bearer ${globalData?.sessionToken}`
  //         // }
  //       })
  //         .then((res: any) => {

  //           const data = res?.data?.data;
  //           const tblShopItemsJSON = JSON.parse(data?.tableItems);


  //           // Convert rows into a list of JSON objects
  //           const tblShopItemRows = tblShopItemsJSON?.rows.map((row: any) => {
  //             // Generate a unique ID for each row
  //             const id = uuidv4();

  //             // Handle the case where row is empty
  //             if (row.length === 0) {
  //               return tblShopItemsJSON?.header.reduce(
  //                 (acc: any, key: any) => {
  //                   acc[key] = "";
  //                   return acc;
  //                 },
  //                 { id }
  //               ); // Include unique id in the object
  //             }

  //             return row.reduce(
  //               (acc: any, value: any, index: any) => {
  //                 acc[tblShopItemsJSON?.header[index]] = value;
  //                 return acc;
  //               },
  //               { id }
  //             ); // Include unique id in the object
  //           });



  //           setShopItemTable({
  //             shopEstimate: data,
  //             headers: tblShopItemsJSON?.header,
  //             rows: tblShopItemRows,
  //           });

  //           // setTotalRepairersAmount(data?.totalAmount);
  //           // setTotalLaborAmount(data?.totalLabor);


  //         })
  //         .catch((err: any) => {
  //           setShopItemTable({
  //             shopEstimate: null,
  //             headers: null,
  //             rows: null,
  //           });

  //           console.error(err);
  //           let errorMessage = "";
  //           if (err?.response?.status === 401){
  //             errorMessage = err?.response?.data;

  //           }
  //           else if (err?.response?.status === 400){
  //             errorMessage = err?.response?.data;

  //           }

  //         });
  //     })();
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const patchStatusAndUser = async (docIdFromDe: Number) => {
    try {
      // 1. Fetch Head CLEC Users
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/accounts/all`,
        {
          params: { rolename: 'Head Clec' },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const headClecUsers = response.data.data;
      if (!headClecUsers || headClecUsers.length === 0) {
        throw new Error('No Head Clec users found.');
      }

      // 2. Determine the Head CLEC User ID
      const headClecUser =
        globalData?.role === 'Head Clec' ? 89 : headClecUsers[0].id;

      // 3. Determine Document Status
      const docStatus = mainState?.selections?.status?.value;
      if (typeof docStatus !== 'number') {
        throw new Error('Invalid document status.');
      }

      // 4. First PATCH Request: Update Dedetails

      const headclecApprove = globalData?.role === 'Head Clec' ? 1 : 0;
      const headclecStatus = globalData?.role === 'Head Clec' ? 3 : 9;

      axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${transId}`,
        {
          status: 25,
          headclecApprove: headclecApprove,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
        {
          status: headclecStatus,
          userId: headClecUser,
        },
        {
          params: {
            transId: transId,
            id: docIdFromDe,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );


      // 6. Navigate After Successful Updates
      navigate('/damage-evaluation');
    } catch (error: any) {
      console.error('Error in patchStatusAndUser:', error);

      // Optionally, display an error message to the user
      // For example, using a state variable to show a notification
      // setErrorMessage(error.message || 'An unexpected error occurred.');
    }
  };


  // State to handle snackbar visibility
  // const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  // const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  const handleSubmit = async () => {
    const deDetailsResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${transId}`,
      {
        method: "GET",
      
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${globalData?.accessToken}`
        },

      }
    );


    const deDetailsData = await deDetailsResponse.json();
    const status = deDetailsData?.status;

    const deTaskResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/${transId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${globalData?.accessToken}`
        },

      }
    );
    const deTaskData = await deTaskResponse.json();
    const docId = deTaskData?.data?.id;



    setSubmitting(true);
    if (
      mainState?.selections?.status?.value === 4 &&
      globalData?.role === "Clec" && status === 3
    ) {
      setSubmitting(false);
      patchStatusAndUser(docId);

    } else if (
      mainState?.selections?.status?.value === 9 &&
      globalData?.role === "Clec" && status === 25
    ) {

      setSubmitting(false);



      // handleExternalCall();
      // const endpoint = `/api/acap/email/ca`
      // const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };

      // axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      //   email: emailRequest.email,
      //   claimNo: emailRequest.claimNo,
      // })
      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 9 &&
      globalData?.role === "Head Clec"
    ) {
      // alert('pasok kay head clec')
      setSubmitting(false);
      // patchStatusAndUser(docId);
      handleExternalCall();
      try {
        // 1. Fetch Head CLEC Users
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 9,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: docId,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );


      } catch (error: any) {
        console.error('Error in patchStatusAndUser:', error);
      }
      const endpoint = `/api/acap/email/ca`
      const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };

      axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
        email: emailRequest.email,
        claimNo: emailRequest.claimNo,
      })
      navigate('/damage-evaluation');
    } else {
      // Proceed with the existing logic
      setSubmitting(false);
      handleExternalCall();

      await apiSubmitEmail(
        `/api/acap/damage-evaluation/task/approve?transId=${transId}`
      );

      navigate("/damage-evaluation");
    }

  };

  const assignTaskUser = async () => {
    if (assignedUser === null || assignedUser === undefined) {
      alert("Select User before submit");
      return;
    }

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/assign`,
      {
        transId: transId,
        deId: deId,
        userId: assignedUser?.id,
        assignor: globalData.id
      },
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    ).then((res: any) => {

      alert(res?.data?.message)
    }).catch((err: any) => {
      alert(err?.response?.data?.message)
      console.error(err?.response?.data?.message);
    })
  }

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    setUserData(userData);
    fetchDeTaskData();
    // fetchShopItems();
  }, []);



  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography align="center">{snackbarMessage}</Typography>
        </Alert>
      </Snackbar> */}
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card sx={{ my: 4 }}>
        <CardContent className="tp-mlr-50px tp-mtb-20px">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Box>
                  <Typography variant="h4" className="tp-text-header-primary">
                    Damage Evaluation
                  </Typography>
                  <Typography
                    variant="body1"
                    display="block"
                    className="tp-text-input-sub"
                  >
                    Note: Please check all Required Fields with (
                    <span className="tp-text-required">*</span>) before you submit.
                  </Typography>
                </Box>
                <Stack direction={"row"} spacing={1}>
                  {["Clec", "Jr Clec", "Canvasser"].includes(globalData.role) && ((<>
                    <IconButton
                      aria-label="delete"
                      id="basic-button"
                      aria-controls={openTaskHistoryAnchor ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openTaskHistoryAnchor ? 'true' : undefined}
                      onClick={handleOpenTaskHistoryClick}
                    >
                      <InfoIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openTaskHistoryAnchor}
                      onClose={handleOpenTaskHistoryClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {openTasks && openTasks.map((item: IOpenTask, idx: number) => (
                        <MenuItem key={idx} onClick={handleOpenTaskHistoryClose}>{`Task #${item.id} is assigned to ${item.name} (${item.role})`}</MenuItem>
                      ))}
                    </Menu>
                    <FormControl>
                      <InputLabel>Assign User</InputLabel>
                      <MuiSelect
                        value={assignedUser?.id?.toString() || ""}
                        onChange={handleImsiAdminUserChange}
                        label="Assign User"
                        sx={{ maxWidth: 300 }}
                      >
                        {users && users.map((item: IUserDetail) => (
                          <MenuItem key={item.id} value={item.id.toString()}>
                            {`${item.userName} - ${item.roleName}`}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                    <Button className="tp-custom-button-primary" variant="contained" onClick={assignTaskUser}>Assign</Button>
                  </>))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Peril Type:{" "}
                <span className="tp-text-default">Own Damage</span>
              </Typography>
            </Grid>
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Claim No: <span className="tp-text-default">{claimNo}</span>
              </Typography>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          {/* <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9}>
              <Typography variant="h5" className="tp-text-header-primary">
                Initial Estimate
              </Typography>
              <br />

              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Clec Estimate: &ensp;</span>
              </Typography>

              <UploadClec
                claimNo={claimNo}
                onUpdate={handleUpdate}
                transId={transId}
                onBrandModelUpdate={onBrandModelUpdate}
              />
              <br />
              <br />
              <div className="tp-text-bold">
                Initial Total Parts Amount: ₱ {formatNumber(initialTotalParts)}
              </div>
              <div className="tp-text-bold">
                Initial Total Labor Amount: ₱ {formatNumber(initialTotalLabor)}
              </div>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br /> */}
          <br />

          <Grid container spacing={2}>
            {/* Main Grid Item */}
            <Grid item xs={8} sm={8} md={8}>
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                ACAP - {transId || deDetails?.transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Date Received: &ensp;</span>
                2024-09-16
              </Typography>
              <br />
              <br />

              {/* Insured Information */}
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Insured:
                  </Typography>
                </Grid>
                <Grid item md={6} style={{ textAlign: 'right' }}>
                  <Typography variant="body1" className="tp-text-primary">
                    {deDetails?.insuredName}
                  </Typography>
                </Grid>

              </Grid>

              <hr />

              {/* Policy Information */}
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Policy No.:
                  </Typography>
                </Grid>
                <Grid item md={6} style={{ textAlign: 'right' }}>
                  <Typography variant="body1" className="tp-text-primary">
                    {deDetails?.policyNo}
                  </Typography>
                </Grid>
              </Grid>
              <hr />

              {/* Conditionally Rendered Components */}
              {shopEstimate && (
                <>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Typography variant="body1" className="tp-text-bold">
                        Venodr Name:
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        size="small"
                        disablePortal
                        freeSolo
                        value={shopEstimate?.data?.vendorName}
                        options={repairShops}
                        renderInput={(params) => (
                          <TextField {...params} />
                        )}
                        onInputChange={(_event: any, value: string, reason: string) => {

                          setMainState((prev: any) => ({
                            ...prev,
                            data: { ...prev.data, vendorName: value }
                          }))
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>

                      <Button
                        // disabled={isLoading}
                        onClick={() => {
                          handleShowRepairShopDetails();
                        }}
                        variant="contained"
                        size="medium"
                        color="error"
                        fullWidth
                      >
                        See Details
                      </Button>
                    </Grid>
                  </Grid>
                  <hr />
                  <br />
                  {/* {shopEstimate?.data?.customerName?.toLowerCase().includes('jimmy') ? (
                    <EstimationTableJimmy transID={transId} />
                  ) : (
                    <EstimationTable transID={transId} />
                  )} */}


                  {/* <TextField
                    label="Total Parts Amount"
                    variant="outlined"
                    type="text"
                    value={formatNumber(totalRepairersAmount)}
                    onChange={(e) =>
                      handleNumberChange(e, setTotalRepairersAmount)
                    }
                    fullWidth
                    margin="normal"
                  /> */}

                  {/* Total Labor Amount */}
                  {/* <TextField
                    label="Total Labor Amount"
                    variant="outlined"
                    type="text"
                    value={formatNumber(totalLaborAmount)}
                    onChange={(e) => handleNumberChange(e, setTotalLaborAmount)}
                    fullWidth
                    margin="normal"
                  /> */}
                </>
              )}
            </Grid>

            {/* Conditionally Rendered Document Viewer */}
            {shopEstimate && (
              <Grid item xs={4} sm={4} md={4}>
                <DocumentViewer documentUrl={shopEstimate.data.url} />
              </Grid>
            )}
          </Grid>



          {
            // #endregion Estimation Table
          }

          <hr className="tp-border-hr_primary" />
          <br />
          {
            // #region ClientTransaction
          }
          {/* Client Transaction */}
          <CanvasserClientTransaction
            transID={transId}
            data={mainState?.data}
            vatValue={vatValue}
            perilType={perilType}
            dol={dol}
            lossReserveParts={lossReserveParts}
            setLossReserveParts={setLossReserveParts}
            lossReserveLabor={lossReserveLabor}
            setLossReserveLabor={setLossReserveLabor}
            initialLaborAmount={initialTotalLabor}
            initialPartsAmount={initialTotalParts}
            updateGrossTotal={updateGrossTotal}
            onLaborDataChange={handleLaborDataChange}
            updateTotalDeductible={updateTotalDeductible}
            updateCart={handleUpdateCart}
            onTotalDepreciationChange={handleTotalDepreciationChange}
            onTotalOfferAmountChange={handleTotalOfferAmountChange}
            totalRepairersAmount={totalRepairersAmount}
            totalLaborAmount={totalLaborAmount}
            setTotalLaborAmount={setTotalLaborAmount}
            selectedBrand={selectedBrand}
            selectedModel={selectedModel}
            mainState={mainState}
            setMainState={setMainState}
            onIsTotalLossChange={handleIsTotalLossChange}
            fmv={fmv} setFmv={setFmv}
            salvage={salvage} setSalvage={setSalvage}
            policyNo={''}
            crDate={''}
            cartCTrans={cartCTrans}
            setCartCTrans={setCartCTrans}
            vatCTrans={vatCTrans}
            setVatCTrans={setVatCTrans}
            totalDiscountCTrans={totalDiscountCTrans}
            setTotalDiscountCTrans={setTotalDiscountCTrans}
          />
          <br />
          <DocumentTable />
          <TaskRemarks />
          {
            // #endregion For Processor
          }
          <hr className="tp-border-hr_primary" />
          <br />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>

          </Box>
          <Modal
            open={repairShopDetails.isShow}
            onClose={() =>
              setRepairShopDetails((prev: any) => ({
                data: [],
                isShow: false,
              }))
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                p: 4,
              }}
            >
              {repairShopDetails?.data.length > 0 ? (
                <React.Fragment>
                  <Typography variant="h6" className="tp-text-header-primary">
                    Negotiated Agreed Rates
                  </Typography>
                  <hr />
                  <List>
                    {repairShopDetails?.data.map((item: any) => (
                      <ListItem>{item}</ListItem>
                    ))}
                  </List>
                </React.Fragment>
              ) : (
                <Typography>
                  No details, make sure that vendor name is valid
                </Typography>
              )}
            </Box>
          </Modal>
          <br />
        </CardContent>
      </Card >
    </Container >
  );
}
