import React, { useState, useEffect } from "react";
import { FormControl, OutlinedInput } from "@mui/material";
import Typography from "@mui/material/Typography";
import Select from "react-select";

export default function ForProcessor({
  mainState,
  isDisable,
  handleSelectChange,
  status,
  transId,
  isTotalLoss,
  isResend,
  onRaEmailValueChange,
}: any) {
  const [raEmail, setRaEmail] = useState<string | undefined>(
    mainState?.selections?.raEmail || ''
  );
  const [selectedStatus, setSelectedStatus] = useState<any>(undefined);

  const handleChangeRaEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setRaEmail(newValue);
    onRaEmailValueChange?.(newValue);
  };

  // Update the local selectedStatus when the API status or available options change.
  useEffect(() => {
    // Convert the API status to a number (in case it comes as a string)
    const defaultOption =
      mainState?.statusOptions.find(
        (option: { value: number; label: string }) =>
          option.value === Number(status)
      ) || mainState?.statusOptions[0];
    setSelectedStatus(defaultOption);
    // console.log('defaultOption', defaultOption);
  }, [status, mainState?.statusOptions]);

  // When user selects an option, update local state and call parent's handler.
  const onChange = (option: any, actionMeta: any) => {
    setSelectedStatus(option);
    handleSelectChange(option, actionMeta);
    // console.log('option', option); 
  };

  // console.log('status', status);

  return (
    <div>
      <Typography variant="h6" className="tp-text-header-primary">
        For Processor
      </Typography>
      <hr className="tp-border-hr_primary" />

      {isResend ? (
        <FormControl variant="outlined" fullWidth className="tp-mb-20px">
          <Typography variant="body1" className="tp-text-bold">
            CA Email <span className="tp-text-required">*</span>
          </Typography>
          <OutlinedInput
            id="raEmail"
            value={raEmail}
            onChange={handleChangeRaEmail}
            disabled={isDisable}
            placeholder="Enter CA Email"
          />
        </FormControl>
      ) : (
        <FormControl variant="outlined" fullWidth className="tp-mb-20px">
          <Typography variant="body1" className="tp-text-bold">
            Status <span className="tp-text-required">*</span>
          </Typography>
          <Select
            id="status"
            placeholder="Please select status here..."
            value={selectedStatus}
            isDisabled={isDisable}
            onChange={onChange}
            options={(mainState?.statusOptions || []).filter(
              (option: { value: number; label: string }) => option.value !== 26
            )}
          />
        </FormControl>
      )}
    </div>
  );
}
